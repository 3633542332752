/* eslint-disable camelcase */
import {
  LIST_PENDING,
  LIST_FULFILLED,
  LIST_REJECTED,
  CHANGE_LIST_SORT,
  CHANGE_ACTIVE_PAGE,
  REMOVE_COMPANY_LOCATION_IMAGE,
} from 'actions/ListActions';

import {
  INVOICE_MARK_REFUNDED_PENDING,
  INVOICE_MARK_DENIED_TO_PAY_PENDING,
  INVOICE_MARK_PAID_AMOUNT_PENDING,
  INVOICE_SENT_REMINDERS_PENDING,
  INVOICE_MARK_REFUNDED_FULFILLED,
  INVOICE_MARK_DENIED_TO_PAY_FULFILLED,
  INVOICE_MARK_PAID_AMOUNT_FULFILLED,
  INVOICE_SENT_REMINDERS_FULFILLED,
} from 'actions/InvoiceActions';

import { FORM_FINISHED } from 'actions/FormStatusActions';
import { DELETER_FULFILLED } from 'actions/DeleterActions';
import { UPLOAD_FILE_FINISHED } from 'actions/UploadActions';
import { SOCKET_EMIT, SOCKET_EVENT } from 'actions/SocketActions';
import { CONVERSATION_ENDED } from 'actions/ChatterActions';
import { WEBRTCCALL_ENDED } from 'actions/WebRTCCallActions';
import _, { find as _find, forEach as _forEach, findIndex as _findIndex, cloneDeep as _cloneDeep } from 'lodash';

import subscriptionPricesReducer from './subscriptionPrices';
import subscriptionsReducer from './subscriptions';

export const standardState = {
  pending: true,
  hasError: false,
  errors: [],
  activePage: 1,
  resPerPage: 20,
  searchTerm: '',
  sort: 'relevance',
  dir: 'desc',
  data: {
    list: [],
    count: 0,
  },
  complete: false,
  exeThen: '',
  changedR: false,
  campaignSavingR: false,
  linkDataR: '',
  pendingArchive: false,
  pendingUI: false,
  messageUI: '',
  pendingRI: false,
  errorMessages: '',
  pendingCopyItem: false,
  copiedItemId: '',
  pendingUp: false,
  updateNotification: undefined,
  pendingProductGroup: false,
};

/*
const standardStateForCampaignsSpecial = {
  pending: false,
  hasError: false,
  errors: [],
  activePage: 1,
  resPerPage: 20,
  searchTerm: '',
  sort: 'relevance',
  dir: 'desc',
  data: {
    list: [],
    count: 0,
  },
  complete: false,
  exeThen: '',
  changedR: false,
  campaignSavingR: false,
  linkDataR: '',
  pendingArchive: false,
  pendingUI: false,
  messageUI: '',
  pendingRI: false,
  errorMessages: '',
};
*/

const initialState = {
  items: { ...standardState },
  accountSearchItems: { ...standardState },
  clients: { ...standardState },
  clientUsers: { ...standardState },
  clientTemplates: { ...standardState },
  users: { ...standardState },
  assignees: { ...standardState },
  accountSearchClients: { ...standardState },
  holdingCompanies: { ...standardState },
  payments: { ...standardState, sort: 'dueDate', dir: 'asc' },
  questionnaires: { ...standardState },
  products: { ...standardState },
  templates: { ...standardState },
  roles: { ...standardState, complete: true },
  rolePrices: { ...standardState, complete: true },
  dynamicDataDefinitions: { ...standardState, complete: true },
  cars: { ...standardState },
  carOverview: { ...standardState, resPerPage: 50 },
  clientDocuments: { ...standardState, sort: 'cdate' },
  quotes: { ...standardState },
  invoices: { ...standardState, sort: 'cdate', dir: 'desc' },
  provisionService: { ...standardState, sort: 'cdate', dir: 'desc' },
  commissionServiceInvoices: { ...standardState, sort: 'cdate', dir: 'desc' },
  clientInvoices: { ...standardState, sort: 'cdate', dir: 'desc' },
  clientQuotes: { ...standardState, sort: 'cdate', dir: 'desc' },
  orderInvoices: { ...standardState, sort: 'cdate', dir: 'desc' },
  docTypes: { ...standardState },
  teams: { ...standardState },
  brands: { ...standardState },
  categories: { ...standardState, resPerPage: 999 },
  categoryAttributes: { ...standardState, resPerPage: 50 },
  attributes: { ...standardState },
  attributeGroups: { ...standardState },
  attributeCategories: { ...standardState },
  itemVariants: { ...standardState },
  leads: { ...standardState, resPerPage: 100, sort: 'cdate', dir: 'desc' },
  leadsAppDash: { ...standardState },
  leadsPastDeadline: { ...standardState },
  leadDocuments: { ...standardState, sort: 'cdate' },
  orderDocuments: { ...standardState, sort: 'cdate' },
  pipelines: { ...standardState },
  languages: { ...standardState },
  orders: { ...standardState, sort: 'cdate', dir: 'desc' },
  carServiceOrders: { ...standardState, sort: 'cdate', dir: 'desc' },
  pages: { ...standardState },
  cmsMedia: { ...standardState, sort: 'cdate' },
  cmsElements: { ...standardState },
  summary: { ...standardState, resPerPage: 24, sort: 'price', dir: 'asc' },
  summaryFacets: { ...standardState },
  summaryAttributes: { ...standardState },
  summaryModels: { ...standardState },
  models: { ...standardState },
  modelGroups: { ...standardState },
  modelAssets: { ...standardState },
  apiDocGroups: { ...standardState },
  apiDocGroupDetails: { ...standardState },
  handoverAttributes: { ...standardState },
  recognitionTags: { ...standardState },
  dataPush: { ...standardState },
  whiteLabel: { ...standardState },
  whiteLabelPlugin: { ...standardState },
  breadcrumbs: { ...standardState },
  whiteLabelBrands: { ...standardState },
  reservation: { ...standardState, sort: 'rentPrice', dir: 'asc' },
  reservationFacets: { ...standardState },
  carRentLocations: { ...standardState },
  carServiceLocations: { ...standardState },
  companyLocations: { ...standardState },
  salesmen: { ...standardState },
  leadCatchers: { ...standardState },
  vklBusinesses: { ...standardState },
  salesmanList: { ...standardState },
  chartList: { ...standardState },
  vklFacets: { ...standardState },
  filtersets: { ...standardState },
  serviceRequestList: { ...standardState },
  myWhiteLabelProject: { ...standardState },
  sepaAgreements: { ...standardState },
  socialMediaLoginSecrets: { ...standardState },
  pluginActivationRequests: { ...standardState },
  pluginTerminationRequests: { ...standardState },
  chatUsers: { ...standardState, complete: true },
  externalAccounts: { ...standardState },
  externalAccountsStats: { ...standardState },
  leadPerMailRules: { ...standardState },
  stripePaymentMethods: { ...standardState },
  stripeAccounts: { ...standardState },
  orderCancellationReasons: { ...standardState },
  activityList: { ...standardState },
  carList: { ...standardState },
  failedRefunds: { ...standardState, sort: 'cdate', dir: 'desc' },
  similiarLeads: { ...standardState },
  communities: { ...standardState, sort: 'cdate', dir: 'desc' },
  clientCars: { ...standardState },
  leadTags: { ...standardState },
  gudatSolutionDaServices: { ...standardState },
  gudatSolutionDaResources: { ...standardState },
  gudatSolutionDaFreeSlots: { ...standardState },
  carServiceServices: { ...standardState },
  carServiceResources: { ...standardState },
  carServiceFreeSlots: { ...standardState },
  carServices: { ...standardState },
  carServiceStations: { ...standardState },
  cmsTemplates: { ...standardState },
  publicCmsTemplates: { ...standardState },
  pageHistory: { ...standardState, sort: 'cdate', dir: 'desc' },
  campaigns: { ...standardState, sort: 'cdate', dir: 'desc' },
  // campaignsSpecial: { ...standardStateForCampaignsSpecial, sort: 'cdate', dir: 'desc' },
  editions: { ...standardState, sort: 'cdate', dir: 'desc' },
  cmsTemplateHistory: { ...standardState, sort: 'cdate', dir: 'desc' },
  newVendorOrders: { ...standardState, sort: 'cdate', dir: 'desc' },
  stylesVariables: { ...standardState },
  docTemplates: { ...standardState, sort: 'cdate', dir: 'desc' },
  quoteTemplates: { ...standardState, sort: 'cdate', dir: 'desc' },
  autoresponses: { ...standardState },
  productTemplates: { ...standardState, sort: 'name', dir: 'desc' },
  contentBlocks: { ...standardState },
  teamMembers: { ...standardState },
  customerChats: { ...standardState, complete: true },
  whiteLabelSubscriptionPlan: { ...standardState },
  whiteLabelMainPlugin: { ...standardState },
  dynamicElements: { ...standardState },
  mainMenuVersions: { ...standardState },
  leadTaggers: { ...standardState },
  coupons: { ...standardState },
  leadAssignmentStrategies: { ...standardState },
  leadSources: { ...standardState },
  leadEscalationRules: { ...standardState },
  userImport: { ...standardState },
  userRemove: { ...standardState },
  sub_clients: { ...standardState },
  sub_companies: { ...standardState },
  sub_items: { ...standardState },
  sub_cos_and_cls: { ...standardState },
  subscriptions: { ...standardState },
  subscriptionPrices: { ...standardState },
  subscriptionsForLoggedInUser: { ...standardState },
  updates: { ...standardState },
  productGroups: { ...standardState, sort: 'cdate', dir: 'desc' },
  couponUsages: { ...standardState },
  couponBalance: { pending: false, value: undefined, hasError: false },
  allowedDomains: { ...standardState, sort: 'cdate', dir: 'desc' },
};

export default function list(state = initialState, action) {
  switch (action.type) {
    case 'GET_UPDATE_NOTIFICATION_PENDING': {
      const { updates } = state;
      return { ...state, updates: { ...updates, pendingUp: true } };
    }

    case 'GET_UPDATE_NOTIFICATION_FULFILLED': {
      const newState = { ...state };
      const { updates } = state;
      let _updateNotification = [];
      _forEach(action.payload?.list, update => {
        const currentDate = new Date();
        const dateFromAsString = update.date_from;
        const dateToAsString = update.date_to;
        const dateFromToCompare = new Date(dateFromAsString);
        const dateToToCompare = new Date(dateToAsString);
        if (currentDate.getTime() >= dateFromToCompare.getTime() && currentDate.getTime() < dateToToCompare.getTime()) {
          _updateNotification.push(update.description);
        }
      });

      newState.updates = {
        ...updates,
        data: { ...updates.data, list: action.payload.list },
        updateNotification: _updateNotification,
        pendingUp: false,
      };
      return newState;
    }

    case 'NEW_UPDATE_NOTIFICATION_PENDING': {
      const { updates } = state;
      return { ...state, updates: { ...updates, pendingUp: true } };
    }

    case 'NEW_UPDATE_NOTIFICATION_FULFILLED': {
      const { updates: srcUpdates } = state;
      const { data: srcData } = srcUpdates;
      const { list: srcList } = srcData;
      const { payload } = action;

      const list = [...srcList, payload];

      return {
        ...state,
        updates: { ...srcUpdates, data: { ...srcData, list }, pendingUp: false },
      };
    }

    case 'EDIT_UPDATE_NOTIFICATION_PENDING': {
      const { updates } = state;
      return { ...state, updates: { ...updates, pendingUp: true } };
    }

    case 'EDIT_UPDATE_NOTIFICATION_FULFILLED': {
      const { updates: srcUpdates } = state;
      const { data: srcData } = srcUpdates;
      const { list: srcList } = srcData;
      const { payload } = action;
      const { id: replaceId } = payload;

      const index = srcList.findIndex(({ id }) => id === replaceId);
      const list = [...srcList.slice(0, index), payload, ...srcList.slice(index + 1)];

      return { ...state, updates: { ...srcUpdates, data: { ...srcData, list }, pendingUp: false } };
    }

    case 'DELETE_UPDATE_NOTIFICATION_PENDING': {
      const { updates } = state;
      return { ...state, updates: { ...updates, pendingUp: true } };
    }

    case 'DELETE_UPDATE_NOTIFICATION_FULFILLED': {
      const { updates: srcUpdates } = state;
      const { data: srcData } = srcUpdates;
      const { list: srcList } = srcData;
      const { payload } = action;

      const list = srcList.filter(({ id }) => id !== payload);

      return {
        ...state,
        updates: { ...srcUpdates, list, pendingUp: false },
      };
    }

    case 'SET_NEW_PRODUCT_GROUP_PENDING': {
      const { productGroups } = state;
      return { ...state, productGroups: { ...productGroups, pendingProductGroup: true } };
    }

    case 'SET_NEW_PRODUCT_GROUP_FULFILLED': {
      const { productGroups: srcProductGroups } = state;
      const { data: srcData } = srcProductGroups;
      const { list: srcList } = srcData;
      const { payload } = action;

      const list = [...srcList, payload];

      return {
        ...state,
        productGroups: { ...srcProductGroups, data: { ...srcData, list }, pendingProductGroup: false },
      };
    }

    case 'EDIT_PRODUCT_GROUP_PENDING': {
      const { productGroups } = state;
      return { ...state, productGroups: { ...productGroups, pendingProductGroup: true } };
    }

    case 'EDIT_PRODUCT_GROUP_FULFILLED': {
      const { productGroups: srcProductGroups } = state;
      const { data: srcData } = srcProductGroups;
      const { list: srcList } = srcData;
      const { payload } = action;
      const { id: replaceId } = payload;

      const index = srcList.findIndex(({ id }) => id === replaceId);
      const list = [...srcList.slice(0, index), payload, ...srcList.slice(index + 1)];

      return {
        ...state,
        productGroups: { ...srcProductGroups, data: { ...srcData, list }, pendingProductGroup: false },
      };
    }

    case 'DELETE_PRODUCT_GROUP_PENDING': {
      const { productGroups } = state;
      return { ...state, productGroups: { ...productGroups, pendingProductGroup: true } };
    }

    case 'DELETE_PRODUCT_GROUP_FULFILLED': {
      const { productGroups: srcProductGroups } = state;
      const { data: srcData } = srcProductGroups;
      const { list: srcList } = srcData;
      const { payload } = action;
      const { id: deleteId } = payload;

      const list = srcList.filter(({ id }) => id !== deleteId);

      return {
        ...state,
        productGroups: { ...srcProductGroups, data: { ...srcData, list }, pendingProductGroup: false },
      };
    }

    case 'IMPORT_USERS_PENDING': {
      const newState = { ...state };
      const message = 'pending';
      newState.userImport = {
        ...state.userImport,
        pendingUI: true,
        messageUI: message,
      };
      return newState;
    }

    case 'IMPORT_USERS_FULFILLED': {
      const newState = { ...state };
      const message = 'fulfilled';
      newState.userImport = {
        ...state.userImport,
        pendingUI: false,
        messageUI: message,
        errorMessages: action.payload,
      };
      return newState;
    }

    case 'IMPORT_USERS_REJECTED': {
      const newState = { ...state };
      const message = 'rejected';
      newState.userImport = {
        ...state.userImport,
        pendingUI: false,
        messageUI: message,
        errorMessages: action.payload,
      };
      return newState;
    }

    case 'REVERSE_USERS_PENDING': {
      const newState = { ...state };
      const message = 'pendingRE';
      newState.userImport = {
        ...state.userImport,
        pendingRI: true,
        messageUI: message,
      };
      return newState;
    }

    case 'REVERSE_USERS_FULFILLED': {
      const newState = { ...state };
      const message = 'fulfilledRE';
      newState.userImport = {
        ...state.userImport,
        pendingRI: false,
        messageUI: message,
        errorMessages: action.payload,
      };
      return newState;
    }

    case 'REVERSE_USERS_REJECTED': {
      const newState = { ...state };
      const message = 'rejectedRE';
      newState.userImport = {
        ...state.userImport,
        pendingRI: false,
        messageUI: message,
        errorMessages: action.payload,
      };
      return newState;
    }

    case 'REMOVE_USERS_PENDING': {
      const newState = { ...state };
      const message = 'pending';
      newState.userRemove = {
        ...state.userRemove,
        pendingUI: true,
        messageUI: message,
      };
      return newState;
    }

    case 'REMOVE_USERS_FULFILLED': {
      const newState = { ...state };
      const message = 'fulfilled';
      newState.userRemove = {
        ...state.userRemove,
        pendingUI: false,
        messageUI: message,
        errorMessages: action.payload,
      };
      return newState;
    }

    case 'REMOVE_USERS_REJECTED': {
      const newState = { ...state };
      const message = 'rejected';
      newState.userRemove = {
        ...state.userRemove,
        pendingUI: false,
        messageUI: message,
        errorMessages: action.payload,
      };
      return newState;
    }

    case 'ARCHIVE_CAMPAIGN_PENDING': {
      const { campaigns } = state;
      return { ...state, campaigns: { ...campaigns, pendingArchive: true } };
    }

    case 'ARCHIVE_CAMPAIGN_FULFILLED': {
      const newState = { ...state };
      const { campaigns } = state;
      const archivedId = action.payload;
      campaigns.data.list = _.filter(campaigns.data.list, x => x.id !== archivedId);

      newState.campaigns = {
        ...state.campaigns,
        data: { ...state.campaigns.data, list: state.campaigns.data.list },
        pendingArchive: false,
      };
      return newState;
    }
    case 'SET_VARIABLES_R': {
      const { campaigns } = state;
      const _campaigns = _cloneDeep(campaigns);
      if (action.payload === 'startForceUpdateReset' || action.payload === 'startRenewListAndCloseSettingsReset') {
        _campaigns.exeThen = '';
      } else if (action.payload === 'campaignsavingtrue') {
        _campaigns.campaignSavingR = true;
      } else if (action.payload === 'campaignsavingfalse') {
        _campaigns.campaignSavingR = false;
      } else if (action.payload === 'changedtrue') {
        _campaigns.changedR = true;
      } else if (action.payload === 'startHistoryReset') {
        _campaigns.exeThen = '';
      } else if (action.payload === 'pendingToFalse') {
        _campaigns.pending = false;
      }

      return { ...state, campaigns: _campaigns };
    }

    case 'SAVE_SETTINGS_NEW_FULFILLED': {
      const { payload } = action;
      const { campaigns: srcCampaigns } = state;
      const { data: srcData } = srcCampaigns;
      const { list: srcList } = srcData;

      const campaigns = {
        ...srcCampaigns,
        campaignSavingR: false,
        changedR: false,
        exeThen: 'startHistory',
        linkDataR: action.payload.id,
        data: { ...srcData, list: [...srcList, payload] },
      };

      return { ...state, campaigns };
    }

    case 'MARK_RECENT_EDITION_FULFILLED': {
      const { campaigns } = state;
      const { data } = campaigns;
      const { m_edition: srcMEdition, r_edition: srcREdition } = data;
      const { payload } = action;
      const { marked: isMarked, id: markedId } = payload;

      let m_edition;
      if (isMarked) {
        // add
        m_edition = [...srcMEdition, payload];
      } else {
        // remove
        m_edition = srcMEdition.filter(({ id }) => id !== markedId);
      }

      const index = srcREdition.findIndex(({ id }) => id === markedId);
      const r_edition = [...srcREdition.slice(0, index), payload, ...srcREdition.slice(index + 1)];

      return { ...state, campaigns: { ...campaigns, data: { ...data, m_edition, r_edition } } };
    }

    case 'UNMARK_MARKED_EDITION_FULFILLED': {
      const { campaigns } = state;
      const { data } = campaigns;
      const { m_edition: srcMarkedEdition } = data;
      const { payload } = action;
      const { id: markedId } = payload;

      const m_edition = srcMarkedEdition.filter(({ id }) => id !== markedId);

      return { ...state, campaigns: { ...campaigns, data: { ...data, m_edition } } };
    }

    case 'SAVE_SETTINGS_EDIT_FULFILLED': {
      const { campaigns: srcCampaigns } = state;
      const { data: srcData } = srcCampaigns;
      const { list: srcList } = srcData;
      const { payload } = action;
      const { id: replaceId } = payload;

      const index = srcList.findIndex(({ id }) => id === replaceId);
      const list = [...srcList.slice(0, index), payload, ...srcList.slice(index + 1)];

      return {
        ...state,
        campaigns: {
          ...srcCampaigns,
          campaignSavingR: false,
          changedR: false,
          exeThen: 'startRenewListAndCloseSettings',
          data: { ...srcData, list },
        },
      };
    }

    case LIST_PENDING: {
      const newState = { ...state };
      const oldListState = newState[action.payload.listName];

      newState[action.payload.listName] = {
        ...oldListState,
        pending: true,
        hasError: false,
        errors: [],
        data: { list: [], count: 0 },
        ...action.payload,
      };
      return newState;
    }

    case LIST_FULFILLED: {
      const newState = { ...state };
      const oldListState = newState[action.payload.listName];

      newState[action.payload.listName] = {
        ...oldListState,
        pending: false,
        hasError: false,
        errors: [],
        data: action.payload.response.data,
      };
      return newState;
    }

    case LIST_REJECTED: {
      const newState = { ...state };
      const oldListState = newState[action.payload.listName];

      newState[action.payload.listName] = {
        ...oldListState,
        pending: false,
        hasError: true,
        errors: action.payload.response ? action.payload.response.data : {},
        data: { list: [], count: 0 },
      };
      return newState;
    }

    case CHANGE_LIST_SORT: {
      const newState = { ...state };
      const oldListState = newState[action.payload.listName];

      newState[action.payload.listName] = {
        ...oldListState,
        sort: action.payload.sort,
        dir: action.payload.dir,
      };
      return newState;
    }

    case CHANGE_ACTIVE_PAGE: {
      const newState = { ...state };
      const oldListState = newState[action.payload.listName];

      newState[action.payload.listName] = {
        ...oldListState,
        activePage: action.payload.activePage,
        resPerPage: action.payload.resPerPage,
      };
      return newState;
    }

    case 'COPY_ITEM_PENDING': {
      const { items } = state;
      return { ...state, items: { ...items, pendingCopyItem: true } };
    }

    case 'COPY_ITEM_FULFILLED': {
      const itemsArray = state.items.data.list.slice();
      itemsArray.splice(0, 0, action.payload);
      const newState = { ...state };
      newState.items = {
        ...state.items,
        data: { ...state.items.data, count: state.items.data.count + 1, list: itemsArray },
        pendingCopyItem: false,
        copiedItemId: action.payload.id,
      };
      return newState;
    }

    case FORM_FINISHED: {
      const {
        payload: { formName },
      } = action;

      // after a template has been saved, adjust the list
      if (formName === 'templateEdit') {
        const templatesArray = state.templates.data.list.slice().map(template => {
          if (template.id === action.payload.response.data.id) {
            return action.payload.response.data;
          }

          return template;
        });
        const templates = { ...state.templates.data, list: templatesArray };
        const newState = { ...state };
        newState.templates = {
          ...state.templates,
          pending: false,
          hasError: false,
          errors: [],
          data: templates,
        };
        return newState;
      }

      // after a product has been saved, adjust the list
      if (formName === 'productEdit') {
        const productsArray = state.products.data.list.slice().map(product => {
          return product.id === action.payload.response.data.id ? action.payload.response.data : product;
        });
        const newState = { ...state };
        newState.products = {
          ...state.products,
          data: { ...state.products.data, list: productsArray },
        };
        return newState;
      }
      if (formName === 'campaignEdit') {
        const campaignsArray = state.campaigns.data.list.slice().map(campaign => {
          return campaign.id === action.payload.response.data.id ? action.payload.response.data : campaign;
        });
        const newState = { ...state };
        newState.campaigns = {
          ...state.campaigns,
          data: { ...state.campaigns.data, list: campaignsArray },
        };
        return newState;
      }

      // after a lead gets saved, update the list
      if (formName === 'leadEdit') {
        const leadsArray = state.leads.data.list.slice().map(lead => {
          return lead.id === action.payload.response.data.id ? action.payload.response.data : lead;
        });
        const newState = { ...state };
        newState.leads = {
          ...state.leads,
          data: { ...state.leads.data, list: leadsArray },
        };
        return newState;
      }

      // after a lead gets moved (stage changed), adjust the list
      if (formName === 'leadChangeStage') {
        const leadsArray = state.leads.data.list.slice().map(lead => {
          return lead.id === action.payload.response.data.id ? action.payload.response.data : lead;
        });
        const newState = { ...state };
        newState.leads = {
          ...state.leads,
          data: { ...state.leads.data, list: leadsArray },
        };
        return newState;
      }

      if (formName === 'productTemplateNew') {
        const productTemplatesArray = state.productTemplates.data.list.slice();
        productTemplatesArray.splice(0, 0, action.payload.response.data);
        const templates = { ...state.templates.data, list: productTemplatesArray };
        const newState = { ...state };
        newState.productTemplates = {
          ...state.productTemplates,
          pending: false,
          hasError: false,
          errors: [],
          data: templates,
        };
        return newState;
      }
      /*
      if (formName === 'productTemplateEdit') {
        const productTemplateArray = state.productTemplates.data.list.slice().map(productTemplate => {
          if (productTemplate.id === action.payload.response.data.id) {
            return action.payload.response.data;
          }
          return productTemplate;
        });
        const templates = { ...state.productTemplates.data, list: productTemplateArray };
        const newState = { ...state };
        newState.productTemplates = {
          ...state.productTemplates,
          pending: false,
          hasError: false,
          errors: [],
          data: templates,
        };
        return newState;
      }
*/
      // after a team has been saved, adjust the list
      if (formName === 'teamNew') {
        const teamsArray = state.teams.data.list.slice();
        teamsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.teams = {
          ...state.teams,
          data: { ...state.teams.data, list: teamsArray },
        };
        return newState;
      }

      // after a team has been saved, adjust the list
      if (formName === 'teamEdit') {
        const teamsArray = state.teams.data.list.slice().map(team => {
          return team.id === action.payload.response.data.id ? action.payload.response.data : team;
        });
        const newState = { ...state };
        newState.teams = {
          ...state.teams,
          data: { ...state.teams.data, list: teamsArray },
        };
        return newState;
      }

      // after a client document has been saved, adjust the list
      if (formName === 'documentNew') {
        const clientDocumentsArray = state.clientDocuments.data.list.slice();
        clientDocumentsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.clientDocuments = {
          ...state.clientDocuments,
          data: { ...state.clientDocuments.data, list: clientDocumentsArray },
        };
        return newState;
      }

      // after a lead document has been saved, adjust the list
      if (formName === 'leadDocumentNew') {
        const leadDocumentsArray = state.leadDocuments.data.list.slice();
        leadDocumentsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.leadDocuments = {
          ...state.leadDocuments,
          data: { ...state.leadDocuments.data, list: leadDocumentsArray },
        };
        return newState;
      }

      // after a brand has been saved, adjust the list
      if (formName === 'brandNew') {
        const brandsArray = state.brands.data.list.slice();
        brandsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.brands = {
          ...state.brands,
          data: { ...state.brands.data, list: brandsArray },
        };
        return newState;
      }

      // after a brand has been saved, adjust the list
      if (formName === 'brandEdit') {
        const brandsArray = state.brands.data.list.slice().map(brand => {
          return brand.id === action.payload.response.data.id ? action.payload.response.data : brand;
        });
        const newState = { ...state };
        newState.brands = {
          ...state.brands,
          data: { ...state.brands.data, list: brandsArray },
        };
        return newState;
      }

      // after a lead catcher has been saved, adjust the list
      if (formName === 'leadCatcherNew') {
        const leadCatchersArray = state.leadCatchers.data.list.slice();
        leadCatchersArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.leadCatchers = {
          ...state.leadCatchers,
          data: { ...state.leadCatchers.data, list: leadCatchersArray },
        };
        return newState;
      }

      // after a lead catcher has been saved, adjust the list
      if (formName === 'leadCatcherEdit') {
        const leadCatchersArray = state.leadCatchers.data.list.slice().map(leadCatcher => {
          return leadCatcher.id === action.payload.response.data.id ? action.payload.response.data : leadCatcher;
        });
        const newState = { ...state };
        newState.leadCatchers = {
          ...state.leadCatchers,
          data: { ...state.leadCatchers.data, list: leadCatchersArray },
        };
        return newState;
      }

      // after a variant has been saved, adjust the list
      if (formName === 'variantNew') {
        const variantsArray = state.itemVariants.data.list.slice();
        variantsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.itemVariants = {
          ...state.itemVariants,
          data: { ...state.itemVariants.data, list: variantsArray },
        };
        return newState;
      }

      // after a document type has been saved, adjust the list
      if (formName === 'docTypeNew') {
        const docTypesArray = state.docTypes.data.list.slice();
        docTypesArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.docTypes = {
          ...state.docTypes,
          data: { ...state.docTypes.data, list: docTypesArray },
        };
        return newState;
      }

      // after a document type has been saved, adjust the list
      if (formName === 'docTypeEdit') {
        const docTypesArray = state.docTypes.data.list.slice().map(docType => {
          return docType.id === action.payload.response.data.id ? action.payload.response.data : docType;
        });
        const newState = { ...state };
        newState.docTypes = {
          ...state.docTypes,
          data: { ...state.docTypes.data, list: docTypesArray },
        };
        return newState;
      }

      // after a document type has been saved, adjust the list
      if (formName === 'allowedDomainNew') {
        const allowedDomainsArray = state.allowedDomains.data.list.slice();
        allowedDomainsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.allowedDomains = {
          ...state.allowedDomains,
          data: { ...state.allowedDomains.data, list: allowedDomainsArray },
        };
        return newState;
      }

      // after a document type has been saved, adjust the list
      if (formName === 'allowedDomainEdit') {
        const allowedDomainsArray = state.allowedDomains.data.list.slice().map(allowedDomain => {
          return allowedDomain.id === action.payload.response.data.id ? action.payload.response.data : allowedDomain;
        });
        const newState = { ...state };
        newState.allowedDomains = {
          ...state.allowedDomains,
          data: { ...state.allowedDomains.data, list: allowedDomainsArray },
        };
        return newState;
      }

      // after a data push config has been saved, adjust the list
      if (formName === 'dataPushNew') {
        const dataPushArray = state.dataPush.data.list.slice();
        dataPushArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.dataPush = {
          ...state.dataPush,
          data: { ...state.dataPush.data, list: dataPushArray },
        };
        return newState;
      }

      // after a data push config has been saved, adjust the list
      if (formName === 'dataPushEdit') {
        const dataPushArray = state.dataPush.data.list.slice().map(dataPush => {
          return dataPush.id === action.payload.response.data.id ? action.payload.response.data : dataPush;
        });
        const newState = { ...state };
        newState.dataPush = {
          ...state.dataPush,
          data: { ...state.dataPush.data, list: dataPushArray },
        };
        return newState;
      }

      // watch for order actions (which return lead data)
      if (
        [
          'orderActionApproveCarSale',
          'orderActionEnterVinNumber',
          'orderActionEnterEinNumber',
          'orderActionMarkCarAsPaid',
          'orderActionSendPaymentInstructions',
          'orderActionCancelOrder',
          'orderActionPickupInvite',
          'orderActionPickedUp',
          'orderActionCancelBooking',
          'orderActionMarkAssigneeKroschkeDocumentsSent',
          'orderActionMarkClientKroschkeDocumentsSent',
          'orderActionDefineCustomLicensePlateNumber',
        ].indexOf(formName) > -1
      ) {
        const newState = { ...state };

        const leadsArray = state.leads.data.list.slice().map(lead => {
          return lead.id === action.payload.response.data.id ? action.payload.response.data : lead;
        });
        newState.leads = {
          ...state.leads,
          data: { ...state.leads.data, list: leadsArray },
        };

        const ordersArray = state.orders.data.list.slice().map(order => {
          const leadOrder = _find(action.payload.response.data.orders, ['id', order.id]);
          return leadOrder || order;
        });
        newState.orders = {
          ...state.orders,
          data: { ...state.orders.data, list: ordersArray },
        };

        return newState;
      }

      if (formName === 'salesActivityEdit') {
        const newState = { ...state };

        const vklBusinessesArray = state.vklBusinesses.data.list.slice().map(vklBusiness => {
          return vklBusiness.id === action.payload.response.data.id ? action.payload.response.data : vklBusiness;
        });
        newState.vklBusinesses = {
          ...state.vklBusinesses,
          data: { ...state.vklBusinesses.data, list: vklBusinessesArray },
        };

        return newState;
      }

      /* else if (formName === 'serviceRequestEdit') {
        let newState = { ...state }

        const serviceRequestListArray = state.serviceRequestList.data.list.slice().map(serviceRequest => {
          return (serviceRequest.id === action.payload.response.data.id) ? action.payload.response.data : serviceRequest
        })
        newState.serviceRequestList = {
          ...state.serviceRequestList,
          data: { ...state.serviceRequestList.data, list: serviceRequestList }
        }

        return newState
      } */

      // after a app secret (used for social media login) gets created, alter the list
      if (formName === 'socialMediaLoginSecretNew') {
        const socialMediaLoginSecretsArray = state.socialMediaLoginSecrets.data.list.slice();
        socialMediaLoginSecretsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.socialMediaLoginSecrets = {
          ...state.socialMediaLoginSecrets,
          data: { ...state.socialMediaLoginSecrets.data, list: socialMediaLoginSecretsArray },
        };
        return newState;
      }

      // after a app secret (used for social media login) gets updated, alter the list
      if (formName === 'socialMediaLoginSecretEdit') {
        const newState = { ...state };

        const socialMediaLoginSecretsArray = state.socialMediaLoginSecrets.data.list
          .slice()
          .map(socialMediaLoginSecret => {
            return socialMediaLoginSecret.id === action.payload.response.data.id
              ? action.payload.response.data
              : socialMediaLoginSecret;
          });
        newState.socialMediaLoginSecrets = {
          ...state.socialMediaLoginSecrets,
          data: { ...state.socialMediaLoginSecrets.data, list: socialMediaLoginSecretsArray },
        };

        return newState;
      }

      // after a new external account is added, we need to alter the list
      if (formName === 'externalAccountNew') {
        const externalAccountsArray = state.externalAccounts.data.list.slice();
        externalAccountsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.externalAccounts = {
          ...state.externalAccounts,
          data: { ...state.externalAccounts.data, list: externalAccountsArray },
        };
        return newState;
      }

      // after an external account is edited, we need to alter the list
      if (formName === 'externalAccountEdit') {
        const newState = { ...state };

        const externalAccountsArray = state.externalAccounts.data.list.slice().map(externalAccount => {
          return externalAccount.id === action.payload.response.data.id
            ? action.payload.response.data
            : externalAccount;
        });
        newState.externalAccounts = {
          ...state.externalAccounts,
          data: { ...state.externalAccounts.data, list: externalAccountsArray },
        };

        return newState;
      }

      // after a community is joined, alter the list
      if (formName === 'communityJoin' || formName === 'communityLeave') {
        const newState = { ...state };

        const communitiesArray = state.communities.data.list.slice().map(community => {
          return community.id === action.payload.response.data.id ? action.payload.response.data : community;
        });
        newState.communities = {
          ...state.communities,
          data: { ...state.communities.data, list: communitiesArray },
        };

        return newState;
      }

      if (formName === 'addUserToTeam') {
        const teamMembersArray = state.teamMembers.data.list.slice();
        teamMembersArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.teamMembers = {
          ...state.teamMembers,
          data: { ...state.teamMembers.data, list: teamMembersArray },
        };
        return newState;
      }

      if (formName === 'clientCarNew') {
        const clientCarsArray = state.clientCars.data.list.slice();
        clientCarsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.clientCars = {
          ...state.clientCars,
          data: { ...state.clientCars.data, list: clientCarsArray },
        };
        return newState;
      }

      if (formName === 'carServiceNew') {
        const carServicesArray = state.carServices.data.list.slice();
        carServicesArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.carServices = {
          ...state.carServices,
          data: { ...state.carServices.data, list: carServicesArray },
        };
        return newState;
      }

      if (formName === 'carServiceStationNew') {
        const carServiceStationsArray = state.carServiceStations.data.list.slice();
        carServiceStationsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.carServiceStations = {
          ...state.carServiceStations,
          data: { ...state.carServiceStations.data, list: carServiceStationsArray },
        };
        return newState;
      }

      if (formName === 'quoteNew' || formName === 'convertQuoteFromTemplate') {
        const quotesArray = state.quotes.data.list.slice();
        quotesArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.quotes = {
          ...state.quotes,
          data: { ...state.quotes.data, count: state.quotes.data.count + 1, list: quotesArray },
        };
        return newState;
      }

      // add new invoice to the list
      if (formName === 'invoiceNew' || formName === 'convertQuoteToInvoice') {
        const invoicesArray = state.invoices.data.list.slice();
        invoicesArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.invoices = {
          ...state.invoices,
          data: { ...state.invoices.data, count: state.invoices.data.count + 1, list: invoicesArray },
        };

        // if the invoice was created from a quote, adjust the quote information
        if (formName === 'convertQuoteToInvoice' && action.payload.response.data.quote) {
          const quotesArray = state.quotes.data.list.slice().map(quote => {
            return quote.id === action.payload.response.data.quote
              ? { ...quote, invoice: action.payload.response.data.id }
              : quote;
          });
          newState.quotes = {
            ...state.quotes,
            data: { ...state.quotes.data, list: quotesArray },
          };
        }

        return newState;
      }

      // after a model is edited, we need to update the list
      if (formName === 'modelEdit') {
        const newState = { ...state };

        const modelsArray = state.models.data.list.slice().map(model => {
          return model.id === action.payload.response.data.id ? action.payload.response.data : model;
        });
        newState.models = {
          ...state.models,
          data: { ...state.models.data, list: modelsArray },
        };

        return newState;
      }

      // after a call or chat is initiated, we need to add the agent to the chat users list
      if (formName === 'customerVideoCallNew' || formName === 'customerChatNew') {
        const { currentAgent, currentAgentName, isAnonymousUser } = action.payload.response.data;
        const newState = { ...state };

        const chatUsersArray = state.chatUsers.data.list.slice();
        const chatUser = _find(chatUsersArray, ['id', currentAgent]);
        if (!chatUser) {
          chatUsersArray.push({
            id: currentAgent,
            first_name: currentAgentName,
            last_name: '',
            is_online: true,
            is_anonymous_user: isAnonymousUser,
            name: currentAgentName,
            role: 'employee',
          });
        }

        newState.chatUsers = {
          ...state.chatUsers,
          data: { ...state.chatUsers.data, list: chatUsersArray },
        };

        return newState;
      }

      // after a quote is edited, we need to update the list
      if (formName === 'quoteEdit') {
        const newState = { ...state };

        const quotesArray = state.quotes.data.list.slice().map(quote => {
          return quote.id === action.payload.response.data.id ? action.payload.response.data : quote;
        });
        newState.quotes = {
          ...state.quotes,
          data: { ...state.quotes.data, list: quotesArray },
        };

        return newState;
      }

      // after an invoice is edited, we need to update the list
      if (formName === 'invoiceEdit') {
        const newState = { ...state };

        const invoicesArray = state.invoices.data.list.slice().map(invoice => {
          return invoice.id === action.payload.response.data.id ? action.payload.response.data : invoice;
        });
        newState.invoices = {
          ...state.invoices,
          data: { ...state.invoices.data, list: invoicesArray },
        };

        return newState;
      }

      if (formName === 'invoiceCancel') {
        const newState = { ...state };

        const invoicesArray = state.invoices.data.list.slice().map(invoice => {
          return invoice.id === action.payload.response.data.invoice.id
            ? action.payload.response.data.invoice
            : invoice;
        });
        newState.invoices = {
          ...state.invoices,
          data: { ...state.invoices.data, list: invoicesArray },
        };

        // Credit note
        if (action.payload.response.data.creditNote) {
          invoicesArray.splice(0, 0, action.payload.response.data.creditNote);
          newState.invoices = {
            ...state.invoices,
            data: { ...state.invoices.data, count: state.invoices.data.count + 1, list: invoicesArray },
          };
        }

        return newState;
      }

      // after a category is added to an attribute
      if (formName === 'attributeCategories') {
        const categoriesArray = state.attributeCategories.data.list.slice();
        categoriesArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.attributeCategories = {
          ...state.attributeCategories,
          data: { ...state.attributeCategories.data, list: categoriesArray },
        };
        return newState;
      }

      // after a target is added, we need to add it to the list
      if (formName === 'addTarget') {
        const clientsArray = state.clients.data.list.slice();
        clientsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.clients = {
          ...state.clients,
          data: { ...state.clients.data, count: state.clients.data.count + 1, list: clientsArray },
        };
        return newState;
      }

      // after an item is added, we need to add it to the list
      if (formName === 'addItem') {
        const itemsArray = state.items.data.list.slice();
        itemsArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.items = {
          ...state.items,
          data: { ...state.items.data, count: state.items.data.count + 1, list: itemsArray },
        };
        return newState;
      }

      // after a category is added, we need to add it to the list
      if (formName === 'addCategory') {
        const categoriesArray = state.categories.data.list.slice();
        categoriesArray.splice(0, 0, action.payload.response.data);
        const newState = { ...state };
        newState.categories = {
          ...state.categories,
          data: { ...state.categories.data, count: state.categories.data.count + 1, list: categoriesArray },
        };
        return newState;
      }

      break;
    }

    case DELETER_FULFILLED: {
      const {
        payload: {
          deleterName,
          response: { data: deletedId },
        },
      } = action;

      if (deleterName === 'doc') {
        // clientDocuments list should be altered
        const clientDocumentsArray = state.clientDocuments.data.list.slice().filter(clientDocument => {
          return clientDocument.id !== deletedId;
        });

        const newState = { ...state };
        newState.clientDocuments = {
          ...state.clientDocuments,
          data: {
            ...state.clientDocuments.data,
            count: state.clientDocuments.data.count - 1,
            list: clientDocumentsArray,
          },
        };
        return newState;
      }

      if (deleterName === 'leadDoc') {
        // leadDocuments list should be altered
        const leadDocumentsArray = state.leadDocuments.data.list.slice().filter(leadDocument => {
          return leadDocument.id !== deletedId;
        });

        const newState = { ...state };
        newState.leadDocuments = {
          ...state.leadDocuments,
          data: { ...state.leadDocuments.data, count: state.leadDocuments.data.count - 1, list: leadDocumentsArray },
        };
        return newState;
      }

      if (deleterName === 'client') {
        // clients list should be altered
        const clientsArray = state.clients.data.list.slice().filter(client => {
          return client.username !== deletedId;
        });

        const newState = { ...state };
        newState.clients = {
          ...state.clients,
          data: { ...state.clients.data, count: state.clients.data.count - 1, list: clientsArray },
        };
        return newState;
      }

      if (deleterName === 'productTemplates') {
        const templatesArray = state.productTemplates.data.list.slice().filter(template => {
          return template.id !== deletedId;
        });

        const newState = { ...state };
        newState.productTemplates = {
          ...state.productTemplates,
          data: { ...state.productTemplates.data, count: state.productTemplates.data.count - 1, list: templatesArray },
        };
        return newState;
      }

      if (deleterName === 'campaign') {
        // campaigns list should be altered
        const campaignsArray = state.campaigns.data.list.slice().filter(campaign => {
          return campaign.id !== deletedId;
        });

        const newState = { ...state };
        newState.campaigns = {
          ...state.campaigns,
          data: { ...state.campaigns.data, count: state.campaigns.data.count - 1, list: campaignsArray },
        };
        return newState;
      }

      if (deleterName === 'allowedDomain') {
        // campaigns list should be altered
        const allowedDomainsArray = state.allowedDomains.data.list.slice().filter(allowedDomain => {
          return allowedDomain.id !== deletedId;
        });

        const newState = { ...state };
        newState.allowedDomains = {
          ...state.allowedDomains,
          data: { ...state.allowedDomains.data, count: state.allowedDomains.data.count - 1, list: allowedDomainsArray },
        };
        return newState;
      }

      /* if (deleterName === 'edition') {
        // campaigns list should be altered
        const campaignsArray = state.campaigns.data.list;
        let found = false;
        for (let i = 0, len = campaignsArray.length; i < len; i++) {
          campaignsArray[i].editions = campaignsArray[i].editions.slice().filter(edition => {
            if (edition.id === deletedId) {
              found = true;
            }
            return edition.id !== deletedId;
          });

          if (found) {
            break;
          }
        }
        const newState = { ...state };
        newState.campaigns = {
          ...state.campaigns,
          data: { ...state.campaigns.data, count: state.campaigns.data.count, list: campaignsArray },
        };
        return newState;
      } */

      if (deleterName === 'item') {
        // items list should be altered
        const itemsArray = state.items.data.list.slice().filter(item => {
          return item.id !== deletedId;
        });

        const newState = { ...state };
        newState.items = {
          ...state.items,
          data: { ...state.items.data, count: state.items.data.count - 1, list: itemsArray },
        };
        return newState;
      }

      if (deleterName === 'car') {
        // cars list should be altered
        const carsArray = state.cars.data.list.slice().filter(car => {
          return car.id !== deletedId;
        });

        const newState = { ...state };
        newState.cars = {
          ...state.cars,
          data: { ...state.cars.data, count: state.cars.data.count - 1, list: carsArray },
        };
        return newState;
      }

      if (deleterName === 'attribute') {
        // attributes list should be altered
        const attributesArray = state.attributes.data.list.slice().filter(attribute => {
          return attribute.id !== deletedId;
        });

        const newState = { ...state };
        newState.attributes = {
          ...state.attributes,
          data: { ...state.attributes.data, count: state.attributes.data.count - 1, list: attributesArray },
        };
        return newState;
      }

      if (deleterName === 'attributeCategories') {
        // attributes category list should be altered
        const categoriesArray = state.attributeCategories.data.list.slice().filter(category => {
          return category.id !== deletedId;
        });

        const newState = { ...state };
        newState.attributeCategories = {
          ...state.attributeCategories,
          data: {
            ...state.attributeCategories.data,
            count: state.attributeCategories.data.count - 1,
            list: categoriesArray,
          },
        };
        return newState;
      }

      if (deleterName === 'brand') {
        // brands list should be altered
        const brandsArray = state.brands.data.list.slice().filter(brand => {
          return brand.id !== deletedId;
        });

        const newState = { ...state };
        newState.brands = {
          ...state.brands,
          data: { ...state.brands.data, count: state.brands.data.count - 1, list: brandsArray },
        };
        return newState;
      }

      if (deleterName === 'page') {
        // pages list should be altered
        const pagesArray = state.pages.data.list.slice().filter(page => {
          return page.id !== deletedId;
        });

        const newState = { ...state };
        newState.pages = {
          ...state.pages,
          data: { ...state.pages.data, count: state.pages.data.count - 1, list: pagesArray },
        };
        return newState;
      }

      if (deleterName === 'category') {
        // categories list should be altered
        const categoriesArray = state.categories.data.list.slice().filter(category => {
          return category.id !== deletedId;
        });

        const newState = { ...state };
        newState.categories = {
          ...state.categories,
          data: { ...state.categories.data, count: state.categories.data.count - 1, list: categoriesArray },
        };
        return newState;
      }

      if (deleterName === 'pipeline') {
        // pipelines list should be altered
        const pipelinesArray = state.pipelines.data.list.slice().filter(pipeline => {
          return pipeline.id !== deletedId;
        });

        const newState = { ...state };
        newState.pipelines = {
          ...state.pipelines,
          data: { ...state.pipelines.data, count: state.pipelines.data.count - 1, list: pipelinesArray },
        };
        return newState;
      }

      if (deleterName === 'questionnaire') {
        // questionnaires list should be altered
        const questionnairesArray = state.questionnaires.data.list.slice().filter(questionnaire => {
          return questionnaire.id !== deletedId;
        });

        const newState = { ...state };
        newState.questionnaires = {
          ...state.questionnaires,
          data: { ...state.questionnaires.data, count: state.questionnaires.data.count - 1, list: questionnairesArray },
        };
        return newState;
      }

      if (deleterName === 'team') {
        // teams list should be altered
        const teamsArray = state.teams.data.list.slice().filter(team => {
          return team.id !== deletedId;
        });

        const newState = { ...state };
        newState.teams = {
          ...state.teams,
          data: { ...state.teams.data, count: state.teams.data.count - 1, list: teamsArray },
        };
        return newState;
      }

      if (deleterName === 'template') {
        // templates list should be altered
        const templatesArray = state.templates.data.list.slice().filter(template => {
          return template.id !== deletedId;
        });

        const newState = { ...state };
        newState.templates = {
          ...state.templates,
          data: { ...state.templates.data, count: state.templates.data.count - 1, list: templatesArray },
        };
        return newState;
      }

      if (deleterName === 'user') {
        // user list should be altered
        const usersArray = state.users.data.list.slice().filter(user => {
          return user.username !== deletedId;
        });

        const newState = { ...state };
        newState.users = {
          ...state.users,
          data: { ...state.users.data, count: state.users.data.count - 1, list: usersArray },
        };
        return newState;
      }

      if (deleterName === 'removeUserFromTeam') {
        // team member list should be altered
        const teamMembersArray = state.teamMembers.data.list.slice().filter(user => {
          return user.username !== deletedId;
        });

        const newState = { ...state };
        newState.teamMembers = {
          ...state.teamMembers,
          data: { ...state.teamMembers.data, count: state.teamMembers.data.count - 1, list: teamMembersArray },
        };
        return newState;
      }

      if (deleterName === 'variant') {
        // itemVariants list should be altered
        const variantsArray = state.itemVariants.data.list.slice().filter(variant => {
          return Array.isArray(deletedId) ? deletedId.indexOf(variant.id) === -1 : variant.id !== deletedId;
        });

        const newState = { ...state };
        newState.itemVariants = {
          ...state.itemVariants,
          data: { ...state.itemVariants.data, count: variantsArray.length ?? 0, list: variantsArray },
        };
        return newState;
      }

      if (deleterName === 'dataPush') {
        // dataPush list should be altered
        const dataPushArray = state.dataPush.data.list.slice().filter(dataPush => {
          return dataPush.id !== deletedId;
        });

        const newState = { ...state };
        newState.dataPush = {
          ...state.dataPush,
          data: { ...state.dataPush.data, count: state.dataPush.data.count - 1, list: dataPushArray },
        };
        return newState;
      }

      if (deleterName === 'leadCatcher') {
        // lead catchers list should be altered
        const leadCatchersArray = state.leadCatchers.data.list.slice().filter(leadCatcher => {
          return leadCatcher.id !== deletedId;
        });

        const newState = { ...state };
        newState.leadCatchers = {
          ...state.leadCatchers,
          data: { ...state.leadCatchers.data, count: state.leadCatchers.data.count - 1, list: leadCatchersArray },
        };
        return newState;
      }

      if (deleterName === 'dynamicDataDefinition') {
        // dynamic data definition list should be altered
        const dynamicDataDefinitionsArray = state.dynamicDataDefinitions.data.list
          .slice()
          .filter(dynamicDataDefinition => {
            return dynamicDataDefinition.id !== deletedId;
          });

        const newState = { ...state };
        newState.dynamicDataDefinitions = {
          ...state.dynamicDataDefinitions,
          data: {
            ...state.dynamicDataDefinitions.data,
            count: state.dynamicDataDefinitions.data.count - 1,
            list: dynamicDataDefinitionsArray,
          },
        };
        return newState;
      }

      if (deleterName === 'externalAccount') {
        // external accounts list should be altered
        const externalAccountsArray = state.externalAccounts.data.list.slice().filter(externalAccount => {
          return externalAccount.id !== deletedId;
        });

        const newState = { ...state };
        newState.externalAccounts = {
          ...state.externalAccounts,
          data: {
            ...state.externalAccounts.data,
            count: state.externalAccounts.data.count - 1,
            list: externalAccountsArray,
          },
        };
        return newState;
      }

      if (deleterName === 'leadPerMailRule') {
        // lead per mail rules
        const leadPerMailRulesArray = state.leadPerMailRules.data.list.slice().filter(leadPerMailRule => {
          return leadPerMailRule.id !== deletedId;
        });

        const newState = { ...state };
        newState.leadPerMailRules = {
          ...state.leadPerMailRules,
          data: {
            ...state.leadPerMailRules.data,
            count: state.leadPerMailRules.data.count - 1,
            list: leadPerMailRulesArray,
          },
        };
        return newState;
      }

      if (deleterName === 'clientCar') {
        const clientCarsArray = state.clientCars.data.list.slice().filter(clientCar => {
          return clientCar.id !== deletedId;
        });

        const newState = { ...state };
        newState.clientCars = {
          ...state.clientCars,
          data: { ...state.clientCars.data, count: state.clientCars.data.count - 1, list: clientCarsArray },
        };
        return newState;
      }

      if (deleterName === 'cmsTemplate') {
        const cmsTemplatesArray = state.cmsTemplates.data.list.slice().filter(cmsTemplate => {
          return cmsTemplate.id !== deletedId;
        });

        const newState = { ...state };
        newState.cmsTemplates = {
          ...state.cmsTemplates,
          data: { ...state.cmsTemplates.data, count: state.cmsTemplates.data.count - 1, list: cmsTemplatesArray },
        };
        return newState;
      }

      if (deleterName === 'docTemplate') {
        const docTemplatesArray = state.docTemplates.data.list.slice().filter(docTemplate => {
          return docTemplate.id !== deletedId;
        });

        const newState = { ...state };
        newState.docTemplates = {
          ...state.docTemplates,
          data: { ...state.docTemplates.data, count: state.docTemplates.data.count - 1, list: docTemplatesArray },
        };
        return newState;
      }

      if (deleterName === 'cmsElement') {
        const cmsElementsArray = state.cmsElements.data.list.slice().filter(cmsElement => {
          return cmsElement.id !== deletedId;
        });

        const newState = { ...state };
        newState.cmsElements = {
          ...state.cmsElements,
          data: { ...state.cmsElements.data, count: state.cmsElements.data.count - 1, list: cmsElementsArray },
        };
        return newState;
      }

      if (deleterName === 'leadTagger') {
        const leadTaggersArray = state.leadTaggers.data.list.slice().filter(leadTagger => {
          return leadTagger.id !== deletedId;
        });

        const newState = { ...state };
        newState.leadTaggers = {
          ...state.leadTaggers,
          data: { ...state.leadTaggers.data, count: state.leadTaggers.data.count - 1, list: leadTaggersArray },
        };
        return newState;
      }

      if (deleterName === 'quote') {
        const quotesArray = state.quotes.data.list.slice().filter(quote => {
          return quote.id !== deletedId;
        });

        const newState = { ...state };
        newState.quotes = {
          ...state.quotes,
          data: { ...state.quotes.data, count: state.quotes.data.count - 1, list: quotesArray },
        };
        return newState;
      }

      if (deleterName === 'invoice') {
        const invoicesArray = state.invoices.data.list.slice().filter(invoice => {
          return invoice.id !== deletedId;
        });

        // if we removed an invoice, adjust the quotes list, so the user may create a new invoice
        // if not, no "create invoice" button will be shown
        const quotesArray = state.quotes.data.list.slice().map(quote => {
          return quote.invoice === deletedId ? { ...quote, invoice: null } : quote;
        });

        const newState = { ...state };
        newState.invoices = {
          ...state.invoices,
          data: { ...state.invoices.data, count: state.invoices.data.count - 1, list: invoicesArray },
        };
        newState.quotes = {
          ...state.quotes,
          data: { ...state.quotes.data, list: quotesArray },
        };
        return newState;
      }

      if (deleterName === 'coupon') {
        const couponsArray = state.coupons.data.list.slice().filter(coupon => {
          return coupon.id !== deletedId;
        });

        const newState = { ...state };
        newState.coupons = {
          ...state.coupons,
          data: { ...state.coupons.data, count: state.coupons.data.count - 1, list: couponsArray },
        };
        return newState;
      }

      break;
    }

    case UPLOAD_FILE_FINISHED: {
      if (
        state.companyLocations.data.list &&
        state.companyLocations.data.list.length &&
        action.payload.response.config.url.indexOf('/api/locations') !== -1
      ) {
        // a company location image has been uploaded; we need to adjust the list
        const response = action.payload.response.data;

        if (response && response.links && response.links[0] && response.links[0].type === 'location') {
          const locationId = response.links[0].id;
          const locations = state.companyLocations.data.list.slice();
          for (let i = 0; i < locations.length; i++) {
            if (locations[i].id === locationId) {
              locations[i].imgs.push(response);

              break;
            }
          }

          const newState = { ...state };
          newState.companyLocations = {
            ...newState.companyLocations,
            data: { ...newState.companyLocations.data, list: locations },
          };
          return newState;
        }
      } else if (action.payload.response.config.url.indexOf('/api/leads/') !== -1) {
        // a lead file was uploaded
        // get the lead id
        const match = action.payload.response.config.url.match(/\/api\/leads\/([a-z0-9]{32})\.json/);
        const leadId = match[1];

        const newState = { ...state };

        if (newState.leadDocuments.data.list.length === 0 || newState.leadDocuments.data.list[0].lead_id === leadId) {
          const list = newState.leadDocuments.data.list.slice();
          list.push(action.payload.response.data);

          newState.leadDocuments = {
            ...newState.leadDocuments,
            data: { ...newState.leadDocuments.data, list },
          };
        }

        return newState;
      }

      break;
    }

    case SOCKET_EMIT: {
      const { eventName, args } = action.payload;

      switch (eventName) {
        case 'call_ended':
        case 'videocall_ended': {
          const { ender } = args;

          const chatUsersArray = state.chatUsers.data.list.slice();
          const userIndex = _findIndex(chatUsersArray, ['id', ender]);
          chatUsersArray.splice(userIndex, 1);

          return { ...state, chatUsers: { ...state.chatUsers, list: chatUsersArray } };
        }
      }

      break;
    }

    case SOCKET_EVENT: {
      const { eventName, args } = action.payload;

      switch (eventName) {
        case 'user_connected': {
          const chatUsers = { ...state.chatUsers };
          const { username, isMobile, isAudioCallAvailable, isVideoCallAvailable } = args;
          chatUsers.data.list = chatUsers.data.list.slice();
          chatUsers.data.list = chatUsers.data.list.map(chatUser => {
            return chatUser.id === username
              ? {
                  ...chatUser,
                  is_online: true,
                  is_mobile_online: isMobile,
                  is_audio_call_available: isAudioCallAvailable,
                  is_video_call_available: isVideoCallAvailable,
                }
              : chatUser;
          });

          return { ...state, chatUsers };
        }

        case 'user_disconnected': {
          const chatUsers = { ...state.chatUsers };
          chatUsers.data.list = chatUsers.data.list.slice();
          chatUsers.data.list = chatUsers.data.list.map(chatUser => {
            return chatUser.id === args ? { ...chatUser, is_online: false } : chatUser;
          });

          return { ...state, chatUsers };
        }

        case 'mobile_user_disconnected': {
          const chatUsers = { ...state.chatUsers };
          chatUsers.data.list = chatUsers.data.list.slice();
          chatUsers.data.list = chatUsers.data.list.map(chatUser => {
            return chatUser.id === args ? { ...chatUser, is_mobile_online: false } : chatUser;
          });

          return { ...state, chatUsers };
        }

        case 'add_user': {
          const chatUsers = { ...state.chatUsers };
          chatUsers.data.list = chatUsers.data.list.slice();
          chatUsers.data.list.splice(0, 0, { ...args, is_online: true });

          return { ...state, chatUsers };
        }
      }

      break;
    }

    case REMOVE_COMPANY_LOCATION_IMAGE: {
      if (action.payload) {
        const { locationIndex, imageIndex } = action.payload;

        const companyLocationsArray = [];
        _forEach(state.companyLocations.data.list.slice(), (companyLocation, i) => {
          if (i === locationIndex) {
            const foundLocation = companyLocation;
            foundLocation.imgs = foundLocation.imgs.slice().filter((img, j) => j !== imageIndex);
            companyLocationsArray.push(foundLocation);
          } else {
            companyLocationsArray.push(companyLocation);
          }
        });

        const newState = { ...state };
        newState.companyLocations = {
          ...state.companyLocations,
          data: { ...state.companyLocations.data, list: companyLocationsArray },
        };
        return newState;
      }
      break;
    }

    case CONVERSATION_ENDED: {
      const { from, myRole } = action.payload;

      if (myRole === 'customer') {
        const chatUsersArray = state.chatUsers.data.list.slice();
        const userIndex = _findIndex(chatUsersArray, ['id', from]);
        chatUsersArray.splice(userIndex, 1);

        return {
          ...state,
          chatUsers: {
            ...state.chatUsers,
            data: {
              ...state.chatUsers.data,
              list: chatUsersArray,
            },
          },
        };
      }

      break;
    }

    case WEBRTCCALL_ENDED: {
      const { ender, myRole } = action.payload;

      if (myRole === 'customer') {
        const chatUsersArray = state.chatUsers.data.list.slice();
        const userIndex = _findIndex(chatUsersArray, ['id', ender]);
        chatUsersArray.splice(userIndex, 1);

        return {
          ...state,
          chatUsers: {
            ...state.chatUsers,
            data: {
              ...state.chatUsers.data,
              list: chatUsersArray,
            },
          },
        };
      }

      break;
    }

    /**
     * Same actions to take:
     * - immediately on button press and action PENDING
     *   for "Optimistic UI update"
     * - and after the real updated invoice is returned from an API call.
     */
    case INVOICE_MARK_REFUNDED_PENDING:
    case INVOICE_MARK_REFUNDED_FULFILLED:
    case INVOICE_MARK_DENIED_TO_PAY_PENDING:
    case INVOICE_MARK_DENIED_TO_PAY_FULFILLED:
    case INVOICE_MARK_PAID_AMOUNT_PENDING:
    case INVOICE_MARK_PAID_AMOUNT_FULFILLED:
    case INVOICE_SENT_REMINDERS_PENDING:
    case INVOICE_SENT_REMINDERS_FULFILLED: {
      const { invoice, listName } = action.payload;

      if (!listName || !state[listName]) return state;

      return replaceListitem(state, listName, invoice);
    }

    case 'UNDELETED_USER': {
      const { username: payloadUsername } = action.payload;
      const listName = 'users';
      const newState = {
        ...state,
        [listName]: {
          ...state[listName],
          data: {
            ...state[listName].data,
            list: state[listName].data.list.slice(), // shallow copy
          },
        },
      };

      const { data: { list = [] } = {} } = newState[listName];
      const userIndex = list.findIndex(({ username }) => username === payloadUsername);
      if (userIndex >= 0) {
        list.splice(userIndex, 1, { ...list[userIndex], is_deleted: false });
      }

      return newState;
    }

    case 'UNDELETED_CLIENT': {
      const { username: payloadUsername } = action.payload;
      const listName = 'clients';
      const newState = {
        ...state,
        [listName]: {
          ...state[listName],
          data: {
            ...state[listName].data,
            list: state[listName].data.list.slice(), // shallow copy
          },
        },
      };

      const { data: { list = [] } = {} } = newState[listName];
      const userIndex = list.findIndex(({ username }) => username === payloadUsername);
      if (userIndex >= 0) {
        list.splice(userIndex, 1, { ...list[userIndex], is_deleted: false });
      }

      return newState;
    }

    case 'GET_COUPON_BALANCE_PENDING': {
      return {
        ...state,
        couponBalance: {
          ...state.couponBalance,
          pending: true,
          hasError: false,
        },
      };
    }

    case 'GET_COUPON_BALANCE_FULFILLED': {
      return {
        ...state,
        couponBalance: {
          ...state.couponBalance,
          pending: false,
          value: action.payload.balance,
          hasError: false,
        },
      };
    }

    case 'GET_COUPON_BALANCE_REJECTED': {
      return {
        ...state,
        couponBalance: {
          ...state.couponBalance,
          pending: false,
          value: undefined,
          hasError: true,
        },
      };
    }

    case 'RESTORE_PRODUCT_TEMPLATE_FULFILLED': {
      const deletedId = action.payload;
      const templatesArray = state.productTemplates.data.list.slice().filter(template => {
        return template.id !== deletedId;
      });

      const newState = { ...state };
      newState.productTemplates = {
        ...state.productTemplates,
        data: { ...state.productTemplates.data, count: state.productTemplates.data.count - 1, list: templatesArray },
      };
      return newState;
    }
  }

  const moreReducers = [subscriptionsReducer, subscriptionPricesReducer];

  return moreReducers.reduce((_state, reducer) => reducer(_state, action), { ...state });
}

/**
 * Replace single list item with a new one.
 * refer to item "id" property to find the item in the list.
 */
function replaceListitem(state, listName, invoice) {
  const newState = {
    ...state,
    [listName]: {
      ...state[listName],
      data: {
        ...state[listName].data,
        list: state[listName].data.list.slice(), // shallow copy
      },
    },
  };

  const { list } = newState[listName].data;
  const index = list.findIndex(item => item.id === invoice.id);
  if (index > -1) {
    list.splice(index, 1, invoice); // replace the single invoice in the list
  }

  return newState;
}
