/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-unused-vars */
import React from 'react';

export const locale = {
  "locales": ["fr"],
  "messages": {
    "editor": {
      "first_name": "Prénom",
      "name": "Nom",
      "subject": "Sujet",
      "message": "Message",
      "custom_dropdown": "liste déroulante personnalisée",
      "custom_label": "Propre inscription",
      "set_autoplay": "Lecture automatique",
      "autoplay_info": "Attention ! Dans la plupart des cas, certains navigateurs n'autorisent pas l'autoplay. L'autoplay désactivé est cependant toujours autorisé.",
      "set_muted": "Muet",
      "data_protection_officer": "Délégué à la protection des données",
      "data_protection_authority": "Autorité de protection des données",
      "button_font_color": "Couleur de la police",
      "set_label_dropdown": "Veuillez attribuer une étiquette à la liste déroulante",
      "set_label_custom_field": "Veuillez attribuer une étiquette au champ de saisie",
      "empty_field": "champ libre",
      "custom_field": "Champ défini par l'utilisateur",
      "change_label": "Modifier l'étiquette",
      "edit_label": "Modifier l'étiquette",
      "salutation": "Titre de civilité",
      "title": "Titre",
      "link_title": "Désignation du lien",
      "main_text": "Texte principal",
      "more_text": "texte supplémentaire",
      "create_text": "Créer un texte",
      "edit_text_slider": "Aucun élément de texte n'a encore été créé",
      "edit_rss_elements": "Aucun élément de forme n'a encore été sélectionné, cliquez ici pour en ajouter.",
      "rss_settings": "Paramètres du flux RSS",
      "select_rss": "Vous pouvez choisir un flux RSS parmi les modèles suivants",
      "available_rss": "Flux RSS disponibles",
      "load_rss_part_1": "Vous avez sélectionné le flux RSS suivant.",
      "load_rss_part_2": "Cliquez sur le bouton 'Obtenir les flux RSS' pour télécharger les flux",
      "load_rss_feeds": "Consulter les flux RSS",
      "color": "Couleur",
      "background_color": "Couleur de fond",
      "distance_to_border": "Distance par rapport au bord",
      "color_of_qrcode": "Code QR couleur",
      "click_me": "Clique sur moi",
      "new_menu_item": "Nouveau point de menu",
      "external": "externe",
      "imprint": "Mentions légales",
      "privacy": "Protection des données",
      "january": "Janvier",
      "february": "Février",
      "march": "Mars",
      "april": "Avril",
      "may": "Mai",
      "june": "Juin",
      "july": "Juillet",
      "august": "Août",
      "september": "Septembre",
      "october": "Octobre",
      "november": "Novembre",
      "december": "Décembre",
      "monday": "Lundi",
      "tuesday": "Mardi",
      "wednesday": "Mercredi",
      "thursday": "Jeudi",
      "friday": "Vendredi",
      "saturday": "Samedi",
      "sunday": "Dimanche",
      "edit_date": "Modifier un rendez-vous",
      "oclock": "Horloge",
      "filter_selection": "Choix du filtre",
      "use_filter": "Appliquer",
      "sample_picture_found": "Image type trouvée",
      "already_edited_in_another_tab": "Cette édition est peut-être déjà en cours de traitement dans un autre onglet.",
      "information_for_contact": "Données pour le formulaire de contact",
      "mail_receiver": "Destinataire de l'e-mail",
      "google_analytics": "Google Analytics",
      "measurement_id": "ID de mesure pour Google Analytics",
      "new_picture_inserted": "Nouvelle image insérée",
      "text_changed": "Texte modifié",
      "edit_title": "Cliquez ici pour modifier le titre",
      "edit_description": "Cliquez ici pour modifier la description de l'image",
      "edit_copyright": "Cliquez ici pour modifier le copyright",
      "no_changes": "Aucun changement",
      "changes_on_document": "Modifications du document",
      "changes_on_page": "Modifications de la page",
      "page": "Page",
      "choose_button_label": "Veuillez choisir un libellé pour le bouton",
      "edit_calendly": "Cliquez ici pour modifier les paramètres de Calendly",
      "exchanged_with_page": "échangé avec page",
      "upload_failed": "Échec du téléchargement",
      "try_again": "Veuillez réessayer",
      "saving_failed": "Échec de l'enregistrement",
      "new_page_inserted": " nouvelle(s) page(s) insérée(s) par page",
      "delete_pages": "Supprimer des pages",
      "all_content_deleted": "Les pages et l'ensemble de leur contenu seront supprimés.",
      "yes_delete": "Oui, supprimer",
      "pages_deleted": " Page(s) supprimée(s), par page",
      "rather_not": "Je ne préfère pas",
      "new_here": "Vous êtes nouveau ?",
      "first_start": "Il semblerait que vous ayez ouvert notre nouvel éditeur pour la première fois. Vous voulez une introduction ?",
      "yes_absolutely": "Oui, absolument",
      "im_fine": "Je me débrouille",
      "no_page_definitions_found": "Aucune page de définition trouvée !",
      "move_up": " Déplacer la ou les pages vers le haut",
      "side_position_fixed": "Position latérale fixée",
      "generated_in_pdf": "Les pages sont générées automatiquement dans le PDF",
      "move_down": " Déplacer la ou les pages vers le bas",
      "by": "Pour",
      "add_page": " Insérer des pages",
      "remove_page": " Supprimer des pages",
      "unsaved_changes": "Vous avez des modifications non enregistrées",
      "i_have_it": "Ok, maintenant j'ai compris !",
      "document_pictures": "Images dans le document",
      "used_images": "Images utilisées",
      "take_a_photo": "Prendre une photo",
      "unused_images": "Images non utilisées",
      "content_is_saved": "Le contenu est enregistré",
      "page_arrangement": "Disposition des pages",
      "print_sheet_view": "Vue de la feuille d'impression",
      "view_safety_distance": "Vue de la distance de sécurité",
      "view_structure": "Vue Structure",
      "start_tutorial": "Démarrer le tutoriel",
      "adjust_by_window": "Adapter la page à la fenêtre",
      "adjust_by_width": "Adapter la page à la largeur",
      "adjust_by_height": "Ajuster la page à la hauteur",
      "delete_content": "Supprimer le contenu",
      "delete_selected_content_block": "Cela supprime le bloc de contenu sélectionné et son contenu (images, textes).",
      "delete_children_of_parents": "Attention, il s'agit d'un élément parent. Si vous le supprimez, tous les éléments enfants qui lui sont associés seront également supprimés.",
      "reset_content": "Réinitialiser le contenu",
      "reset_content_block_to_root": "Cela rétablit l'état initial du bloc de contenu sélectionné et de son contenu (images, textes).",
      "yes_reset": "Oui, réinitialiser",
      "copy_full_content": "Copier tout le contenu de la page",
      "paste_content": "Insérer le contenu du presse-papiers",
      "enlarge_block": "Agrandir le bloc",
      "shrink_block": "Réduire le bloc",
      "text_overflow": "Débordement de texte",
      "initial_image_available": " Image initiale disponible",
      "paste_content_after": "Coller le contenu du presse-papiers après ceux-ci",
      "deselect_element": "Désélectionner un élément",
      "select_element": "Sélectionner un élément",
      "block_can_not_move": "Le bloc ne peut pas être déplacé",
      "up": "Haut de page",
      "reset_block": "Réinitialiser le bloc",
      "change_backgroundcolor": "Changer la couleur de fond",
      "change_fontcolor": "Changer la couleur de la police",
      "copy_block": "Copier un bloc",
      "delete_block": "Supprimer un bloc",
      "down": "Vers le bas",
      "product_in_cart": "Produit dans le panier",
      "added_to_cart": " a été ajouté à votre panier.",
      "great": "Super !",
      "to_cart": "Aller au panier",
      "are_you_sure": "Vous en êtes sûr ?",
      "reset_to_selected_status": "Le document revient à l'état sélectionné.",
      "abort": "Annuler",
      "reset_to_version": "Réinitialiser à la version",
      "choose_a_campaign": "Choisir une campagne",
      "name_for_edition": "Nom pour cette édition",
      "save_to": "Enregistrer sous...",
      "save": "Enregistrer",
      "settings": "Réglages",
      "short_summary": "Brève description du contenu",
      "keywords_separated": "Mots clés séparés par une virgule",
      "serial_letter": "Lettre en série",
      "activate_serial_letter": "Activer la lettre en série",
      "serial_letter_database": "Base de données Lettre en série",
      "serial_letter_database_upload_info": "Le téléchargement prend en charge les formats de fichiers suivants - .xlsx, .xls et .csv",
      "serial_letter_info": "Dans la base de données, veuillez saisir les caractères de remplacement dans les cellules de la première ligne. En dessous, les valeurs correspondantes. Par exemple, comme suit",
      "preview_of_database": "Aperçu de la base de données",
      "serial_letter_database_upload": "Télécharger",
      "no_error": "Sans erreur",
      "error": "Erreur",
      "information_for_imprint": "Données pour l'impressum",
      "responsible_for_content": "Responsable du contenu",
      "company_association": "Entreprise / Association",
      "street": "Rue",
      "zip_code": "Code postal",
      "city": "Lieu",
      "email": "Adresse électronique",
      "phone_number": "Téléphone",
      "data_protection_information": "Informations sur la protection des données",
      "responsible": "Responsable",
      "change": "Modifier",
      "export_image": "Exporter l'image",
      "preview": "Aperçu",
      "preview_button": "Aperçu des boutons",
      "print_pdf": "PDF d'impression",
      "initialize": "Initialiser !",
      "order": "Commander",
      "versions": "Versions",
      "currently_used_image": "Image actuellement utilisée",
      "upload_new_image": "Télécharger une nouvelle image",
      "new_cut": "Redécouper",
      "take_on": "Reprendre",
      "choose_file": "Sélectionner un fichier",
      "image_description": "Description de l'image",
      "image_upload": "Téléchargement d'images",
      "mediathek": "Médiathèque",
      "pixabay": "Pixabay",
      "add_content": "Ajouter du contenu",
      "copied_content_block": "Bloc de contenu copié",
      "unsaved_content": "Vous avez des modifications non enregistrées ! Voulez-vous vraiment quitter l'éditeur ?",
      "filter_settings": "Paramètres du filtre",
      "filter_grayscale": "Niveaux de gris",
      "filter_sepia": "Sépia",
      "filter_blur": "estomper",
      "filter_brightness": "Luminosité",
      "filter_contrast": "Contraste",
      "filter_hueRotate": "Tourner la teinte",
      "filter_invert": "Inverser les couleurs",
      "filter_opacity": "Transparence",
      "filter_saturate": "Saturation",
      "filter_default": "Standard",
      "filter_set": "Classé",
      "work_surface": "C'est ici que se trouve le plan de travail.",
      "work_surface_description": "C'est ici que se déroule le processus de création. Les images, les textes et les éléments peuvent être insérés et modifiés ici.",
      "page_navigation": "C'est ici que se trouve la navigation dans les pages",
      "page_navigation_description": "Vous pouvez ici passer d'une page à l'autre et ajouter, supprimer ou échanger des pages entre elles.",
      "content_blocks": "Vous trouverez ici les blocs de contenu",
      "content_blocks_description": "Vous pouvez ici insérer différents éléments d'image et de texte dans votre support publicitaire en cliquant dessus. Si l'espace disponible sur le support publicitaire n'est pas suffisant, vous pouvez à tout moment supprimer les éléments superflus.",
      "screen_view": "Ici, vous pouvez modifier l'affichage de l'écran.",
      "screen_view_description": "Vous pouvez ici modifier l'affichage de votre support web. 100% représente toujours la taille originale du support publicitaire.",
      "error_display": "Les erreurs sont affichées ici.",
      "error_display_description": "Le système vous informe ici si un dépassement de texte ou d'autres erreurs techniques ont été trouvés.",
      "options": "Vous trouverez ici des options pour votre support publicitaire.",
      "options_description": "Cliquez sur le bouton d'aperçu pour générer un PDF d'aperçu ou sur le bouton de commande pour placer le support publicitaire dans le panier. En cliquant sur 'Enregistrer sous', vous pouvez copier votre support publicitaire dans une autre campagne.",
      "tutorial_end": "Voilà, en bref, ce qu'était notre éditeur !",
      "tutorial_end_description": "Vous êtes désormais en mesure de concevoir rapidement et facilement votre support publicitaire en respectant l'identité visuelle de votre entreprise.",
      "content_block_copied": "Bloc de contenu copié",
      "content_blocks_switched": "Blocs de contenu échangés",
      "content_block_reset": "Bloc de contenu réinitialisé",
      "content_block_resize": "Taille du bloc de contenu modifiée",
      "content_block_removed": "Bloc de contenu supprimé",
      "no_place": "Pas de place",
      "content_block_inserted": "Bloc de contenu inséré",
      "no_dates_available": "Aucun événement n'est disponible, cliquez ici pour en ajouter un.",
      "new_date": "Nouvelle date",
      "edit_dates": "Modifier les rendez-vous",
      "date_description": "Description du rendez-vous",
      "add": "Ajouter",
      "no_dates_availableTwo": "Aucun rendez-vous disponible",
      "date_title": "Titre du rendez-vous",
      "date_place": "Lieu du rendez-vous",
      "date_from": "Am/b",
      "date_to": "Jusqu'à",
      "no_forms": "Pas encore d'éléments de forme sélectionnés, cliquez ici pour les ajouter",
      "read_privacy_policy": "J'ai lu et compris la déclaration de protection des données.",
      "contact_form_settings": "Paramètres du formulaire de contact",
      "dropdown_settings": "Paramètres de la liste déroulante",
      "add_options": "Ajouter des options",
      "dropdown_label": "Étiquette déroulante",
      "dropdown_option_fields": "Boutons radio déroulants",
      "pls_choose": "Veuillez sélectionner un élément du formulaire",
      "pls_chooseTwo": "Veuillez sélectionner les éléments du formulaire de contact que vous souhaitez",
      "send": "Envoyer",
      "google_maps_element_settings": "Paramètres de localisation Google Maps",
      "no_location": "Vous n'avez pas encore ajouté d'adresse, cliquez ici pour la modifier",
      "found_textoverflow": "Débordement de texte trouvé",
      "minimum_size": "Taille minimale",
      "too_low_resolution": "L'image a une résolution trop faible !",
      "set_scale_free": "activer la mise à l'échelle libre",
      "unset_scale_free": "désactiver la mise à l'échelle libre",
      "no_images_selected": "Pas encore de photos sélectionnées, cliquez ici pour ajouter",
      "gallery_settings": "Paramètres de la galerie",
      "add_image": "Ajouter une image",
      "add_image_front": "Ajouter une image au début",
      "add_image_back": "Ajouter une image à la fin",
      "slider_images_invert": "Inverser l'ordre des images",
      "image_text_settings": "Paramètres du texte de l'image",
      "change_slider": "Pour modifier le titre ou la description, cliquez sur le texte correspondant dans le slider.",
      "change_image_title": "Modifier le titre de l'image",
      "change_image_description": "Modifier la description de l'image",
      "image_copyright": "Copyright de l'image",
      "slider_settings": "Paramètres Slider",
      "new_titel": "Nouveau titre",
      "new_link_title": "Nouveau titre de lien",
      "edit_text_title": "Modifier le titre",
      "edit_text_link": "Modifier le lien",
      "edit_link_title": "Modifier le titre du lien",
      "edit_text_text": "Modifier le texte",
      "add_element": "Ajouter un élément",
      "add_element_before": "Ajouter un élément à l'avant",
      "add_element_after": "Ajouter un élément à l'arrière",
      "edit_text_of_element": "Pour modifier les textes, cliquez sur le texte correspondant dans le slider",
      "invert_elements": "Inverser l'ordre",
      "edit_nav": "Modifier la navigation",
      "term": "Désignation",
      "link": "Lien",
      "actions": "Actions",
      "content_of_QRCode": "Quel contenu doit être inclus dans le code QR :",
      "address_of_website": "Saisir l'adresse du site web :",
      "name_surname": "Prénom et nom de famille",
      "business_card": "Carte de visite",
      "must_url": "Doit être une URL",
      "position": "Position",
      "phone": "Téléphone",
      "website": "Site web",
      "address": "Adresse",
      "email_message": "Message électronique",
      "text": "Texte",
      "recipient_email_address": "Adresse e-mail du destinataire",
      "reference": "Sujet",
      "content": "Contenu",
      "your_text": "Votre texte",
      "add_QRCode": "Insérer un code QR",
      "no_preview_of_twitter": "Pour Twitter, l'affichage n'est malheureusement pas possible ici, veuillez utiliser la fonction de prévisualisation des pages web",
      "no_preview_of_calendly": "Pour Calendly, l'affichage n'est malheureusement pas possible ici, veuillez utiliser la fonction d'aperçu du site web",
      "twitter_link": "Lien Twitter établi :",
      "social_media_settings": "Paramètres des médias sociaux",
      "calendly_settings": "Paramètres Calendly",
      "button_settings": "Bouton Paramètres",
      "action_button_content_text": "Cliquez ici pour modifier les paramètres du bouton d'action",
      "action_button_link_element": "Référence à un élément",
      "action_button_external_link": "Renvoi à un lien externe",
      "action_button_or": "ou",
      "button_color": "Couleur du bouton",
      "add_social_media_account": "Pas encore de compte de médias sociaux ajouté, cliquez ici pour en choisir un.",
      "choice_of_provider": "Sélection de fournisseurs de médias sociaux",
      "embed_code": "Code d'intégration du canal de médias sociaux",
      "link_to_your_page": "Lien vers votre site chez",
      "link_to_your_calendly_page": "Lien vers votre page Calendly",
      "link_to_your_page_two": "Lien vers votre site",
      "video_settings": "Paramètres vidéo",
      "add_video": "Pas encore de vidéo sélectionnée, cliquez ici pour en insérer une",
      "choice_video_provider": "Sélection de fournisseurs vidéo",
      "upload_video": "Télécharger la vidéo",
      "choice_mp4_file": "Sélectionner le fichier vidéo (.mp4)",
      "nav_type": "Type",
      "nav_type_url": "URL",
      "nav_type_page": "Page",
      "nav_type_page_prefix": "Page"
    },
    "medialibrary": {
      "new_folder": "Nouveau dossier",
      "sorting": "Triage",
      "directories": "Répertoires",
      "medialibrary": "Médiathèque",
      "media": "Médias",
      "upload_media": "Télécharger des médias",
      "sorting_description": "Veuillez noter que le tri se fait selon le nom du fichier et non selon le titre défini.",
      "sorting_info_text": "Choisissez un ordre de tri pour les dossiers et les médias. Le tri défini s'applique également à tous les appels de dossiers.",
      "paste": "Insérer",
      "info": "Remarque !",
      "info_text": "Un ou plusieurs fichiers existent déjà. Ils n'ont pas été téléchargés à nouveau.",
      "back_to_root": "Retour au répertoire racine",
      "directory_by": "Répertoire de",
      "created_by": "Créé par",
      "no_folder_created": "Aucun dossier n'a été créé",
      "no_media_found": "Aucun média dans le dossier",
      "upload_processing": "Vos téléchargements sont traités",
      "not_uploaded": "Les médias n'ont pas pu être téléchargés !",
      "close": "Fermer",
      "preview": "Aperçu des images/vidéos",
      "name": "Nom",
      "upload_info": "Info",
      "options": "Options",
      "status": "Statut",
      "adjust_meta": "Personnaliser les métadonnées",
      "title": "Titre",
      "description": "Description",
      "save": "Enregistrer",
      "create_new_directory": "Créer un nouveau répertoire",
      "directory_name": "Nom du répertoire",
      "rename_folder": "Renommer un dossier",
      "copyright": "Copyright",
      "upload": "Télécharger",
      "wastebasket": "Corbeille à papier",
      "are_you_sure_erase_file": "Êtes-vous sûr de vouloir supprimer le fichier de manière irréversible ?",
      "are_you_sure_erase_folder": "Êtes-vous sûr de vouloir supprimer le dossier de manière irréversible ?",
      "erase": "Supprimer"
    },
    "meta": {
      "campaigns": {
        "title": "Campagnes",
        "description": "Campagnes",
        "name": "Campagnes"
      },
      "productShop": {
        "title": "Boutique",
        "description": "Tu cherches des articles de merchandising ? Alors tu es au bon endroit.",
        "name": "Article"
      },
      "carShop": {
        "title": "Boutique de véhicules",
        "description": "Tu cherches la voiture de tes rêves ? Alors consulte dès maintenant nos véhicules.",
        "name": "Véhicules"
      },
      "car_detail_search": {
        "title": "Vous ne savez pas quelle voiture vous convient ? Utilisez notre moteur de recherche pour trouver la voiture idéale.",
        "description": "Vous recherchez une voiture répondant à certaines exigences, mais vous ne savez pas exactement de quel type de voiture vous avez besoin ? Sélectionnez le type de véhicule, la chaîne cinématique et le niveau d'équipement.",
        "name": "Recherche détaillée"
      },
      "car_brands": {
        "title": "Choisissez votre marque",
        "description": "Choisissez votre marque et découvrez les meilleures offres du marché.",
        "name": "Sélection de marques"
      },
      "car_models": {
        "title": "Sélectionnez le modèle de votre {brandName}",
        "description": "Choisissez un modèle parmi les plus populaires {modelOne} à {modelTwo}.",
        "name": "Modèles"
      },
      "car_model_drive": {
        "title": "Sélectionnez le type de carburant et la transmission pour votre {brandName} {modelName}",
        "description": "Nous avons la {brandName} {modelName} avec {fuelTypes} moteurs. Les types de boîtes de vitesses disponibles sont {gearboxTypes}.",
        "name": "Type de carburant"
      },
      "car_model_equipment": {
        "title": "Sélectionnez le niveau d'équipement pour votre {brandName} {modelName}",
        "description": "Choisissez parmi Budget, Famille, Confort ou Business. Le plus populaire, Confort, offre des capteurs de stationnement, Bluetooth et plus.",
        "name": "Équipement"
      },
      "favorites": {
        "title": "Vos favoris, sauvegardés pour plus tard",
        "description": "C'est formidable que vous ayez gardé certains de nos meilleurs articles pour plus tard. Essayez maintenant de choisir celui que vous souhaitez.",
        "name": "Favoris"
      },
      "cart": {
        "title": "Panier d'achat",
        "description": "Panier d'achat",
        "name": "Panier d'achat"
      },
      "checkout": {
        "title": "Caisse",
        "description": "Caisse",
        "name": "Caisse"
      },
      "clients": {
        "title": "Clients",
        "description": "Clients",
        "name": "Clients"
      },
      "client_details": {
        "title": "Société",
        "description": "Société",
        "name": "Société"
      },
      "leads": {
        "title": "Leads",
        "description": "Leads",
        "name": "Leads"
      },
      "lead_details": {
        "title": "Lead",
        "description": "Lead",
        "name": "Lead"
      },
      "cars": {
        "title": "Véhicules",
        "description": "Véhicules",
        "name": "Véhicules"
      },
      "car_overview": {
        "title": "Aperçu des véhicules",
        "description": "Aperçu des véhicules",
        "name": "Aperçu des véhicules"
      },
      "car_details": {
        "title": "Véhicule",
        "description": "Véhicule",
        "name": "Véhicule"
      },
      "pages": {
        "title": "Page",
        "description": "Page",
        "name": "Page"
      },
      "page_details": {
        "title": "Page",
        "description": "Page",
        "name": "Page"
      },
      "profile": {
        "title": "Profil",
        "description": "Profil",
        "name": "Profil"
      },
      "locations": {
        "title": "Sites",
        "description": "Sites",
        "name": "Sites"
      },
      "company_locations": {
        "title": "Sites",
        "description": "Sites",
        "name": "Sites"
      },
      "data_push": {
        "title": "Push de données",
        "description": "Push de données",
        "name": "Push de données"
      },
      "white_label_projects": {
        "title": "Projets en marque blanche",
        "description": "Projets en marque blanche",
        "name": "Projets en marque blanche"
      },
      "users": {
        "title": "Utilisateur",
        "description": "Utilisateur",
        "name": "Utilisateur"
      },
      "user_details": {
        "title": "Utilisateur",
        "description": "Utilisateur",
        "name": "Utilisateur"
      },
      "user_import": {
        "title": "Importer des utilisateurs",
        "description": "Importer des utilisateurs",
        "name": "Importer des utilisateurs"
      },
      "teams": {
        "title": "Équipes",
        "description": "Équipes",
        "name": "Équipes"
      },
      "questionnaires": {
        "title": "Questionnaires",
        "description": "Questionnaires",
        "name": "Questionnaires"
      },
      "questionnaire_details": {
        "title": "Questionnaire",
        "description": "Questionnaire",
        "name": "Questionnaire"
      },
      "document_types": {
        "title": "Types de documents",
        "description": "Types de documents",
        "name": "Types de documents"
      },
      "document_type_details": {
        "title": "Type de document",
        "description": "Type de document",
        "name": "Type de document"
      },
      "templates": {
        "title": "Modèles",
        "description": "Modèles",
        "name": "Modèles"
      },
      "cms_elements": {
        "title": "Éléments CMS",
        "description": "Éléments CMS",
        "name": "Éléments CMS"
      },
      "cms_element_details": {
        "title": "Élément CMS",
        "description": "Élément CMS",
        "name": "Élément CMS"
      },
      "brands": {
        "title": "Marques",
        "description": "Marques",
        "name": "Marques"
      },
      "models": {
        "title": "Modèles",
        "description": "Modèles",
        "name": "Modèles"
      },
      "categories": {
        "title": "Catégories",
        "description": "Catégories",
        "name": "Catégories"
      },
      "category_details": {
        "title": "Catégorie",
        "description": "Catégorie",
        "name": "Catégorie",
        "authorization_on_mobile_device": "Autorisation sur les appareils mobiles",
        "hide_category": "Masquer la catégorie"
      },
      "attributes": {
        "title": "Attributs",
        "description": "Attributs",
        "name": "Attributs"
      },
      "attribute_details": {
        "title": "Attribut",
        "description": "Attribut",
        "name": "Attribut"
      },
      "attribute_groups": {
        "title": "Groupes d'attributs",
        "description": "Groupes d'attributs",
        "name": "Groupes d'attributs"
      },
      "dynamic_data_definitions": {
        "title": "Définitions de données dynamiques",
        "description": "Définitions de données dynamiques",
        "name": "Définitions de données dynamiques"
      },
      "pipelines": {
        "title": "Pipelines",
        "description": "Pipelines",
        "name": "Pipelines"
      },
      "pipeline_details": {
        "title": "Pipeline",
        "description": "Pipeline",
        "name": "Pipeline"
      },
      "items": {
        "title": "Produits",
        "description": "Produits",
        "name": "Produits"
      },
      "item_details": {
        "title": "Produit",
        "description": "Produit",
        "name": "Produit"
      },
      "calendar": {
        "title": "Calendrier",
        "description": "Calendrier",
        "name": "Calendrier"
      },
      "orders": {
        "title": "Commandes",
        "description": "Commandes",
        "name": "Commandes"
      },
      "settings": {
        "title": "Réglages",
        "description": "Réglages",
        "name": "Réglages"
      },
      "four_zero_four": {
        "title": "404",
        "description": "404",
        "name": "404"
      },
      "account": {
        "title": "Compte",
        "description": "Compte",
        "name": "Compte"
      },
      "forgot_password": {
        "linktext": "Mot de passe oublié ?",
        "title": "Mot de passe oublié",
        "description": "Mot de passe oublié",
        "name": "Mot de passe oublié"
      },
      "renew_password": {
        "title": "Renouveler le mot de passe",
        "description": "Renouveler le mot de passe",
        "name": "Renouveler le mot de passe"
      },
      "new_password_after_reset": {
        "title": "Nouveau mot de passe après la réinitialisation",
        "description": "Nouveau mot de passe après la réinitialisation",
        "name": "Nouveau mot de passe après la réinitialisation"
      },
      "pricing": {
        "title": "Prix",
        "description": "Prix",
        "name": "Prix"
      },
      "signedup": {
        "title": "Enregistré",
        "description": "Enregistré",
        "name": "Enregistré"
      },
      "go_pro": {
        "title": "Go pro",
        "description": "Go pro",
        "name": "Go pro"
      },
      "paypal": {
        "title": "Payer avec Paypal",
        "description": "Payer avec Paypal",
        "name": "Payer avec Paypal"
      },
      "lead_catchers": {
        "title": "Lead catchers",
        "description": "Lead catchers",
        "name": "Lead catchers"
      },
      "bills": {
        "title": "Factures",
        "description": "Factures",
        "name": "Factures"
      },
      "provisionServiceDocuments": {
        "title": "Commissions"
      },
      "commissionServiceDocuments": {
        "title": "Documents de la Commission"
      },
      "integrations": {
        "title": "Intégrations",
        "description": "Intégrations",
        "name": "Intégrations"
      },
      "order_complete": {
        "title": "Commande effectuée. Merci beaucoup",
        "description": "Commande effectuée. Merci beaucoup",
        "name": "Commande effectuée. Merci beaucoup"
      },
      "order_details": {
        "title": "Détails de la commande",
        "description": "Détails de la commande",
        "name": "Détails de la commande"
      },
      "my_company": {
        "title": "Mon entreprise",
        "description": "Mon entreprise",
        "name": "Mon entreprise"
      },
      "white_label_plugins": {
        "title": "Plugins en marque blanche",
        "description": "Plugins en marque blanche",
        "name": "Plugins en marque blanche"
      },
      "plugins": {
        "title": "Plugins Creacheck",
        "description": "Plugins Creacheck",
        "name": "Plugins Creacheck"
      },
      "bill_details": {
        "title": "Détails de la facture",
        "description": "Détails de la facture",
        "name": "Détails de la facture"
      },
      "activate_plugin": {
        "title": "Activer le plugin",
        "description": "Activer le plugin",
        "name": "Activer le plugin"
      },
      "model_groups": {
        "title": "Groupes de modèles",
        "description": "Groupes de modèles",
        "name": "Groupes de modèles"
      },
      "model_group_details": {
        "title": "Détails du groupe de modèles",
        "description": "Détails du groupe de modèles",
        "name": "Détails du groupe de modèles"
      },
      "model_assets": {
        "title": "Images de modèles",
        "description": "Images de modèles",
        "name": "Images de modèles"
      },
      "model_asset_details": {
        "title": "Modèle Détails de l'image",
        "description": "Modèle Détails de l'image",
        "name": "Modèle Détails de l'image"
      },
      "lead_per_mail_rules": {
        "title": "Règles de lead par e-mail",
        "description": "Règles de lead par e-mail",
        "name": "Règles de lead par e-mail"
      },
      "car_rental": {
        "title": "Réservation sûre et rapide à {city}. {count} voitures au choix",
        "description": "Réservez votre voiture rapidement et en toute sécurité. Croyez-nous, louer une voiture n'a jamais été aussi simple et rapide.",
        "name": "Location"
      },
      "failed_refunds": {
        "title": "Échec des remboursements",
        "description": "Échec des remboursements",
        "name": "Échec des remboursements"
      },
      "companySignup": {
        "title": "Inscription pour les entreprises",
        "description": "Enregistrez votre entreprise et profitez du tout dernier écosystème pour les concessionnaires automobiles",
        "name": "Inscription pour les entreprises"
      },
      "price_negotiations": {
        "title": "Négociations de prix",
        "description": "Négociations de prix",
        "name": "Négociations de prix"
      },
      "price_negotiation_details": {
        "title": "Négociation de prix",
        "description": "Négociation de prix",
        "name": "Négociation de prix"
      },
      "communities": {
        "title": "Liste des communautés",
        "description": "Liste des communautés",
        "name": "Liste des communautés"
      },
      "car_service_locations": {
        "title": "Sites où des rendez-vous de service sont disponibles",
        "description": "Sites",
        "name": "Sites"
      },
      "car_service_resources": {
        "title": "Choisissez un conseiller de service",
        "description": "Conseiller de service",
        "name": "Conseiller de service"
      },
      "car_service_free_slots": {
        "title": "Choisissez un créneau horaire libre pour garer votre voiture",
        "description": "Créneau horaire",
        "name": "Créneau horaire"
      },
      "car_service_client_data": {
        "title": "Connectez-vous ou inscrivez-vous pour réserver votre rendez-vous",
        "description": "Connexion",
        "name": "Connexion"
      },
      "car_service_car_data": {
        "title": "Saisissez les données du véhicule pour réserver le service",
        "description": "Données du véhicule",
        "name": "Données du véhicule"
      },
      "car_service_summary": {
        "title": "Dernière étape - Vérifier vos données et votre sélection",
        "description": "Vérifier",
        "name": "Vérifier"
      },
      "car_services": {
        "title": "Services d'atelier",
        "description": "Services",
        "name": "Services"
      },
      "car_service_stations": {
        "title": "Atelier Postes de travail",
        "description": "Postes de travail",
        "name": "Postes de travail"
      },
      "document_templates": {
        "title": "Modèles de documents",
        "description": "Modèles de documents",
        "name": "Modèles de documents"
      },
      "document_template_details": {
        "title": "Modèle de document",
        "description": "Modèle de document",
        "name": "Modèle de document"
      }
    },
    "sort": {
      "relevance": "Pertinence",
      "username": "Email",
      "lastName": "Nom de famille",
      "cdate": "Les plus récents en premier",
      "cdateDesc": "Les plus récents en premier",
      "dueDate": "Date d'échéance",
      "fav": "Les plus populaires",
      "priceAsc": "Prix le plus bas",
      "rentPriceAsc": "Prix le plus bas",
      "priceDesc": "Prix le plus élevé",
      "rentPriceDesc": "Prix le plus élevé",
      "priority": "Priorité",
      "mdate": "Dernière mise à jour",
      "countryName": "Pays",
      "vat": "TVA",
      "sumGross": "Montant"
    },
    "jobTypes": {
      "wedding": "Mariage",
      "family_portrait": "Portrait de famille",
      "maternity": "Maternité",
      "newborn": "Nouveau-né",
      "couple": "Paire",
      "pet": "Animal de compagnie",
      "commercial_advertising": "Commercial / Publicité",
      "fashion": "Mode",
      "model_portfolio": "Portefeuille de modèles",
      "event": "Événement",
      "headshots": "Tirs à la tête",
      "real_estate_and_architecture": "Immobilier et architecture",
      "other": "Autres"
    },
    "pipelineEvent": {
      "afterLeadCreated": "créé par lead",
      "afterJobAccepted": "accepté après le travail",
      "beforeJobScheduled": "avant le travail prévu",
      "afterJobScheduled": "après le travail prévu",
      "afterGalleryCreated": "créé par galerie",
      "afterPreviousStage": "par niveau précédent"
    },
    "paymentEvents": {
      "beforeJobDate": "avant la date du job",
      "onJobDate": "sur la date de l'emploi",
      "afterJobDate": "par date d'emploi",
      "afterInvoiceDate": "après la date de la facture"
    },
    "autocomplete": {
      "placeholder": "Choisir...",
      "noResultsText": "Aucun résultat trouvé",
      "loadingPlaceholder": "Chargement"
    },
    "salesDoc": {
      "debtor_number": "Numéro de client",
      "order_number": "Numéro de commande",
      "order_totals": "Total des commandes",
      "quote_from": "De",
      "quote": "Offre",
      "quote_for": "Offre pour",
      "quotes": "Offres",
      "new_quote": "Nouvelle offre",
      "invoice_from": "De",
      "invoice": "Facture",
      "invoice_date": "Date de la facture",
      "tax_invoice": "Facture",
      "order": "Commande",
      "credit_note": "Avoir",
      "credit_notes": "Crédits",
      "invoice_for": "Facture pour",
      "invoices": "Factures",
      "new_invoice": "Nouvelle facture",
      "cancellation_invoice_to": "Facture d'annulation à",
      "cancellation_invoice": "Facture d'annulation",
      "to_refunded": "A rembourser :",
      "positions": "Positions",
      "issued": "Délivré",
      "cancelled": "STORNO",
      "billing_period": "Période de facturation",
      "billing_period_not_valid": "Période de facturation non valide",
      "pro_rate": "au prorata",
      "name": "Nom",
      "description": "Description",
      "quantity": "Quantité",
      "unit_price": "Prix unitaire",
      "inc_tax": "Taxes comprises",
      "exc_tax": "Hors taxes",
      "subtotal": "Sous-total",
      "total": "Montant total",
      "amount_due": "Total net",
      "total_due": "Montant de la facture",
      "target": "Mandant",
      "due_date": "Échéance le",
      "to_pay": "Reste à payer :",
      "payed": "Paye",
      "payment": "Paiement",
      "download_csv_ready": "Exportation CSV terminée",
      "no_vat_eu_country": "Livraisons intracommunautaires exonérées d'impôt conformément au § 4 Nr. 1b UstG (loi allemande sur la TVA).",
      "no_vat_non_eu_country": "Livraison à l'exportation exonérée d'impôt conformément à l'article 6 de la loi allemande sur la TVA (UstG)",
      "thanks_for_order": "Nous vous remercions de votre commande. Sauf indication contraire, l'exécution des prestations susmentionnées aura lieu à la date de la présente facture.",
      "bill_by_date": "Veuillez virer le montant sur le compte suivant, en indiquant le numéro de la facture, avant le %date% :",
      "intended_use": "Motif de l'utilisation",
      "paid_with_cash_cc": "La facture a été payée en espèces / par carte EC",
      "amount_from_credit_card": "Le montant de %amount% sera débité de votre carte de crédit.",
      "amount_from_paypal": "Le montant de %amount% sera débité de votre compte PayPal.",
      "debit_from_account": "Le montant de %amount% sera débité de votre compte.",
      "register_and_taxes": "Registre du commerce & impôts",
      "managing_director": "Directeur général"
    },
    "provisionDoc": {
      "provision_calculation": "Calcul de la commission",
      "client": "Client",
      "invoice_no_date": "N° / date de la facture",
      "payedDate": "payé le",
      "serviceType": "Type de service",
      "amount": "Montant",
      "provisionRate": "Taux de commission",
      "provisionValue": "Commission",
      "totalAmount": "Montant total",
      "provision": "Commission",
      "provisions": "Commissions",
      "provision_for": "Commission pour",
      "download_pdf": "Télécharger le PDF"
    },
    "paymentStatus": {
      "late": "Tard",
      "paid": "Paye",
      "unpaid": "Non rémunéré",
      "inProgress": "En cours de traitement"
    },
    "shopItem": {
      "plus_shipping": "plus frais de port",
      "variants_possible": "Plusieurs choix sont possibles"
    },
    "sepaAgreement": {
      "sepa_direct_debit_mandate": "Mandat de prélèvement SEPA (autorisation de prélèvement)",
      "mandate_reference": "Référence du mandat",
      "will_be_communicated_separately": "sera communiquée séparément",
      "payee": "Bénéficiaire",
      "creditor": "Créancier",
      "payer": "Payeur",
      "i_allow_payee_direct_debit": "Par la présente, j'autorise le bénéficiaire à prélever des paiements sur mon compte par note de débit jusqu'à nouvel ordre. En même temps, je demande à mon institut de crédit d'encaisser les notes de débit tirées sur mon compte par le bénéficiaire du paiement.",
      "note_i_can_claim_reimbursement_within_eight_weeks": "Remarque : je peux demander le remboursement du montant débité dans un délai de huit semaines à compter de la date de débit. Les conditions convenues avec mon institut de crédit s'appliquent.",
      "document_issued_electronically_and_valid_without_signature": "Cette lettre a été rédigée à la machine et est valable même sans signature.",
      "shortening_notice_period": "Réduction du délai de préavis",
      "deadline_for_advance_information_of_direct_debit_is_shortened_to_one_day": "Le délai de notification préalable du prélèvement SEPA est réduit à un jour.",
      "i_issue_sepa_direct_debit_mandate": "Par la présente, je donne le mandat de prélèvement SEPA"
    },
    "sad": {
      "offer": "Offre",
      "modification": "Modification",
      "order": "Commande",
      "offers": "Offres",
      "orders": "Commandes",
      "offer_short": "Ang.",
      "order_short": "Best.",
      "conversion_rate_short": "CR",
      "conversion_rate": "Taux de conversion",
      "last_offer_date": "Dernière offre",
      "last_offer_date_short": "Dernière ang.",
      "car_data": "Données du véhicule",
      "class": "Classe",
      "model_name": "Nom du modèle",
      "model_name_short": "Nom du modèle (court)",
      "model_range": "Série",
      "body_design": "Forme de la carrosserie",
      "car_color": "Couleur",
      "base_price": "Prix de base",
      "list_price": "Prix catalogue",
      "optional_equipment": "Equipement spécial",
      "incl": "incl.",
      "factory_discount": "Patrimoine de l'usine",
      "cash_discount": "Remise en espèces",
      "volume_discount": "Rabais de quantité",
      "transportation_costs": "Frais de transfert",
      "price_reduction": "Réduction de prix",
      "car_image": "Image du véhicule à partir du configurateur",
      "discount": "Succession",
      "sales_price": "Prix de vente",
      "max_speed": "Vitesse maximale",
      "car_acceleration": "Accélération (0 - 100)",
      "online_code": "Code en ligne",
      "sales_activity": "Activité de vente",
      "last_modified": "Dernière modification",
      "offer_date": "Date de l'offre",
      "order_date": "Date de commande",
      "order_location": "Lieu de commande",
      "pre_contract_nr": "N° de commande V",
      "pre_contract_date": "V-Date de l'ordre",
      "contract_nr": "N° de commande",
      "shipment_place": "Lieu de livraison",
      "shipment_key": "Clé de livraison",
      "special_agreement": "Convention spéciale",
      "contact_person": "Personne de contact",
      "customer_account_number": "N° de compte client",
      "financing": "Financement",
      "leasing": "Leasing",
      "financing_product": "Produit",
      "paid_deposit": "Acompte",
      "paid_deposit_leasing": "Paiement spécial du leasing",
      "credit_amount": "Montant total du crédit",
      "monthly_rate": "Mensualité",
      "contract_duration": "Durée de validité",
      "total_mileage": "Kilométrage",
      "purchase_price": "Prix d'achat",
      "annual_percentage_rate": "Taux annuel effectif global",
      "nominal_interest_percentage_rate": "Taux débiteur lié p.a.",
      "debit_interest_percentage_rate": "Taux d'intérêt nominal p.a.",
      "total_amount": "Montant total",
      "final_instalment": "Paiement final",
      "application_date": "Date de la demande",
      "application_approval_date": "Date d'approbation",
      "application_rejection_date": "Date de refus",
      "client_private": "Privé",
      "client_business": "Commercial",
      "monthly_leasing_rate": "Mensualités de leasing",
      "monthly_financing_rate": "Mensualité de financement",
      "monthly_insurance_rate": "Cotisation mensuelle d'assurance automobile",
      "no_winleas_data_available": "Pas de données WinLEAS disponibles",
      "winleas_data_available": "Données de financement (F) et / ou de leasing (L) disponibles à partir de WinLEAS",
      "discount_cash": "Barkulanz",
      "discount_volume": "Remise sur la quantité/le recyclage",
      "discount_environmental": "Bonus écologique",
      "discount_additional": "Conditions supplémentaires",
      "deduction": "Réduction",
      "deduction_mbl": "Réduction MBL",
      "deduction_mbl_interest": "Réduction d'intérêt MBL",
      "deduction_consultant": "Conseiller de réduction",
      "deduction_consultant_interest": "Conseiller en réduction des intérêts",
      "commission": "Commission",
      "commission_standard": "Standard",
      "commission_addition_deduction": "Augmentation / diminution",
      "commission_subsequent_scope_of_supply": "Continuer à avancer. Contenu de la livraison",
      "commission_total": "Total",
      "subsidy": "Subvention",
      "subsidy_contract_partner": "Subvention VP",
      "subsidy_factory": "Subvention usine",
      "subsidy_mbvd": "Subvention MBVD",
      "shortcut_lp_kp": "LP/CP",
      "campaign": "Action",
      "service_rate": "Taux de service",
      "residual_value": "Valeur résiduelle",
      "table_residual_value": "Valeur résiduelle du tableau",
      "residual_value_change": "Modification de la valeur résiduelle",
      "ref": "en ce qui concerne",
      "net": "net",
      "gross": "brut",
      "list_price_short": "LP",
      "sales_price_short": "KP",
      "note_save": "Enregistrer la note",
      "notes": "Notes",
      "salesman": "Vendeur",
      "salesman_blacklist": "Vendeur (liste noire)",
      "salesman_number": "N° de vendeur",
      "salesman_number_short": "No.",
      "enterprise_number": "N° de l'établissement",
      "Mercedes": "Mercdes",
      "Smart": "Smart",
      "equipment_line": "Ligne",
      "packages": "Paquets",
      "upholstery": "Rembourrage",
      "single_equipments": "Autres",
      "included_in": "contenue dans",
      "store": "Filiale",
      "save_filterset": "Enregistrer le jeu de filtres",
      "internal_activity": "Affaires internes",
      "offer_made": "Offre créée",
      "in_progress": "En cours de traitement",
      "predecessor_offer": "Offre précédente / Duplicata",
      "lost": "Affaire perdue",
      "ordered": "Commande effectuée",
      "canceled": "Commande annulée"
    },
    "rad": {
      "earnings": "Rendement",
      "occupancy_rate": "Taux d'occupation",
      "amount": "Montant"
    },
    "external_account_status": {
      "login_fail": "Veuillez vérifier les données d'accès ! Dans le cas contraire, il n'est plus possible de lire les données.",
      "login_warning": "Veuillez vérifier les données d'accès !",
      "login_valid": "Données d'accès correctes",
      "last_successful_login": "Dernière connexion réussie"
    },
    "categoryitems_stock": "pièce",
    "initialize": "Init",
    "customer": "Société",
    "customer_": "Client",
    "select_mandant": "Sélectionner un mandant",
    "initial_title": "Titre initial du support publicitaire",
    "will_be_shown_to_the_user_in_frontend": "Est affiché à l'utilisateur dans le frontend",
    "producttemplate_data_saved": "Données du modèle enregistrées",
    "product_size": "Format / taille du produit",
    "template_upload_files": "Sélectionner le(s) fichier(s) à télécharger",
    "example_of_product_size": "par ex : DIN A4, DIN long...",
    "output_format": "Format de sortie",
    "product_size_width": "Largeur en pixels",
    "product_size_height": "Hauteur en pixels",
    "template_folder": "Chemin du modèle",
    "template_dpi": "DPI pour la sortie",
    "template_upload": "Téléchargement",
    "template_pageSpace": "Emplacements tramés",
    "output_exportType": "Type de sortie",
    "template_fonts": "Polices de caractères",
    "template_fontSizeMin": "Taille de la police min",
    "template_styles": "Données CSS & LESS",
    "template_fontSizeMax": "Taille de police max",
    "template_init_amount_of_pages": "Nombre de pages initial",
    "template_page_add_increase": "Rencontre coquine",
    "template_pageMaxAmount": "Nombre de pages max",
    "template_pageMinAmount": "Nombre de pages min",
    "template_pageTop": "Haut de padding",
    "template_pageBottom": "Padding Bottom",
    "template_pageFirst": "Numéro de page Page de couverture",
    "template_pageFirstFixed": "Page de couverture fixée",
    "template_pageLast": "Numéro de page Dernière page",
    "template_pageLastFixed": "Dernière page fixée",
    "template_endless": "Page sans fin",
    "template_fixed": "Côté fixé",
    "template_sample_page": "Numéro de page Contenu standard",
    "font_colors": "Couleurs du texte",
    "back_colors": "Couleurs de fond",
    "client_logo": "Logo de l'entreprise",
    "template_pageNumber": "Nombre de pages",
    "page_names": "Paramètres de la page",
    "add_contentBlock": "Ajouter un bloc de contenu",
    "css": "CSS général",
    "cssPDF": "CSS uniquement pour la sortie PDF",
    "cssPrintPDF": "CSS uniquement pour la sortie PDF imprimable",
    "cssWebsite": "CSS pour la sortie en tant que page web",
    "chosenItem": "Sélection actuelle de produits",
    "pdf_price": "Prix PDF",
    "auflagen": "Éditions",
    "print_prices": "Prix de base",
    "proof_base_price": "Prix de base vérification des données",
    "new_proof_base_price": "Nouveau prix de base Vérification des données",
    "get_vendor_price": "Consulter les prix",
    "add_variant_line": "Ajouter une ligne",
    "remove_all_item_variant": "Supprimer tous les tirages",
    "get_vendor_prices": "Appel de prix fournisseur",
    "get_vendor_id": "Demander l'ID produit du fournisseur",
    "no_vendor_id": "Aucun ID de produit fournisseur n'a été défini !",
    "cc_item_id": "ID du produit CreaCheck",
    "vendor_item_id": "ID du produit Fournisseur",
    "vendor_item_name": "Nom du produit Fournisseur",
    "vendor_item_missing_attributes": "Aucun prix ne peut être trouvé pour cette combinaison d'attributs.",
    "vendor_item_save_attributes": "Veuillez sélectionner les attributs !",
    "vendor_item_must_define_attributes": "Vous devez définir des attributs pour créer des variantes.",
    "vendor_item_has_no_attributes": "Cette famille de produits n'a pas d'attributs",
    "vendor_item_all_n_quantities": "Affiche tous les ensembles {quantityCount}.",
    "vendor": "Fournisseur",
    "internal_item_id": "ID interne",
    "select_vendor_product": "Sélectionner un groupe de produits",
    "item_type": "Type de produit",
    "print_item": "Produit imprimé",
    "website_item": "Site web",
    "normal_item": "Autre produit",
    "not_more_pages": "Il n'est pas possible de créer plus de noms de pages que de pages possibles !",
    "info_title_page": "La page de titre ne peut pas être déplacée ou supprimée",
    "info_last_page": "La dernière page ne peut pas être déplacée ou supprimée",
    "page_definition_page_count_info": "Numéro de page 0 = valeur par défaut pour toutes les pages sans définition, last pour la dernière page",
    "padding_on_top": "Distances en haut",
    "padding_on_bottom": "Distances en bas",
    "padding_on_left": "Distances à gauche",
    "padding_on_right": "Distances à droite",
    "delimitation_of_grid_blocks": "Il est possible d'ajouter des blocs de grille sans limite. La page devient alors de plus en plus longue.",
    "fixed_page": "La page est fixée et ne peut pas être déplacée.",
    "page_definition": "Définition de la page",
    "add_definition": "Ajouter une définition",
    "page_default_value": "Nombre de pages 0 = valeur par défaut pour toutes les pages sans définition",
    "page_count": "Nombre de pages",
    "page_widths": "Largeurs latérales",
    "page_heights": "Hauteurs des côtés",
    "no_special_formatting": "Pas de formatage particulier",
    "min_max_font_size": "Taille de police min/max",
    "activate_min_max_font_size": "Activer ou désactiver Min-/Max-FontSize",
    "filename": "Nom de fichier",
    "for_user": "Pour les utilisateurs",
    "user_can_use_font": "L'utilisateur peut utiliser cette police dans l'éditeur",
    "import_successful": "Importation terminée, consulte les autres onglets",
    "import_resets_template": "L'importation réinitialise le modèle, il doit être réinitialisé, toutes les éditions clients existantes avec ce modèle deviennent inutilisables !!!",
    "problem": "Un problème est survenu",
    "reading_pages": "Pages de lecture",
    "done": "Prêt",
    "versions": "Versions",
    "template_not_edited": "Ce modèle n'a pas encore été modifié.",
    "dataProof_infoText": "Lors d'un contrôle manuel des données, nos experts vérifient pour vous tous les critères techniques du prépresse. Parmi eux, l'imprimabilité, le respect de toutes les spécifications techniques et l'effet subjectif des couleurs. Vous trouverez des informations plus précises sur la vérification des données sous FAQ.",
    "media_library": "Médiathèque",
    "count": "Nombre",
    "welcome_message": "Bonjour, {nom}",
    "email": "Email",
    "website": "Site web",
    "phone": "Téléphone",
    "fax": "Fax",
    "password": "Mot de passe",
    "repeat_password": "Répéter le mot de passe",
    "company": "Société",
    "company_name": "Nom de l'entreprise",
    "company_data_timeline_tab_title": "Chronologie des données de l'entreprise",
    "company_data_success": "Les données de l'entreprise sont modifiées avec succès !",
    "company_data_error": "Erreur lors de la modification des données de l'entreprise !",
    "updated_by": "Mis à jour par",
    "companies": "Entreprises",
    "first_name": "Prénom",
    "last_name": "Nom de famille",
    "street": "Rue",
    "house_number": "Numéro de maison",
    "apartment_number": "Numéro d'appartement",
    "address_line2": "Ligne d'adresse 2",
    "zip_code": "Code postal",
    "city": "Ville",
    "country": "Pays",
    "currency": "Monnaie",
    "previous_year": "Année précédente",
    "vat_name": "Nom de la TVA",
    "vat_id_name": "Nom d'identification de la TVA",
    "vat_id": "Numéro d'identification à la TVA",
    "vat_rates": "Phrases VAT",
    "coc_number": "Numéro COC",
    "registered_at": "Inscrit à",
    "page": "Page",
    "page_of": "de",
    "save": "Enregistrer",
    "save_and_email": "Sauvegarde et e-mail",
    "add": "Ajouter",
    "add_client": "Ajouter une entreprise",
    "update_company_data": "Modifier le nom de l'entreprise",
    "add_user": "Ajouter un utilisateur",
    "add_product": "Ajouter un produit",
    "add_producttemplate": "Ajouter un modèle",
    "add_question": "Ajouter une question",
    "add_option": "Ajouter une option",
    "add_questionnaire": "Ajouter un questionnaire",
    "add_template": "Ajouter un modèle",
    "add_position": "Ajouter un poste",
    "add_pipeline": "Ajouter un pipeline",
    "add_quote": "Ajouter une offre",
    "add_invoice": "Ajouter une facture",
    "add_stage": "Ajouter un niveau",
    "add_gallery": "Ajouter une galerie",
    "add_job": "Ajouter un job",
    "add_payment_schedule": "Ajouter un plan de paiement",
    "add_payment": "Ajouter un paiement",
    "add_location": "Ajouter un site",
    "add_data_definition": "Ajouter une définition de données",
    "add_car": "Ajouter un véhicule",
    "add_document": "Ajouter un document",
    "add_document_type": "Ajouter un type de document",
    "add_team": "Ajouter une équipe",
    "add_brand": "Ajouter une marque",
    "add_model": "Ajouter un modèle",
    "add_model_group": "Ajouter un groupe de modèles",
    "add_model_asset": "Ajouter une image de modèle",
    "add_category": "Ajouter une catégorie",
    "add_attribute": "Ajouter une propriété",
    "add_attribute_group": "Ajouter un groupe d'attributs",
    "add_value": "Ajouter une valeur",
    "add_variant": "Ajouter une variante",
    "add_lead": "Ajouter un lead",
    "add_task": "Ajouter une tâche",
    "add_page": "Ajouter une page",
    "add_data_push": "Ajouter un push de données",
    "add_white_label": "Ajouter un projet en marque blanche",
    "add_menu_element": "Ajouter un élément de menu",
    "add_secondary_menu_element": "Ajouter un élément de menu secondaire",
    "add_cms_element": "Ajouter un élément CMS",
    "add_lead_catcher": "Ajouter un lead catcher",
    "add_white_label_plugin": "Ajouter un plugin en marque blanche",
    "add_lead_per_mail_rule": "Ajouter un lead par e-mail Règle",
    "add_message": "Ajouter un message",
    "add_car_service": "Ajouter un service",
    "add_car_service_station": "Ajouter un poste de travail",
    "add_team_member": "Ajouter un membre de l'équipe",
    "add_document_template": "Ajouter un modèle de document",
    "add_lead_autoresponse": "Ajouter un lead auto-réponse",
    "add_main_menu_version": "Ajouter une version du menu principal",
    "add_lead_tagger": "Ajouter un lead tagger",
    "add_lead_assignment_strategy": "Ajouter une stratégie d'attribution",
    "add_escalation_rule": "Ajouter une règle d'escalade",
    "add_coupon": "Ajouter un bon d'achat",
    "add_company": "Ajouter une entreprise",
    "add_item": "Ajouter un produit",
    "add_manual_entry": "ajouter une saisie manuelle",
    "add_allowed_domain": "Ajouter un domaine autorisé",
    "update_allowed_domain": "Mettre à jour le domaine autorisé",
    "send_message": "Envoyer un message",
    "send": "Envoyer",
    "details": "Détails",
    "delete": "Supprimer",
    "undelete": "Restaurer",
    "undelete_user_confirm": "Restaurer l'utilisateur ?",
    "or": "ou",
    "actions": "Actions",
    "client": "Société",
    "job": "Emploi",
    "active_jobs": "Emplois actifs",
    "questionnaire": "Questionnaire",
    "review_and_send": "Vérifier et envoyer",
    "download": "Télécharger",
    "duplicate": "Dupliquer",
    "client_name": "Nom",
    "address": "Adresse",
    "title": "Titre",
    "uploading": "Télécharger",
    "status": "Statut",
    "next_stage": "Prochaine étape",
    "type": "Type",
    "schedule": "Horaire",
    "location": "Site",
    "sent_on": "Envoyé le",
    "name": "Nom",
    "description": "Description",
    "bill_number": "Numéro de facture",
    "quote_number": "Numéro de l'offre",
    "do_sort": "Trier",
    "date": "Date",
    "gallery": "Galerie",
    "questionnaires": "Questionnaires",
    "job_takes_whole_day": "Ce travail prend toute la journée",
    "from": "De",
    "to": "À l'adresse",
    "question": "Question",
    "subject": "Sujet",
    "content": "Contenu",
    "template": "Modèle",
    "stage_has_due_date": "Cette étape a une date d'échéance",
    "stage_sends_automated_email": "Cette étape envoie un e-mail automatisé",
    "day": "Jour",
    "days": "Jours",
    "hours": "Heures",
    "review": "Vérifier",
    "timezone": "Fuseau horaire",
    "client_since": "Société ajoutée le",
    "user_since": "Utilisateur depuis",
    "payment_instructions": "Instructions de paiement",
    "bank_name": "Nom de la banque",
    "debtor_number": "ID client",
    "account_holder": "Titulaire du compte",
    "account_number": "Numéro de compte",
    "bank_location": "Site de la banque",
    "bank_code_number": "Code bancaire",
    "iban": "IBAN",
    "swift_bic": "SWIFT/BIC",
    "company_logo": "Logo de l'entreprise",
    "percent_of_order": "Pourcentage de la commande",
    "percent_of_remaining_balance": "Pourcentage du solde",
    "amount": "Montant",
    "due": "Échéance",
    "payment_schedule": "Plan de paiement",
    "record_payment": "Enregistrer le paiement",
    "mark_as_unpaid": "Marquer comme non payé",
    "close": "Fermer",
    "yes": "Oui",
    "want_to_mark_the_payment_as_paid": "Voulez-vous vraiment marquer ce paiement comme étant payé ?",
    "want_to_mark_the_payment_as_unpaid": "Voulez-vous vraiment marquer ce paiement comme non payé ?",
    "payment_marked_as_paid": "Paiement consigné",
    "payment_marked_as_unpaid": "Paiement marqué comme impayé",
    "overdue_payments": "Paiements en retard",
    "next_payment": "Prochain paiement",
    "quote_accepted": "Offre acceptée",
    "quote_rejected": "Offre refusée",
    "todays_tasks": "Les tâches actuelles",
    "overdue_tasks": "Tâches en retard",
    "overdue_and_upcoming_payments": "Paiements en retard et à venir",
    "jobs_in_progress": "Emplois en cours",
    "appointments": "Dates",
    "appointment": "Rendez-vous",
    "last_update": "Dernière mise à jour",
    "mark_as_completed": "Marquer comme terminé",
    "want_to_mark_stage_complete": "Souhaitez-vous marquer l'étape comme étant terminée ?",
    "stage_marked_complete": "Étape marquée comme terminée",
    "gallery_download": "Télécharger l'image",
    "gallery_sharing": "Partager la galerie",
    "gallery_social_media": "Images sur les médias sociaux",
    "preview": "Aperçu",
    "invoice_preview": "Aperçu de la facture",
    "preview_pdf": "PDF d'aperçu",
    "message": "Message",
    "signup": "S'inscrire",
    "continue": "Continuer",
    "password_assistance": "Aide sur les mots de passe",
    "password_renewing": "Nouveau mot de passe",
    "not_any_user_information": "Aucune information sur l'utilisateur.",
    "failed_redirection": "Échec de la redirection.",
    "renew_password": "Avant de pouvoir vous connecter, vous devez créer un nouveau mot de passe. Nous venons de vous envoyer un e-mail avec des instructions.",
    "get_new_password": "Demander un nouveau mot de passe",
    "set_new_password": "Indiquez votre nouveau mot de passe",
    "forgot_password": {
      "infotext": "Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser ici. Veuillez saisir votre adresse e-mail enregistrée dans le formulaire ci-dessous et cliquer sur \"Continuer\". Nous vous enverrons ensuite un e-mail avec plus d'informations.",
      "infotext_robethood": "Si tu as oublié ton mot de passe, tu peux le réinitialiser ici. Veuillez saisir l'adresse e-mail enregistrée dans le formulaire ci-dessous et cliquer sur \"Continuer\". Nous t'enverrons ensuite un e-mail avec plus d'informations.",
      "info_email_not_used": "Si vous n'utilisez plus l'adresse e-mail associée à votre compte Creacheck, veuillez nous contacter via notre formulaire de contact : {link}.",
      "info_email_not_used_robethood": "Si tu n'utilises plus l'adresse e-mail associée à ton compte, contacte-nous via notre formulaire de contact : {link}.",
      "enter_new_password": "Il n'y a que cette étape devant vous pour vous connecter à Creacheck. Il vous suffit de saisir deux fois votre nouveau mot de passe. C'est aussi simple que ça."
    },
    "thanks_for_signing_up": "Merci de vous être enregistré",
    "confirm_your_email": "Confirmez votre adresse e-mail",
    "signed_up_gratulations": "Bienvenue sur CreaCheck. Pour terminer le processus d'inscription, nous vous avons envoyé un e-mail. Veuillez confirmer votre inscription.",
    "signed_up_gratulations_robethood": "Bienvenue sur le site. Pour terminer le processus d'inscription, nous t'avons envoyé un e-mail. Merci de confirmer ton inscription.",
    "check_email_for_message_from_us": "Veuillez vérifier votre boîte de réception électronique.",
    "check_email_for_message_from_us_robethood": "Vérifie ta boîte de réception électronique",
    "new_clients": "Nouveaux clients",
    "users": "Utilisateur",
    "user": "Utilisateur",
    "won_from_count_leads": "Gagné de {compte} leads",
    "deals_won_out_of_count": "Deals gagnés par {count}",
    "new_badge": "Nouveau",
    "answer_badge": "Réponse",
    "accept": "Accepter",
    "reject": "Refuser",
    "quote_rejected_on_date": "Offre refusée le {date}",
    "quote_accepted_on_date": "Offre acceptée le {date}",
    "want_to_accept_the_quote": "Vous souhaitez accepter l'offre ? L'entrepreneur sera directement informé de votre décision.",
    "want_to_reject_the_quote": "Vous souhaitez refuser l'offre ? Le contractant sera directement informé de votre décision.",
    "want_to_convert_quote_to_invoice": "Souhaitez-vous convertir cette offre en facture ?",
    "convert_quote_to_invoice": "Convertir l'offre en facture",
    "convert_to_invoice": "Convertir en facture",
    "convert_quote_from_template": "Convertir une offre en un autre modèle",
    "completed_on_date": "Terminé le {date}",
    "due_date_on_date": "Date d'échéance {date}",
    "due_date": "Date d'échéance",
    "current_stages_due_date": "Date d'échéance de l'étape actuelle",
    "go_pro": "Go Pro",
    "recent_activity": "Dernière activité",
    "load_more": "Charger davantage",
    "your_galleries": "Tes galeries",
    "unread_by_you": "Non lu par vous",
    "count_images": "{compte} images",
    "view": "Voir",
    "filled_out_on": "Rempli le",
    "filled_out_by": "Rempli par",
    "you_answered_the_questionnaire_on_date": "Vous avez répondu au questionnaire le {date}.",
    "company_sent_a_questionnaire": "{company} a envoyé un questionnaire et demande des réponses",
    "allow_to_download_full_res": "Permettez au client de télécharger des images en pleine résolution",
    "allow_to_share_with_friends": "Permet au client de partager la galerie avec ses amis",
    "allow_to_share_on_social_media": "Permettez au client de partager des images via les médias sociaux",
    "got_it": "OK",
    "learn_more": "En savoir plus",
    "this_website_uses_cookies": "Notre site utilise des cookies pour s'assurer que vous bénéficiez de la meilleure expérience sur notre site.",
    "are_you_sure": "Vous êtes sûr ?",
    "no": "Non",
    "closed_please_add_timeframe": "<span><span className='text-danger semi-bold'>Fermé</span> - veuillez ajouter un délai</span>.",
    "not_active_please_add_timeframe": "<span><span className='text-danger semi-bold'>Non actif</span> - veuillez ajouter un délai</span>.",
    "note": "Note",
    "change_password": "Modifier le mot de passe",
    "roles": "Rôles et droits",
    "active_set": "Abonnement actuel",
    "infos": "Informations",
    "documents": "Documents",
    "document": "Document",
    "financial": "Financier",
    "bookingAccountNumber": "Compte de comptabilisation",
    "costAccountNumber": "Centre de coûts",
    "locations": "Sites",
    "white_label": "Marque blanche",
    "show_password_text": "Afficher le texte du mot de passe",
    "parent": "Parent",
    "change_parent": "Changer de parent",
    "cancel": "Annuler",
    "timeline_undo": "Annuler",
    "confirm_action_ramifications": "Veuillez confirmer que vous comprenez l'impact de votre action",
    "client_will_not_be_visible_in_search_results": "Le client sélectionné n'est plus visible dans votre outil de recherche de clients.",
    "changing_parent_takes_a_while": "La modification du précurseur peut prendre un certain temps",
    "load_message_from_template": "Charger un message à partir du modèle",
    "questions": "Questions",
    "search": "Rechercher",
    "search_for": "Recherche de",
    "dynamic_data_definitions": "Définitions de données dynamiques",
    "dynamic_data_definition": "Définition dynamique des données",
    "data_identifier": "Identificateur de données",
    "cars": "Véhicules",
    "rentals": "Location",
    "no_car_available": "Pas de voiture disponible",
    "file": "Fichier",
    "files": "Fichiers",
    "teams": "Équipes",
    "team": "Équipe",
    "document_types": "Types de documents",
    "document_type": "Type de document",
    "system_type": "Type de système",
    "brands": "Marques",
    "models": "Modèles",
    "model": "Modèle",
    "model_groups": "Groupes de modèles",
    "model_group": "Groupe de modèles",
    "model_assets": "Images de modèles",
    "model_asset": "Image du modèle",
    "categories": "Catégories",
    "attributes": "Attributs",
    "attribute": "Attribut",
    "values": "Valeurs",
    "value": "Valeur",
    "select_category": "Choisir une catégorie",
    "position": "Position",
    "color": "Couleur",
    "car": "Produit",
    "category": "Catégorie",
    "brand": "Marque",
    "required": "Champ obligatoire",
    "multilanguage": "Plusieurs langues",
    "seo": "SEO",
    "page_title": "Titre de la page",
    "keywords": "Mots clés",
    "item": "Article",
    "sku": "SKU",
    "variants": "Variantes",
    "variant": "Variante",
    "price": "Prix",
    "prices": "Prix",
    "variant_attributes": "Attributs de la variable",
    "no_value_set": "Aucune valeur définie",
    "identification": "Identification",
    "templates": "Modèles",
    "vin": "FIN",
    "leads": "Leads",
    "lead": "Lead",
    "order_it": "Commander",
    "order": "Commande",
    "orders": "Commandes",
    "images": "Images",
    "image": "Image",
    "upc": "UPC",
    "visibility": "Visibilité",
    "show_item_online": "Produit visible en ligne",
    "use_as_resource": "Utiliser comme ressource",
    "stock_and_prices": "Stock et prix",
    "sales_price": "Prix de vente",
    "sales_point": "Ventes",
    "warehouse": "Entrepôt",
    "stock_level": "Stock",
    "rent_price": "Prix de location",
    "pseudo_price": "Pseudo-prix",
    "express_price": "Prix express",
    "overnight_price": "Prix overnight",
    "sameday_price": "Prix Sameday",
    "basic_price": "Prix de base",
    "weekday_price": "Jour de la semaine Prix",
    "weekend_price": "Prix du week-end",
    "week_price": "Prix hebdomadaire",
    "month_price": "Prix mensuel",
    "unit_of_measure": "Unité de mesure",
    "basic_unit": "Unité de base",
    "packaging_unit": "Unité d'emballage",
    "subscribe": "S'abonner",
    "all": "Tous les",
    "pipeline": "Pipeline",
    "pipelines": "Pipelines",
    "default_team": "Équipe standard",
    "select_pipeline": "Sélectionner un pipeline",
    "please_select_pipeline_first": "Veuillez d'abord sélectionner un pipeline",
    "view_type": "Vue",
    "stage": "Niveau",
    "stages": "Marches",
    "properties": "Caractéristiques",
    "unassigned": "Sans affectation",
    "assignee": "Responsable du traitement",
    "order_type": "Type de commande",
    "internal_comment": "Commentaire interne",
    "comments": "Commentaires",
    "comment": "Commentaire",
    "no_comments_yet": "Il n'y a pas encore de commentaires",
    "show_all_comments": "Voir tous les commentaires",
    "people": "Personnes",
    "watchers": "Observateur",
    "dates": "Dates",
    "date_created": "Créé",
    "date_updated": "Actualisé",
    "closed": "Fermé",
    "start_watching_lead": "Commence à observer ce lead",
    "stop_watching_lead": "Arrête de regarder ce lead",
    "no_longer_watching_lead": "Vous n'observez plus ce lead",
    "watching_lead": "Vous observez ce lead",
    "wrote_comment": "A écrit un commentaire",
    "replied_to_comment": "A répondu à un commentaire",
    "language": "Langue",
    "languages": "Langues",
    "primary_language": "Langue principale",
    "secondary_languages": "Langues secondaires",
    "won": "Gagné",
    "lost": "Perdu",
    "change_pipeline": "Modifier le pipeline",
    "start_pipeline": "Démarrer le pipeline",
    "pipeline_started": "Pipeline lancé",
    "change_stage": "Changer de niveau",
    "switch_team": "Changer d'équipe",
    "open": "Ouvert",
    "budget": "Budget",
    "always_available": "Toujours disponible",
    "default_location": "Site standard",
    "selected_category_has_no_attributes": "La catégorie sélectionnée n'a pas d'attributs",
    "car_overview": "Aperçu des véhicules",
    "make_reservation": "Réserver",
    "reservation": "Réservation",
    "cms_pages": "Pages",
    "grid_fluid": "Liquide",
    "grid_normal": "Normal",
    "cms_add_container": "Ajouter un conteneur",
    "cms_add_row": "Ajouter une ligne",
    "cms_add_element": "Ajouter un élément",
    "media_files": "Fichiers multimédias",
    "upload": "Télécharger",
    "page_design": "Conception des pages",
    "translations": "Traductions",
    "translate": "Traduire",
    "category_filters": "Filtre de catégorie",
    "attribute_groups": "Groupes d'attributs",
    "select_attribute": "Choisir un attribut",
    "display": "Représenter",
    "select_multiple": "Choix multiple",
    "use_null_as_min_val": "Définir 0 comme minimum",
    "filter": "Filtre",
    "do_filter": "Filtrer",
    "mobile_phone": "Téléphone portable",
    "mark_lead_won": "Marquer le lead comme gagné",
    "mark_lead_lost": "Marquer le lead comme perdu",
    "mark_lead_deleted": "Marquer le lead comme supprimé",
    "reopen_lead": "Réouvrir le lead",
    "won_reason": "Raison de la victoire",
    "lost_reason": "Raison de la perte",
    "delete_reason": "Raison de la suppression",
    "reopen_reason": "Raison de la réouverture",
    "no_reason_specified": "Aucune raison indiquée",
    "lead_marked_won": "Lead marké gagné. Félicitations",
    "lead_marked_lost": "Lead marqué perdu",
    "lead_marked_deleted": "Lead marqué supprimé",
    "lead_reopened": "Lead rouvert",
    "stage_changed": "Niveau de lead modifié",
    "pipeline_changed": "Lead Pipeline modifié",
    "team_changed": "Lead affecté à une autre équipe",
    "current_pipeline": "Pipeline actuel",
    "current_stage": "Niveau actuel",
    "file_extension": "Extension",
    "file_size": "Taille",
    "file_uploaded": "Téléchargé",
    "no_client_assigned": "Pas de client attribué",
    "no_products_assigned": "Aucun produit attribué",
    "no_order_assigned": "Aucune commande affectée",
    "planned_duration_in_days": "Durée prévue (jours)",
    "lead_budget_must_be_set": "Le budget des leads doit être fixé",
    "client_must_be_assigned": "Le client doit être attribué",
    "order_must_be_assigned": "La commande doit être attribuée",
    "product_must_be_assigned": "Le produit doit être attribué",
    "lead_has_to_have_following_documents": "Lead doit avoir les documents suivants (les documents doivent être valables)",
    "client_has_to_have_following_documents": "Le client doit avoir les documents suivants (les documents doivent être valables)",
    "count_days": "{compte, pluriel, un {# jour} autre {# jours}}",
    "longer": "Plus long",
    "date_from": "Date de",
    "time_from": "Temps de",
    "date_to": "Date jusqu'à",
    "time_to": "Temps jusqu'à",
    "rate": "Taux",
    "car_rental_weekday": "Location - Taux jour de la semaine",
    "car_rental_weekend": "Location - Tarifs week-end",
    "car_rental_week": "Location - Tarifs à la semaine",
    "car_rental_month": "Location - Mois Taux",
    "document_overdue_please_review": "Document en retard. Veuillez vérifier",
    "count_cars": "{compte, pluriel, un {# véhicule} autres {# véhicules}}",
    "count_products": "{compte, pluriel, un {# produit} autre {# produits}}",
    "we_found_count_cars": "Nous avons trouvé {compte, pluriel, un {# véhicule} d'autres {# véhicules}}.",
    "we_found_count_products": "Nous avons trouvé {compte, pluriel, un {# produit} autres {# produits}}.",
    "delete_all_filters": "Supprimer tous les filtres",
    "image_recognition_tags": "Tags détectés à partir de l'image",
    "username": "Nom d'utilisateur",
    "handover_must_be_performed": "Le procès-verbal de remise du véhicule doit être rempli",
    "document_pages": "Pages du document",
    "pages": "Pages",
    "handover": "Remise",
    "mileage": "Kilométrage",
    "client_documents": "Documents clients",
    "lead_documents": "Documents de référence",
    "fuel_level": "Niveau de carburant",
    "start_editing": "Démarrer le traitement",
    "design": "Design",
    "responsive": "Responsive",
    "back": "Retour",
    "use_text_description": "Utiliser la description du texte",
    "use_visual_builder": "Utiliser le Builder visuel",
    "set_image": "Régler l'image",
    "select_image": "Sélectionner une image",
    "uploaded_by_me": "Téléchargé par moi",
    "current_item": "Article actuel",
    "current_page": "Page actuelle",
    "difficulties": "Difficultés",
    "damages": "Dommages",
    "damage_note": "Avis de dommage",
    "no_damages_in_protocol": "Aucun dommage dans le protocole",
    "add_damage": "Ajouter un dommage",
    "my_company": "Mon entreprise",
    "user_administration": "Gestion des utilisateurs",
    "client_administration": "Gestion des contacts",
    "crm_administraion": "Gestion du CRM",
    "content_administration": "Gestion du contenu",
    "product_administration": "Gestion des produits",
    "producttemplate_administration": "Gestion des templates",
    "car_service_administration": "Gestion de l'atelier",
    "site_administration": "Administration du site web",
    "data_push": "Push de données",
    "client_has_no_ancestors": "Le client n'a pas d'ancêtres",
    "domain": "Domaine",
    "entity": "Entité",
    "event": "Événement",
    "endpoint_url": "URL du point final",
    "authentication": "Authentification",
    "headers": "En-têtes",
    "none": "Pas de",
    "social_media": "Médias sociaux",
    "style": "Style",
    "html_header": "En-tête HTML",
    "custom_html_header": "En-tête HTML défini par l'utilisateur",
    "css_file_name": "Nom de fichier CSS défini par l'utilisateur",
    "custom_css": "CSS personnalisé",
    "custom_less": "LESS personnalisé",
    "custom_font_folder": "Sélectionner le répertoire de polices",
    "custom_font": "Téléchargement d'une police personnalisée",
    "custom_css_mail": "CSS personnalisé pour les e-mails",
    "main_menu": "Menu principal",
    "menu_css_class": "Menu principal Classe CSS",
    "menu_css_class_scroll": "Menu principal Classe CSS au défilement",
    "white_label_projects": "Projets en marque blanche",
    "imprint": "Mentions légales",
    "terms_of_service": "CONDITIONS GÉNÉRALES DE VENTE",
    "rental_terms_of_service": "Conditions générales de location",
    "car_sales_terms_of_service": "Conditions générales de vente de véhicules d'occasion",
    "item_sales_terms_of_service": "Conditions générales de vente des produits",
    "privacy_policy": "Protection des données",
    "cancellation_policy": "Information sur le droit de rétractation",
    "footer": "Pied de page",
    "hide_footer": "Masquer le pied de page",
    "unknown_property": "Paramètre inconnu",
    "unknown": "inconnu",
    "price_from": "à partir de",
    "price_to": "jusqu'à",
    "slider_from": "à partir de",
    "slider_to": "jusqu'à",
    "within": "dans un délai de",
    "of": "de",
    "within_count_km_of_place": "à moins de {count}km de {place}",
    "click_to_start_geolocation": "Cliquez ici pour commencer la géolocalisation",
    "geolocation_error": "Nous avons eu un problème pour identifier votre position, ou votre position n'est pas connue. Veuillez saisir votre position manuellement.",
    "count_km_from": "km de",
    "car_power": "Puissance",
    "car_power_unit": "PS",
    "car_gear": "Boîte de vitesses",
    "car_capacity": "Cylindrée",
    "car_capacity_unit": "cm³",
    "car_fueltype": "Type de carburant",
    "car_type": "Type de véhicule",
    "car_num_previous_owners": "Nombre de détenteurs de véhicules",
    "car_num_doors": "Nombre de portes",
    "car_num_seats": "Nombre de places assises",
    "car_emission_class": "Classe de pollution",
    "car_condition": "État du véhicule",
    "car_construction_year": "Année de construction",
    "car_color_manufacturer": "Couleur (fabricant)",
    "car_parking_assistance": "Aide au stationnement",
    "car_interior_material": "Matériau de l'équipement intérieur",
    "car_airbags": "Airbags",
    "car_air_conditioning": "Climatisation",
    "car_environment_badge": "Plaquette environnementale",
    "car_co2_emissions": "Émissions de CO2",
    "car_co2_emissions_unit": "g/km",
    "car_fuel_consumption": "Consommation",
    "car_fuel_consumption_unit": "l/100km",
    "combined": "combiné",
    "combined_short": "combiné",
    "urban": "en ville",
    "extra_urban": "hors agglomération",
    "energy_efficiency_class": "Classe d'efficacité énergétique",
    "technical_data": "Données techniques",
    "first_registration": "Première immatriculation",
    "proceed_to_checkout": "Aller à la caisse",
    "home": "Page d'accueil",
    "checkout": "Caisse",
    "your_cart": "Ton panier d'achat",
    "your_car": "Votre voiture",
    "your_data": "Vos données",
    "services": "Service",
    "shipping_information": "Transfert",
    "payment_details": "Paiement",
    "summary": "Résumé",
    "find_out_more": "En savoir plus",
    "qty": "Quantité",
    "checkout_next": "Continuer",
    "checkout_previous": "Retour",
    "please_select": "Veuillez sélectionner",
    "enum_many_glue": ",",
    "enum_last_glue": " et",
    "enum_end": " sélectionner",
    "show_details": "Afficher les détails",
    "add_to_cart": "Ajouter au panier",
    "show_cart": "Voir le panier",
    "cart": "Panier d'achat",
    "missingQuantity": {
      "cart": "panier d'achat"
    },
    "cart_is_empty": "Le panier est vide",
    "item_already_in_cart": "Cet article se trouve déjà dans votre panier",
    "remove": "Supprimer",
    "removed_from_cart": "a été supprimé du panier",
    "continue_with_login": "Continuer avec le login",
    "dont_have_an_account": "Pas encore de compte",
    "continue_without_login": "Continuer sans se connecter",
    "already_have_an_account_enter_username_and_password": "Vous avez déjà un compte ? Veuillez saisir votre nom d'utilisateur et votre mot de passe dans le formulaire ci-dessous.",
    "continue_without_login_and_register": "Vous pouvez poursuivre le processus d'achat et créer votre compte ici.",
    "continue_booking_without_login_and_register": "Vous pouvez poursuivre le processus de réservation et créer votre compte ici",
    "online_discount": "Réduction en ligne",
    "your_personal_data": "Vos données personnelles",
    "login_data": "Données d'accès",
    "shipping": "Expédition",
    "payment_cash": "Espèces",
    "payment_creditCard": "Carte de crédit",
    "payment_payPal": "PayPal",
    "payment_wire_transfer": "Virement bancaire",
    "payment_bill": "Facture",
    "choose_different_payment_method": "Choisir un autre mode de paiement",
    "i_will_pick_up_my_car": "Je vais chercher ma voiture",
    "i_will_pick_up_my_order": "Je vais chercher ma commande",
    "i_want_my_car_delivered": "Je souhaite que mon véhicule soit livré.",
    "i_want_my_order_delivered": "Je souhaite que ma commande soit livrée",
    "we_could_not_calculate_route_to_address": "Nous n'avons pas pu calculer l'itinéraire vers l'adresse indiquée. Veuillez vérifier et réessayer.",
    "credit_card_holder": "Titulaire de la carte de crédit",
    "credit_card_number": "Numéro de carte de crédit",
    "car_registration": "Immatriculation des véhicules",
    "car_insurance": "Assurance automobile",
    "service_provided_in_country_by_company": "Service fourni dans {country} par {company}",
    "i_wish_to_have_car_registered": "Oui, je souhaite utiliser le service d'immatriculation",
    "i_wish_to_get_car_insurance": "Je souhaite souscrire une assurance automobile",
    "today": "Aujourd'hui",
    "in_10_days": "Dans 10 jours",
    "your_money_is_safe_with_us": "Ton argent est en sécurité chez nous",
    "car_transport": "Transport de voitures",
    "delivery": "Livraison",
    "decide_car_delivery_or_pickup": "C'est à vous de décider si vous voulez venir chercher votre voiture vous-même ou si vous voulez que votre voiture soit livrée. Dans les deux cas, la voiture sera en parfait état, propre et avec le plein d'essence.",
    "secured_payment_until_car_is_delivered": "Paiement sécurisé jusqu'à la livraison du véhicule",
    "e_payment_to_reserve_car_and_secure_services": "Paiement électronique pour réserver la voiture et assurer les services choisis",
    "e_payment_credit_card_paypal": "Paiement électronique (carte de crédit ou PayPal)",
    "multiple_secure_options_to_pay_for_the_car": "Possibilités de paiement sécurisé pour la voiture (virement bancaire, en espèces lors de l'enlèvement)",
    "buy_car_by_clicking_button": "Vous pouvez acheter cette voiture maintenant en cliquant sur le bouton ci-dessous",
    "payment_with_credit_card_ending_last4_expiring_exp_month_exp_year_issued_to_card_holder": "Paiement par carte de crédit se terminant par {last4} et expirant dans {expMonth}/{expYear}, émis au nom de {cardHolder}",
    "click_on_paypal_checkout_to_continue": "Veuillez cliquer sur le bouton Paypal Checkout pour continuer.",
    "paypal_is_approved_continue_to_summary": "Votre paiement PayPal a été autorisé. Veuillez passer au récapitulatif pour finaliser votre commande et autoriser le paiement.",
    "paypal_buttons_loading": "Chargement des boutons PayPal...",
    "please_wait_saving_order": "Enregistrer la commande. Veuillez patienter...",
    "error_loading_paypal_scripts": "Les scripts PayPal n'ont pas pu être chargés. Veuillez fermer ce popup et l'ouvrir à nouveau.",
    "link": "Lien",
    "hide_on_devices": "Masquer sur les appareils",
    "mobile_phones": "Téléphones portables",
    "tablets": "Tablettes",
    "desktop_pcs": "Ordinateurs de bureau",
    "show_content_on_mouse_over": "Afficher le contenu lorsque la souris est au-dessus",
    "menu_content": "Menu Contenu",
    "link_to_page": "Lien vers la page",
    "page_has_no_translations": "Cette page n'a pas de traductions",
    "search_results": "Résultats de la recherche",
    "press_enter_to_search": "Appuyez sur la touche \"Entrée\" pour rechercher",
    "no_results_found": "Aucun résultat trouvé",
    "create_new_entry_now": "Vous pouvez maintenant créer une nouvelle entrée :",
    "no_results_for_your_search": "Nous n'avons pas trouvé de résultats pour votre recherche",
    "change_image": "Modifier l'image",
    "employee_data": "Données des employés",
    "newsletter": "Autorisation de recevoir la newsletter",
    "editorAutosave": "Enregistrement automatique dans l'éditeur",
    "is_profile_public": "Le profil est-il public ?",
    "parent_page": "Site parent",
    "cms_elements": "Éléments CMS",
    "cms_element": "Élément CMS",
    "new_lead_from_website": "Nouveau lead du site web",
    "website_settings": "Paramètres du site",
    "profile": "Profil",
    "my_bills": "Mes factures",
    "integrations": "Intégrations",
    "email_templates": "Modèles de courriels",
    "cms_templates": "Modèles CMS",
    "cms_template": "Modèle CMS",
    "select_brand": "Choisir une marque",
    "all_brands_visible": "Toutes les marques sont visibles",
    "contact_us": "Contactez-nous",
    "thank_you": "Merci beaucoup",
    "thank_you_for_contacting_us": "Nous vous remercions de nous avoir contactés. Nous avons bien reçu votre demande et nous vous contacterons dès que possible.",
    "cms_standard": "Standard",
    "select_element": "Sélectionner un élément CMS",
    "divider": "Diviseur",
    "testimonial": "Recommandation",
    "hero_unit": "Élément Hero",
    "image_carousel": "Slider",
    "call_to_action": "Appel à l'action",
    "bar_counter": "Compteur à barres",
    "products": "Produits",
    "producttemplates": "Modèles",
    "single_product": "Produit unique",
    "single_producttemplate": "Modèle unique",
    "lead_form": "Formulaire de prospection",
    "image_carousel_element": "Image du slider",
    "cms_grid": "Conteneur",
    "cms_row": "Ligne",
    "cms_col": "Colonne",
    "text_color": "Couleur du texte",
    "bar_counter_now": "Maintenant",
    "shadow": "Ombre",
    "no_shadow": "Pas d'ombre",
    "small": "Petit",
    "big": "Grand",
    "rounded_corners": "Coins arrondis",
    "no_rounded_corners": "Pas de coins arrondis",
    "image_position": "Position de l'image",
    "icon": "Ikon",
    "icon_class": "Classe d'icônes (CSS)",
    "position_top": "En haut",
    "position_left": "Liens",
    "bar_color": "Couleur de la barre",
    "bar_background_color": "Couleur d'arrière-plan des barres",
    "button_icon": "Icône du bouton",
    "button_text": "Texte du bouton",
    "full_height": "Pleine hauteur",
    "padding_top": "Distance en haut",
    "padding_bottom": "Distance en bas",
    "lead_source": "Source de leads",
    "change_item": "Modifier l'article",
    "show_infos_on_top": "Afficher les infos en haut",
    "show_infos_on_bottom": "Afficher les infos ci-dessous",
    "condensed": "Condensé",
    "assign_item": "Attribuer un produit",
    "image_animation": "Animation d'images",
    "no_animation": "Pas d'animation",
    "fade_in": "Fade in",
    "from_left": "De gauche à droite",
    "from_right": "Depuis la droite",
    "from_top": "Depuis le haut",
    "from_bottom": "Depuis le bas",
    "image_gallery": "Galerie d'images",
    "elements": "Éléments",
    "accordion": "Accordéon",
    "map": "Carte",
    "height": "Hauteur",
    "video": "Vidéo",
    "link_to_video": "Lien vers la vidéo",
    "video_possible_sources": "Les vidéos des portails suivants sont supportées: : YouTube, Vimeo, Dailymotion",
    "autoplay": "Lecture automatique",
    "aspect_ratio": "Rapport d'aspect",
    "image_compare": "Comparer l'image",
    "second_image": "Deuxième image",
    "login_form": "Formulaire de connexion",
    "button_signin": "Se connecter",
    "alternative_login": "...ou se loge avec :",
    "alternative_login_long": "Utilisez notre fonction de connexion sociale",
    "login_with": "S'inscrire avec",
    "show_facebook_login": "Afficher le login Facebook",
    "show_google_login": "Afficher la connexion Google",
    "signup_form": "Formulaire d'inscription",
    "signup_legal_explanation": "Inscription - déclaration juridique",
    "signup_agreement_text": "En m'inscrivant, j'accepte les {terms}.",
    "contact_agreement_text": "J'ai lu et compris les {data_use}.",
    "signup_agreement_text_terms": "Conditions générales de vente",
    "signup_agreement_text_data_use": "Déclaration de confidentialité",
    "signup_agreement_text_cancellation_policy_and_form": "Informations sur le droit de rétractation et formulaire de rétractation",
    "will_become_link_with_title_title": "un lien sera créé avec le titre : {title}",
    "show_only_when_user_logged_id": "Afficher uniquement si l'utilisateur est connecté",
    "car_rental_form": "Formulaire de location de voiture",
    "car_search_form": "Formulaire de recherche de voiture",
    "car_search_funnel": "Configurateur de recherche de voiture",
    "background_image": "Fond d'écran",
    "background_color": "Couleur de fond",
    "same_col_height": "Même hauteur de colonne",
    "pick_up_and_drop_off": "Enlèvement et retour",
    "pick_up_time": "Heure de prise en charge",
    "drop_off_time": "Heure de retour",
    "get_a_quote": "Déterminer les prix",
    "select_location": "Veuillez sélectionner un site",
    "type_address_to_start_search": "Veuillez saisir une adresse pour lancer la recherche",
    "menu_version": "Version du menu",
    "menu_transparent": "Transparent",
    "menu_dark": "Sombre",
    "menu_light": "Lumineux",
    "logo_version": "Version du logo",
    "logo_default": "Utiliser le logo par défaut",
    "logo_scroll": "Utiliser le logo de défilement",
    "select_a_pick_up_location": "Veuillez d'abord choisir un lieu de prise en charge",
    "could_not_find_location_please_enter_more_details": "Nous n'avons pas trouvé d'emplacement. Veuillez indiquer votre lieu de collecte avec plus de détails",
    "client_not_found": "Client non trouvé",
    "order_will_directly_be_marked_paid": "La commande est directement marquée comme payée",
    "invoice_will_directly_be_marked_paid": "La facture est directement marquée comme payée",
    "rental_period": "Durée de location",
    "change_booking_dates": "Modifier les données de réservation",
    "per_day": "par jour",
    "buy_now": "Acheter maintenant",
    "book_now": "Réserver maintenant",
    "rent_car_now": "Louer maintenant",
    "commit_to_buy": "Commande avec obligation de paiement",
    "back_to_cart": "Retour au panier",
    "equipment": "Équipement",
    "technical_specifications": "Données techniques",
    "cancel_checkout_question": "Annuler le checkout ?",
    "how_it_works": "Voici comment cela fonctionne",
    "secure_payment": "Paiement sécurisé",
    "top_services": "Top services",
    "if_you_buy_this_car_you_will_get_lots_of_services": "Si vous achetez cette voiture, n'ayez pas peur de la paperasserie. Nous pouvons organiser l'enregistrement, l'assurance et le crédit automobile si vous le souhaitez. Il vous suffit de sélectionner le service souhaité lors du passage en caisse.",
    "need_more_info_question": "Vous avez besoin d'informations supplémentaires ?",
    "contact_dealer": "Contacter un revendeur",
    "move_around_to_see_changes": "Déplacer pour voir les changements",
    "lead_catchers": "Lead catchers",
    "lead_catcher": "Lead catcher",
    "advanced_routing": "Routage avancé",
    "add_route": "Ajouter un itinéraire",
    "source": "Source",
    "can_be_ordered_online": "Peut être commandé en ligne",
    "count_years_warranty": "{compte} {compte, pluriel, one {année} other {années}} Garantie",
    "amount_online_advantage": "{amount} avantage en ligne",
    "your_order": "Votre commande",
    "file_name": "Nom de fichier",
    "tags": "Tags",
    "save_and_set_image": "Enregistrer et régler",
    "choose_or_drop_file_to_upload": "Sélectionnez ou faites glisser un fichier sur la page de téléchargement.",
    "media_formats_supported": "JPG et PNG sont pris en charge.",
    "max_file_upload_size": "La taille maximale des fichiers est de 15 Mo",
    "no_files_found": "Aucun fichier trouvé.",
    "upload_file_by_dragging_over": "Téléchargez un fichier en le faisant simplement glisser sur la page.",
    "your_car_is_ready_to_check_out": "La voiture de vos rêves vous attend",
    "choosing_one_of_our_cars_is_a_good_decision": "Plus que quelques étapes pour obtenir le véhicule de vos rêves. Profitez de nos avantages en ligne.",
    "your_car_will_be_delivered_to_you": "Votre véhicule est livré à l'endroit de votre choix",
    "your_will_get_a_amount_discount_for_ordering_online": "Pour les commandes en ligne, vous bénéficiez d'une réduction de {amount}.",
    "you_will_receive_extended_warranty": "Vous bénéficiez d'une garantie étendue",
    "you_will_get_exclusive_offers_when_using_our_app": "Vous recevez des offres exclusives lorsque vous utilisez notre application mobile",
    "you_dont_risk_when_ordering_online": "Vous ne risquez pas grand-chose en commandant en ligne.",
    "your_car_rent_is_nearly_done": "Votre location de voiture est presque prête !",
    "please_continue_with_booking_and_payment": "Veuillez procéder à la réservation et au paiement. Nous nous assurons que votre voiture est prête à la date de prise en charge choisie. La réservation en ligne vous fait gagner du temps !",
    "please_provide_address_delivery_method_payment": "Veuillez indiquer votre adresse, choisir le mode d'expédition et les détails du paiement.",
    "your_information_is_safe_with_us": "Vos informations sont en sécurité chez nous.",
    "we_offer_parcel_shipment_for_just_amount": "Pour votre commodité, nous vous proposons un envoi par colis pour toutes nos commandes pour seulement {amount}.",
    "free_delivery_on_all_our_orders": "Nous offrons la livraison gratuite pour toutes nos commandes.",
    "get_your_car_delivered": "Livraison",
    "cant_pick_your_car_yourself_we_got_you_covered": "Que se passe-t-il si vous trouvez la voiture de vos rêves et que vous ne pouvez pas aller la chercher vous-même ? Ne vous inquiétez pas. Nous avons une solution. Une fois votre voiture enregistrée et assurée, un chauffeur conviendra avec vous d'une date de livraison et garera votre voiture dans votre allée.",
    "we_are_more_than_happy_to_show_you_around_your_new_car": "Bien entendu, nous nous réjouissons de vous accueillir personnellement et de vous montrer votre nouvelle voiture.",
    "we_can_complete_your_order_in_shop_for_pickup": "Mais, si vous le souhaitez, nous pouvons compléter votre commande dans notre boutique et vous pouvez venir la chercher quand vous avez le temps.",
    "we_will_inform_you_when_we_complete_order_for_pickup": "Nous vous informerons lorsque nous aurons complété votre commande. Ensuite, nous attendrons patiemment que vous veniez la chercher.",
    "we_will_ship_your_order_to_provided_address": "Nous envoyons votre commande par DHL à l'adresse que vous avez indiquée. Nous vous informerons lorsque la commande sera expédiée. Vous recevrez également un numéro de suivi de commande.",
    "we_calculated_route_from_car_to_you": "Nous avons calculé un itinéraire depuis l'endroit où se trouve la voiture jusqu'à l'adresse que vous avez indiquée. Il semblerait que notre chauffeur doive parcourir {distance}km pour amener la voiture à votre porte.",
    "service_includes": "Le service comprend",
    "driver_will_call_you_and_schedule_delivery": "Notre chauffeur vous appellera et vous livrera la voiture au moment qui vous conviendra le mieux.",
    "your_car_will_be_washed": "Ta voiture sera lavée",
    "your_car_will_be_fully_tanked": "Le plein de votre voiture est fait",
    "you_will_get_quick_tour_around_car": "Vous obtenez un bref tour de la voiture",
    "you_can_pick_up_car_yourself": "Vous avez encore des questions ? Notre équipe se tient à votre disposition.",
    "only_one_step_left": "Plus qu'un pas !",
    "check_if_everything_is_correct_and_provide_payment_data": "Veuillez vérifier que tout est correct, nous donner vos coordonnées de paiement et transmettre la commande.",
    "terms_of_contract_for_additional_services": "Conditions contractuelles pour les services complémentaires",
    "terms_of_use_marketplace": "Conditions d'utilisation de la place de marché",
    "for_the_sales_of_used_vehicles": "Vente de véhicules d'occasion",
    "for_the_rental_of_vehicles": "Location de véhicules",
    "your_payment_schedule": "Cycle de paiement",
    "shipping_pickup": "Collecte",
    "shipping_parcel": "Paquet",
    "shipping_transport": "Transport",
    "car_interior": "Aménagement intérieur",
    "car_exterior": "Equipement extérieur",
    "we_are_processing_your_order_please_wait": "Nous traitons votre commande. Veuillez patienter...",
    "we_are_processing_your_booking_please_wait": "Nous traitons votre réservation. Veuillez patienter...",
    "your_order_was_completed_successfully": "Votre commande a bien été prise en compte.",
    "we_created_a_user_profile": "Nous avons créé un profil d'utilisateur pour vous",
    "check_your_email": "Vérifiez votre boîte de réception",
    "your_orders": "{count, pluriel, one {votre numéro de commande} other {vos numéros de commande}} :",
    "first_experience_with_our_shop_saved_profile": "Il semble que ce soit votre première expérience avec notre boutique en ligne. Nous avons enregistré avec succès les informations de votre profil dans notre base de données. Afin de poursuivre le traitement de votre commande, veuillez vérifier votre boîte aux lettres et confirmer votre adresse e-mail.",
    "order_details_sent_to_email_check_history_for_details": "Tous les détails de la commande ont été envoyés à votre adresse e-mail. Vous pouvez à tout moment consulter l'historique et le statut de votre commande dans votre compte d'utilisateur.",
    "timeline": "Ligne de temps",
    "promotions": "Actions",
    "payment_method": "Mode de paiement",
    "first_payment": "1er paiement",
    "second_payment": "2ème paiement",
    "shipping_method": "Mode d'expédition",
    "payment_1_e_payment_to_secure_your_order": "Acompte via paiement électronique pour réserver votre véhicule.",
    "payment_2_choose_payment_method_for_the_car": "Choisissez un mode de paiement pour votre véhicule.",
    "payment_escrow": "Compte fiduciaire",
    "payment_car_credit": "Financement",
    "full_refund": "Remboursement intégral",
    "partial_refund": "Remboursement partiel",
    "cancel_order": "Annuler la commande",
    "create_shipping": "Créer un envoi",
    "schedule_car_registration": "Autorisation",
    "schedule_transport": "Planifier le transport",
    "is_published": "Est publié",
    "no_salesman_available": "Aucun vendeur n'est disponible pour le moment",
    "any_questions": "Des questions ?",
    "feel_free_to_ask_our_online_sales_team": "Contactez simplement notre équipe de vente en ligne",
    "please_complete_your_details": "Veuillez compléter vos informations",
    "registration_is_stressfull_get_your_car_registered": "Pour certaines personnes, l'enregistrement d'une voiture est stressant et prend du temps. Faites enregistrer votre voiture en votre nom sans effort.",
    "review_your_order": "Vérifier votre commande",
    "in_order_to_confirm_identity_please_complete_your_details": "Pour confirmer votre identité, nous avons besoin de quelques informations supplémentaires :",
    "payment_instructions_will_be_provided_after_seller_accepts_order": "Le vendeur vous donnera des instructions de paiement détaillées après l'acceptation de votre commande.",
    "nationality": "Nationalité",
    "date_of_birth": "Date de naissance",
    "place_of_birth": "Lieu de naissance",
    "discover_more": "En savoir plus",
    "more_of_product": "Plus {product}",
    "more_of_product_in_city": "Plus de {product} dans {city}",
    "order_placed": "Commande passée",
    "show_order_details": "Afficher les détails de la commande",
    "white_label_plugins": "Plugins en marque blanche",
    "white_label_plugin": "Plugin de marque blanche",
    "enhance_chedri_project_with_plugins": "Améliorer l'expérience Creacheck avec des plugins",
    "plugin": "Plugin",
    "plugins": "Plugins",
    "enhance_your_project_with_plugins": "Vous pouvez choisir la quantité de fonctionnalités Creacheck dont vous avez besoin. Vérifiez les plugins disponibles, leurs fonctionnalités et leurs prix.",
    "week": "Semaine",
    "month": "Mois",
    "quarter": "1er trimestre",
    "year": "Année",
    "more_infos": "Plus d'informations",
    "less_infos": "Moins d'informations",
    "activate_plugin": "Activer le plugin",
    "partially_activate_plugin": "Signer l'activation du plugin",
    "terminate_plugin": "Quitter le plugin",
    "detailed_description": "Description détaillée",
    "installation_instructions": "Guide d'installation",
    "you_are_not_an_adminitrator": "Vous n'êtes pas administrateur",
    "creditor_id": "ID du créancier",
    "i_understand_the_cost_and_want_activate_plugin": "Je comprends les coûts et souhaite activer le plugin",
    "i_understand_the_running_cost_when_terminating_plugin": "Je comprends les coûts calculés à la fin de la période de facturation",
    "are_you_sure_you_want_to_terminate_plugin": "Tu veux vraiment arrêter le plugin ?",
    "security_settings": "Paramètres de sécurité",
    "limit_write_rights": "Limiter les droits d'écriture",
    "limit_read_rights": "Limiter les droits de lecture",
    "edit_your_company_data": "Vous pouvez modifier les données de votre entreprise ici. Modifier les coordonnées et les données financières utilisées à des fins de facturation",
    "latest_order": "Dernière commande",
    "latest_orders": "Dernières commandes",
    "latest_invoices": "Dernières factures",
    "latest_editions": "Dernièrement édité",
    "overdue_invoices": "Factures échues",
    "view_all": "Tout afficher",
    "i_accept": "J'accepte",
    "accept_terms_before_continuing": "Avant de poursuivre, vous devez accepter nos conditions générales et notre politique de confidentialité.",
    "approve_car_sale": "Approuver la vente d'une voiture",
    "enter_vin_number": "Saisir le numéro de châssis du véhicule",
    "mark_as_paid": "Marquer comme payé",
    "enter_ein_number": "Saisir la confirmation d'assurance électronique",
    "send_payment_instructions": "Envoyer des instructions de paiement",
    "submit_vin_number": "Envoyer le numéro de châssis du véhicule",
    "approve_and_inform_client": "Approuver et informer le client",
    "submit_ein_number": "Envoyer une confirmation d'assurance électronique",
    "ein": "Confirmation d'assurance électronique",
    "payment_reference": "Référence de paiement",
    "upload_sales_cotract": "Télécharger le contrat de vente",
    "cancellation_reason": "Motif d'annulation",
    "enter_bank_data": "Saisir les données bancaires",
    "pickup_invite": "Invitation à la collecte",
    "mark_picked_up": "Marquer comme collecté",
    "refunds": "Remboursements",
    "sent_on_date": "Envoyé le {date}",
    "not_sent_yet": "Pas encore envoyé",
    "pick_up_location": "Lieu de collecte",
    "emails": "E-mails",
    "use_following_list_to_personalize_your_emails": "Utilise la liste suivante pour personnaliser tes e-mails :",
    "recipients_first_name": "Prénom du destinataire",
    "recipients_last_name": "Nom de famille du destinataire",
    "senders_company_name": "Nom de l'entreprise de l'expéditeur",
    "system_mail_registration_description": "Envoyé lorsqu'un nouvel utilisateur s'inscrit sur le site web",
    "system_mail_invitation_description": "Envoyé lorsqu'un employé crée un nouvel utilisateur avec une adresse e-mail",
    "system_mail_car_rental_pickup_reminder_description": "Envoyé la veille de la prise en charge de la voiture par le client.",
    "system_mail_car_rental_dropoff_reminder_description": "Envoyé un jour avant que le client ne rende sa voiture de location",
    "terms_of_use": "Conditions d'utilisation",
    "contract": "Contrat",
    "order_data_processing": "Contrat de traitement des commandes",
    "i_read_and_accept_plugins_terms_of_use": "J'ai lu et compris les conditions d'utilisation et je les accepte expressément.",
    "i_read_and_accept_plugins_contract": "J'ai lu et compris le contrat et je l'accepte expressément.",
    "i_read_and_accept_plugins_order_data_processing": "J'ai lu et compris le traitement des données de commande et je l'accepte expressément.",
    "send_activation_request": "Envoyer une demande d'activation",
    "missing_companys_bank_data": "Il semble que les coordonnées bancaires de votre entreprise nous manquent.",
    "you_can_fill_missing_data_in_settings": "Vous pouvez saisir les données manquantes dans {settings}.",
    "not_sole_representative_click_below_to_request_plugin_activation": "Vous n'êtes pas autorisé à activer vous-même les plugins. Cliquez sur le bouton pour activer le plugin. L'utilisateur autorisé est invité à terminer le processus d'activation.",
    "not_authorized_click_below_to_request_plugin_activation": "Vous n'êtes pas autorisé à activer les plugins. Cliquez sur le bouton pour envoyer une demande d'activation aux utilisateurs autorisés.",
    "select": "Choisir",
    "enter_app_secret": "Inscrire App secret",
    "enter_page_access_token": "Saisir un jeton d'accès à la page",
    "legal": "Juridique",
    "activation_pending": "Activation en attente",
    "termination_pending": "Résiliation en attente",
    "form_contains_errors": "Il y a quelques problèmes dans le formulaire",
    "internal_car_reference": "Référence interne Auto",
    "system_debtor_number": "Numéro de débiteur du système",
    "internal_company_reference": "Référence interne de l'entreprise",
    "internal_location_reference": "Référence interne du site",
    "show_notes": "Afficher les notes",
    "show_my_notes": "Afficher mes notes",
    "show_by_me": "Montrer de moi",
    "items": "Produits",
    "send_text_message": "Envoyer un SMS",
    "wrote_message": "Message écrit",
    "sent_text_message": "Un SMS envoyé",
    "wrote_note": "Note écrite",
    "please_check_document_check_expiration_date": "Veuillez vérifier le document. Assurez-vous que vous avez bien vu le document. Vérifier la date d'expiration",
    "i_reviewed_the_document": "J'ai vérifié le document",
    "receive_updates_in_messenger": "Souhaitez-vous recevoir des mises à jour dans Messenger ?",
    "weve_received_your_request_and_will_contact_you": "Nous avons bien reçu votre demande et nous vous contacterons dans les plus brefs délais.",
    "check_your_email_to_confirm": "Veuillez vérifier votre e-mail pour confirmer l'envoi de ce formulaire de contact.",
    "response_text": "Texte de réponse",
    "entries": "Entrées",
    "timeline_block": "Bloc de la ligne du temps",
    "external_accounts": "Comptes externes",
    "send_copy_to_me": "Envoie-moi une copie",
    "user_tracking": "Suivi des utilisateurs",
    "select_model": "Choisir un modèle",
    "select_model_group": "Sélectionner un groupe de modèles",
    "equipment_level_budget": "Budget",
    "equipment_level_family": "Famille",
    "equipment_level_comfort": "Confort",
    "equipment_level_business": "Business",
    "salutation": "Titre de civilité",
    "getting_started": "Premiers pas",
    "set_your_locations": "Définissez vos sites",
    "set_up_locations_for_your_car_business_set_services_opening_hours_upload_photos": "Chaque véhicule a besoin d'un emplacement parmi lequel les clients peuvent choisir. Allez dans les paramètres, où vous pouvez définir tous les emplacements souhaités. Vous pouvez y décider des services que vous offrez à chaque emplacement, définir les heures d'ouverture et télécharger des photos pour aider les clients à trouver votre magasin.",
    "now_that_locations_are_set_create_users_so_they_can_work_together": "Maintenant que vos clients savent où vous exercez votre activité, faites-nous savoir qui travaille pour vous. Veuillez créer des utilisateurs afin qu'ils puissent collaborer à l'aide des technologies Creacheck.",
    "cms_content": "Contenu du CMS",
    "this_is_blank_page_use_editor_to_create_great_one": "Il s'agit d'une page vierge. Dans notre éditeur visuel, vous pouvez créer une belle page avec des graphiques, une belle typographie et compatible avec tous les appareils.",
    "cars_to_sell": "Véhicules à vendre",
    "your_business_is_car_sales_but_there_are_no_cars_create_yourself_or_use_import": "Votre métier consiste à vendre des véhicules. La nôtre est de vous faciliter la tâche. Jusqu'à présent, vous n'avez pas encore introduit de véhicules dans notre système. Pour cela, il vous suffit d'utiliser l'importation de véhicules ou de saisir vos véhicules manuellement.",
    "create_manually": "Créer manuellement",
    "import": "Importation",
    "car_import": "Importer des véhicules",
    "most_car_dealers_use_mobilede_enter_your_interface_username_and_password_to_import": "Comme nous l'avons découvert, la plupart des concessionnaires automobiles utilisent mobile.de pour présenter leurs voitures. Il est donc fort probable qu'ils aient accès au service web. Veuillez saisir votre nom d'utilisateur et votre mot de passe. Nous importons automatiquement toutes vos voitures et gardons votre offre synchronisée. Si vous disposez de plusieurs données d'accès, vous pouvez en saisir d'autres dans votre profil.",
    "we_will_import_count_cars_for_this_username": "Nous allons importer {count} voitures pour ce nom d'utilisateur",
    "mobile_de_username": "Mobile.de Seller-API Nom d'utilisateur",
    "mobile_de_password": "Mot de passe API vendeur Mobile.fr",
    "missing_bank_data": "Données bancaires manquantes",
    "we_dont_have_your_bank_data": "Apparemment, nous n'avons pas vos coordonnées bancaires",
    "monthly_billing_relies_on_sepa_direct_debit_fill_in_bank_data_in_settings": "Notre facturation mensuelle est basée sur le prélèvement SEPA. Comme nous n'avons pas de données bancaires, vous ne pouvez pas utiliser plusieurs de nos plugins. Veuillez vous rendre dans les paramètres et saisir vos données bancaires.",
    "fill_in_bank_data": "Saisissez les données bancaires",
    "sepa_mandate": "Mandat SEPA",
    "sepa_mandates": "Mandats SEPA",
    "missing_sepa_mandate": "Il nous manque un mandat SEPA",
    "changed_bank_data_please_generate_new_sepa_mandate": "Il semble que vous ayez récemment changé de numéro de compte bancaire. Afin d'automatiser notre processus de facturation mensuelle et de générer moins de stress pour vous, veuillez créer un nouveau mandat SEPA.",
    "generate_sepa_mandate_now": "Générez un nouveau mandat SEPA",
    "columns": "Colonnes",
    "send_doc_as_message": "Envoyer le document au client (e-mail)",
    "alternative_recepient": "Récepteur alternatif",
    "enter_email_address": "Veuillez saisir une adresse e-mail valide",
    "job_title": "Titre du poste",
    "service_point": "Service",
    "rental_point": "Base de location",
    "seller_data": "Données du vendeur",
    "car_location": "Emplacement du véhicule",
    "save_data_before_nav_to_pathname": "Vos données ne seront pas enregistrées. Souhaitez-vous passer à {pathname} sans enregistrer ?",
    "share": "Partager",
    "assignee_changed_from_assignee_to_assignee": "L'agent est passé de %oldAssignee% à %newAssignee%.",
    "stage_changed_from_name_to_name": "Le niveau est passé de %oldName% à %newName%.",
    "handover_filled_out": "Le procès-verbal de remise du véhicule a été rempli",
    "data_changed": "Données mises à jour",
    "key": "Clé",
    "old_value": "Ancienne valeur",
    "new_value": "Nouvelle valeur",
    "no_details_available": "Les informations détaillées ne sont pas disponibles",
    "services_we_offer_in_city": "Services que nous vous proposons à {city}",
    "damages_present": "Présence de dommages",
    "user_is_online": "L'utilisateur est en ligne",
    "user_is_offline": "L'utilisateur est hors ligne",
    "click_to_start_chat": "Cliquez ici pour démarrer le chat",
    "click_to_view_chat_history": "Cliquez ici pour afficher l'historique du chat",
    "theme_colors": "Couleurs du thème",
    "logos": "Logos",
    "send_invitation_with_username_and_password": "Envoyer une invitation avec nom d'utilisateur et mot de passe (Uniquement pour les nouvelles créations)",
    "cookie_settings": "Détails des cookies",
    "accept_only_necessary_cookies": "Accepter uniquement les cookies nécessaires",
    "accept_all_cookies": "Accepter tous les cookies",
    "privacy_preference_center": "Centre de référence pour la protection des données",
    "your_privacy": "Votre vie privée",
    "basic_information_on_cookies": "Lorsque vous visitez un site web, des informations peuvent être stockées ou récupérées dans votre navigateur, généralement sous la forme de cookies. Ces informations peuvent vous concerner, vous, vos préférences ou votre appareil. Ces informations sont principalement utilisées pour permettre au site de fonctionner comme prévu. Ces informations ne vous identifient généralement pas directement, mais peuvent offrir une expérience web plus personnalisée. Comme nous respectons votre droit à la vie privée, vous pouvez choisir de ne pas autoriser certains types de cookies. Sélectionnez une catégorie pour en savoir plus et modifier nos paramètres par défaut. Cependant, le blocage de certains types de cookies peut avoir un impact sur votre expérience du site et des services que nous proposons.",
    "necessary_cookies": "Cookies nécessaires",
    "performance_cookies": "Cookies de performance",
    "targeting_cookies": "Cookies de ciblage",
    "always_active": "Toujours actif",
    "cookies_used": "Cookies utilisés",
    "necessary_cookies_information": "Ces cookies sont nécessaires au bon fonctionnement du site et ne peuvent pas être désactivés dans nos systèmes. Ils ne sont généralement définis qu'en réponse à des actions que vous effectuez et qui sont liées à une demande de service, par exemple la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer votre navigateur pour qu'il bloque ces cookies ou vous informe de leur présence, mais certaines parties du site ne fonctionneront pas. Ces cookies ne stockent pas d'informations permettant de vous identifier personnellement.",
    "save_settings": "Enregistrer les paramètres",
    "save_and_continue": "Enregistrer et continuer",
    "priority": "Priorité",
    "closed_leads": "Leads fermés",
    "show": "Annonces",
    "hide": "Cacher",
    "first_registration_from": "Première immatriculation à partir du",
    "first_registration_to": "Première immatriculation jusqu'au",
    "power_from": "Puissance du moteur à partir de",
    "power_to": "Puissance du moteur jusqu'à",
    "mileage_from": "Kilométrage à partir de",
    "mileage_to": "Kilométrage jusqu'à",
    "included_mileage": "{mileage} Kilomètre",
    "really_cancel": "Vraiment annuler ?",
    "unsaved_information_will_be_lost": "Les données non enregistrées sont perdues",
    "client_signature": "Signature du client",
    "another_photo": "Autre photo",
    "try_again": "Essayer à nouveau",
    "back_to_vehicle": "Retour au véhicule",
    "image_upload_finished": "Le téléchargement des images est terminé",
    "you_have_no_internet_connection": "Vous n'avez pas de connexion Internet",
    "waiting_to_reconnect": "Attendre la reconnexion",
    "plus_count_more": "+ {compte} plus",
    "document_is_missing": "Il manque un document",
    "upload_document_name_now": "Télécharger le document '{nom}' maintenant",
    "lead_per_mail": "Lead par e-mail Règle",
    "create_rules_to_forward_emails": "Créez des règles pour transmettre des e-mails en copie à d'autres utilisateurs. Si le 'Data e-mail plugin' est activé, vous pouvez également transférer des e-mails contenant des informations importantes au format XML directement vers Customer One.",
    "lead_per_mail_rules": "Règles de lead par e-mail",
    "include_xml_data_in_email": "Insérer des données au format XML dans le courriel",
    "sad_activity": "Activité SAD",
    "e_payments": "Paiements électroniques",
    "stripe_connect": "Stripe Connect",
    "we_will_handle_epayments_for_you": "Stripe Connect nous permet de traiter les paiements par carte de crédit en votre nom. Après avoir cliqué sur \"Se connecter à Stripe\", vous pouvez utiliser votre compte Stripe existant pour vous connecter à la plateforme Creacheck ou créer un nouveau compte Stripe si vous n'en avez pas encore un. Avec votre propre compte Stripe, vous verrez tout votre historique de paiement et pourrez retirer de l'argent à tout moment.",
    "connect_with_stripe": "Se connecter à Stripe",
    "stripe_accounts": "Comptes Stripe",
    "stripe_account_connected": "Votre compte Stripe est maintenant connecté",
    "stripe_accounts_are_connected": "Votre compte Stripe est maintenant associé à {projectName}. {projectName} peut maintenant accepter des paiements par carte de crédit en votre nom.",
    "money_won": "Argent gagné",
    "leads_won": "Leads gagnés",
    "offered_by": "Votre concessionnaire automobile",
    "pay_with_saved_credit_card": "Payer avec votre carte de crédit enregistrée",
    "pay_with_new_credit_card": "Payer avec une nouvelle carte de crédit",
    "settings": "Réglages",
    "account": "Fonctions",
    "clients": "Entreprises",
    "payments": "Paiements",
    "calendar": "Calendrier",
    "contact": "Contact",
    "login": "Se connecter",
    "logout": "Déconnexion",
    "previous_odometer_reading": "Kilométrage précédent",
    "current_odometer_reading": "Kilométrage actuel",
    "odometer_reading": "Kilométrage",
    "tank_volume": "Volume du réservoir",
    "vehicle_fuel_costs": "Faire le plein du véhicule coûte",
    "mileage_surcharge": "Supplément pour kilométrage supplémentaire",
    "insurance_self_participation": "Assurance Franchise",
    "additional_costs_due": "Frais supplémentaires dus",
    "no_additional_costs": "Pas de frais supplémentaires",
    "charge_customer_additional_costs": "Facturer des frais supplémentaires au client",
    "warning_you_chose_not_to_charge_customer_for_additional_costs": "Attention ! Vous avez décidé de ne pas facturer de frais supplémentaires au client.",
    "cancel_booking": "Annuler la réservation",
    "no_invoices_found": "Aucune facture trouvée",
    "upload_drivers_license": "Télécharger le permis de conduire",
    "order_details": "Détails de la commande",
    "shipping_address": "Adresse d'expédition",
    "order_overview": "Aperçu des commandes",
    "booked_by_mistake": "Réservé par erreur",
    "booked_wrong_dates": "Mauvaises dates réservées",
    "found_better_price": "Trouvé un meilleur prix",
    "change_of_plans": "Changement de plan",
    "client_called_to_cancel": "Le client a appelé et a demandé l'annulation.",
    "client_wrote_email_to_cancel": "Le client a envoyé un e-mail demandant l'annulation de la réservation",
    "car_damaged_before_transport": "Le véhicule a été endommagé avant le transport",
    "car_damaged_in_transport": "Le véhicule a été endommagé pendant le transport",
    "item_not_available": "Article plus disponible",
    "other_reason": "Autres",
    "license_plate_number": "Numéro d'immatriculation",
    "open_in_google_maps": "Ouvrir dans Google Maps",
    "access_identifier": "Code d'accès",
    "branch": "Filiale",
    "new": "Nouveau",
    "in_progress": "En cours de traitement",
    "failed": "Échec",
    "not_started": "Pas démarré",
    "finished": "Terminé",
    "failed_refunds": "Échec des remboursements",
    "list_all_failed_refund_payments": "Lister tous les remboursements qui n'ont pas abouti avec des détails",
    "rent_sync_vamoso_reservation_success": "Réservé avec succès dans le système Vamoso. Le numéro de réservation est %reservationId%.",
    "rent_sync_vamoso_reservation_failure": "La réservation de la voiture dans le système Vamoso n'a pas été possible. Le système a renvoyé le message d'erreur suivant : \"%errorText%\".",
    "rent_sync_vamoso_cancellation_success": "La réservation dans le système Vamoso a été annulée avec succès",
    "rent_sync_vamoso_cancellation_failure": "Il n'a pas été possible d'annuler la réservation dans le système Vamoso. Le système a renvoyé le message d'erreur suivant : \"%errorText%\".",
    "rent_sync_vamoso_booking_success": "Réservé avec succès dans le système Vamoso",
    "rent_sync_vamoso_booking_failure": "L'enregistrement de la réservation dans le système Vamoso n'a pas été possible. Le système a renvoyé le message d'erreur suivant : \"%errorText%\".",
    "rent_sync_vamoso_return_success": "Le véhicule a été marqué avec succès comme étant retourné dans le système Vamoso.",
    "rent_sync_vamoso_return_failure": "Le retour de la voiture dans le système Vamoso n'a pas été possible. Le système a renvoyé le message d'erreur suivant : \"%errorText%\".",
    "view_lead_details": "Afficher les détails du lead",
    "change_category": "Changer de catégorie",
    "json_url_parameters": "Paramètres d'URL JSON",
    "layout_version": "Version de mise en page",
    "layout_wide": "Large",
    "layout_narrow": "Étroit",
    "layout_detailed": "Détaillé",
    "layout_slider": "Slider",
    "layout_list": "Liste",
    "search_version": "Version du module de recherche",
    "not_sure_go_to_advisory_mode": "Je ne suis pas sûr : voici le {link}",
    "link_to_advisory_mode": "Mode de consultation",
    "desired_pickup_location": "Votre lieu de prise en charge souhaité",
    "incl_short": "incl.",
    "included": "Y compris",
    "included_services": "Services inclus",
    "insurance": "Assurance",
    "liability_and_comprehensive_insurance_with_excess_amount_of_amount_per_claim": "L'assurance responsabilité civile et l'assurance tous risques avec une franchise de {amount} par sinistre",
    "tyres_suitable_for_winter": "Pneus adaptés à l'hiver",
    "additional_driver": "Conducteur supplémentaire",
    "on_request": "sur demande",
    "navigation_system": "Système de navigation",
    "child_seat": "Siège pour enfant",
    "order_comments_and_special_wishes": "Autres souhaits",
    "ordering_as_person": "Je commande en tant que particulier",
    "ordering_as_company": "Je commande en tant qu'entreprise",
    "filter_by_date": "Filtrer par date",
    "car_rental_max_days_count_exceeded": "Malheureusement, la période sélectionnée dépasse notre durée maximale de location de {compte, pluriel, one {# jour} other {# jours}}.",
    "require_document_number": "Demander un numéro de document",
    "require_document_issuing_city": "Demander le lieu de délivrance du document",
    "require_document_issuing_authority": "Demander à l'autorité émettrice du document",
    "require_document_issue_date": "Demander la date d'émission du document",
    "require_document_expiration_date": "Demander la date d'expiration du document",
    "doc_number": "Numéro de document",
    "doc_issuing_city": "Lieu d'émission du document",
    "doc_issuing_authority": "Autorité de délivrance du document",
    "doc_issue_date": "Date d'émission du document",
    "doc_expiration_date": "Date d'expiration du document",
    "chedri_plugins": "Plugins Creacheck",
    "in_order_to_use_our_software_in_its_full_potential_you_need_to_activate_plugins_in_order_to_do_that_please_confirm_that_you_may_sign_contracts_on_behalf_of_your_company": "<p>Pour pouvoir utiliser notre logiciel, vous devez activer les fonctions souhaitées. Chaque fonction est fournie sous la forme d'un plugin, ce qui implique généralement une obligation de paiement (frais).<br/>Lorsque vous activez un plugin, il vous est demandé d'accepter les conditions du plugin. Toutefois, avant de pouvoir continuer, vous devez confirmer que vous êtes autorisé à agir au nom de votre entreprise.</p>",
    "i_declare_that_i_am_entitled_to_legally_sign_contracts_on_behalf_of_my_company": "Je déclare que je suis autorisé(e) à signer des contrats juridiquement contraignants au nom de ma société, {company}.",
    "your_company_was_successfully_registered": "Nous vous avons enregistré avec succès, vous et votre entreprise, dans notre système.",
    "check_email_for_more_instructions_about_your_company_registration": "Nous avons envoyé des informations sur la procédure à suivre à l'adresse e-mail que vous avez indiquée. Veuillez vérifier votre boîte de réception. Si vous avez des questions ou si vous ne savez pas comment procéder, n'hésitez pas à nous contacter.",
    "dependent_on": "Dépend de",
    "we_have_more_plugins_that_further_enhance_the_functionality": "Nous avons d'autres plugins qui améliorent encore la fonctionnalité de {plugin}.",
    "finish_plugin_configuration": "Terminer la configuration du plugin",
    "it_seems_that_you_havent_finished_configuring_plugins": "Il semble que vous n'ayez pas encore terminé la configuration des plugins activés. Pour certains plugins, des données de connexion externes doivent être définies (par ex. pour l'interface mobile.de ou les données de connexion Vamoso). Veuillez vous rendre dans les paramètres et ajouter les comptes manquants.",
    "lead_created_from_an_email": "Ce lead a été créé à partir d'un e-mail",
    "show_original_message": "Afficher le message original",
    "we_found_count_similar_leads": "Nous avons trouvé {compte, pluriel, un {# lead similaire} other {# leads similaires}}.",
    "show_similiar_leads": "Afficher les prospects similaires",
    "new_lead_from_failed_refund": "Nouveau lead en raison de l'échec du remboursement",
    "you_can_apply_company_data_from_company_profile": "Tu peux reprendre les données de l'entreprise à partir du profil de l'entreprise",
    "load_company_data": "Charger les données de l'entreprise",
    "company_lookup": "Recherche d'entreprise",
    "match_lead_tags": "Faire correspondre les tags de prospects",
    "is_active": "Est actif",
    "is_renew_password_at_next_login": "Renouveler le mot de passe à la prochaine connexion",
    "no_overdue_tasks": "Il n'y a pas de tâches en retard",
    "great_job": "Bien joué !",
    "no_leads_for_today": "Pas de leads pour vous aujourd'hui",
    "address_not_set": "Adresse non définie",
    "statistics": "Statistiques",
    "select_assignee": "Sélectionner un agent",
    "kroschke_order_in_progress": "La commande Kroschke avec l'ID de véhicule %kroschkeVehicleId% a été initialisée avec succès",
    "in_original": "Dans l'original",
    "a_copy": "Une copie",
    "mark_documents_sent": "Marquer les documents comme envoyés",
    "i_sent_the_documents": "J'ai envoyé les documents",
    "documents_were_sent": "Les documents ont été envoyés",
    "documents_sent": "Documents envoyés",
    "define_custom_license_plate_number": "Définir le code souhaité",
    "about_car_registration": "Le service d'immatriculation comprend",
    "i_would_like_individual_license_plate_number": "Oui, je souhaite choisir le signe de mon choix",
    "we_gather_necessary_documents": "Inscription auprès de votre autorité d'immatriculation",
    "we_produce_license_plates": "Obtention et montage des plaques d'immatriculation",
    "license_plate_reservation_number": "Numéro de réservation de la plaque d'immatriculation",
    "with_custom_license_plate_number": "Avec plaque d'immatriculation souhaitée",
    "after_the_order_reserve_desired_license_plate_at_local_registration_office": "Après avoir passé commande, vous devez réserver en ligne la plaque d'immatriculation de votre choix auprès de votre service d'immatriculation local.",
    "inform_us_about_your_license_plate_reservation_so_we_can_start_the_process": "Si vous avez fait cela, veuillez nous communiquer le numéro d'immatriculation que vous souhaitez et votre numéro de réservation (que vous avez reçu du service d'immatriculation).",
    "clarify": "Clarifier",
    "notification": "Communiqué",
    "license_plate_reservation_data_missing": "Les données de réservation pour la plaque d'immatriculation souhaitée manquent",
    "license_plate_reservation_number_filled_out": "Données de réservation saisies pour les plaques d'immatriculation souhaitées. Numéro d'immatriculation souhaité : %licensePlateNumber%. Numéro de réservation : %reservationNumber%.",
    "distance_planned": "Itinéraire prévu",
    "distance_driven": "Itinéraire parcouru",
    "planned_pickup_date": "Date de collecte prévue",
    "pickup_date": "Date d'enlèvement",
    "planned_delivery_date": "Livraison prévue",
    "your_initial_offer": "Votre première offre",
    "your_next_offer": "Votre prochaine offre",
    "car_number": "Numéro de véhicule",
    "send_price_proposal": "Envoyer une proposition de prix",
    "our_price": "Notre prix",
    "purchase_price": "Prix d'achat",
    "our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it": "Nous essayons d'optimiser nos prix pour vous, mais si vous pensez que le prix est encore trop élevé, faites-nous part de ce que nous pouvons faire pour vous.",
    "select_the_car_you_love": "Sélectionner la voiture souhaitée",
    "sign_up_or_log_in_if_you_already_have_an_account": "S'inscrire ou se connecter si vous avez déjà un compte",
    "enter_desired_price": "Saisissez le prix souhaité",
    "send_the_form_our_employee_will_contact_you": "Envoyer le formulaire. Notre collaborateur vous contactera dans les plus brefs délais",
    "if_we_settle_on_a_price_you_can_buy_the_car_online": "Si nous nous mettons d'accord sur un prix, vous avez la possibilité d'acheter la voiture directement sur notre site web.",
    "you_have_to_log_in_to_proceed": "Vous devez vous connecter pour continuer",
    "rent_a_car": "Louer un véhicule",
    "pending_price_negotiations_count": "En attente {compte, pluriel, one {# proposition de prix} other {# propositions de prix}}",
    "my_price_negotiation": "Ma proposition de prix",
    "price_negotiation": "Proposition de prix",
    "price_negotiations": "Propositions de prix",
    "new_price_negotiation_request": "Nouvelle demande de négociation de prix",
    "expired": "Expiré",
    "first_offer": "Première offre",
    "current_offer": "Offre actuelle",
    "expires": "Expire",
    "accept_the_offer": "Accepter l'offre",
    "send_a_new_offer": "Envoyer une nouvelle offre",
    "your_new_offer": "Ta nouvelle offre",
    "your_project_dashboard": "Votre {projet} tableau de bord",
    "add_my_vehicle": "Ajouter mon véhicule",
    "no_reply_yet": "Pas encore de réponse",
    "employee_replied": "L'employé a répondu",
    "price_negotiation_details": "Détails du prix par défaut",
    "current_offer_is_amount_you_can_now_proceed_to_checkout": "Votre offre actuelle est {amount}. Vous pouvez passer à la caisse avec le prix que vous avez négocié.",
    "show_timeline": "Afficher l'historique",
    "communities": "Communautés",
    "gain_coverage_with_community": "Augmentez votre portée avec de nouvelles plates-formes. Gagner de nouveaux clients en un seul clic",
    "community": "Communauté",
    "is_community": "Communauté active",
    "join_community": "Rejoindre la communauté",
    "leave_community": "Quitter la communauté",
    "join_jahreswagen_community_and_profit_from_additional_sales_channel": "<div><p>Une chose totalement nouvelle dans Creacheck, ce sont les communautés. Grâce au logiciel Creacheck, il est possible de créer des plates-formes pour l'adhésion de plusieurs entreprises.</p><p>L'une de ces plates-formes est Jahreswagen.de. Vous pouvez jeter un coup d'œil aux offres de voitures de l'année. Si vous souhaitez devenir membre, accédez aux paramètres de la communauté et rejoignez la communauté des voitures de l'année.</p><p>L'adhésion est gratuite. Toutefois, des frais de transaction s'appliquent.</p><p className='m-t-20 alert-info'>Si vous avez une idée personnelle pour une communauté, contactez-nous. Nous trouverons alors une solution pour vous.</p></div>",
    "car_description": "Description du véhicule",
    "marketplace_terms": "Conditions d'utilisation de la place de marché",
    "online_appointment_booking": "Réservation de rendez-vous en ligne",
    "my_online_appointments": "Mes rendez-vous en ligne",
    "employee": "Collaborateurs",
    "choice_of_date": "Choix de la date",
    "works": "Travailler",
    "make_selection": "Sélectionner",
    "remove_selection": "Supprimer",
    "tasks": "Tâches",
    "task": "Tâche",
    "car_service": "Service",
    "car_services": "Services",
    "car_service_station": "Poste de travail",
    "car_service_stations": "Postes de travail",
    "services_offered_at_your_locations": "Définir les services sur vos sites.",
    "service_stations_where_your_employees_work": "Définir des postes de travail pour suivre la charge de travail et voir les ressources occupées et libres.",
    "team_members": "Membres de l'équipe",
    "service_details": "Détails du service",
    "service_location": "Le lieu de service que vous avez choisi",
    "service_drop_off_time": "L'heure de service que vous avez choisie",
    "car_service_appointments": "Dates d'atelier",
    "car_service_booked_at": "Date réservée",
    "show_booking_details": "Afficher les détails de la réservation",
    "saving_draft": "Le projet est enregistré",
    "draft_saved": "Brouillon enregistré",
    "define_custom_page_templates_to_meet_your_cd": "Nous livrons le système Creacheck avec des modèles intégrés. Vous pouvez y créer vos propres modèles afin de mieux correspondre à votre identité visuelle.",
    "edit_description": "Modifier la description",
    "edit_language": "Modifier la langue",
    "create_blank_page": "Créer une page vierge",
    "create_from_template": "Créer à partir d'un modèle",
    "use_template": "Utilise le modèle",
    "time_left": "Temps restant",
    "animated_header": "En-tête animé",
    "header_size": "Taille de l'en-tête",
    "options": "Options",
    "effects": "Effets",
    "animation": "Animation",
    "parallax": "Parallaxe",
    "animation_repeat": "Répéter l'animation",
    "animation_intensity": "Intensité de l'animation",
    "animation_duration": "Durée de l'animation",
    "animation_delay": "Retard d'animation",
    "animation_initial_opacity": "Opacité initiale de l'animation",
    "animation_speed_curve": "Courbe de vitesse d'animation",
    "parallax_intensity": "Intensité de parallaxe",
    "animation_direction": "Direction de la parallaxe",
    "parallax_initial_opacity": "Opacité initiale de la parallaxe",
    "append_html": "Joindre du HTML",
    "css_classes": "Classes CSS",
    "forward_url": "Rediriger vers cette URL",
    "effect_fade": "Fade in",
    "effect_slide": "Toboggans",
    "effect_zoom": "Zoom",
    "effect_bounce": "Prallen",
    "effect_flip": "Flip",
    "effect_fold": "Rides",
    "effect_roll": "Rouleaux",
    "direction_center": "Centre",
    "direction_left": "Liens",
    "direction_right": "Droite",
    "direction_top": "En haut",
    "direction_bottom": "En bas",
    "repeat_once": "Une fois",
    "repeat_loop": "Boucle",
    "selected_template": "Modèle sélectionné",
    "premade_layouts": "Des mises en page prédéfinies",
    "your_existing_pages": "Vos pages existantes",
    "count_templates": "{count} Modèles",
    "background_attachment": "Annexe de fond",
    "background_position": "Position de l'arrière-plan",
    "background_repeat": "Arrière-plan Répétition",
    "background_size": "Taille de l'arrière-plan",
    "edit_mode": "Mode d'édition",
    "effects_active": "Effets actifs",
    "document_templates": "Modèles de documents",
    "code_document_templates_for_print_use_the_templates_for_individual_quotes_and_invoices": "Encodez vos propres modèles de documents afin de les imprimer pour vos clients. Vous pouvez également concevoir vos propres versions de devis et de factures.",
    "currencies": "Monnaies",
    "document_template": "Modèle de document",
    "document_content": "Contenu du document",
    "number_prefix": "Préfixe du numéro",
    "sales_documents": "Documents de vente",
    "theme": "Thème",
    "choose_template": "Choisir un modèle de document",
    "default": "Standard",
    "favorites": "Favoris",
    "favorites_list": "Liste des favoris",
    "require_client_address": "Demander l'adresse du client",
    "require_client_email": "Demander l'adresse e-mail du client",
    "require_client_phone": "Demander le numéro de téléphone du client",
    "require_message": "Demander un message",
    "subscription": "Abonnement",
    "subscription_plan": "Plan d'abonnement",
    "main_plugin": "Plugin principal",
    "target_organisation": "Organisation cible",
    "reference_number": "Numéro de référence",
    "show_invoice": "Afficher la facture",
    "delivery_date": "Date de livraison",
    "test_drive": "Essai sur route",
    "find_your_perfect_car": "Trouvez le véhicule idéal",
    "select_car_design": "Choisir le type de construction",
    "multiple_selection_possible": "Choix multiple possible",
    "fuel_type": "Carburant",
    "select_equipment_level": "Choisir le niveau d'équipement",
    "engine": "Motorisation",
    "like_budget": "Comme le budget",
    "like_family": "Comme Family",
    "like_comfort": "Comme le confort",
    "we_did_not_find_any_results_for_your_search": "Désolé, votre recherche n'a donné aucun résultat",
    "we_did_not_find_any_cars_at_this_location_for_the_selected_period": "Nous n'avons trouvé aucune voiture à cet endroit pour la période sélectionnée.",
    "we_did_not_find_any_cars_for_your_seaech": "Désolé, nous n'avons trouvé aucune voiture correspondant à votre recherche.",
    "all_cars": "Montrer toutes les voitures",
    "search_for_cars": "Rechercher des voitures",
    "used_car_search": "Recherche de voitures d'occasion",
    "dropdown": "Liste déroulante",
    "checkboxes": "Case à cocher",
    "multiple_choice": "Choix multiple",
    "text_input": "Saisie de texte",
    "number_input": "Saisie de chiffres",
    "multiline_input": "Saisie de texte sur plusieurs lignes",
    "paragraph": "Section",
    "change_questionnaire": "Modifier le questionnaire",
    "answers_to_a_questionnaire": "Réponses à un questionnaire",
    "request_filling_out_questionnaire": "Envoyez un questionnaire à vos clients",
    "send_questionnaire_as_message": "Envoyer le questionnaire en tant que message",
    "ingoing": "entrant",
    "outgoing": "Sortie",
    "vat_short": "TVA",
    "vat": "Taxe sur la valeur ajoutée",
    "price_net": "{prix} net",
    "environment": "Environnement",
    "inquire": "Demandes",
    "continue_to_product_name": "Aller à {productName}",
    "you_can_find_this_and_other_vehicles_at_company": "Vous trouverez ce véhicule et d'autres chez {company}.",
    "more_vehicles_based_on_your_search": "Autres véhicules basés sur votre recherche",
    "add_to_favorites": "Enregistrer comme favori",
    "remove_from_favorites": "Supprimer des favoris",
    "share_the_offer": "Partager l'offre",
    "any_question_the_company_team_is_at_your_disposal": "Vous avez des questions ? L'équipe de {company} est à votre disposition.",
    "you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal": "Vous voulez ce véhicule mais le prix n'est pas encore tout à fait correct ? Faites votre proposition de prix.",
    "you_want_to_rent_this_vehicle_at_project_name_you_can": "Vous souhaitez conduire ce véhicule pendant une longue période, chez {projectName} vous avez la possibilité de louer le véhicule de vos rêves.",
    "uploaded_video": "Télécharger la vidéo",
    "lead_autoresponse": "Lead auto-réponse",
    "automatically_respond_to_incoming_leads": "Répond automatiquement aux leads entrants. Définissez les jours de la semaine et les heures pendant lesquels l'autorépondeur est actif.",
    "client_gave_count_answers_to_the_name_questionnaire": "Le client a donné {count} réponses au questionnaire \"{nom}\".",
    "new_lead_from_comms_center": "Nouveau lead après une communication fermée",
    "customer_chat_ended": "Fin du chat avec un client",
    "chatter_welcome_message": "Nous vous souhaitons la bienvenue. Je vous remercie d'avoir essayé notre chat. Comment puis-je vous aider aujourd'hui ?",
    "please_rate_us": "Veuillez nous évaluer",
    "reservation_fee": "Frais de réservation",
    "item_name": "Nom de l'article",
    "deposit": "Acompte",
    "balance_due": "Solde dû",
    "authorize": "Approuver",
    "authorized_by_name_on_date": "Approuvé par {nom} le {date}",
    "auto_generated": "Généré automatiquement",
    "not_authorized": "Non approuvé",
    "settings_instructions": "Instructions sur les réglages",
    "payment_config": "Configuration des paiements",
    "projects": "Projets",
    "invoice_address": "Adresse de facturation",
    "deliver_adress": "Adresse de livraison",
    "your_contract_partner": "Votre partenaire contractuel",
    "remaining_balance": "Montant restant",
    "item_filter_is_active": "Visible en ligne",
    "item_filter_is_resource": "Ressource réelle",
    "item_filter_is_subscription": "Financement",
    "cancelled_by_name_on_date": "Annulé par {nom} le {date}",
    "add_credit_card_data_in_next_step": "Dans l'étape suivante, vous pouvez ajouter les données de votre carte de crédit",
    "upon_collection": "En cas d'enlèvement",
    "leads_manually_set_to_high_priority": "Leads définis manuellement comme hautement prioritaires",
    "leads_with_no_reaction_for_2_hours_since_creation": "Leads sans réponse pendant 2 heures depuis la création",
    "leads_that_were_not_updated_for_2_days": "Leads qui n'ont pas été actualisés pendant 2 jours",
    "use_this_element_as_reference": "Utiliser cet élément comme référence",
    "you_can_reuse_this_element_on_multiple_pages": "Il est possible de faire référence à des contenus qui sont réutilisés sur plusieurs sites web. Les éléments pour Express Page Builder ne peuvent pas être définis comme référence. De tels éléments sont toujours copiés",
    "company_data": "Données de l'entreprise",
    "company_singup_company_data_hint": "Pour que nous puissions créer votre entreprise et vous attribuer un domaine, nous avons besoin des informations suivantes de votre part.",
    "company_singup_personal_data_hint": "<p>Pour que vous puissiez obtenir votre accès avec vos propres données d'utilisateur, nous avons besoin des informations suivantes.<br/>Vous recevrez ensuite un e-mail avec vos données d'accès personnelles.</p>",
    "your_test_domaine": "Votre nom de domaine test",
    "your_test_domaine_hint": "<p>Veuillez créer un nom de domaine dans lequel votre projet sera hébergé. Le nom de domaine sera disponible immédiatement après l'enregistrement.<br/>N'oubliez pas que nous pouvons également héberger des projets sous un nom de domaine que vous aurez indiqué. Il vous suffit de nous contacter ultérieurement.</p>",
    "features": "Caractéristiques",
    "forms": "Formulaires",
    "pricings": "Prix",
    "testimonials": "Références",
    "show_your_page_structure_and_responsive_visibility": "Affiche la structure de votre page avec des légendes et une visibilité responsive",
    "toggle_edit_mode_for_page_preview": "Commutez le mode d'édition pour un aperçu de la page",
    "toggle_animation_and_parallax_effects": "Activer et désactiver les effets d'animation et de parallaxe",
    "use_quick_page_builder_to_create_content_faster": "Utilisez le Quick Page Builder pour créer des contenus beaucoup plus rapidement",
    "cancel_invoice": "Annuler une facture",
    "refunded": "Remboursé",
    "invoice_mark_refunded": "Régler la facture remboursée",
    "denied_to_pay": "Refus de paiement",
    "invoice_mark_denied_to_pay": "Ne paiera pas",
    "registration_success": "Enregistré avec succès",
    "no_images_yet": "Pas encore d'images.",
    "sortingPrice": "Prix du tri",
    "main_menu_version": "Version du menu principal",
    "main_menu_versions": "Versions du menu principal",
    "administration_of_main_menu_versions": "Gestion des versions du menu principal",
    "featured": "Souligné",
    "list_element_class": "Élément de liste (li) Classe CSS",
    "link_class": "Lien Classe CSS",
    "width": "Largeur",
    "primary_menu_elements": "Éléments du menu principal",
    "secondary_menu_elements": "Éléments du menu secondaire",
    "has_secondary_elements": "A des éléments secondaires",
    "active": "actif",
    "company_singup_wizard_step_subscription_plan": "Sélectionner des paquets",
    "company_singup_wizard_step_subscription_plan_headline": "Sélectionnez le paquet que vous souhaitez tester.",
    "company_singup_wizard_step_additional_plugins": "Paquets supplémentaires",
    "company_singup_wizard_step_subscription_plan_main_plugin_headline": "Je souhaite tester les fonctionnalités suivantes :",
    "company_singup_wizard_step_company_data": "Coordonnées de votre entreprise",
    "company_singup_wizard_step_company_data_headline": "Nom et coordonnées de votre entreprise",
    "company_singup_wizard_step_personal_data": "Votre profil d'employé",
    "company_singup_wizard_step_personal_data_headline": "Votre profil d'employé",
    "company_singup_wizard_step_personal_data_hint": "Pour pouvoir créer un profil de collaborateur pour vous, nous avons besoin d'informations sur votre personne. Avec ce compte, vous obtenez des droits d'administration pour votre projet %platformName%.",
    "company_singup_wizard_step_personal_data_domain_hint": "<p>Veuillez créer un nom de domaine dans lequel votre projet sera hébergé. Le nom de domaine sera disponible immédiatement après l'enregistrement.<br/>N'oubliez pas que nous pouvons également héberger des projets sous un nom de domaine que vous aurez indiqué. Il vous suffit de nous contacter ultérieurement.</p>",
    "company_singup_wizard_free_trial_hint": "Testez nos offres gratuitement et sans engagement pendant un mois.",
    "company_signup_wizard_free_trial_plugin_hint": "Tester gratuitement pendant un mois, puis :",
    "included_in_subscription_plan": "Inclus dans l'abonnement, sinon",
    "mostly_asked": "Le plus souvent demandé",
    "test_package": "Tester le paquet",
    "active_since": "actif depuis",
    "test_period_until": "Phase de test jusqu'à",
    "navigate_to_plugins_and_configure": "Vers l'aperçu des plugins et les paramètres",
    "termination_date": "Date de fin",
    "lead_taggers": "Lead Taggers",
    "lead_tagger": "Lead Tagger",
    "share_with": "Partager avec",
    "password_weak": "Faible",
    "password_medium": "Moyens",
    "password_strong": "Forte",
    "plugin_already_added_in_whitelabel_project": "Le plug-in est déjà associé au projet Whitelabel.",
    "lead_channel": "Canal",
    "lead_channel_web": "Web",
    "lead_channel_mobile": "App",
    "lead_channel_phone": "Téléphone",
    "lead_channel_email": "E-mail",
    "lead_channel_personally": "Personnel",
    "allow_others_to_share": "Permettre aux autres de partager avec plus de personnes",
    "additional_imprint_information": "Mentions légales supplémentaires",
    "share_with_the_company_i_work_for": "Partager avec l'entreprise pour laquelle je travaille",
    "share_with_all_companies_in_my_holding_structure": "Avec toutes les entreprises de ma structure de holding, partager",
    "latest_lead": "Dernier lead",
    "latest_edit": "Dernière édition",
    "reaction_time": "Temps de réaction",
    "no_reaction_time": "pas de réaction",
    "gt_2_days": "> 2 jours",
    "lt_5_minutes": "< 5 minutes",
    "lt_1_hour": "< 1 heure",
    "lt_2_hours": "< 2 heures",
    "lt_6_hours": "< 6 heures",
    "lt_1_day": "< 1 jour",
    "lt_2_days": "< 2 jours",
    "by_employee": "Par employé",
    "by_company": "Par entreprise",
    "by_location": "Par site",
    "by_source": "Par source",
    "client_data": "Données des clients",
    "show_in_lead_description": "Afficher dans la description du lead",
    "this_answer_will_be_shown_in_lead_description": "Cette réponse s'affiche dans la description du lead",
    "this_answer_will_not_be_shown_in_lead_description": "Cette réponse n'apparaît pas dans la description du lead",
    "lead_assignment_strategy": "Stratégie d'attribution des leads",
    "lead_assignment_strategies": "Stratégies d'attribution des leads",
    "change_the_lead_assignment_strategies_for_companies_and_pipelines": "Modifier la manière dont vos collaborateurs attribuent les prospects en fonction de leurs entreprises et de leurs pipelines.",
    "strategy": "Stratégie",
    "deleted": "Supprimé",
    "assign_to_me": "M'attribuer",
    "responsibilities": "Responsabilités",
    "escalation_rules": "Règles d'escalade",
    "escalation_rule": "Règle d'escalade",
    "define_rules_when_you_would_like_to_inform_users_about_overdue_leads": "Définit des règles permettant à vos équipes d'identifier les leads qui ont été abandonnés ou auxquels les membres de l'équipe ne répondent pas.",
    "category_images": "Images de la catégorie",
    "use_document_template": "Utiliser un modèle de document",
    "escalation_level_one": "Première étape - Informer les employés",
    "escalation_level_two": "Deuxième étape - Informer le chef d'équipe",
    "automatically_terminate_the_lead": "Terminer automatiquement le lead (marquer le lead comme perdu)",
    "no_reaction": "Pas de réaction",
    "no_reaction_for": "Pas de réaction pour",
    "no_update": "Pas de mise à jour",
    "no_update_for": "Pas de mise à jour pour",
    "escalation_level": "Niveau d'escalade",
    "time_settings": "Temps Réglages",
    "pending": "En attente",
    "skipped": "Sauté",
    "holding_structure": "Structure de la holding",
    "show_sibling_companies": "Montrer des entreprises sœurs",
    "show_descendant_companies": "Afficher les entreprises subordonnées",
    "subscription_type": "Modèle de financement",
    "no_subscription_type_selected": "Il faut choisir au moins un modèle de financement.",
    "financing": "Financement",
    "monthly_payment": "Mensualité",
    "final_payment": "Paiement final",
    "interest_rate": "Taux d'intérêt",
    "availability": "Disponibilité",
    "delivery_time_month": "Délai de livraison (en mois)",
    "delivery_time": "Délai de livraison",
    "cancelation_period_month": "Délai de préavis (en mois)",
    "enter_financing_data": "Saisir les données de financement",
    "marketing_agency": "Agence de marketing",
    "edit_company_read_and_write_rights": "Modifier les droits de lecture et d'écriture de l'entreprise",
    "grant_data_access": "Autoriser l'accès aux données",
    "deny_data_access": "Refuser l'accès aux données",
    "allow_data_access_for_a_single_company": "Permettre l'accès aux données pour une seule entreprise",
    "allow_data_access_for_a_whole_level": "Permettre l'accès aux données pour tout un niveau",
    "deny_data_access_for_a_single_company": "Refuser l'accès aux données pour une seule entreprise",
    "deny_data_access_for_a_whole_level": "Refuser l'accès aux données pour un niveau entier",
    "closing_costs": "Frais de souscription",
    "nominal_interest_rate": "Utilisation théorique",
    "has_rate_insurance": "Garantie des mensualités",
    "is_user_based": "Est basé sur l'utilisateur",
    "min_user_count": "Min Utilisateur Nombre",
    "max_user_count": "Nombre max. d'utilisateurs",
    "price_per_user": "Prix par utilisateur",
    "monthly": "Mensuel",
    "instantly": "Immédiatement",
    "quarterly": "Trimestriel",
    "annual": "Annuellement",
    "monthly_fee": "Frais mensuels",
    "starting_fee": "Frais d'inscription",
    "included_templates_count": "Nombre de modèles inclus",
    "simple_templates_count": "Nombre de modèles inclus (Simple)",
    "middle_templates_count": "Nombre de modèles inclus (moyenne)",
    "complex_templates_count": "Nombre de modèles inclus (Complex)",
    "surcharge": "Supplément",
    "base_surcharge": "Supplément de base",
    "max_surcharge": "Supplément maximal",
    "dataproof_surcharge": "Supplément Dataproof",
    "aws_domain_hosting": "Hébergement de domaine via AWS",
    "domain_hosting": "Hébergement de site web, y compris le domaine",
    "desired_domain": "Domaine souhaité",
    "redirect_uri": "Transmission",
    "check_availability": "Vérifier la disponibilité",
    "cart_edition_title": "Désignation :",
    "cart_recent_edition": "Dernièrement édité :",
    "cart_campaign_title": "campagne :",
    "cart_last_saved": "Dernier enregistré :",
    "remove_item": "Supprimer le produit",
    "config": "Configurer",
    "submit_config": "Confirmer la configuration",
    "complete_order": "Terminer la commande",
    "payment_method_description": "Veuillez sélectionner le mode de paiement souhaité",
    "cart_hint": "Remarque :",
    "cart_hint_description_part_1": "Veuillez n'inscrire ici que des remarques générales. Les remarques sur les produits ne peuvent être prises en compte que si vous avez sélectionné l'option",
    "cart_hint_description_part_2": "Vérification manuelle des données",
    "cart_hint_description_part_3": "et que vous avez saisi la remarque pour le produit concerné.",
    "your_message": "Votre message pour nous",
    "hint_dataproof_1": "Vous avez renoncé à la vérification manuelle des données par notre expert pour un ou plusieurs articles.",
    "hint_dataproof_2": "Nous attirons votre attention sur le fait que, dans ce cas, vous assumez vous-même l'entière responsabilité de votre création. En cas d'erreurs de données, de couleurs incorrectes dans les photos, etc., toute réclamation est exclue. Nos conditions générales de vente s'appliquent.",
    "hint_dataproof_3": "Si vous optez pour la vérification manuelle des données, votre fichier sera examiné par un expert afin de détecter d'éventuelles erreurs (vérification technique, pas d'orthographe juridique). Au cas où nous passerions à côté d'une erreur, nous en assumerions la responsabilité. Aucun risque pour vous !",
    "hint_missing_order_quantity": "Quantité commandée manquante !",
    "missing_order_quantity": "Vous n'avez pas sélectionné de quantité de commande pour un ou plusieurs articles.",
    "missing_invoice_shipping_address": "L'adresse de facturation ou de livraison est manquante. Veuillez sélectionner une adresse.",
    "billing_address_form_is_not_submitted": "L'adresse de facturation n'a pas été transmise. Veuillez remplir le formulaire.",
    "shipping_address_form_is_not_submitted": "L'adresse de livraison n'a pas été transmise. Veuillez remplir le formulaire.",
    "no_invoice_address": "Aucune adresse de facturation créée",
    "no_delivery_address": "Aucune adresse de livraison créée",
    "use_invoice_contact": "Utiliser le contact de facturation",
    "order_item": "Article",
    "order_price": "Prix",
    "order_copies": "Tirage",
    "order_totals": "Total des commandes",
    "subtotal": "Sous-total",
    "invoice_amount": "Montant de la facture",
    "remainder_available": "Reste non distribué disponible",
    "reduce_edition": "Pour ajouter une nouvelle adresse, vous devez d'abord réduire le tirage de l'adresse actuelle.",
    "split_delivery_item_hint": "La répartition de la quantité totale n'est pas encore terminée. La quantité indiquée doit être une valeur comprise entre 1 et la quantité restante à répartir ou le tirage ; actuellement une valeur de maximum",
    "net_amount": "Montant net",
    "gross_amount": "Montant brut",
    "is_debtor": "Fournisseur",
    "is_debtor_number_provider": "Numéro de débiteur Fournisseur",
    "is_never_remind": "ne pas envoyer de rappels",
    "is_demo": "Mode démo",
    "is_vendor": "Fournisseur",
    "should_user_pay": "L'utilisateur doit payer",
    "receive_payment_from_users": "Paiement reçu des utilisateurs",
    "use_payment_receiver": "Utiliser l'option du destinataire du paiement",
    "payment_receiver": "Bénéficiaire",
    "use_summary_invoice": "Utiliser une facture groupée",
    "credit_limit": "Limite de crédit",
    "commission_receiver": "Bénéficiaire de la commission",
    "budget_value": "Valeur budgétaire",
    "billing_value": "Valeur de règlement",
    "coupon_billing": "Valeur de facturation pour la période de facturation sélectionnée",
    "coupon_billing_period": "Période de facturation",
    "coupon_recharge_period": "Recharger le bon",
    "coupon_print_type": "Limiter le bon",
    "coupon_never": "Jamais",
    "coupon_unset": "Aucune restriction",
    "coupon_print_pdf": "PDF d'impression uniquement",
    "recurring": "Récurrent",
    "period": "Période",
    "without_end_date": "Sans date de fin",
    "budget_settings": "Paramètres budgétaires",
    "no_budget_set": "Pas de budget défini",
    "current_period_budget_usage": "Utilisation du budget dans la période actuelle",
    "user_entered_no_message": "L'utilisateur n'a pas saisi de message",
    "coupons": "Bons d'achat",
    "coupon": "Bon d'achat",
    "create_discounts_and_subsidy_products": "Créer des codes de réduction et subventionner des produits pour vos partenaires",
    "authorization_status": "Statut d'autorisation",
    "user_budget": "Budget des utilisateurs",
    "manual_vat_check": "Contrôle réduit du taux de TVA",
    "manual_data_check": "Vérification manuelle des données",
    "completed": "Terminé",
    "changes_requested": "Modifications demandées",
    "rejected": "Refusé",
    "request_changes": "Demander des modifications",
    "reply": "Réponses",
    "coupon_monetary": "Remise fixe",
    "coupon_percentage": "Pourcentage de réduction",
    "calculations": "Calculs",
    "percent_discount_value": "Pourcentage de réduction",
    "monetary_discount_value": "Remise en argent",
    "company_budget": "Budget de l'entreprise",
    "enter_0_to_ignore_this_setting": "Saisir 0 pour ignorer ce paramètre",
    "min_order_value": "Valeur minimale de commande",
    "max_order_discount": "Remise maximale par commande",
    "coupon_budget_exceeded": "Budget des bons dépassé",
    "billing_target": "Objectif de facturation",
    "billing_settings": "Paramètres de facturation",
    "dataproof_textarea": "Faut-il faire attention à quelque chose ?",
    "edit": "Modifier",
    "edit_client": "Traiter les données des clients",
    "edit_gallery": "Modifier les détails de la galerie",
    "edit_job": "Modifier les détails du travail",
    "edit_position": "Modifier le poste",
    "edit_product": "Modifier le produit",
    "edit_producttemplate": "Modifier le modèle",
    "edit_question": "Modifier la question",
    "edit_template": "Modifier le modèle",
    "edit_step": "Modifier le niveau du pipeline",
    "edit_payment": "Traiter le paiement",
    "edit_team": "Modifier l'équipe",
    "edit_document_type": "Modifier le type de document",
    "edit_brand": "Modifier la marque",
    "edit_model": "Modifier le modèle",
    "edit_category": "Modifier la catégorie",
    "edit_attribute": "Modifier l'attribut",
    "edit_data_push": "Modifier le push de données",
    "edit_lead_catcher": "Modifier le lead catcher",
    "edit_external_account": "Modifier un compte externe",
    "edit_comment": "Modifier le commentaire",
    "edit_delivery_date": "Traiter la date de livraison",
    "edit_lead_tagger": "Modifier le lead tagger",
    "edit_commission_receiver": "Traiter les bénéficiaires de commissions",
    "edit_payment_receiver": "Traiter le destinataire du paiement",
    "edit_billing_target": "Traiter l'objectif de facturation",
    "create_client": "Créer une nouvelle entreprise",
    "create_gallery": "Créer une nouvelle galerie",
    "create_job": "Créer un nouveau travail",
    "create_product": "Créer un nouveau produit",
    "create_producttemplate": "Créer un nouveau modèle",
    "create_questionnaire": "Créer un nouveau questionnaire",
    "create_question": "Créer une nouvelle question",
    "create_template": "Créer un nouveau modèle d'e-mail",
    "create_cms_template": "Créer un nouveau modèle CMS",
    "create_pipeline": "Créer un nouveau pipeline",
    "create_step": "Créer un nouveau niveau de pipeline",
    "create_payment_schedule": "Créer un nouveau plan de paiement",
    "create_payment": "Créer un nouveau paiement",
    "create_message": "Écrire un message",
    "create_text_message": "Écrire un message texte (SMS)",
    "create_note": "Écrire une note",
    "create_user": "Créer un nouvel utilisateur",
    "create_users": "Créer un utilisateur",
    "create_doc_type": "Créer un nouveau type de document",
    "create_team": "Créer une équipe",
    "create_brand": "Créer une marque",
    "create_model": "Créer un modèle",
    "create_category": "Créer une catégorie",
    "create_attribute": "Créer un attribut",
    "create_attribute_group": "Créer un groupe d'attributs",
    "create_car": "Créer un véhicule",
    "create_item": "Créer un produit",
    "create_lead": "Créer un lead",
    "assign_lead": "Affecter un lead",
    "create_page": "Créer une page",
    "create_data_push": "Créer un nouveau push de données",
    "create_white_label_project": "Créer un nouveau projet en marque blanche",
    "create_cms_element": "Créer un nouvel élément CMS",
    "create_lead_catcher": "Recruter un nouveau lead catcher",
    "create_white_label_plugin": "Créer un nouveau plugin en marque blanche",
    "create_dynamic_data_definition": "Créer une nouvelle définition de données dynamique",
    "add_account": "Ajouter un compte",
    "create_external_account": "Ajouter un nouveau compte externe",
    "create_model_group": "Créer un nouveau groupe de modèles",
    "create_model_asset": "Créer une nouvelle image de modèle",
    "create_lead_per_mail_rule": "Créer un nouveau lead par règle d'e-mail",
    "duplicate_page": "Dupliquer la page",
    "duplicate_cms_element": "Dupliquer un élément CMS",
    "duplicate_cms_template": "Dupliquer un modèle CMS",
    "create_task": "Créer une tâche",
    "create_car_service": "Créer un service",
    "create_car_service_station": "Créer un poste de travail",
    "create_doc_template": "Créer un nouveau modèle de document",
    "create_lead_autoresponse": "Créer un nouveau lead auto-réponse",
    "create_main_menu_version": "Créer une version du menu principal",
    "create_lead_tagger": "Créer un lead tagger",
    "create_lead_assignment_strategy": "Créer une stratégie d'attribution des leads",
    "create_escalation_rule": "Créer une règle d'escalade",
    "create_coupon": "Créer un bon d'achat",
    "client_data_saved": "Données clients enregistrées",
    "user_data_saved": "Données utilisateur enregistrées",
    "job_data_saved": "Données du travail enregistrées",
    "product_data_saved": "Données de produits enregistrées",
    "template_data_saved": "Modèle Données enregistrées",
    "company_logo_saved": "Le logo a été enregistré",
    "payment_schedule_data_saved": "Le plan de paiement a été enregistré",
    "questionnaire_filled_out": "Questionnaire rempli",
    "your_message_has_been_sent": "Votre message a été envoyé",
    "email_with_password_reset_instructions_sent": "E-mail avec réinitialisation du mot de passe Les instructions ont été envoyées",
    "password_changed_after_reset": "Votre mot de passe a été modifié. Retour de bienvenue",
    "quote_accepted_saved": "L'offre a été acceptée",
    "quote_rejected_saved": "Offre refusée",
    "quote_saved": "Offre enregistrée",
    "message_sent": "Le message a été envoyé",
    "note_saved": "La note a été enregistrée",
    "holding_info_saved": "Informations de la holding enregistrées",
    "new_password_set": "Un nouveau mot de passe a été défini",
    "questionnaire_data_saved": "Questionnaire enregistré",
    "doc_type_saved": "Type de document enregistré",
    "team_saved": "Équipe enregistrée",
    "document_saved": "Document enregistré",
    "brand_saved": "Marque enregistrée",
    "model_saved": "Modèle enregistré",
    "category_saved": "Catégorie enregistrée",
    "attribute_saved": "Attribut enregistré",
    "item_data_saved": "Données de produits enregistrées",
    "car_data_saved": "Données du véhicule enregistrées",
    "variant_saved": "Variant enregistré",
    "pipeline_saved": "Pipeline enregistré",
    "comment_saved": "Commentaire enregistré",
    "lead_saved": "Lead enregistré",
    "order_saved": "Commande enregistrée",
    "document_reviewed": "Document vérifié",
    "locations_saved": "Sites enregistrés",
    "email_confirmed": "E-mail confirmé",
    "page_saved": "Page enregistrée",
    "template_saved": "Modèle enregistré",
    "data_push_saved": "Push de données enregistré",
    "white_label_saved": "Projet enregistré en marque blanche",
    "attribute_group_saved": "Groupe d'attributs enregistré",
    "cms_element_saved": "Élément CMS enregistré",
    "lead_catcher_saved": "Lead catcher enregistré",
    "image_data_saved": "Données d'image enregistrées",
    "white_label_plugin_saved": "Plugin en marque blanche enregistré",
    "dynamic_data_definition_saved": "Définition dynamique des données enregistrée",
    "company_data_saved": "Données de l'entreprise enregistrées",
    "contact_data_saved": "Coordonnées enregistrées",
    "no_changes_made": "Aucun changement n'a été apporté",
    "car_order_approved": "Vente de voitures autorisée",
    "vin_number_updated": "Numéro de châssis du véhicule mis à jour",
    "ein_number_updated": "Mise à jour de la confirmation d'assurance électronique",
    "order_marked_as_paid": "Commande marquée comme payée",
    "payment_instructions_sent": "Instructions de paiement envoyées",
    "order_cancelled": "Commande annulée",
    "sales_contract_uploaded": "Contrat de vente téléchargé",
    "bank_data_updated": "Données bancaires mises à jour",
    "pickup_invite_sent": "Collecte Invitation envoyée",
    "order_picked_up": "Commande récupérée",
    "order_assignee_kroschke_documents_sent": "Le concessionnaire a envoyé les documents nécessaires à l'enregistrement du véhicule.",
    "order_client_kroschke_documents_sent": "Le client a envoyé les documents nécessaires à l'enregistrement de son véhicule.",
    "plugin_partial_activated": "Plugin partiellement activé",
    "plugin_request_activate_sent": "Demande d'activation de plugin envoyée",
    "plugin_activated": "Plugin activé",
    "plugin_terminated": "Plugin terminé",
    "changes_saved": "Modifications enregistrées",
    "app_secret_saved": "App secret sauvegardé",
    "page_access_token_saved": "Jeton d'accès à la page enregistré",
    "text_message_sent": "SMS envoyé",
    "account_saved": "Compte enregistré",
    "model_group_saved": "Groupe de modèles enregistré",
    "model_asset_saved": "Image du modèle enregistrée",
    "sepa_agreement_saved": "Mandat SEPA enregistré",
    "document_uploaded": "Un document a été téléchargé",
    "customer_sent_message": "Le client a envoyé un message",
    "lead_created": "Lead créé",
    "lead_watching": "A commencé à observer les leads",
    "lead_unwatching": "Met fin à l'observation des leads",
    "lead_per_mail_rule_saved": "Lead enregistré par règle d'e-mail",
    "drivers_license_uploaded": "Permis de conduire téléchargé",
    "plugin_saved": "Plugin enregistré",
    "settings_saved": "Paramètres enregistrés",
    "page_duplicated": "Page dupliquée",
    "cms_template_duplicated": "Modèle CMS dupliqué",
    "cms_element_duplicated": "Élément CMS dupliqué",
    "price_negotiation_started": "Négociation de prix lancée",
    "new_price_negotiation_message_offer_sent": "Nouvelle offre de négociation de prix envoyée",
    "you_joined_community": "Tu as rejoint la communauté",
    "you_left_community": "Tu as quitté la communauté",
    "car_service_booked": "Rendez-vous réservé en ligne",
    "car_service_saved": "Service enregistré",
    "car_service_station_saved": "Poste de travail enregistré",
    "team_member_added": "Membre de l'équipe ajouté",
    "team_member_removed": "Membre de l'équipe retiré",
    "doc_template_saved": "Modèle de document enregistré",
    "invoice_issued": "Facture émise",
    "request_to_fill_out_questionnaire_saved": "Demande de remplissage d'un questionnaire enregistrée",
    "request_to_fill_out_questionnaire_name_saved": "Demande de remplissage d'un questionnaire \"{nom}\" enregistré",
    "lead_autoresponse_saved": "Lead Autoresponse enregistré",
    "main_menu_version_saved": "Version du menu principal enregistrée",
    "lead_tagger_saved": "Lead Tagger enregistré",
    "share_settings_updated": "Mise à jour des paramètres de partage",
    "lead_assignment_strategy_saved": "Stratégie d'attribution des leads enregistrée",
    "escalation_rule_saved": "Règle d'escalade enregistrée",
    "value_type": "Type",
    "value_precision": "Précision",
    "category_already_in_attribute": "La catégorie est déjà affectée à l'attribut.",
    "no_attributes_for_variants": "Vous devez définir des attributs pour créer des variantes.",
    "budget_saved": "Budget enregistré",
    "budget_removed": "Budget supprimé",
    "coupon_saved": "Bon enregistré",
    "authorization_data_saved": "Données d'autorisation enregistrées",
    "template_data": "Généralités",
    "template_pages": "Paramètres de la page",
    "template_editor": "Éditeur & paramètres PDF",
    "template_timeline": "Ligne de temps",
    "template_blocks": "Blocs de contenu",
    "template_import": "Importer",
    "template_export": "Exporter",
    "template_generate": "Générateur (TEST)",
    "template_print_data": "Données d'impression",
    "template_import_select_template": "Sélectionner un dossier de modèles",
    "template_import_select_print_data_document": "Données d'impression Sélectionner le document",
    "template_upload_print_data_title": "Télécharger les données d'impression dans le répertoire des modèles",
    "template_upload_print_data_actual_file_title": "Données d'impression téléchargées",
    "template_upload_print_data_info": "Ajoute un fichier au répertoire de modèles S3. Ce fichier est utilisé pour les produits lorsqu'ils sont créés et aucune donnée d'impression n'est générée.",
    "template_blocks_template": "Bloc HTML",
    "template_category_thumb": "Classe d'icônes de vignettes (ex. : fad fa-circle)",
    "add_block_category": "Nouvelle catégorie",
    "edit_block_category": "Modifier la catégorie",
    "add_block_variant": "Nouvelle variante",
    "edit_block_variant": "Modifier la variante",
    "add_block": "Nouveau bloc",
    "copy_block": "Copier le bloc",
    "edit_block": "Modifier le bloc",
    "edit_takeit": "Reprendre",
    "edit_import": "Importer",
    "edit_import_warning": "En toute sécurité ? Cela écrase toutes les entrées non enregistrées !",
    "template_has_content_blocks": "Contenu variable",
    "template_in_test": "Mode test pour les commandes",
    "placeholder": "Caractère de remplacement pour lettre en série",
    "select_customer": "Sélectionner un client",
    "company_added": "Société ajoutée",
    "company_removed": "Société supprimée",
    "item_added": "Produit ajouté",
    "item_removed": "Produit retiré",
    "category_added": "Catégorie ajoutée",
    "category_removed": "Catégorie supprimée",
    "select_user_set": "Sélectionner l'autorisation de l'utilisateur",
    "sent_reminders": "Souvenirs envoyés",
    "number_of_sent_reminders": "Nombre de rappels envoyés",
    "set_number": "Définir un numéro",
    "email_to_the_customer_on_save": "Envoyer par e-mail au client lors de l'enregistrement",
    "email_on_save": "E-mail lors de l'enregistrement",
    "shown_name": "Nom de l'annonceur",
    "filename_thumbnail": "Nom de fichier pour la vignette",
    "name_of_imagefile": "Nom du fichier image, sans répertoire. Ex : thumb1.jpg",
    "block_position": "Position du bloc en lignes de la grille, 0 pour le haut, rend le bloc absolu",
    "defined_raster_space": "emplacement de grille prédéfini",
    "needed_raster_space": "espace de grille nécessaire",
    "size_group_info_text": "Tous les blocs avec le même SizeGroup peuvent être redimensionnés dans l'éditeur.",
    "size_group": "Groupe de taille",
    "group_index_info_text": "Tous les blocs avec le même SizeGroup et la même taille de bloc peuvent être commutés avec l'index. A partir de l'index 1.",
    "group_index": "Index du groupe",
    "bottom_info_text": "ATTENTION : bottom positionne le bloc de manière absolue au bas de la page, avec l'espacement défini dans PX",
    "bottom_label": "Positionner le bloc absolument en bas",
    "fixed_block_label": "Bloc fixe",
    "fixed_block_info_text": " Le bloc ne peut pas être déplacé",
    "once_per_document_label": "Une fois par document",
    "once_per_document_info_text": "autorisé une seule fois dans l'ensemble du document",
    "no_copies_label": "Bloc non copiable",
    "no_copies_info_text": "Le bloc ne peut pas être copié",
    "no_options_label": "Ne pas afficher les options",
    "no_options_info_text": "Le bloc n'a pas d'options dans l'éditeur lorsqu'il est inséré.",
    "once_per_page_label": "Une fois par page",
    "once_per_page_info_text": "autorisé une seule fois sur une page",
    "once_per_variant_label": "Once per Variant",
    "once_per_variant_info_text": "autorisé une seule fois par variante",
    "once_per_category_label": "Une fois par catégorie",
    "once_per_category_info_text": "un seul autorisé par catégorie",
    "only_whitelabel_admin_can_modify_label": "Administrateur Whitelabel uniquement",
    "only_whitelabel_admin_can_modify_info_text": "Ce champ ne peut être modifié que par l'administrateur Whitelabel. Il doit être utilisé lorsque l'administrateur whitelabel crée un modèle.",
    "restricted_to_page_label": "Autorisé sur la page",
    "restricted_to_page_info_text": "Disponible uniquement sur ce site, -1 = autorisé partout",
    "exclude_pages_label": "Exclure des pages (ex : 1,2,3...)",
    "exclude_pages_info_text": "Non autorisé sur ces pages, séparer par une virgule",
    "only_last_label": "Seulement sur la dernière page",
    "only_last_info_text": "Le bloc ne peut être inséré que sur la dernière page.",
    "container_block_label": "Bloc de conteneurs",
    "container_block_info_text": "Ce bloc contient un conteneur pour d'autres blocs",
    "inline_block_label": "Bloc en ligne",
    "inline_block_info_text": "Le bloc est traité comme un display:inline-block",
    "inner_label": "Peut être inséré dans un conteneur de bloc",
    "inner_info_text": "Le bloc peut être inséré dans un conteneur de bloc",
    "container_class_label": "Limiter au bloc de conteneurs",
    "container_class_info_text": "Nom de classe du conteneur dans lequel le bloc peut être inséré.",
    "only_inside_block_label": "Ne peut être inséré que dans un conteneur bloc",
    "only_inside_block_info_text": "Le bloc ne peut être inséré que dans un conteneur de bloc",
    "container_space_label": "Emplacements tramés dans le bloc de conteneurs",
    "container_space_info_text": "Lignes de trame disponibles dans le bloc (même trame que Page !)",
    "container_cols_label": "Colonnes dans le bloc de conteneurs",
    "container_cols_info_text": "les lignes de grille disponibles sont multipliées par cette valeur",
    "website_only": "Pour les sites web uniquement",
    "navName": "Nom pour la navigation",
    "nav_link_label": "Lien pour la navigation",
    "nav_link_info_text": "Lien d'ancrage vers l'ID du bloc",
    "nav_options": "Options de navigation",
    "combi_parent_label": "Élément combiné (Parent)",
    "combi_parent_info_text": "Élément parent, peut être inséré à tout moment",
    "combi_child_label": "Elément combiné (Child)",
    "combi_child_info_text": "Les éléments enfants ne peuvent être placés qu'en combinaison avec l'élément parent.",
    "disable_group_label": "Options de groupe",
    "disable_group_info_text": "Vous pouvez définir ici des groupes qui s'excluent mutuellement. Par exemple, P#groupe1,groupe2 et D#groupe2,groupe1. Paramètres de définition - P = valable pour la page, D = valable pour le document.",
    "events": {
      "TimelineMailPasswordReset": "Le client a demandé le changement de mot de passe",
      "TimelineMailRegistration": "Le client s'est inscrit à Creacheck.com",
      "TimelineMailOauthRegistration": "Le client s'est inscrit sur Creacheck.com avec Social Media Login",
      "TimelineMailInvitation": "Le client a été invité à utiliser Creacheck.com",
      "TimelineMailContactForm": "Le client a envoyé un message via le formulaire de contact",
      "TimelineMailInvitationAccepted": "Le client a accepté l'invitation",
      "TimelineMailNewInvoice": "Vous avez envoyé une facture au client",
      "TimelineMailNewQuote": "Vous avez envoyé une offre au client",
      "TimelineMailNewMessage": "Vous avez envoyé un message au client",
      "TimelineQuoteAccepted": "Le client a accepté votre offre",
      "TimelineQuoteRejected": "Malheureusement, le client a refusé votre offre",
      "TimelineOrderCreated": "Nouvelle commande",
      "TimelineMailNewCustomerMessage": "Message électronique du client",
      "TimelineMonitoringPaymentReceipt": "Suivi des encaissements",
      "TimelineClosingManualDataCheck": "Contrôle manuel des données fermé",
      "TimelineUpdatedByForeignStatus": "Statut de la commande mis à jour",
      "TimelineMailedForeignOrderStatusOrderCanceled": "annulé par le fournisseur",
      "TimelineMailedForeignOrderStatusInDelivery": "en cours de livraison",
      "TimelineMailedForeignOrderStatusInProduction": "en production",
      "TimelineMailedForeignOrderStatusInReview": "en consultation",
      "TimelineMailedForeignOrderStatusInOrder": "en commande",
      "TimelineMailedOrderedWebAndDownloadPDF": "PDF téléchargeable et compatible avec le web envoyé aux destinataires d'e-mails.",
      "TimelineMailedOrderedDownloadPDF": "PDF à télécharger envoyé aux destinataires de l'e-mail.",
      "TimelineMailedOrderedWebPDF": "Web à des destinataires de courrier électronique.",
      "TimelineDomainHostingHasBeenAuthorized": "L'hébergement de domaine a été autorisé",
      "TimelineDomainNotAvailable": "Le domaine n'est plus disponible",
      "TimelineDomainHostingDomainValidated": "Le domaine a été validé",
      "TimelineExceptionCheckDomainAvailability": "Erreur lors de la vérification de la disponibilité du domaine de {0}. Erreur : {2}",
      "TimelineDomainFailRegistering": "Echec de l'enregistrement du domaine {0}",
      "TimelineExceptionRegisterDomain": "Echec de l'enregistrement du domaine {0} avec erreur : {2}",
      "TimelineDomainSuccessfullyRegistered": "Le domaine {0} a été enregistré avec succès.",
      "TimelineDomainSuccessfullyRedirected": "Domaine redirigé avec succès",
      "TimelineDomainCreatingCertificate": "Certificat pour le domaine {0} en cours de création avec erreur : {1}",
      "TimelineDomainCreatingDistributionFailed": "Echec de la distribution du domaine {0} avec erreur : {1}",
      "TimelineDomainCreatingSuccessfullyHosted": "Le domaine {0} a été hébergé avec succès",
      "TimelineDomainCreatingFailedHosting": "Le domaine {0} n'a pas pu être hébergé avec l'erreur : {1}",
      "TimelineDomainHostingCompleted": "Terminé",
      "TimelineMissingClientDataFirstAndLastName": "Absence du prénom et du nom du client !",
      "TimelineMissingClientDataPhoneNumber": "Numéro de téléphone du client manquant !",
      "TimelineDomainCreatingSuccessfullyFinished": "Le domaine {0} a été clôturé avec succès"
    },
    "attributeName": {
      "select": "Champ de sélection",
      "html": "Champ HTML",
      "checkbox": "Case à cocher",
      "text": "Zone de texte",
      "colors": "Choix de couleurs",
      "number": "Champ numérique",
      "boolean": "Oui/Non",
      "date": "Date"
    },
    "attributeType": {
      "select": "Vous pouvez définir plusieurs options à sélectionner. L'utilisateur peut alors en choisir une",
      "html": "L'utilisateur peut écrire un texte plus long avec l'éditeur de contenu enrichi",
      "checkbox": "Vous pouvez définir plusieurs options à sélectionner. L'utilisateur peut alors en sélectionner plusieurs",
      "text": "L'utilisateur peut saisir un texte simple",
      "colors": "L'utilisateur peut choisir une couleur parmi celles définies ici",
      "number": "L'utilisateur peut saisir un nombre",
      "boolean": "L'utilisateur peut choisir entre oui et non",
      "date": "L'utilisateur peut choisir une date"
    },
    "attributeValueType": {
      "string": "Texte",
      "number": "Nombre",
      "date": "Date"
    },
    "attributePrecision": {
      "year": "Année",
      "month": "Mois",
      "day": "Jour",
      "time": "Heure"
    },
    "categoryDisplay": {
      "select": "Oui/non",
      "number_range": "Plage numérique",
      "date_range": "Plage de dates",
      "list": "Sélection de la liste"
    },
    "invoice_actions": {
      "tab_title": "Actions sur la facture",
      "refunded": {
        "title": "Remboursé manuellement",
        "label": "Marquer comme remboursé",
        "info_text": "Marquage manuel de la facture comme déjà remboursée, sans création d'une facture d'annulation"
      },
      "denied_to_pay": {
        "title": "Paiement refusé",
        "label": "Marquer comme refusé",
        "info_text": "Marquer que la facture ne sera jamais payée"
      },
      "amount_paid": {
        "title": "Saisir le montant payé",
        "placeholder": "Saisir le montant payé",
        "button": "Enregistrer le montant payé"
      },
      "sent_reminders": {
        "title": "Rappels envoyés",
        "placeholder": "Nombre de rappels envoyés",
        "button": "Enregistrer le numéro"
      }
    },
    "mail": {
      "registration": {
        "subject": "Merci de vous être enregistré auprès de %company%.",
        "subject_robethood": "Portail Robethood Creatives : inscription réussie.",
        "explanation": "Nous vous remercions de votre inscription. Veuillez confirmer votre adresse e-mail dans les 24 heures. Seule une adresse e-mail confirmée vous permettra de recevoir un nouveau mot de passe Internet ou des informations sur les modifications.",
        "explanation_first_name_form": "<p>Merci pour ton inscription. Merci de confirmer ton adresse e-mail dans les 24 heures.</p><p>Ce n'est qu'avec une adresse e-mail confirmée que tu pourras recevoir un nouveau mot de passe ou des informations sur les changements.</p>",
        "possible_actions": "Une fois votre adresse e-mail confirmée, vous aurez immédiatement accès à toutes les fonctions que nous avons préparées pour vous.",
        "possible_actions_first_name_form": "Une fois ton adresse e-mail confirmée, tu auras immédiatement accès à toutes les fonctions que nous avons préparées pour toi.",
        "call_to_action": "Confirmer"
      },
      "ordered_web_pdf": {
        "subject": "PDF compatible avec le Web pour la commande %orderId% de %company",
        "explanation": "Nous vous remercions pour votre commande. Vous pouvez ouvrir et télécharger le PDF compatible avec le web en cliquant sur le bouton ci-dessous.",
        "explanation_first_name_form": "Merci beaucoup pour ta commande. Tu peux ouvrir et télécharger le PDF compatible avec le web en cliquant sur le bouton ci-dessous.",
        "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
        "possible_actions_first_name_form": "Tu peux te connecter à tout moment au portail en ligne sous %domaine% et consulter ta commande.",
        "call_to_action": "Afficher la commande",
        "download": "Télécharger le PDF compatible avec le web"
      },
      "ordered_download_pdf": {
        "subject": "PDF de la commande %orderId% de %company",
        "explanation": "Nous vous remercions pour votre commande. Vous pouvez ouvrir et télécharger le PDF en cliquant sur le bouton ci-dessous.",
        "explanation_first_name_form": "Merci beaucoup pour ta commande. Tu peux ouvrir et télécharger le PDF en cliquant sur le bouton ci-dessous.",
        "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
        "possible_actions_first_name_form": "Tu peux te connecter à tout moment au portail en ligne sous %domaine% et consulter ta commande.",
        "call_to_action": "Afficher la commande",
        "download": "Télécharger le PDF"
      },
      "order_vat_data_check_done": {
        "subject": "Votre demande de vérification manuelle des données pour la commande %orderId% de %company%.",
        "explanation": "Nous vous remercions de votre commande et d'avoir demandé une vérification manuelle des données.",
        "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
        "call_to_action": "Afficher le statut"
      },
      "order_reduced_vat_authorized": {
        "subject": "Votre demande de taux de TVA réduit pour la commande %orderId% a été approuvée",
        "explanation": "Nous vous remercions pour votre commande. Nous avons validé la commande pour le taux de TVA réduit.",
        "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
        "call_to_action": "Afficher le statut"
      },
      "order_reduced_vat_rejected": {
        "subject": "Votre demande de taux de TVA réduit pour la commande %orderId% n'a pas été approuvée",
        "explanation": "Nous vous remercions pour votre commande. Nous avons vérifié votre commande et avons constaté qu'elle n'était pas éligible au taux de TVA réduit. C'est le taux de TVA normal qui s'applique.",
        "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
        "call_to_action": "Afficher le statut"
      },
      "chedri_registration": {
        "subject": "Votre inscription dans %company%.",
        "subject_fiendly": "Ton inscription à %company%.",
        "explanation": "<p>Salut %firstName% %lastName%,</p><p>Merci pour votre inscription.</p><p>Nous avons créé avec succès un compte test pour vous et votre entreprise.</p>",
        "possible_actions": "Une fois votre adresse e-mail confirmée, vous pouvez vous connecter en utilisant <strong>%username%</strong> comme nom d'utilisateur et le mot de passe choisi lors de l'inscription.",
        "call_to_action": "Confirmer"
      },
      "invitation": {
        "subject": "Invitation de %company%",
        "explanation": "nous sommes très heureux de vous inviter, au nom de %company%, à visiter notre portail de conception en ligne.",
        "login_data": "Si vous acceptez l'invitation, utilisez les données suivantes pour vous connecter : <strong>%username%</strong> comme votre nom d'utilisateur et <strong>%password%</strong> comme votre mot de passe. Veillez à changer votre mot de passe après vous être connecté.",
        "call_to_action": "Accepter l'invitation",
        "explanation_first_name_form": "nous sommes très heureux de t'inviter, au nom de %company%, à participer à notre portail de design en ligne.",
        "login_data_first_name_form": "Si tu acceptes l'invitation, utilise les données suivantes pour te connecter : <strong>%username%</strong> comme nom d'utilisateur et <strong>%password%</strong> comme mot de passe. Veille à changer ton mot de passe après t'être connecté."
      },
      "new_quote": {
        "subject": "Nouvelle offre de %company%.",
        "explanation": "%company% vous envoie une offre pour un service que vous avez demandé.",
        "possible_actions": "Vous avez maintenant la possibilité d'examiner l'offre et de l'accepter. Vous pouvez également refuser l'offre et laisser un message expliquant pourquoi.",
        "call_to_action": "Vérifier l'offre"
      },
      "new_invoice": {
        "subject": "Nouvelle facture de %company%.",
        "explanation": "%company% vous envoie une facture pour un service que vous avez demandé. Vous trouverez cette facture sur notre portail en ligne. Nous avons également joint la facture à ce message.",
        "explanation_first_name_form": "%company% t'envoie une facture pour un service que tu as demandé. Tu trouveras la facture sur notre portail en ligne. Nous avons également joint la facture à ce message.",
        "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter vos factures.",
        "possible_actions_first_name_form": "Tu peux te connecter à tout moment au portail en ligne sous %domaine% et consulter tes factures.",
        "call_to_action": "Afficher la facture"
      },
      "new_sales_commission": {
        "subject": "Nouvelle commission de %company%.",
        "explanation": "%company% vous envoie une commission pour un service que vous avez demandé. Vous trouverez la commission sur notre portail en ligne. Nous avons également joint la commission à ce message.",
        "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et afficher votre commission en ligne.",
        "call_to_action": "Afficher la commission"
      },
      "new_questionnaire": {
        "subject": "%company% souhaite que vous remplissiez un questionnaire",
        "explanation": "<p>Salut %firstName% %lastName%,</p><p>%company% vous envoie un nouveau questionnaire.</p>",
        "possible_actions": "Vous avez la possibilité de consulter et de remplir le questionnaire en ligne.",
        "call_to_action": "Voir le questionnaire"
      },
      "questionnaire_filled_out": {
        "subject": "Le questionnaire vient d'être rempli",
        "explanation": "<p>Salut %firstName% %lastName%,</p><p>Le questionnaire demandé par %targetFirstName% vient d'être rempli.</p>",
        "possible_actions": "Vous pouvez consulter les réponses dans la chronologie des leads.",
        "call_to_action": "Ouvrir un lead"
      },
      "contact_form_cms": {
        "subject": "Nouveau contact de %name%",
        "info": "Notification de la soumission d'un formulaire de contact. Veuillez le traiter immédiatement et vous assurer que le client bénéficie de la meilleure assistance possible."
      },
      "contact_form_edition": {
        "subject": "Nouveau contact",
        "info": "Notification de la soumission d'un formulaire de contact. Veuillez le traiter immédiatement et vous assurer que le client bénéficie de la meilleure assistance possible.",
        "form_fields": "Champs de formulaire remplis"
      },
      "password_renew": {
        "subject": "Renouveler le mot de passe",
        "explanation": "<p>Bonjour %email%,<br><br>Vous avez essayé de vous connecter à %platformName%. Votre mot de passe a expiré. Vous devez créer un nouveau mot de passe pour pouvoir vous connecter à l'avenir. Cliquez sur le lien ci-dessous pour saisir un nouveau mot de passe.",
        "possible_actions": "Vous avez la possibilité de définir un nouveau mot de passe. Il suffit de cliquer sur le bouton.",
        "call_to_action": "Définir un nouveau mot de passe",
        "explanation_first_name_form": "<p>Bonjour %email%,<br><br>Tu as essayé de te connecter à %platformName%. Ton mot de passe a expiré. Tu dois créer un nouveau mot de passe pour pouvoir te connecter à l'avenir. Clique sur le lien ci-dessous pour saisir un nouveau mot de passe.</p>",
        "possible_actions_first_name_form": "Tu as la possibilité de définir un nouveau mot de passe. Clique simplement sur le bouton."
      },
      "password_reset": {
        "subject": "Réinitialiser le mot de passe",
        "explanation": "<p>Bonjour %email%,</p><br /><p>Un nouveau mot de passe vient d'être demandé à %platformName% via notre plateforme. Si vous avez fait cette demande, cliquez sur le lien ci-dessous.</p>",
        "explanation_first_name_form": "<p>Bonjour %email%,</p><br /><p>Un nouveau mot de passe vient d'être demandé à %platformName% via notre plateforme. Si tu as fait cette demande, clique sur le lien ci-dessous.</p>",
        "possible_actions": "Vous avez la possibilité de définir un nouveau mot de passe. Il suffit de cliquer sur le bouton.",
        "possible_actions_first_name_form": "Tu as la possibilité de définir un nouveau mot de passe. Clique simplement sur le bouton.",
        "call_to_action": "Définir un nouveau mot de passe"
      },
      "message": {
        "explanation": "%company% vous a envoyé un message",
        "possible_actions": "Vous pouvez répondre à ce message en cliquant sur ce bouton :",
        "call_to_action": "Réponses"
      },
      "customerMessage": {
        "explanation": "Le client vous a envoyé un message"
      },
      "lead_comment_mention": {
        "subject": "Vous avez été mentionné dans le %title% Lead",
        "explanation": "%creator% vous a mentionné dans un commentaire.",
        "call_to_action": "Ouvrir un lead"
      },
      "lead_assignee_changed": {
        "subject_assigned": "Vous êtes maintenant assigné au lead '%title%'.",
        "subject_unassigned": "Vous n'êtes plus affecté au lead '%title%'.",
        "explanation_assigned": "Vous êtes maintenant affecté au lead '%title%'. L'agent précédent était %oldAssignee%.",
        "explanation_unassigned": "Ils ne sont plus affectés au lead '%title%'. Le nouvel agent est %newAssignee%.",
        "call_to_action": "Ouvrir un lead"
      },
      "bank_data_reminder": {
        "subject": "Nous avons besoin de vos coordonnées bancaires pour votre commande",
        "explanation": "Vous avez réservé des prestations pour lesquelles des données bancaires sont nécessaires.",
        "possible_actions": "Veuillez aller à la commande et saisir les données manquantes.",
        "call_to_action": "Saisir les données bancaires"
      },
      "order_approved": {
        "subject": "Votre commande auprès de %company% vient d'être approuvée",
        "explanation": "nous avons le plaisir de vous informer que votre commande %orderId% a été approuvée.",
        "possible_actions": "Il est possible que vous souhaitiez accéder à votre commande pour en afficher les détails.",
        "call_to_action": "Afficher la commande"
      },
      "sales_contract_uploaded": {
        "subject": "Le contrat de vente pour votre commande est maintenant disponible"
      },
      "order_cancelled": {
        "subject": "Votre commande chez %company% a été annulée",
        "explanation": "nous sommes désolés, mais nous avons dû annuler votre commande %orderId%.",
        "possible_actions": "Veuillez vous rendre sur votre commande pour en afficher les détails.",
        "call_to_action": "Afficher la commande"
      },
      "payment_instructions": {
        "subject": "Informations de paiement pour votre commande auprès de %company%.",
        "explanation": "Vous trouverez ci-dessous les informations de paiement pour votre commande %orderId% :",
        "possible_actions": "Veuillez vous rendre sur votre commande pour en afficher les détails.",
        "call_to_action": "Afficher la commande"
      },
      "pickup_invite": {
        "subject": "Votre commande est prête à être retirée",
        "explanation": "Votre commande avec le %orderId% est prête à être retirée.",
        "possible_actions": "Veuillez vous rendre sur votre commande pour en afficher les détails.",
        "call_to_action": "Afficher la commande"
      },
      "order_picked_up": {
        "subject": "Merci pour votre commande"
      },
      "request_plugin_activation": {
        "subject": "Activation du plugin demandée",
        "explanation": "<p>Salut %firstName% %lastName%,</p><p>%creatorFirstName% %creatorLastName% souhaite activer le plugin <strong>%pluginName%</strong> pour le projet %platformName%.</p>",
        "possible_actions": "Vous pouvez vous connecter et utiliser le formulaire d'activation en appuyant sur le bouton suivant.",
        "call_to_action": "Activer le plugin"
      },
      "plugin_activated": {
        "subject": "Plugin activé",
        "explanation": "<p>Salut %firstName% %lastName%,</p><p>Nous souhaitons vous informer que le %pluginName% a été activé pour le projet %platformName%.</p>",
        "call_to_action": "Regardez-le en action"
      },
      "lead_data": {
        "subject": "Nouveau lead : %title%",
        "call_to_action": "Afficher le lead"
      },
      "order": {
        "item_sales": {
          "title": "Votre commande",
          "explanation": "Vous trouverez ci-dessous une liste des données relatives à votre commande.",
          "subject": "Votre commande auprès de %company%.",
          "possible_actions": "Vous trouverez également toutes les informations relatives à votre commande dans votre compte client.",
          "call_to_action": "Voir dans le compte client"
        }
      },
      "rent_sync_vamoso_error": {
        "subject": "Commande #%orderId% : Erreur dans le plugin Rent Sync pour Vamoso : %errorType%.",
        "explanation": "Malheureusement, notre plugin n'a pas pu effectuer de tâche dans votre système Vamoso. Veuillez vérifier manuellement le statut de l'écriture. <br/>Type d'erreur : %errorType% <br/>Message d'erreur : %errorText% <br/>Message d'erreur : %errorText"
      },
      "lead_deadline_reminder": {
        "subject": "Ils sont associés à des prospects qui doivent être livrés demain.",
        "explanation": "Vous trouverez ici la liste des leads qui vous ont été attribués. Ces leads sont encore ouverts et arrivent à échéance demain.",
        "explanation_first_name_form": "Tu trouveras ici une liste des leads qui t'ont été attribués. Ces leads sont encore ouverts et arrivent à échéance demain."
      },
      "chat_history": {
        "subject": "Ton historique de chat",
        "explanation": "Merci de nous avoir contactés. Vous trouverez en pièce jointe l'historique de votre chat avec %firstName% %lastName%. J'espère que nous avons pu vous aider avec toutes vos questions ou problèmes. N'hésitez pas à nous contacter à nouveau si vous avez d'autres questions.",
        "greatingchat_history": "Historique de chat",
        "greating": "Merci d'avoir utilisé le chat de %platformName%. Votre partenaire de chat était %firstName% %lastName% Ci-dessous, vous pouvez voir l'historique de chat de %date%.",
        "adaption": "J'espère que nous avons pu répondre à toutes vos questions ou vous aider à résoudre vos problèmes. N'hésitez pas à nous contacter si vous avez d'autres questions.",
        "privacy_policy": "Vous trouverez notre déclaration de protection des données sous <a href=\"%link%\" target=\"_blank\">'%link%</a>.",
        "call_to_action": "Revenez nous voir"
      },
      "lead_pool_notification": {
        "subject": "Nouveau lead dans le pool : %title%",
        "explanation": "Un nouveau lead est disponible dans le système. Aucun collaborateur n'a encore été attribué. Clique sur le bouton ci-dessous pour afficher les détails.",
        "call_to_action": "Ouvrir un lead"
      },
      "lead_escalation_level1": {
        "subject": "[NIVEAU1] %nom%",
        "explanation": "Vous recevez cet e-mail parce qu'un ou plusieurs prospects ont atteint le premier niveau d'escalade défini dans la règle %nom%. Veuillez vérifier les prospects pour déterminer ce qu'il convient de faire."
      },
      "lead_escalation_level2": {
        "subject": "[NIVEAU2] %nom%",
        "explanation": "Vous recevez cet e-mail parce qu'un ou plusieurs leads ont atteint le deuxième niveau d'escalade défini dans la règle %nom%. Veuillez vérifier les leads et essayer de motiver vos équipes à faire plus d'efforts."
      },
      "campaign_budget_auth_request": {
        "subject": "Avertissement : budget de la campagne dépassé.",
        "explanation": "le budget de la campagne a été dépassé",
        "call_to_action": "Afficher la commande"
      },
      "campaign_budget_auth_request_unsuccessful": {
        "subject": "Avertissement : problème d'autorisation du budget de la campagne.",
        "explanation": "un problème d'autorisation du budget de la campagne a été détecté.",
        "call_to_action": "Afficher la commande"
      },
      "user_budget_auth_request": {
        "subject": "Avertissement : budget utilisateur dépassé.",
        "explanation": "le budget des utilisateurs a été dépassé",
        "call_to_action": "Afficher la commande"
      },
      "user_budget_auth_request_unsuccessful": {
        "subject": "Avertissement : problème d'autorisation du budget de l'utilisateur",
        "explanation": "un problème a été détecté dans l'autorisation du budget des utilisateurs.",
        "call_to_action": "Afficher la commande"
      },
      "order_auth_changes_requested": {
        "subject": "Info : modifications demandées",
        "call_to_action": "Afficher la commande"
      },
      "order_auth_changes_implemented": {
        "subject": "Info : des modifications ont été apportées",
        "call_to_action": "Afficher la commande"
      },
      "manage_notification": "Gérer les notifications par e-mail",
      "unsubscribe": "Se désabonner",
      "confidentiality_notice": "Avertissement de confidentialité : cet e-mail est destiné exclusivement au destinataire indiqué et pourrait contenir des informations confidentielles et/ou protégées par la loi. Si vous n'êtes pas le bon destinataire ou si vous avez reçu cet e-mail par erreur, veuillez en informer immédiatement l'expéditeur et détruire cet e-mail. La copie non autorisée ainsi que la transmission non autorisée de cet e-mail sont interdites.",
      "no_legal_binding_notice": "Ce message sert uniquement à l'échange d'informations et n'a aucun effet juridique contraignant. En raison de la facilité de manipulation des e-mails, nous ne pouvons assumer aucune responsabilité quant à leur contenu.",
      "invoice_due_reminder": {
        "subject": "Rappel de paiement",
        "explanation": "Malheureusement, nous n'avons pas encore pu constater la réception du paiement de la facture suivante.",
        "explanation_first_name_form": "Malheureusement, nous n'avons pas encore pu constater la réception du paiement de la facture suivante.",
        "request_payment": "Afin d'éviter des frais supplémentaires, nous vous prions de bien vouloir payer le montant total dans les plus brefs délais. Veuillez utiliser les coordonnées bancaires suivantes pour le paiement de cette facture :",
        "request_payment_first_name_form": "Afin d'éviter des frais supplémentaires, nous vous prions de bien vouloir payer le montant total dans les plus brefs délais. Veuillez utiliser les coordonnées bancaires suivantes pour le paiement de cette facture :",
        "contact": "Si vous avez des questions concernant ce rappel, veuillez nous contacter par e-mail %email% ou par téléphone à la hotline %phone%.",
        "contact_first_name_form": "Si tu as des questions sur ce rappel, contacte-nous à l'adresse e-mail %email% ou par téléphone à la hotline %phone%.",
        "bank_account": "Coordonnées bancaires",
        "bank_name": "Nom de la banque",
        "account_holder": "Titulaire du compte",
        "iban": "IBAN",
        "swift_bic": "SWIFT/BIC",
        "bank_code_number": "Code bancaire",
        "account_number": "Numéro de compte",
        "invoice_number": "N° de facture :"
      },
      "last_seen_reminder": {
        "subject": "Dernièrement vu Rappel",
        "user_not_seen": "L'utilisateur '%clientUsername%' n'a pas été vu depuis %daysNotSeen% jours.",
        "contact_user": "Veuillez contacter ce dernier afin de vous assurer que tout est en ordre.",
        "contact_user_first_name_form": "Veuillez contacter ce dernier afin de vous assurer que tout est en ordre.",
        "customer_profil": "Profil de client"
      },
      "order_foreign_status": {
        "do_contact_support": {
          "subject": "Commande - Veuillez contacter le support client",
          "explanation": "Nous vous remercions pour votre commande. Nous avons une question concernant votre commande. Veuillez contacter notre support client.",
          "explanation_first_name_form": "merci beaucoup pour ta commande. Nous avons une question concernant ta commande. Veuillez contacter notre support client.",
          "possible_actions": "-",
          "contact": "Contact",
          "phone": "Téléphone :",
          "email": "Courrier électronique :",
          "call_to_action": "-"
        },
        "in_delivery": {
          "subject": "La commande est en cours d'envoi",
          "explanation": "votre commande a été produite et remise au coursier pour livraison.",
          "explanation_first_name_form": "ta commande a été produite et remise au coursier pour livraison.",
          "possible_actions_tracking": "Vous pouvez suivre la livraison sur le site web du service de messagerie.",
          "possible_actions_tracking_first_name_form": "Tu peux suivre la livraison sur le site web du service de messagerie.",
          "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
          "possible_actions_first_name_form": "Tu peux te connecter à tout moment au portail en ligne sous %domaine% et consulter ta commande.",
          "call_to_action": "Détails de la commande",
          "tracking_numbers": "Numéros de suivi"
        },
        "in_production": {
          "subject": "La commande est en cours de production",
          "explanation": "Nous vous remercions pour votre commande. Nous avons passé votre commande. Celle-ci est en cours de production.",
          "explanation_first_name_form": "Merci beaucoup pour ta commande. Nous avons passé ta commande. Celle-ci est en cours de production.",
          "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
          "possible_actions_first_name_form": "Tu peux te connecter à tout moment au portail en ligne sous %domaine% et consulter ta commande.",
          "call_to_action": "Détails de la commande"
        },
        "invalid_print_data": {
          "subject": "Commande - données d'impression non valables",
          "explanation": "Des données d'impression non valables ont été trouvées lors de la commande.",
          "call_to_action": "Détails de la commande"
        },
        "missing_print_data": {
          "subject": "Commande - données d'impression manquantes",
          "explanation": "Lors de la commande, des données d'impression manquantes ont été constatées.",
          "call_to_action": "Détails de la commande"
        },
        "order_canceled": {
          "subject": "La commande a été annulée",
          "explanation": "votre commande a été annulée avec succès. Nous sommes désolés que vous ayez dû annuler votre commande et nous espérons pouvoir vous aider à nouveau à l'avenir.",
          "explanation_first_name_form": "ta commande a été annulée avec succès. Nous sommes désolés que tu aies dû annuler ta commande et nous espérons pouvoir te servir à nouveau à l'avenir.",
          "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
          "possible_actions_first_name_form": "Tu peux te connecter à tout moment au portail en ligne sous %domaine% et consulter ta commande.",
          "call_to_action": "Détails de la commande"
        },
        "payment_pending": {
          "subject": "Commande - paiement en attente",
          "explanation": "nous n'avons pas encore pu constater la réception du paiement de votre commande.",
          "explanation_first_name_form": "nous n'avons pas encore pu constater la réception du paiement de ta commande.",
          "possible_actions": "Vous pouvez vous connecter à tout moment au portail en ligne sous %domaine% et consulter votre commande.",
          "possible_actions_first_name_form": "Tu peux te connecter à tout moment au portail en ligne sous %domaine% et consulter ta commande.",
          "call_to_action": "Détails de la commande"
        }
      },
      "contact_form_cms_confirm": {
        "subject": "Veuillez confirmer votre e-mail",
        "explanation": "<p>Votre e-mail a été saisi dans le formulaire de contact de notre site Web.</p><p>Si vous avez rempli notre formulaire de contact, veuillez cliquer sur le bouton Confirmer.</p><p>Sinon, ignorez simplement ce message.</p>",
        "call_to_action": "Confirmer"
      },
      "contact_form_edition_confirm": {
        "subject": "Veuillez confirmer votre e-mail",
        "explanation": "<p>Votre adresse e-mail a été saisie dans le formulaire de contact de notre site Web.</p><p>Si vous avez rempli notre formulaire de contact, veuillez cliquer sur le bouton Confirmer.</p><p>Sinon, ignorez simplement ce message.</p>",
        "call_to_action": "Confirmer"
      },
      "order_content_check_status": {
        "subject": "la vérification du contenu de la commande %orderId% a été %status%.",
        "explanation": "merci pour votre commande. Nous avons %status% la commande après la vérification du contenu .",
        "possible_actions": "Vous pouvez accéder à votre commande pour en consulter les détails.",
        "call_to_action": "Voir la commande",
        "reason": "Raison"
      },
      "lead_follow_up_reminder": {
        "subject": "Veuillez vérifier les tâches attribuées",
        "explanation": "vous trouverez ici une liste des tâches qui vous ont été attribuées. Ces tâches sont encore ouvertes.",
        "explanation_first_name_form": "tu trouveras ici une liste des tâches qui t'ont été attribuées. Ces tâches sont encore ouvertes."
      },
      "client_domain_authorization_code": {
        "subject": "Code d'autorisation de domaine",
        "explanation": "Après la résiliation de l'abonnement de %itemName% pour le domaine %clientDomain%, nous vous fournirons un code d'autorisation qui vous permettra de transférer votre domaine vers un autre registraire.",
        "explanation_authorization_code": "Le code d'autorisation est %authorizationCode%."
      }
    },
    "contact_form_cms_confirm": {
      "success": {
        "heading": "Votre demande a été envoyée",
        "body": "<p>Merci de nous avoir contactés!</p><p>Nous avons bien reçu votre demande et nous vous contacterons dès que possible.</p>"
      },
      "already_confirmed": {
        "heading": "Cette demande a déjà été confirmée",
        "body": "<p>Nous avons bien reçu votre demande et nous vous contacterons dans les plus brefs délais.</p>"
      },
      "not_found": {
        "heading": "Pas trouvé",
        "body": "<p>Veuillez vous assurer que vous avez cliqué sur le bouton dans notre e-mail pour accéder à cette page.</p>"
      }
    },
    "contact_form_edition_confirm": {
      "success": {
        "heading": "Votre demande a été envoyée",
        "body": "<p>Merci de nous avoir contactés!</p><p>Nous avons bien reçu votre demande et nous vous contacterons dès que possible.</p>"
      },
      "already_sent": {
        "heading": "Cette demande a déjà été confirmée",
        "body": "<p>Nous avons bien reçu votre demande et nous vous contacterons dans les plus brefs délais.</p>"
      },
      "not_found": {
        "heading": "Pas trouvé",
        "body": "<p>Veuillez vous assurer que vous avez cliqué sur le bouton dans notre e-mail pour accéder à cette page.</p>"
      },
      "expired": {
        "heading": "Code expiré",
        "body": "<p>Le lien de confirmation n'était valable que pendant 2 jours. Veuillez remplir à nouveau le formulaire de contact.</p>"
      }
    },
    "formGroup": {
      "basic_information": "Informations de base",
      "contact_person": "Personne de contact",
      "address_information": "Adresse",
      "address_settings": "Réglages de l'adresse",
      "bank_account": "Coordonnées bancaires",
      "company_information": "Informations sur l'entreprise",
      "user_roles": "Rôles des utilisateurs",
      "subscription_roles": "Abonnement utilisateur",
      "opening_hours": "Heures d'ouverture",
      "position_on_map": "Position sur la carte",
      "white_label_parent": "Précurseur",
      "user_teams": "Équipes d'utilisateurs",
      "contact_data": "Coordonnées",
      "permissions": "Autorisations",
      "person_information": "Informations sur l'utilisateur"
    },
    "hints": {
      "small_hint_for_you": "Un petit conseil pour toi",
      "task_is_automated": "Cette tâche est automatisée. Vous devez attendre que la tâche d'automatisation soit en cours. Ensuite, nous la marquerons comme terminée pour vous.",
      "please_complete_previous_tasks": "Vous ne pouvez pas modifier cette tâche maintenant. Veuillez d'abord vous occuper des tâches précédentes.",
      "companyLogoNew": "<p>Vous n'avez pas encore téléchargé votre logo. Pour en charger un, il suffit de glisser-déposer une image (jpeg ou png) sur cette page.</p>",
      "companyLogoChange": "<p>Il s'agit du logo que vous avez téléchargé. Pour le modifier, il suffit de glisser-déposer une image (jpeg ou png) sur cette page.</p>)",
      "companyFinancial": "<div><p>Veuillez remplir les informations nécessaires à la facturation</p><p>Les données bancaires que vous remplissez ici seront affichées en pied de page sur vos offres et factures</p></div>.",
      "companyPaymentInfo": "<p>Ces informations s'affichent sur chaque facture que vous créez.</p>"
    },
    "vendors": {
      "association": "Association avec les données du client",
      "vendor_api": "Nom du service",
      "pricing": "Détermination du prix",
      "operating_mode": "Mode de fonctionnement",
      "price_update_method": "Méthode de mise à jour des prix",
      "order_processing": "Traitement des commandes",
      "order_method": "Méthode de commande",
      "order_email": "Adresse e-mail pour les commandes",
      "phone_number": "Numéro de téléphone",
      "web_shop_url": "URL de la boutique en ligne",
      "trial": "trial",
      "live": "en direct",
      "prices_by_vendor": "Les prix sont gérés par le fournisseur",
      "prices_by_employee": "Les prix sont vérifiés et gérés par le collaborateur",
      "prices_by_api": "Les prix sont mis à jour via une API",
      "notified_by_email": "Est averti par e-mail des commandes entrantes",
      "accepts_only_email": "Accepte uniquement les commandes par e-mail",
      "use_webshop": "Commandes via la boutique en ligne",
      "order_by_api": "Commandes par API",
      "order_by_api_and_webshop": "Commandes par API ou boutique en ligne"
    },
    "client_account": "Compte client",
    "deleted_campaigns": "Campagnes supprimées",
    "deleted_editions": "Éditions supprimées",
    "deleted_folders": "Dossiers supprimés",
    "deleted_media": "Médias supprimés",
    "root": "Répertoire racine",
    "trash_bin": "Corbeille à papier",
    "delete_trash_bin": "Vider la corbeille",
    "pls_wait": "Veuillez patienter. L'action souhaitée est exécutée.",
    "go_back": "Retour",
    "restore": "Restaurer",
    "archived_campaigns": "Campagnes archivées",
    "load_all_campaigns": "Charger toutes les campagnes",
    "load_creacheck_campaigns": "Charger les campagnes Creacheck",
    "load_flyeralarm_campaigns": "Charger les campagnes Flyeralarm",
    "load_designgenie_campaigns": "Charger les campagnes DesignGenie",
    "archived_editions": "Éditions archivées",
    "sorting_name": "Nom",
    "sorting_date_ASC": "Les plus âgés d'abord",
    "sorting_date_DESC": "Les plus récents en premier",
    "sorting_owner": "Société",
    "sorting_creator": "Créé par",
    "campaign_title": "Campagne",
    "dataproof": "Vérification des données",
    "not_ordered": "non commandé",
    "supplier_order": "Commande de fournisseurs",
    "change_of_address": "Changement d'adresse",
    "not_available": "non disponible",
    "selected_shipping_method": "Mode d'expédition sélectionné",
    "ordered_items": "Articles commandés",
    "standard": "Standard",
    "express": "Express",
    "aws_iframe_hosting": "Hébergement AWS Iframe",
    "aws_hosting_html_file_for_iframe_downloaded": "AWS Hosting Fichier HTML pour iframe avec titre : %title%",
    "lead_stage_change_confirm_title": "Modifier le statut d'un lead",
    "lead_stage_change_confirm_body": "Voulez-vous vraiment changer le statut du lead ?",
    "iframe_implementation_file": "Fichier d'implémentation Iframe",
    "iframe_implementation_file_download_failed": "Le fichier d'implémentation Iframe n'a pas pu être téléchargé : %error%",
    "lead_stage_change_error_domain_hosting_in_progress": "La ligne ne peut pas être modifiée si la phase actuelle est en cours ou si l'autorisation n'est pas approuvée.",
    "lead_stage_change_error_domain_hosting_command_to_start_pipeline_has_been_sent": "La ligne ne peut pas être modifiée si la phase actuelle est en cours ou si l'autorisation n'est pas approuvée.",
    "allowed_domain_already_exists": "Le domaine %domain% est déjà inclus dans la liste des domaines autorisés.",
    "allowed_domain_domain_name_should_not_be_empty": "Le nom de domaine ne doit pas être vide.",
    "allowed_domains_title": "Domaines autorisés",
    "allowed_domains_description": "Ici, vous pouvez ajouter des domaines qui peuvent être utilisés pour l'implémentation d'Iframes.",
    "allowed_domains_add": "Ajouter un domaine",
    "allowed_domains_domain_name": "Nom de domaine",
    "allowed_domains_default_src": "Scr par défaut",
    "allowed_domains_script_src": "Script-Src",
    "allowed_domains_connect_src": "Connect-Src",
    "allowed_domains_style_src": "Style-Src",
    "allowed_domains_img_src": "Img-Src",
    "allowed_domains_font_src": "Font-Src",
    "allowed_domains_frame_src": "Frame-Src",
    "created_at": "Créé le",
    "created_by": "Créé par",
    "overnight": "Overnight",
    "create_printData": "Créer des données d'impression",
    "created_new_printData": "De nouvelles données d'impression ont été créées",
    "uploaded_printData_successful": "Les données d'impression ont été téléchargées avec succès",
    "no_printData": "Pas de données d'impression",
    "no_images": "Aucun fichier image disponible",
    "not_the_original_printFile": "Remarque : il ne s'agit pas du fichier d'impression original.",
    "printDataFiles": "Fichier(s) de données d'impression",
    "image_files": "Fichiers d'images",
    "customer_comment": "Commentaire du client",
    "actions_": " Actions",
    "import_success": "Votre importation a été effectuée",
    "import_rejected": "Votre importation a été annulée",
    "import_exec": "Votre importation est en cours",
    "import_successRE": "Votre importation a été réinitialisée",
    "import_rejectedRE": "Le processus a été interrompu",
    "import_execRE": "Votre importation est réinitialisée",
    "choice_csv": "Veuillez sélectionner un fichier CSV",
    "csv_content": "Contenu du CSV",
    "warning_and_notes": "Avertissements et conseils",
    "start_remove": "Démarrer la suppression",
    "reverse_remove": "Annuler la suppression",
    "user_import_success": "Importation réussie",
    "user_duplicate_import": "Double entrée trouvée",
    "start_import": "Démarrer l'importation",
    "reverse_import": "Annuler l'importation",
    "upload_local_changes": "Télécharger les modifications locales",
    "dataproof_master_copies_upload": "Ici, il est possible de télécharger le(s) modèle(s)/fichier(s) de données de pression retravaillé(s) localement en cliquant sur [Upload].",
    "edit_edition": "Modifier le matériel publicitaire",
    "dataproof_master_copies_recreate": "Ici, il est possible de lancer une nouvelle création du/des modèle(s)/fichier(s) de données d'impression en se basant sur l'original avec [Créer données d'impression] ou de retravailler le/les modèle(s)/fichier(s) de données d'impression localement avec [Editer].",
    "hint": "Remarque",
    "dataproof_hint_master_copies_upload": "Pour que la modification soit effective une fois le post-traitement terminé, le(s) modèle(s)/fichier(s) de données d'impression modifié(s) localement doit/doivent être téléchargé(s) avec [Upload].",
    "edit_": "Modifier",
    "send_invoice": "Envoyer une facture",
    "sended_invoice": "La facture a été envoyée à",
    "send_email_tracking": "Envoi d'e-mails Suivi des envois",
    "sended_email": "L'e-mail a été envoyé !",
    "tracking": "Suivi des envois",
    "send_tracking_to_customer": "Envoyer le suivi des envois aux clients",
    "to_the_product": "Vers le produit",
    "order_sended": "La commande a été envoyée !",
    "failure_when_ordering": "Une erreur est survenue lors de la commande !",
    "please_create_orderhistory_email": "La commande a été envoyée par e-mail ! Veuillez créer un historique des commandes !",
    "new_status_set": "Le nouveau statut a été activé !",
    "change_of_status": "Changement de statut",
    "statusQuery": "Demande de statut",
    "totalStatus": "État général :",
    "printData": "Données d'impression",
    "current_printdata_sended": "Les données d'impression actuelles ont été envoyées !",
    "orderHistory": "Historique des commandes",
    "orderHistory_created": "L'historique des commandes a été créé !",
    "order_created": "La commande a été créée avec succès !",
    "enter_trackingLink": "Saisir le lien de suivi",
    "query_orderStatus": "Consulter l'état de la commande",
    "foreignId": "N° de commande tiers",
    "orderStatus": "Statut de la commande",
    "orderDate": "Date de commande",
    "options_": "Options",
    "type_": "Art",
    "comment_": "Commentaire",
    "orderOptions": "Possibilités de commande",
    "ordering_by_webshop": "Commande via la boutique en ligne",
    "ordering_by_email": "Commande par e-mail",
    "info_to_the_emailOrder": "Informations complémentaires sur la commande par e-mail",
    "add_or_edit_foreignId": "Ajouter ou éditer un numéro de commande tiers",
    "create_trackingLink": "Créer un lien pour le suivi des envois",
    "campaign": {
      "copy_edition": "Dupliquer",
      "created_by": "Créé par :",
      "no_edition": "Vous n'avez pas encore édité de support publicitaire !",
      "configuration": "Options générales",
      "select_all": "Tous les produits",
      "budget": "Budget de la campagne",
      "access": "Droits d'accès",
      "inactive": "Bloquer la campagne",
      "delete": "Supprimer la campagne",
      "transfer_title": "Transférer la campagne à un autre utilisateur",
      "campaign_owner": "Propriétaire de la campagne",
      "campaign_creator": "Créateur de la campagne",
      "change_owner": "Modifier le propriétaire de la campagne",
      "change_creator": "Modifier le créateur de la campagne",
      "order_all": "Commander la campagne complète",
      "share": "Partager la campagne",
      "archive": "Archiver la campagne",
      "recent_editions": "Dernièrement édité",
      "marked_editions": "Supports publicitaires marqués",
      "marked_campaigns": "Campagnes marquées",
      "own_campaigns": "Propres campagnes",
      "shared_editions": "Supports publicitaires partagés",
      "last_edited": "Édité le",
      "generated": "Créé le",
      "edition_edit": "Lancer l'éditeur",
      "edition_item": "Produit choisi",
      "submission_item": "Dépôt sélectionné",
      "edition_configuration": "2. réglages",
      "edition_order": "Ajouter au panier",
      "edition_reorder": "Réassort",
      "ordered": "Commandé !",
      "edition_delete": "Supprimer",
      "edition_preview": "Aperçu",
      "edition_edit_alt": "Modifier",
      "edition_error_empty": "Vous avez %s pages non traitées",
      "submissions": "Modèles",
      "new": "Créer une nouvelle campagne",
      "back_to_list": "Toutes les campagnes",
      "new_edition": "Créer un nouveau support publicitaire",
      "settings": "Gérer la campagne",
      "campaign_archived": "Votre campagne a été archivée",
      "successfully_archived": "Archivage réussi",
      "save_settings": "Enregistrer",
      "cancel_settings": "Annuler",
      "title": "Nom de la campagne",
      "edition_title": "Désignation",
      "publisher": "Indication de l'éditeur",
      "creator": "Campagne créée par :",
      "editions": "Matériel publicitaire",
      "edition": "Matériel publicitaire",
      "sidebar": "Campagnes",
      "mark_edition": "Marquer les supports publicitaires",
      "unmark_edition": "Supprimer la marque",
      "campaign_title_valid": "Veuillez donner un nom à votre campagne.",
      "campaign_publisher_valid": "Veuillez inscrire vos données d'auteur.",
      "choose_company_to_load_campaign": "Pour charger les campagnes, veuillez sélectionner une entreprise ou cliquer sur le bouton 'Charger toutes les campagnes'.",
      "no_product_selected": "Aucun produit sélectionné",
      "new_edition_title": "Nouvelle édition",
      "added_to_cart": "Votre campagne a été ajoutée au panier !",
      "edition_add_to_cart_title": "Produit dans le panier",
      "edition_add_to_cart_button_label": "Prima",
      "edition_add_to_cart_label": "Aller au panier",
      "edition_added_to_cart_message": " a été ajouté à votre panier.",
      "edit_disabled": "Le traitement de l'édition a été brièvement désactivé en raison d'une mise à jour du modèle.",
      "export_image": "Exporter l'image",
      "unknown": "inconnu",
      "already_ordered": "Cette édition a déjà été commandée et ne peut plus être modifiée. Vous pouvez toutefois dupliquer l'édition et la modifier. Voir le menu",
      "modal_tabs": {
        "default_edition": "Supports publicitaires généraux",
        "edition_from_submission": "Supports publicitaires à partir de modèles"
      }
    },
    "uom": {
      "piece": "pièce",
      "gram": "grammes",
      "kilogram": "Kilogramme",
      "running_meter": "Mètre linéaire",
      "liter": "Litres",
      "package": "Paquet",
      "kilometer": "Kilomètres"
    },
    "uom_symbole": {
      "gram": "g",
      "kilogram": "kg",
      "running_meter": "mètre linéaire",
      "liter": "l",
      "package": "pts",
      "kilometer": "km"
    },
    "pipelineViews": {
      "self": "M'attribuer",
      "unassigned": "Sans affectation",
      "pastdeadline": "Date limite dépassée",
      "duetoday": "Échéance aujourd'hui"
    },
    "leadPriority": {
      "critical": "Critique",
      "high": "Haute",
      "normal": "Normal",
      "low": "Faible"
    },
    "orderTypes": {
      "item_sales": "Vente",
      "car_rent": "Location de voiture",
      "car_sales": "Vente de voitures",
      "car_service": "Rendez-vous à l'atelier"
    },
    "genderOptions": {
      "male": "Mâle",
      "female": "Femme"
    },
    "salutationOptions": {
      "mr": "Monsieur",
      "mrs": "Mme"
    },
    "team_service": "Équipe de service",
    "team_dispo": "Équipe de planification",
    "team_rent": "Équipe de location",
    "team_sales": "Équipe de vente",
    "car_documents": "Documents du véhicule",
    "nav_module_chip": "Module / puce Navi",
    "safety_vest": "Gilet de sécurité",
    "parking_disk": "Disque de stationnement",
    "ice_scraper": "Gratte-glace",
    "key_supplement_heating_taxis": "Supplément de clé (chauffage auxiliaire/plaque de taxi)",
    "shelf_blind": "Tablette arrière / store",
    "onboard_folder": "Dossier de bord",
    "first_aid_kit": "Trousse de secours",
    "warning_triangle": "Triangle de signalisation",
    "tirefit_spare_wheel": "Tirefit / Roue de secours",
    "handover_damage": {
      "front": "Avant",
      "back": "Arrière",
      "left": "Liens",
      "right": "Droite",
      "top": "En haut",
      "interior": "Intérieur"
    },
    "dark": "Il fait nuit",
    "garage": "Dans un garage",
    "snow_ice": "Neige / glace",
    "rain_wet": "Il pleut / la voiture est mouillée",
    "countries": {
      "de": "Allemagne",
      "pl": "Pologne",
      "fr": "France"
    },
    "cookie_description": {
      "PHPSESSID": "Identifie votre session de navigation actuelle",
      "tempTarget": "Nous utilisons le cookie cible temporaire pour identifier les produits que vous recherchez et vos intérêts.",
      "chedriCookiesBoxClosed": "Nous utilisons ce cookie pour indiquer que vous avez vu et accepté nos informations sur les cookies.",
      "chedriCookiesPerformance": "Utilisé pour déterminer si vous acceptez nos cookies de performance.",
      "chedriCookiesTargeting": "Utilisé pour déterminer si vous acceptez nos cookies de ciblage.",
      "ga": "Utilisé pour distinguer les utilisateurs dans le service Google Analytics",
      "gid": "Utilisé pour distinguer les utilisateurs dans le service Google Analytics",
      "act": "Il s'agit d'un cookie Facebook utilisé pour l'analyse et la recherche.",
      "c_user": "Connexion Facebook pour les inscriptions des utilisateurs, qui permet de diffuser des publicités plus pertinentes pour les utilisateurs et leurs intérêts.",
      "datr": "Utilisé par Facebook pour identifier le navigateur de l'utilisateur",
      "fr": "Contient un navigateur unique et un ID utilisateur utilisé pour la publicité ciblée.",
      "sb": "Utilisé par Facebook pour améliorer les suggestions des amis",
      "xs": "Un cookie Facebook utilisé pour gérer une session. Il fonctionne en combinaison avec le cookie c_user pour authentifier votre identité sur Facebook."
    },
    "cookie_ttl": {
      "6_months": "6 mois",
      "60_days": "60 jours",
      "2_years": "2 ans",
      "1_day": "1 jour"
    },
    "upload_and_manage_media_files": "Ici, vous pouvez télécharger et gérer vos médias.",
    "profile_information_avatar_contact_address": "Ici, vous pouvez modifier les informations de votre profil. Avatar, coordonnées, adresse pour la facturation, informations financières.",
    "define_sales_points_address_position_map_opening_hours": "Définissez vos points de vente : Adresse, position sur la carte, heures d'ouverture. Important pour la gestion des produits et des collaborateurs.",
    "check_incoming_and_outgoing_invoices_check_details_and_download_as_pdf": "Vous pouvez vérifier les factures entrantes et sortantes qui ont été générées par le système ou par d'autres utilisateurs. Afficher les détails et télécharger au format PDF.",
    "found_api_interesting_personal_api_key_here": "Vous trouvez notre API intéressante ? Vous voulez l'essayer ? Vous trouverez ici votre clé API personnelle.",
    "need_our_data_in_own_system_configure_data_push": "Vous avez besoin de nos données dans votre propre système ? Ici, vous pouvez configurer des services de push de données pour vous abonner à des événements de données.",
    "projects_running_on_different_domains": "Projets exécutés sur différents domaines",
    "administration_of_your_website_logo_menu_pages": "Gérez votre site web. Logo, menu principal, pages système",
    "create_users_manage_profile_financial_roles_teams": "Créez des utilisateurs qui travaillent dans votre entreprise, gérez leurs informations de profil et financières, vérifiez et définissez les rôles de sécurité et attribuez des équipes.",
    "provision_service": "Services réservés",
    "provision_service_description": "Ici, vous pouvez créer, modifier ou supprimer de nouveaux types de services.",
    "provision_service_title": "Titre",
    "provision_service_type": "Nom",
    "provision_service_description_text": "Description",
    "provision_service_primary_rate": "Commission en pourcentage pour prim. Propriétaire",
    "provision_service_secondary_rate": "Commission en pourcentage pour le propriétaire secondaire",
    "provision_service_type_status": "Statut",
    "add_provision_type": "Ajouter un type de commission",
    "edit_provision_type": "Modifier",
    "create_provision_type": "Créer un type de commission",
    "service_type_onboarding": "Onboarding (installation)",
    "service_type_subscriptions": "Abonnements (MRR, abonnements & frais d'utilisation)",
    "service_type_additional_services": "Services supplémentaires (templates)",
    "service_type_product_sales": "Vente de produits",
    "sales": "Distribution",
    "primary_owner": "Propriétaire primaire",
    "secondary_owner": "Propriétaire secondaire",
    "provision_start": "Lancement",
    "provision_ending": "Fin",
    "provision_actions": "Actions",
    "add_provision_service": "Créer une commission",
    "edit_provision_service": "Traiter la commission",
    "save_provision_type": "Enregistrer",
    "prov_service_client_name": "Nom",
    "provision_service_documents": "Commissions",
    "commission_service_documents": "Commissions - Documents",
    "assign_service_type": "Affecter un nouveau type de service",
    "provision_service_documents_description": "Vous pouvez consulter ici toutes les commissions",
    "provision_service_documents_download": "Télécharger des documents",
    "provision_service_documents_download_start": "Téléchargement",
    "provision_service_documents_name": "Nom",
    "provision_service_documents_title": "Titre",
    "provision_service_documents_description_text": "Description",
    "provision_service_documents_serviceType": "Type",
    "provision_service_documents_provisionRate": "Taux de commission",
    "provision_service_documents_provisionValue": "Valeur de la commission",
    "provision_service_documents_provisionAssignee": "Récepteur",
    "provision_service_documents_provisionPayedDate": "payé le",
    "provision_service_documents_IsActive": "Statut",
    "provision_service_documents_Client": "Client",
    "provision_service_documents_choose_month": "Veuillez sélectionner le mois souhaité",
    "commission_service_documents_description": "Vous pouvez consulter et télécharger toutes les factures de commissions et les détails ici",
    "commission_service_documents_download": "Télécharger des documents",
    "commission_service_documents_download_start": "Téléchargement",
    "commission_client": "Client",
    "commission_invoice_no": "Numéro de facture",
    "commission_payed_data": "payé le",
    "commission_service_type": "Type de service",
    "commission_amount": "Montant",
    "commission_provision": "Commission",
    "create_update_teams_assign_system_types": "Créez, modifiez les équipes qui se trouvent dans votre entreprise. Attribuez des types de systèmes à vos équipes.",
    "create_document_types_use_for_process_automation": "Créez vos propres types de documents afin de pouvoir les utiliser dans des intégrations ou pour automatiser des processus.",
    "write_email_templates_send_to_clients": "Créez des modèles d'e-mails qui peuvent être utilisés lors de l'envoi de messages aux clients ou pour automatiser certains processus de travail.",
    "define_reusable_cms_elements": "Définissez des éléments réutilisables qui peuvent être utilisés sur n'importe quelle page.",
    "manage_brands_edit_name_description": "Gérez les marques de produits, modifiez leurs noms et leurs descriptions en plusieurs langues.",
    "manage_car_models_and_aliases": "Gérez les modèles de voiture parmi lesquels les utilisateurs peuvent choisir. Définissez des alias pour l'importation.",
    "display_and_manage_category_tree": "Affichez et manipulez l'arborescence des catégories. Vous pouvez modifier les noms et les descriptions.",
    "display_and_manage_product_templates": "Créez et gérez des modèles et permettez aux utilisateurs d'être créatifs.",
    "define_product_attributes_search_and_variants": "Définissez des attributs supplémentaires que vous pouvez indiquer dans vos produits. Utilisez ces attributs pour aider les utilisateurs à rechercher des produits. Créez des variantes de produits basées sur des attributs.",
    "group_attributes_to_better_manage_and_inform": "Vous pouvez regrouper les attributs afin de mieux gérer l'expérience utilisateur dans le backend et de présenter plus clairement les attributs des articles à vos clients.",
    "entity_fields_missing_add_new": "S'il vous manque certains champs dans vos formulaires, vous pouvez les ajouter ici. Utile pour les campagnes de marketing et les intégrations.",
    "define_manage_pipelines_and_stages": "Définissez et personnalisez des pipelines pour vos workflows. Ajouter, modifier, supprimer des scènes.",
    "create_questionnaires_for_workflows_and_campaigns": "Créez des questionnaires qui peuvent être utilisés dans les flux de travail ou dans différentes actions marketing.",
    "catch_leads_from_other_sources_with_custom_email": "Capturez des leads de plusieurs sources avec notre Lead Catcher. Configurez des adresses e-mail individuelles pour chaque source souhaitée.",
    "group_car_models_into_families_for_users_to_easily_find": "Regroupez les modèles de véhicules en familles pour que les utilisateurs puissent rechercher des voitures encore plus facilement.",
    "enrich_car_search_functionality_with_own_model_images": "Enrichissez la fonction de recherche de voitures avec vos propres photos de modèles. Attribuez des photos à des modèles ou à des groupes de modèles.",
    "tag_leads_with_custom_regular_expressions": "Étendre la fonctionnalité de notre système de balises avec des balises définies par l'utilisateur. Des connaissances en expressions régulières sont nécessaires.",
    "unknown_error": "Une erreur inconnue s'est produite",
    "username_required": "Veuillez saisir votre nom d'utilisateur",
    "email_required": "Saisis ton adresse e-mail",
    "email_invalid": "L'e-mail n'est pas valide",
    "client_name_required": "Veuillez saisir votre nom",
    "password_required": "Saisis ton mot de passe",
    "password_length": "Le mot de passe doit comporter au moins 8 caractères",
    "repeat_password_required": "Répétez votre mot de passe",
    "repeat_password_length": "Le mot de passe répété doit comporter au moins 8 caractères",
    "message_required": "Veuillez saisir un message",
    "second_password_incorrect": "Le deuxième mot de passe n'est pas le même que le premier",
    "registration_not_confirmed": "Votre inscription n'a pas encore été confirmée. Veuillez vérifier votre boîte de réception et cliquer sur le bouton pour confirmer votre adresse e-mail.",
    "account_deleted": "Ce compte a été supprimé !",
    "account_inactive": "L'abonnement à ce compte a été résilié !",
    "company_required": "Veuillez saisir le nom de l'entreprise",
    "first_name_required": "Veuillez saisir le prénom",
    "last_name_required": "Veuillez saisir le nom de famille",
    "country_required": "Veuillez sélectionner un pays",
    "phone_required": "Veuillez saisir le numéro de téléphone",
    "street_required": "Veuillez saisir le nom de la rue",
    "house_number_required": "Veuillez saisir le numéro de la maison",
    "zip_code_required": "Veuillez saisir le code postal",
    "zip_code_invalid": "Le code postal '{{ value }}' n'est pas un code postal valide",
    "city_required": "Veuillez saisir le nom de la ville",
    "vat_id_required": "Veuillez saisir le numéro de TVA intracommunautaire",
    "timezone_required": "Veuillez sélectionner un fuseau horaire",
    "select_job": "Veuillez sélectionner un emploi",
    "title_required": "Veuillez saisir le titre",
    "type_required": "Veuillez sélectionner un type",
    "location_required": "Veuillez indiquer l'emplacement",
    "questionnaire_required": "Veuillez sélectionner un questionnaire",
    "subject_required": "Veuillez saisir l'objet",
    "amount_required": "Veuillez saisir le montant",
    "time_count_required": "Veuillez saisir le montant du temps",
    "name_required": "Veuillez saisir le nom",
    "price_required": "Veuillez saisir le prix",
    "description_required": "Veuillez saisir la description",
    "question_required": "Veuillez saisir la question",
    "content_required": "Veuillez saisir le contenu",
    "template_required": "Veuillez choisir un modèle",
    "payment_schedule_required": "Veuillez sélectionner un plan de paiement",
    "answer_required": "Veuillez saisir la réponse",
    "website_required": "Veuillez saisir l'adresse du site web",
    "fax_required": "Veuillez saisir le numéro de fax",
    "currency_required": "Veuillez sélectionner une devise",
    "vat_name_required": "Veuillez saisir le nom de la TVA",
    "vat_id_name_required": "Veuillez saisir le nom du numéro de TVA intracommunautaire",
    "vat_rates_required": "Veuillez saisir les taux de TVA",
    "coc_number_required": "Veuillez saisir le numéro COC",
    "registered_at_required": "Veuillez saisir les informations sous lesquelles votre entreprise est enregistrée",
    "bank_name_required": "Veuillez saisir le nom de la banque",
    "account_holder_required": "Veuillez saisir le nom complet du titulaire du compte",
    "account_number_required": "Veuillez saisir le numéro de compte",
    "bank_location_required": "Veuillez saisir la localisation de la banque",
    "bank_code_number_required": "Veuillez saisir le code bancaire",
    "iban_required": "Veuillez saisir l'IBAN",
    "min_message": "Cette valeur est trop courte. Elle devrait être {{ limite }}. caractères ou plus",
    "max_message": "Cette valeur est trop longue. Elle devrait être {{ limite }}. caractères ou moins",
    "min_max_length": "Cette valeur doit être comprise entre {{ min }} et {{ max }}. de caractères",
    "greater_than_or_equal": "Cette valeur doit être supérieure ou égale à {{ compared_value }}.",
    "price_type_invalid": "Le type de prix n'est pas valide",
    "This value should not be blank": "Cette valeur ne doit pas être vide",
    "required_message": "Cette valeur ne doit pas être vide",
    "This value is not a valid URL": "Cette valeur n'est pas une URL valide",
    "must_upload_a_file": "Vous devez télécharger un fichier",
    "file_format_not_supported": "Le fichier que vous souhaitez télécharger n'est pas pris en charge",
    "category_not_empty": "La catégorie n'est pas vide",
    "brand_has_items": "La marque a attribué des produits",
    "template_has_items": "Des produits sont attribués au modèle",
    "item_has_variants": "Le produit a des variantes attribuées",
    "no_valid_variant": "Pas de variante valable",
    "no_stage_set": "Aucune étape n'a été définie",
    "no_pipeline_set": "Aucun pipeline n'a été défini",
    "no_users_found": "Aucun utilisateur trouvé",
    "no_team_set": "Pas d'équipe désignée",
    "budget_required": "Veuillez définir le budget",
    "client_required": "Veuillez attribuer un client",
    "order_required": "Veuillez attribuer une commande",
    "item_required": "Veuillez attribuer un produit",
    "document_required": "Veuillez vous assurer que les documents sont disponibles et valables.",
    "brand_required": "Veuillez sélectionner une marque",
    "model_required": "Veuillez sélectionner un modèle",
    "order_type_invalid": "Type de commande non valable",
    "item_already_reserved": "Certains produits ne sont pas disponibles certains jours",
    "wrong_credentials": "Votre nom d'utilisateur ou votre mot de passe a été mal saisi. Essayez à nouveau ou cliquez sur \"Mot de passe oublié\".",
    "no_car_found": "Aucun véhicule trouvé pour ce lead",
    "data_push_event_invalid": "L'événement Push de données n'est pas valide",
    "data_push_auth_invalid": "Le type d'authentification \"data push\" n'est pas valide",
    "cart_type_invalid": "Le type de panier n'est pas valide",
    "system_type_invalid": "Le type de système n'est pas valide",
    "menu_version_invalid": "La version du menu n'est pas valide",
    "logo_version_invalid": "La version du logo n'est pas valable",
    "payment_method_invalid": "Nous n'avons pas pu traiter le mode de paiement que vous avez indiqué.",
    "nationality_required": "Veuillez saisir votre nationalité",
    "place_of_birth_required": "Veuillez saisir votre lieu de naissance",
    "date_of_birth_required": "Veuillez saisir votre date de naissance",
    "id_required": "Veuillez saisir l'ID",
    "meta_identifier_required": "Veuillez sélectionner un identifiant",
    "payment_status_invalid": "Le statut de paiement n'est pas valide",
    "iban_invalid": "La valeur que vous avez saisie n'est pas un numéro de compte valide.",
    "swift_bic_required": "Veuillez saisir un numéro SWIFT/BIC valide",
    "reference_required": "Veuillez saisir une référence",
    "service_status_invalid": "Le statut de service n'est pas valable",
    "date_proposal_type_invalid": "Le type de date proposé n'est pas valide",
    "mobile_phone_invalid": "Le numéro de téléphone portable n'est pas valide",
    "mobile_phone_required": "Veuillez saisir votre numéro de portable",
    "phone_invalid": "Le numéro de téléphone n'est pas valide",
    "fax_invalid": "Le numéro de fax n'est pas valide",
    "salutation_required": "Veuillez sélectionner une formule de politesse",
    "gender_required": "Veuillez sélectionner un sexe",
    "stripe_error": "Erreur lors de la connexion à Stripe",
    "stripe_connect_error_with_type": "Nous n'avons pas pu connecter CreaCheck à votre compte Stripe. Code d'erreur : {type}.",
    "car_rental_pickup_time_invalid": "L'heure de prise en charge souhaitée est en dehors des heures d'ouverture du site",
    "car_rental_drop_off_time_invalid": "L'heure de dépôt demandée pour une voiture est en dehors des heures d'ouverture du site",
    "accept_terms_and_cancellation_before_continuing": "Veuillez confirmer que vous avez lu nos conditions générales de vente et le droit de rétractation.",
    "problem_processing_your_credit_card": "Nous avons un problème avec le traitement de votre carte de crédit",
    "invoice_type_invalid": "Le type de facture n'est pas valide",
    "doc_number_required": "Veuillez saisir le numéro de document",
    "doc_issuing_city_required": "Veuillez indiquer la ville émettrice",
    "doc_issuing_authority_required": "Veuillez indiquer l'autorité émettrice",
    "doc_issue_date_required": "Veuillez saisir la date d'émission",
    "doc_expiration_date_required": "Veuillez saisir la date d'expiration",
    "test_domain_required": "Veuillez saisir un nom de domaine de test",
    "iban_has_wrong_format": "Le format IBAN n'est pas correct",
    "car_cash_payment_data_missing_or_invalid": "Les données de paiement en espèces sont manquantes ou non valables",
    "car_registration_service_only_available_in_germany": "Le service d'immatriculation n'est actuellement disponible qu'en Allemagne",
    "car_transport_only_available_in_germany": "Notre service de transport n'est actuellement disponible qu'en Allemagne",
    "target_type_invalid": "Type de destination non valide",
    "company_email_must_be_different_from_users_email": "L'adresse e-mail de votre entreprise doit être différente de la vôtre.",
    "car_transport_distance_too_short": "Le transport de la voiture n'est possible que dans les endroits situés à plus de 100 km.",
    "first_offer_price_too_low": "Votre première offre est trop basse et n'a pas été acceptée",
    "first_offer_price_too_high": "Votre première offre est plus élevée que le prix initial et n'a pas été acceptée",
    "current_offer_too_low": "Votre offre actuelle est trop basse",
    "not_your_turn_to_negotiate_the_price": "Vous ne pouvez pas mettre à jour votre offre maintenant. Veuillez attendre la réponse de notre collaborateur",
    "the_question_must_be_answered": "Il faut répondre à la question \"{{ question }}\".",
    "validation_problems_with_item_attributes": "Vérifie que les attributs sont correctement remplis. Les problèmes rencontrés sont marqués en rouge",
    "the_field_name_contains_following_errors": "Le champ {fieldName} contient l'erreur suivante : {helpText}.",
    "plugin_required": "Veuillez sélectionner un plan d'abonnement.",
    "invalidRegex": "L'expression régulière que vous avez indiquée n'est pas valide",
    "assignment_strategy_type_invalid": "Le type de stratégie d'affectation n'est pas valable",
    "cannot_remove_role_company": "Il n'est pas possible de supprimer le rôle utilisateur ROLE_COMPANY.",
    "coupon_type_invalid": "Veuillez sélectionner un type de bon valable",
    "subscription_starting_fee": "Frais d'inscription pour l'abonnement",
    "comms_center_monthly_fee": "Centre de communication Creacheck",
    "comms_center_chat": "Creacheck Communication Center - Chat avec les clients",
    "comms_center_call": "Creacheck Communication Center - Appel client",
    "0": "<strong>Fehler beim Einloggen</strong>",
    "You are not authenticated": "Nous ne connaissons malheureusement pas l'adresse e-mail et le mot de passe.",
    "access_denied": "Pour te connecter, tu dois accepter l'accès à Creacheck.com",
    "oauth_email_missing": "Nous ne pouvons pas vous laisser vous connecter par le biais des médias sociaux. Veuillez vous connecter avec votre adresse e-mail.",
    "User already exists": "L'adresse e-mail indiquée existe déjà dans notre base de données. Tu as oublié ton mot de passe ?",
    "next": "Continuer",
    "create_comment": "Ecrire un commentaire",
    "new_pipeline": "Nouveau pipeline",
    "new_stage": "Nouvelle étape",
    "quantity": "Quantité",
    "login_error": "Erreur de connexion",
    "not_found": "Pas trouvé",
    "deal_information": "Informations sur l'accord",
    "login_domain": "Domaine de connexion",
    "login_domain_successfully_changed": "Le domaine de connexion a été modifié avec succès.",
    "missing_lead_information": "Veuillez indiquer les informations de leads manquantes",
    "missing_informations": "Manque d'informations",
    "missing_client_signature": "Absence de signature du client",
    "previous": "Précédent",
    "current": "Actuel",
    "customCssInvalid": "Le CSS personnalisé n'est pas valide ({{ error }})",
    "accountAndLogin": "Compte & Login",
    "invalidEIN": "Numéro eVB (confirmation d'assurance électronique) non valable",
    "radio": "Radio",
    "airConditioning": "Climatisation",
    "parkingAssistanceRear": "Aide au stationnement arrière",
    "airConditioningAutomatic": "Climatisation automatique",
    "navigation": "Navi",
    "alloyRims": "Jantes alu",
    "parkingAssistanceFrontRear": "Aide au stationnement avant & arrière",
    "metallicPaint": "Peinture métallisée",
    "cruiseControl": "Régulateur de vitesse",
    "seatHeating": "Sièges chauffants",
    "leatherSeats": "Sièges en cuir",
    "equipmentLevel": "Niveau d'équipement",
    "motorPower": "Puissance",
    "currentLocation": "Emplacement actuel",
    "distance": "Distance",
    "selected": "sélectionné",
    "noBrandSelected": "Aucune marque sélectionnée",
    "showMore": "Voir plus",
    "showLess": "Afficher moins",
    "optional": "En option",
    "altogether": "Total",
    "monday": "Lundi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "thursday": "Jeudi",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "payment_data_missing_or_invalid": "Les données de paiement sont manquantes ou non valables",
    "previousSearchRequests": "Recherches précédentes",
    "reset": "Réinitialiser",
    "number_days": "{jours, pluriel, zéro {# jours} one {# jour} other {# jours}}",
    "number_months": "{mois, pluriel, zéro {# mois} one {# mois} other {# mois}}",
    "outdated_browser": "Votre navigateur est obsolète !",
    "update_browser_text": "Veuillez actualiser votre navigateur afin d'afficher correctement ce site.",
    "update_browser_btn_text": "Mettre à jour le navigateur maintenant",
    "save_successfully": "Enregistré avec succès",
    "add_quickly": "Ajouter rapidement",
    "load_data": "Charger les données",
    "car_data_import_vin_info": "Saisissez le NIF valide pour remplir automatiquement le formulaire de données du véhicule.",
    "car_data_import_missing_attr_values": "Certaines valeurs d'attributs sont manquantes - elles sont marquées dans le formulaire.",
    "vin_invalid": "Le FIN n'est pas valable",
    "others": "Autres",
    "activate_plugin_to_enable_feature": "Cette fonctionnalité n'est disponible qu'avec le plugin suivant. Activez le plugin pour continuer à utiliser cette fonctionnalité.",
    "car_data_import_failed": "L'importation des données du véhicule n'a pas été possible pour ce FIN. Vérifiez si le VIN indiqué est correct.",
    "send_mail_via_outlook": "L'e-mail est envoyé avec votre compte Microsoft Outlook ({email}) et sera disponible plus tard dans votre dossier \"Envoyé\".",
    "permission_required": "Autorisations requises",
    "phone_call_permissions_required": "Cette application doit avoir accès à vos comptes téléphoniques pour pouvoir passer des appels.",
    "calling": "M'appeler",
    "callingTo": "Appel à",
    "startingCall": "Initier un appel",
    "callEnded": "Appel terminé",
    "inCall": "'En appel",
    "callCancelled": "Appel annulé",
    "callRejected": "Appel refusé",
    "is_calling": "%nom% appelle...",
    "contacts": "Contacts",
    "addresses": "Carnet d'adresses",
    "chat": "Chat",
    "calls": "Appels",
    "endCustomerNewMessage": "Message du nouveau client",
    "returnToTheActiveCall": "Retour à l'appel actif",
    "agentStatus": "Statut de l'agent",
    "switchOnAgentNote": "Activer pour accepter les chats clients et les appels vidéo",
    "switchOffAgentNote": "Désactiver pour ne pas accepter les chats clients et les appels vidéo",
    "activateAgent": "Activer l'acceptation des chats clients",
    "deactivateAgent": "Désactiver l'acceptation des chats clients",
    "startAudioCallWith": "Démarrer un appel téléphonique avec {{to}}",
    "startVideoCallWith": "Démarrer un appel vidéo avec {{to}}",
    "terms_and_conditions": "Conditions générales de vente",
    "form_incorrect_data": "Les données que vous avez saisies ne sont pas correctes. Veuillez vérifier vos saisies et les envoyer à nouveau.",
    "accept_terms_and_condition_start_chat": "En cliquant sur \"Démarrer le chat\", vous acceptez les conditions générales.",
    "start_chat": "Démarrer le chat",
    "connecting_with": "Connecter avec",
    "connected_with": "Lié à",
    "do_calling": "appelle",
    "chat_inactivity_warning": "Le chat est inactif depuis trop longtemps et sera bientôt fermé.",
    "get_in_contact_with_us": "Prenez contact avec nous",
    "no_agent_available": "Malheureusement, aucun agent n'est en ligne. Veuillez laisser un message et nous vous contacterons dès que possible.",
    "hello": "Bonjour !",
    "we_are_here_for_you": "Nous sommes à votre disposition et nous nous réjouissons de vos questions ou de vos commentaires.",
    "start_conversation": "Démarrer une conversation",
    "welcome_customer": "Mon nom est {firstName} {lastName}. Comment puis-je vous aider ?",
    "end_chat": "Quitter",
    "send_request": "Envoyer une demande",
    "checkout_payment_agreement": "J'accepte que {company} commence à fournir les services supplémentaires avant le début du délai de rétractation et j'ai pris connaissance du fait que je perdrai mon droit de rétractation lorsque ces services supplémentaires seront entièrement fournis.",
    "checkout_buy_order_legal_text": "Les {termes_généraux_et_conditions} ainsi que la {politique_et_forme_d'annulation} de {compagnie} ({privacy_policy}) s'appliquent à l'achat.",
    "checkout_services_terms_cancelation_legal_text": "Les {termes_généraux_et_conditions} ainsi que la {politique_et_forme_d'annulation} de {compagnie} ({privacy_policy}) s'appliquent aux services supplémentaires réservés ({booked_services})",
    "no_credit_cards": "Pas de cartes de crédit",
    "minimize_video": "Réduire l'affichage vidéo",
    "maximize_video": "Maximiser l'affichage de la vidéo",
    "enable_fullscreen": "Activer le mode plein écran",
    "disable_fullscreen": "Désactiver le mode plein écran",
    "enable_screensharing": "Partager son bureau",
    "disable_screensharing": "Quitter le partage du bureau",
    "mute_microphone": "Couper le microphone",
    "unmute_microphone": "Désactiver le microphone",
    "enable_camera": "Allumer la caméra",
    "disable_camera": "Éteindre la caméra",
    "end_call": "Mettre fin à l'appel",
    "end_videocall": "Arrêter le flux vidéo",
    "muted": "Mise en sourdine",
    "overall_feedback": "Comment évaluez-vous votre contact avec nous ?",
    "connection_quality_feedback": "Comment évaluez-vous la qualité de la connexion ?",
    "send_feedback": "Envoyer un commentaire",
    "customer_chat_ended_info": "Votre chat a été interrompu",
    "checking_connection": "Connexion en cours de vérification",
    "call_again": "Rappeler",
    "end_call_local_disconnected": "Fin d'appel en raison de problèmes de connexion",
    "end_call_remote_disconnected": "Fin d'appel en raison de problèmes de connexion sur la page {remoteUserName}",
    "network_disconnected_warning": "Il n'y a pas de connexion à Internet pour le moment.",
    "network_disconnected": "Connexion interrompue",
    "network_want_reconnect": "Votre connexion a été interrompue. Souhaitez-vous reprendre la connexion ?",
    "leave_as_an_email_message": "Laissez-nous un message électronique",
    "continue_call": "Poursuivre l'appel",
    "continue_text_chat": "Poursuivre le chat de texte",
    "continue_conversation": "Poursuivre la conversation",
    "disconnected": "Séparé",
    "disconnect_reason": "Raison possible",
    "disconnect_reason_transport_close": "Quitter la page",
    "disconnect_reason_ping_timeout": "Pas de connexion Internet",
    "customer_may_return": "Le client peut revenir bientôt.",
    "mobile": "Téléphone portable",
    "headquarter": "Centrale",
    "extension-number": "Numéro de poste",
    "add_contact": "Ajouter un contact",
    "add_address": "Ajouter une adresse",
    "choose_contact": "Choisir un contact",
    "number": "Numéro",
    "new_number": "Nouveau numéro",
    "address_mail": "Adresse postale",
    "address_delivery": "Adresse de livraison",
    "address_invoice": "Adresse de facturation",
    "new_address": "Nouvelle adresse",
    "find_address": "Trouver une adresse",
    "searching": "Chercher...",
    "address_change": "Modifier l'adresse",
    "address_ask_change": "La modification de cette adresse se répercute sur tous les contacts ayant cette adresse. Vous souhaitez modifier l'adresse ?",
    "designation": "Désignation",
    "net": "Net",
    "gross": "Brut",
    "exp_delivery_time": "vs. délai de livraison",
    "workdays": "Jours ouvrables",
    "special_offer": "Action",
    "transportation_cost": "Frais d'expédition",
    "discount": "Réduction",
    "total": "Total",
    "total_gross": "Total (TVA incluse)",
    "product_details": "Détails du produit",
    "type_of_order": "Type de commande",
    "number_of_copies": "Tirage",
    "type_of_order_choices": "Impression régulière ou téléchargement des données d'impression au format PDF",
    "type_of_order_website": "Mise à disposition de ce site web",
    "print_production": "Production d'imprimés",
    "pdf_download": "Téléchargement PDF",
    "website_upload": "Téléchargement du site web",
    "production_time": "Temps de production",
    "production_time_description": "Veuillez sélectionner l'option souhaitée",
    "production_standard": "Production standard",
    "production_express": "Production express",
    "production_overnight": "Production Overnight",
    "choose_edition": "Sélectionner le tirage",
    "choose_edition_description": "Éditions avec prix échelonnés. Les réductions et les promotions sont mises en évidence, si elles sont disponibles.",
    "more_options": "Autres options",
    "more_options_description": "Ajoutez des options à votre commande.",
    "manual_data_review": "Vérification manuelle des données (y compris l'assurance)",
    "manual_item_entry": "Saisie manuelle de la position",
    "web_enabled_pdf": "Recevoir les données d'impression en plus sous forme de PDF compatible avec le web",
    "cart_production_type_change_warning_title": "Le tirage est adapté",
    "cart_production_type_change_warning": "Le tirage sélectionné n'est pas disponible pour le {{shippingType}}. Il sera remplacé par le tirage maximal disponible. Vous êtes sûr de vouloir continuer ?",
    "cart_no_warranty_without_manual_data_check": "Remarque : Contrôle manuel des données non sélectionné.",
    "campaign_budget_exceed_warning": "Budget de la campagne dépassé. Validation nécessaire !",
    "user_budget_exceed_warning": "Budget utilisateur dépassé. Validation nécessaire !",
    "order_quantity": "Quantité commandée",
    "data_check": "Vérification des données",
    "split_shipping": "Livraison partielle",
    "cart_not_logged_in": "Pas d'utilisateur connecté",
    "cart_contact_or_address_not_found_invoice": "Veuillez sélectionner une adresse de facturation",
    "cart_contact_or_address_not_found_shipping": "Veuillez sélectionner une adresse de livraison",
    "cart_edtion_item_or_variant_not_found": "Édition, article ou variante introuvable",
    "cart_edtion_item_not_available": "L'article %item_name% n'est plus disponible",
    "cart_no_print_type_found": "Aucun type d'impression trouvé",
    "cart_print_type_invalid": "Type d'impression non valide :",
    "cart_no_shipping_type_found": "Aucun mode d'expédition trouvé",
    "cart_checkout_info_missing": "Informations sur les cases à cocher manquantes :",
    "cart_reduced_vat_rate_check_not_supported": "Vérification demandée du taux de TVA réduit pour l'article qui ne le supporte pas :",
    "cart_no_price_found": "Le prix n'a pas pu être trouvé",
    "cart_price_mismatch": "le prix ne correspond pas à celui que nous avons présenté à l'utilisateur",
    "cart_web_pdf_price_mismatch": "Le prix du PDF web ne correspond pas au prix que nous avons présenté à l'utilisateur",
    "cart_contact_or_address_not_found_split_shipping": "Veuillez choisir une adresse pour une expédition partagée",
    "cart_quantity_not_found_split_shipping": "Paramètres de quantité non trouvés lors d'un envoi fractionné",
    "cart_split_shipping_quantity_mismatch": "La quantité expédiée répartie ne correspond pas à la quantité produite",
    "cart_coupons_mismatch": "Les coupons utilisés ne correspondent pas",
    "cart_coupon_cant_be_used": "Le coupon demandé est expiré ou ne peut pas être utilisé.",
    "cart_coupon_value_mismatch": "La valeur du coupon n'est pas correcte",
    "cart_camaign_budget_processing_error": "Un problème inattendu est survenu lors du traitement du budget de la campagne",
    "cart_campaign_budget_has_changed": "Le budget de la campagne a changé",
    "cart_user_budget_has_changed": "Le budget des utilisateurs a changé",
    "cart_user_budget_processing_error": "Un problème inattendu est survenu lors du traitement du budget des utilisateurs",
    "cart_domain_not_active": "Le domaine n'est pas actif",
    "cart_missing_desired_domain": "Indication du domaine souhaité manquante",
    "lead_monitor_order_payment_receipt": "Suivi des encaissements",
    "lead_format_message_description_route53_register_domain": "Enregistrer le domaine {0} et le rediriger vers {1}",
    "lead_title_route53_register_domain": "Enregistrer un domaine AWS",
    "domain_not_available": "Domaine non disponible",
    "invoice_payment_option": {
      "enabled": "Activé",
      "disabled": "Désactivé",
      "inherited": "Légué",
      "title": "Paiement de la facture",
      "loading": "Charger un paramètre hérité",
      "effective_setting": "réglage efficace"
    },
    "invoice_payment_threshold": {
      "title": "Valeur seuil",
      "placeholder": "dans l'exemple, 100",
      "no_effect": "aucun effet",
      "loading": "Chargement des paramètres hérités",
      "info_text": "Valeur minimale de commande pour le paiement par facture",
      "effective_threshold": "Seuil effectif",
      "not_defined": "non défini"
    },
    "remind_period": {
      "days": "Jours",
      "inherited_setting": "Attitude héritée",
      "title": "Échéance de la facture Délai de rappel"
    },
    "sellDownloadPdf": "Télécharger PDF vendre",
    "success_management": {
      "title": "Gestion du succès",
      "manager": "Manager du succès",
      "switch_title": "Activer les souvenirs récents",
      "interval": "Dernière période vue, jours",
      "last_seen": "Dernièrement vu",
      "error_no_self": "Ne peut pas s'attribuer le rôle de Success Manager",
      "error_no_same_parent": "Le Success Manager doit être subordonné à une autre entreprise"
    },
    "copy": "Copier",
    "is_client_registration_allowed": "Activer l'enregistrement",
    "is_email_validation_enabled": "Valider l'email",
    "email_validation_pattern": "Regex pour la validation des e-mails",
    "confirmation_email_cc": "CC pour confirmation d'enregistrement",
    "client_registration_title": "Paramètres d'enregistrement",
    "registration_fields_show": "Annonces de champs",
    "registration_fields_require": "Champs obligatoires",
    "accept_terms_of_use_before_continuing": "Avant de continuer, vous devez accepter nos conditions d'utilisation",
    "subscription_settings": "Abonnements",
    "client_subscription_settings": "Abonnements clients et produits",
    "subscription_settings_description": "Ici, vous pouvez créer un abonnement de base et lui attribuer un type",
    "client_subscription_settings_description": "Vous pouvez ici attribuer l'abonnement de base à un client ou à un article en fonction du type et adapter les prix.",
    "subscriptions_": "Abonnements",
    "subscription_name": "Nom",
    "subscription_title": "Titre",
    "subscription_description": "Description",
    "subscription_fee": "Frais",
    "subscription_startingFee": "Frais d'inscription (facultatif)",
    "subscription_start": "Début",
    "subscription_end": "Fin",
    "subscription_status": "Statut",
    "subscription_action": "Actions",
    "subscription_items": "Produits",
    "subscription_clients": "Clients",
    "subscription_companies": "Entreprises",
    "subscription_companies_detailed_role": "Utilisateur/rôle de l'entreprise",
    "subscription_basic": "Abonnement de base",
    "add_subscription": "Ajouter un abonnement",
    "create_subscription": "Créer un abonnement",
    "edit_subscription": "Enregistrer l'abonnement",
    "subscription_language": "Choix de la langue",
    "period_status": "désactiver à la fin de la période",
    "subscription_edit": "Modifier",
    "omit_subscription_fee": "Supprimer les frais d'inscription",
    "invoice_email": "Adresse e-mail pour les factures",
    "order_processing_contract": "Contrat de traitement des commandes",
    "accept_order_processing_contract_before_continuing": "Avant de poursuivre, vous devez accepter notre contrat de traitement des commandes.",
    "no_image_title": "Aucun titre d'image trouvé. Cliquez ici pour éditer.",
    "no_image_description": "Aucune description d'image n'a été trouvée. Cliquez ici pour éditer",
    "no_image_copyright": "Aucune information sur le copyright n'a été trouvée. Cliquez ici pour éditer",
    "no_text": "Aucun texte n'a été trouvé. Cliquez ici pour éditer",
    "subscription_clients_and_companies": "Clients et entreprises",
    "save_subscription": "Enregistrer",
    "go_to": "Aller à",
    "no_invoices_to_display": "Pas de factures à afficher",
    "url_settings": "Paramètres url",
    "update_notification": "Gestion des mises à jour",
    "update_notification_description": "Description",
    "update_notification_settings_description": "Ici, vous pouvez gérer les notifications de mises à jour.",
    "update_name": "Nom",
    "update_notification_meta": {
      "title": "Gestion des mises à jour",
      "description": "Gérez ici vos notifications de mise à jour",
      "name": "Gestion des mises à jour"
    },
    "new_update_notification": "Créer une nouvelle notification de mise à jour",
    "update_from": "de",
    "update_to": "jusqu'à",
    "edit_update_notification": "Modifier la notification de mise à jour",
    "individual_prices": "Prix individuels",
    "template_pdf_profile_x3": "Créer un PDF au format X-3",
    "countries_for_prices": {
      "de": "Allemagne",
      "ch": "Suisse",
      "at": "Autriche",
      "es": "Espagne"
    },
    "choose_country": "Veuillez sélectionner un pays",
    "product_groups": "Groupes de produits - Fournisseurs",
    "product_groups_description": "Ici, vous pouvez gérer les groupes de produits des fournisseurs.",
    "new_product_group": "Ajouter un nouveau groupe de produits",
    "edit_product_group": "Modifier",
    "create_product_group": "Créer",
    "product_group_number": "Numéro de groupe de produits",
    "save_product_group": "Enregistrer",
    "product_group_actions": "Actions",
    "product_group_status": "Statut",
    "hide_preview": "Désactiver l'aperçu dans l'éditeur",
    "editor_settings": "Paramètres Éditeur",
    "share_edition": "Partager l'édition",
    "canceled_quote": "Offre refusée",
    "active_quote": "Offre en attente",
    "manual_quote_status": "Statut des offres",
    "invalid_manual_quote_status": "Statut non valide de l'offre",
    "internal_status": "Statut interne",
    "edit_quote": "Modifier l'offre",
    "copy_quote": "Copier l'offre",
    "open_lead": "Ouvrir Lead",
    "upload_pdf": "Télécharger le PDF et créer un lien",
    "upload_pdf_title": "Téléchargement de PDF",
    "upload_pdf_status_done": "Votre fichier PDF a été téléchargé avec succès",
    "upload_pdf_status_error": "Échec du téléchargement de PDF.",
    "placeholder_upload_error": "Un problème est survenu lors du téléchargement !",
    "linkedin_insight_tag": "Tag LinkedIn Insight",
    "linkedin_partner_id": "Id partenaire",
    "tracking_pixel": "Pixels de suivi, par exemple Meta Pixel",
    "tracking_pixel_data": "Code de mise en œuvre",
    "jsWebsite": "Javascript",
    "upload_website_favicon": "Favicon",
    "upload_website_favicon_label": "Téléchargement et aperçu des images",
    "upload_website_favicon_info": "Veuillez noter que ! Le format de fichier requis est le PNG. La résolution optimale est comprise entre 32 x 32px et 64 x 64px maximum, selon l'appareil utilisé. Vous pouvez également utiliser d'autres tailles tant qu'elles sont carrées et ne dépassent pas la résolution maximale.",
    "upload_favicon_error": "L'image n'a pas la résolution requise",
    "go_back_to_campaign": "Retour à la campagne",
    "no_orders_to_display": "Keine Bestellungen zum Anzeigen",
    "no_editions_to_display": "Keine Editionen zum Anzeigen",
    "google_tag_manager": "Gestionnaire de balises Google",
    "google_tag_manager_id": "GTM-ID",
    "website_analyse": "Analyse du site web",
    "legal_information": "Informations juridiques",
    "contact_info": "Coordonnées",
    "import_users_via_csv": "Créez des utilisateurs en important un fichier CSV",
    "remove_users_via_csv": "Supprimer des utilisateurs en important un fichier CSV",
    "import_users": "Importer des utilisateurs",
    "remove_users": "Supprimer un utilisateur",
    "fill_data_policy_before_autosave": "Échec de l'enregistrement automatique. Veuillez remplir intégralement les informations relatives à la protection des données et aux mentions légales. Vous les trouverez dans les paramètres",
    "invoice_customer_info": "ID - Société / Nom",
    "invoice_search_debtor": "Numéro de client",
    "invoice_search_vat": "TVA",
    "invoice_search_amount": "Montant",
    "search_field_name": "Sélectionnez un champ de recherche",
    "search_field_info_text": "Si vous souhaitez rechercher une valeur numérique dans le champ de recherche, vous devez sélectionner un champ",
    "your_export": "Votre exportation",
    "export_download_info": "Votre fichier a été créé et peut maintenant être utilisé.",
    "export_download": "Téléchargement",
    "export_file": "Fichier",
    "export_share": "Partager",
    "image_permission_title": "Attention !",
    "image_permission_warning": "Droits d'image disponibles/consentement de toutes les personnes obtenu ?",
    "other_settings": "Autres paramètres",
    "image_rights_query": "Demande de droits d'image",
    "direct_ordering_label": "Commande directe",
    "direct_ordering_info_text": "Lors d'une commande directe, l'édition créée ne peut plus être traitée. Celle-ci est directement placée dans le panier.",
    "subscription_automation": {
      "title": "Objectif de facturation pour les nouveaux abonnements d'utilisateurs",
      "payer": "Payeur",
      "fee_for_role_user": "Frais pour ROLE_USER",
      "fee_for_role_admin": "Frais pour ROLE_ADMIN",
      "fee_for_role_wl_admin": "Frais pour ROLE_WL_ADMIN",
      "hint_title": "Remarque",
      "hint_body": "Veuillez créer et activer un abonnement d'entreprise pour cette société afin de couvrir tous les utilisateurs créés ultérieurement.",
      "debtor_ancestor": "Chaque abonnement d'utilisateur est facturé au %billingTargetName%.",
      "debtor_ancestor_missing": "Aucune entreprise précédente n'est marquée comme débiteur. Veuillez corriger cela."
    },
    "need_content_authorization": "Autorisation de contenu requise",
    "publisher_info_text": "L'éditeur est automatiquement inséré dans chaque support publicitaire nécessaire, conformément aux directives légales.",
    "mark_campaign": "Marquer la campagne",
    "template_exports": {
      "download_complete": "Téléchargement terminé",
      "export": "Exportation",
      "download_files": "Télécharger des fichiers",
      "synchronize": "Synchronise"
    },
    "info_import_and_export": {
      "note": "Informations générales sur l'importation et l'exportation",
      "import": "Importer : Importe toutes les informations sur les paramètres du modèle (CSS, blocs de contenu, autres paramètres) et les fichiers. Crée un fichier Zip initial pour l'exportation. Les fichiers Zip existants sont écrasés.",
      "upload": "Téléchargement : Ajoute des fichiers supplémentaires au répertoire des modèles S3. Le fichier zip pour l'exportation est complété par ces fichiers.",
      "sync": "Synchroniser : Synchronise les paramètres du modèle (CSS, blocs de contenu, autres paramètres) avec les fichiers (index.html, page.css, pdf.css, printpdf.css, website.css, website.js) dans le répertoire du modèle S3 et le fichier zip (exportation)",
      "export": "Exportation : crée la structure d'un index.html à copier-coller",
      "download": "Télécharger les fichiers : Télécharge le fichier zip existant. (Effectuez une synchronisation avant le téléchargement pour obtenir les données les plus récentes).",
      "copy": "Copy Template : la fonction 'Copy Template' ne copie plus que les paramètres du modèle, mais pas les répertoires et les fichiers."
    },
    "template_upload_title": "Télécharger des fichiers vers le répertoire des modèles",
    "pls_choose_text": "Veuillez sélectionner un texte",
    "discounts": {
      "discount_type_invalid": "Le type de remise n'est pas valable",
      "discounts_tab_title": "Réductions",
      "intro_text": "Veuillez indiquer le numéro de groupe de produits FlyerAlarm ainsi que le type et la valeur de la remise correspondante. Celles-ci seront appliquées pendant le processus d'achat pour tous les utilisateurs au sein de l'entreprise actuelle.",
      "none_yet_add_some": "Aucune remise n'a encore été définie. Veuillez en ajouter.",
      "number_must_be_unique": "Le numéro doit être unique",
      "must_be_0_to_100": "Doit être de 0 à 100"
    },
    "link_to_uploaded_pdf": "Url du fichier PDF téléchargé",
    "upload_qrcode_logo": "Télécharger le logo pour le QRCode",
    "choose_file": "Sélectionner un fichier",
    "upload_successful": "Téléchargement réussi",
    "upload_failed": "Échec du téléchargement",
    "qr_logo": "Logo",
    "qr_example": "Exemple",
    "qr_settings": "Paramètres du code QR",
    "qr_margin": "Distance",
    "qr_upload": "Télécharger le logo QRCode",
    "google_search_console": "Console de recherche Google",
    "gsc_html_tag": "Vérification des balises HTML",
    "content_id": "ID de contenu",
    "gsc_html_tag_example": "Veuillez utiliser la vérification des balises HTML. Copiez l'Id, par exemple Xu9c238409d8JDSF8... de l'attribut de contenu de la balise méta que vous recevez de Google pour la validation et collez l'id ici. Exemple : <meta name='google-site-verification' content='Xu9c238409d8JDSF8...' />",
    "order_complete": {
      "good_luck": "Nous vous souhaitons beaucoup de succès avec votre campagne !",
      "order_complete": "Votre commande a bien été prise en compte",
      "pls_check_inbox": "Veuillez vérifier votre boîte de réception.",
      "order_info": "Tous les détails de la commande ont été envoyés à l'adresse e-mail que vous avez enregistrée chez nous. Vous pouvez consulter l'historique de vos commandes à tout moment dans votre compte d'utilisateur sous",
      "orders": "Commandes",
      "view": "de l'entreprise.",
      "start_next_campaign": "Lancer une autre campagne maintenant"
    },
    "no_product_selected": "Aucun produit sélectionné",
    "mail_messages": {
      "header": {
        "header_title": "Le portail de conception en ligne intelligent",
        "support": "Aide & FAQ",
        "login": "LOGIN"
      },
      "footer": {
        "email_info": "Vous recevez cet e-mail parce que vous vous êtes inscrit(e) à %company%.",
        "email_info_first_name_form": "Tu reçois cet e-mail parce que tu t'es inscrit(e) à %company%.",
        "dont_reply": "Cet e-mail a été envoyé depuis une adresse qui ne peut pas recevoir d'e-mails entrants. </br>Veuillez ne pas répondre à ce message. Si vous avez des questions ou des préoccupations, contactez-nous à l'adresse suivante",
        "agb": "CONDITIONS GÉNÉRALES DE VENTE",
        "privacy_policy": "Protection des données",
        "imprint": "Mentions légales"
      }
    },
    "content_check": "Vérification du contenu",
    "terms_of_payment": "Conditions de paiement",
    "terms_of_payment_edit": "Traiter les conditions de paiement",
    "cancel_impersonation": "Mettre fin à l'inversion des rôles",
    "impersonate": "Accepter le rôle",
    "template_deleted_warning": "Attention ! Le modèle de l'édition n'est plus disponible.",
    "click_to_enable_editing": "Cliquez ici pour activer le traitement sur l'onglet actuel.",
    "click_to_send_a_request_to_enable_editing": "Cliquez ici pour envoyer une demande d'activation du traitement",
    "waiting_for_form_release": "En attente de validation",
    "already_edited_by_another_user": "Cette édition est peut-être déjà en cours de traitement par un autre utilisateur.",
    "please_wait": "Veuillez patienter.",
    "user_already_requested_release": "a déjà demandé la libération",
    "user_requested_that_you_give_up_the_editing": "demande la permission d'éditer.",
    "emergency_release": "Forcer la validation. Utilisation à vos propres risques. L'édition pourrait être écrasée par un autre utilisateur si celui-ci travaille dessus en ce moment.",
    "alt_debtor_number": "ID client alternatif",
    "use_bootstrap_label": "Bootstrap",
    "use_bootstrap_infotext": "Si vous activez cette option, les bibliothèques Bootstrap seront intégrées.",
    "last_queried_attributes": "Derniers prix demandés",
    "transfer_campaign": "Transmettre",
    "duplicate_campaign_description": "Dupliquez la campagne et attribuez-la à des utilisateurs individuels ou à tous les utilisateurs d'une entreprise.",
    "duplicate_campaign": "Dupliquer",
    "clone_to_single_user": "Créer un duplicata pour un ou plusieurs utilisateurs",
    "clone_to_all_users_of_company": "Créer un duplicata pour tous les utilisateurs d'une entreprise",
    "enum_option_invalid": "Option non valide",
    "pdf_download_option": {
      "enabled": "Activé",
      "disabled": "Désactivé",
      "inherited": "Légué",
      "title": "Téléchargement de PDF autorisé",
      "loading": "Charger un paramètre hérité",
      "effective_setting": "réglage efficace"
    },
    "choose_image_for_filter": "Veuillez sélectionner une image",
    "invoice_email_bcc": {
      "send_new_invoice_email_bcc": "Envoyer une nouvelle facture par e-mail BCC",
      "email_address_bcc": "Adresse e-mail BCC",
      "info_text": "Une copie cachée du nouvel e-mail de facturation est également envoyée à cette adresse."
    },
    "reduced_vat_rate": {
      "cart_item_hint": "Cet article peut bénéficier d'un taux de TVA réduit après vérification manuelle par un employé de Creacheck."
    },
    "forgot_password_infotext": "Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser ici. Veuillez saisir votre adresse e-mail enregistrée dans le formulaire ci-dessous et cliquer sur \"Continuer\". Nous vous enverrons ensuite un e-mail avec plus d'informations.",
    "forgot_password_infotext_robethood": "Si tu as oublié ton mot de passe, tu peux le réinitialiser ici. Veuillez saisir l'adresse e-mail enregistrée dans le formulaire ci-dessous et cliquer sur \"Continuer\". Nous t'enverrons ensuite un e-mail avec plus d'informations.",
    "forgot_password_info_email_not_used": "Si vous n'utilisez plus l'adresse e-mail associée à votre compte Creacheck, veuillez nous contacter via notre formulaire de contact :",
    "forgot_password_info_email_not_used_robethood": "Si tu n'utilises plus l'adresse e-mail associée à ton compte, contacte-nous via notre formulaire de contact :",
    "invoice_greeting_male": "Cher Monsieur",
    "invoice_greeting_female": "Chère Madame",
    "invoice_greeting_text": "Nous vous remercions vivement pour votre commande via %company%. Nous vous facturons les prestations suivantes :",
    "bank_account": "Coordonnées bancaires",
    "mandatory_field": "Déclarez-le comme champ obligatoire",
    "custom_checkbox": "Case à cocher personnalisée",
    "set_label_checkbox": "Veuillez attribuer une étiquette à la case à cocher",
    "set_checkbox_link": "Vous pouvez créer un lien vers le label ici",
    "change_checkbox_link": "Modifier le lien",
    "checkbox_infotext": "Vous pouvez lier le label en plaçant la zone correspondante entre des caractères $. Par exemple, j'ai accepté la $Politique de confidentialité$.",
    "perforation": {
      "cart_radiobox_title": "Options pour la perforation de classement",
      "attribute_is_custom_fa_setting": "Est spécifique au client FA options de perforation présélectionnées",
      "set_perforation_options": "Régler les options de perforation",
      "preset": "Préréglage",
      "how_to": "Veuillez rechercher l'identifiant du groupe de produits, sélectionner quelques variantes, essayer différentes quantités et différents modes d'expédition. Choisissez ensuite l'option de perforation qui doit être présélectionnée avec cet attribut. Plusieurs options peuvent être ajoutées.",
      "select_variant": "Veuillez sélectionner une variante pour afficher ses quantités et ses modes d'expédition.",
      "select_shipping_type": "Veuillez cliquer sur un mode d'expédition pour voir les options disponibles.",
      "select_option_value": "Veuillez cliquer sur une option pour l'ajouter à votre sélection.",
      "please_try_other": "Veuillez essayer une autre variante, quantité ou mode d'expédition",
      "options": "Options",
      "other_options": "autres options",
      "selected_options": "options choisies",
      "perforation_options": "Possibilités de perforation",
      "product_group": "Groupe de produits",
      "variants": "Variantes",
      "quantities_in_variant": "Quantités en variante"
    },
    "textile_select_exactly_n_items": "Veuillez sélectionner exactement {total}",
    "textile_exact_number_selected": "Tout bon : exactement {total} articles sélectionnés",
    "textile_order_quantity_mismatch": "Le nombre de tailles de textile doit correspondre au tirage choisi.",
    "tracking_pixel_help_text": "Veuillez noter que l'implémentation peut varier selon le fournisseur. Dans notre exemple, nous utilisons le pixel de suivi de Meta. L'intégration nécessite en premier lieu une vérification du domaine. Pour ce faire, vous devez intégrer une balise Meta, qui dans notre exemple a été fournie par Meta. Voir dans l'exemple de code ci-dessous. Veuillez noter que la vérification peut prendre jusqu'à 72 heures selon le fournisseur. Dans un deuxième temps, vous complétez le code de base pour l'intégration du pixel de suivi. Pour plus d'informations, veuillez lire la documentation mise à disposition par votre fournisseur respectif.",
    "reg_greeting_male": "Cher Monsieur",
    "reg_greeting_female": "Chère Madame",
    "reg_gender_male": "Monsieur",
    "reg_gender_female": "Mme",
    "reg_name": "Titre et nom",
    "reg_phone_number": "Numéro de téléphone",
    "reg_domain": "Domaine",
    "reg_ancestors": "ID de structure",
    "reg_explanation": "<p>Merci pour votre inscription. Veuillez confirmer votre adresse e-mail dans les 24 heures.</p><p>Ce n'est qu'avec une adresse e-mail confirmée que vous pourrez recevoir un nouveau mot de passe ou des informations sur les changements.</p>",
    "widget_order_stats": {
      "title": "Total des commandes",
      "orders_count": "Compter les commandes",
      "orders_gross_total": "Total des marchés bruts",
      "no_orders_in_date_range": "Aucune commande pendant la période sélectionnée",
      "loading": "Chargement",
      "this_month": "Ce mois-ci",
      "previous_month": "Dernier mois",
      "last_3_months": "3 derniers mois",
      "last_6_months": "6 derniers mois",
      "last_12_months": "12 derniers mois",
      "for_companies": "pour entreprise(s)",
      "for_clients": "pour le(s) client(s)",
      "title1": "Total des commandes",
      "title2": "Les 10 produits les plus commandés",
      "items_count": "Compter les produits",
      "item_types_count": "Produits (types)"
    },
    "cart_domain": "Le domaine que vous avez indiqué",
    "cart_domain_available": "est disponible !",
    "cart_domain_not_available": "n'est plus disponible !",
    "cart_domain_not_available_title": "Domaine non disponible",
    "pages_to_delete": "Les pages suivantes sont supprimées :",
    "page_to_delete": "Page",
    "sales_doc_reverse_charge": "La facture est établie sans TVA, car le système d'autoliquidation s'applique dans le cas présent. La TVA doit être déclarée et payée par le destinataire de la prestation.",
    "invoice_for_service": "Facture pour service",
    "phone_invalid_country": "Le code pays du numéro de téléphone n'est pas valide",
    "mobile_phone_invalid_country": "Le code pays du numéro de téléphone mobile n'est pas valide",
    "fax_invalid_country": "Le code pays du numéro de fax n'est pas valide",
    "search_campaign": "Rechercher des campagnes",
    "coupon_usage": "Utilisation du bon",
    "coupon_balance_title": "Coupon Bilan",
    "get_coupon_balance": "Consulter le solde",
    "total_coupon_budget": "Budget Total :",
    "remaining_coupon_budget": "Reste du budget :",
    "spent_coupon_budget": "Budget consommé :",
    "coupon_balance_success": "Requête réussie",
    "upload_print_data_previews": "Données d'impression Télécharger des aperçus",
    "uploaded_print_data_previews": "Données d'impression téléchargées Aperçus",
    "confirm_upload": "Confirmer",
    "image_minimum_size": "L'image ne correspond pas à la taille minimale !",
    "data_policy_info": "Protection des données et mentions légales",
    "fill_data_policy_before_save": "Avant de sauvegarder, veuillez remplir intégralement les indications relatives à la protection des données et aux mentions légales. Vous les trouverez dans les paramètres",
    "subscription_liu_subscribe": "S'abonner",
    "subscription_liu_cancel": "Résilier",
    "subscription_liu_extend": "Prolonger",
    "subscription_status_changed_to_active": "Statut modifié en actif !",
    "subscription_status_changed_to_inactive": "Statut changé en inactif !",
    "sub_start_end": "La date de début ne peut pas être plus grande que la date de fin !",
    "subscription_liu_send": "Envoyer",
    "subscription_to_client": {
      "subscription_not_available": "Veuillez choisir un abonnement !",
      "type_parameter_not_present": "Le paramètre %type% est manquant",
      "date_parameter_not_present": "Le paramètre %type% est manquant",
      "end_date_greater_then_start_date": "La date de fin ne peut pas être plus grande que la date de début !",
      "end_date_less_then_first_day_of_next_month": "La date de fin ne peut pas être antérieure à la première date du mois suivant !"
    },
    "subscription_billing_period": "Période de paie",
    "mail_greeting_female": "Bonjour Madame",
    "mail_greeting_male": "Bonjour Monsieur",
    "mail_greeting_first_name_form": "Bonjour",
    "mail_preview": "Aperçu des e-mails",
    "mail_preview_update": "Démarrer/actualiser l'aperçu",
    "mail_preview_close": "Fermer",
    "mail_preview_hint": "Attention, l'aperçu ne sert qu'à vérifier la mise en page et les messages. Tous les modèles contiennent des données fictives.",
    "tracking_details": "Pour plus d'informations, cliquez sur le numéro de suivi de l'envoi",
    "ups_tracking_title": "Suivi des envois via API (UPS)",
    "ups_tracking_button": "Consulter le statut",
    "tracking_informations": "Informations sur l'envoi",
    "ups_tracking_status": "Statut de votre envoi (UPS)",
    "shipment_package": "Paquet",
    "shipment": "Envoi",
    "shipment_package_current_status": "Statut actuel",
    "shipment_package_current_status_description": "Description",
    "shipment_package_delivery_date": "Livré le",
    "shipment_package_delivery_location": "Recevoir où",
    "shipment_package_delivery_receivedBy": "Recevoir des",
    "tracking_error": "Une erreur est survenue lors de la consultation !",
    "1": "<strong>Fehler beim Einloggen</strong>",
    "website_title": "Titre pour le site web (moteurs de recherche)",
    "basic_billing_settings": "Paramètres de base de la facturation",
    "currency_and_vat": "Monnaie et TVA",
    "basic_client_settings": "Réglages de base",
    "client_settings_info_text": {
      "info_text_phone": "Pour saisir correctement votre numéro de téléphone fixe, veuillez indiquer le numéro complet, y compris l'indicatif du pays. Veillez à ne pas utiliser d'espaces ou de caractères spéciaux. Exemple pour l'Allemagne : +49XXXXXXXX.",
      "info_text_mobile": "Pour saisir correctement votre numéro de mobile, veuillez indiquer le numéro complet, y compris l'indicatif du pays. Veillez à ne pas utiliser d'espaces ou de caractères spéciaux. Exemple pour l'Allemagne : +49XXXXXXXX.",
      "info_text_demo": "Activez le mode démo pour effectuer des commandes test par exemple.",
      "info_text_image_rights_query": "Si vous activez cette option, une demande supplémentaire s'affiche à chaque fois que vous téléchargez une image. Il vous sera alors demandé de confirmer que vous possédez les droits d'utilisation de l'image téléchargée. Cela nous aide à garantir que toutes les images téléchargées sont légalement correctes et ne violent pas les droits d'auteur.",
      "info_text_pdf_download_option": "Active l'option de téléchargement de PDF dans l'éditeur.",
      "info_text_needs_content_authorization": "Si vous activez la vérification du contenu, toutes les commandes effectuées par les membres de votre entreprise doivent être vérifiées et confirmées manuellement.",
      "info_text_main_language": "Déterminez ici la langue dans laquelle vous souhaitez principalement utiliser l'application. En choisissant une langue, nous adaptons l'interface utilisateur à vos besoins.",
      "info_text_password_renew": "Si vous activez cette option, nous enverrons à l'utilisateur un e-mail de renouvellement du mot de passe lors de sa prochaine connexion.",
      "info_text_editor_auto_save": "Si vous activez cette option, l'éditeur enregistre automatiquement vos modifications toutes les 5 minutes."
    },
    "content_check_status_rejected": "refusé",
    "content_check_status_approved": "approuvé",
    "cart_remaining_user_budget": "Budget restant des utilisateurs",
    "cart_remaining_user_budget_after_order": "Budget utilisateur restant après la commande",
    "cart_budget_user": "Budget des utilisateurs",
    "archived_product_templates": "Modèles archivés",
    "archived_product_templates_description": "Vous trouverez ici tous les modèles archivés",
    "archive_template": "Archiver",
    "restore_template": "Restaurer",
    "budget_value_used": "Utilisé",
    "budget_value_reserved": "Réservé",
    "budget_value_residual": "Valeur résiduelle",
    "double_opt_in": "Procédure de consentement double opt-in",
    "export_video": "Exporter la vidéo",
    "available_actions": "Actions disponibles",
    "mail_text_rhenus": {
      "support": "FAQ & aide",
      "login": "Connexion",
      "thank_you": "Merci beaucoup pour ta commande !",
      "order_foreign_status": {
        "in_delivery": {
          "explanation_first_name_form": "ta commande a été produite et remise au coursier pour livraison.",
          "possible_actions_tracking_first_name_form": "Suis ta livraison sur",
          "possible_actions": "Sous <a href='%domaine%'><span class='r_url bold'>%domaine%</span></a>, tu peux à tout moment consulter ta commande."
        }
      },
      "order_item_sales": {
        "explanation": "tu trouveras ci-dessous la liste des données relatives à ta commande :",
        "possible_actions": "Sous <a href='%domaine%'><span class='r_url bold'>%domaine%</span></a>, tu peux à tout moment consulter ta commande.",
        "call_to_action": "Détails de la commande"
      },
      "order_content_check_status": {
        "explanation": "merci pour ta commande. La commande a été %status% après le contrôle du contenu.",
        "possible_actions": "Sous <a href='%domaine%'><span class='r_url bold'>%domaine%</span></a>, tu peux à tout moment consulter ta commande.",
        "call_to_action": "Détails de la commande"
      },
      "possible_actions_default": "Sous <a href='%domaine%'><span class='r_url bold'>%domaine%</span></a>, tu peux à tout moment consulter ta commande.",
      "call_to_action_order_details_default": "Détails de la commande",
      "contact_form_edition": {
        "subject": "Nouveau contact",
        "info": "Notification de la soumission d'un formulaire de contact. Merci de le traiter rapidement et de veiller à ce que le client reçoive la meilleure assistance possible.",
        "form_fields": "Champs de formulaire remplis"
      },
      "contact_form_cms": {
        "subject": "Nouveau contact de %name%",
        "info": "Notification de la soumission d'un formulaire de contact. Merci de le traiter rapidement et de veiller à ce que le client reçoive la meilleure assistance possible."
      },
      "password_renew": {
        "greeting": "Bonjour %email%,",
        "explanation_first_name_form": "tu as essayé de te connecter à %platformName%. Ton mot de passe a expiré. Tu dois créer un nouveau mot de passe pour pouvoir te connecter à l'avenir."
      },
      "password_reset": {
        "greeting": "Bonjour %email%,",
        "explanation_first_name_form": "un nouveau mot de passe vient d'être demandé à %platformName% via notre plateforme. Si tu as fait cette demande, clique sur le lien ci-dessous."
      }
    },
    "authorized": "Approuvé",
    "open_in_new_tab": "Ouvrir",
    "subscription_set_availability_period": "Définir la période de disponibilité de l'abonnement",
    "failed_video_generation": "La vidéo n'a pas pu être générée ! Veuillez vérifier que les fichiers téléchargés sont valides !",
    "video_uploads_missing": "Aucun téléchargement de vidéo trouvé dans les blocs d'édition !",
    "subscription_video_missing": "L'abonnement à la vidéo n'a pas été trouvé !",
    "download_invoices": "Télécharger les factures",
    "download_user_list": "Télécharger la liste des utilisateurs",
    "cookie_management": "Gestionnaire d'approbation des cookies",
    "cookie_manager_id": "URL du gestionnaire de cookies (facultatif) - ccm19.fr",
    "supported_cookie_manger_provider": "Seuls les Cookie Constent Manager de ccm19.de sont actuellement pris en charge.",
    "cookie_management_custom": "Ce paramètre est facultatif. Un gestionnaire d'autorisation des cookies est intégré par défaut à votre site web.",
    "payment_options": "Paypal / carte de crédit / prélèvement automatique",
    "default_logo_size": "Logo de l'email. Veuillez noter que pour la mise en page standard des e-mails, une taille de logo de ...px x 175px (H x L) est nécessaire. De plus, l'option 'Mail Logo' doit être activée.",
    "lead_authorization": {
      "authorization": "Autorisation",
      "status": "Statut",
      "approved": "approuvé",
      "rejected": "refusé",
      "pending": "en suspens",
      "type": "Type"
    },
    "loading": "Chargement",
    "no_data": "Pas de données",
    "whiteLabel": "WhiteLabel",
    "widget_login_stats": {
      "widget_title": "Statistiques de connexion",
      "number_of_logins_label_title": "Nombre d'inscriptions"
    },
    "widget_edition_stats": {
      "widget_title": "Statistiques de l'édition",
      "social_media_downloads_label_title": "Téléchargements de médias sociaux",
      "total_editions_label_title": "Total des dépenses",
      "amount_of_new_edition_per_date": "Nombre de nouvelles éditions par date",
      "amount_of_new_edition_per_template_per_date": "Nombre de nouvelles éditions par modèle"
    },
    "widget_item_stats": {
      "widget_title": "Statistiques sur les articles",
      "used_coupons_title": "Bons d'achat utilisés",
      "turnovers_title": "Chiffre d'affaires",
      "for_items": "pour les articles",
      "total_used_coupons_count_label_title": "Total des bons utilisés",
      "total_turnovers_count_label_title": "Total des ventes",
      "total_used_coupons_value_label_title": "Valeur totale des bons utilisés",
      "total_turnovers_value_label_title": "Valeur totale des ventes"
    },
    "pipeline_owner": "Propriétaire",
    "set_to_null": "Mettre le propriétaire à zéro"
  }
}