import React, { Suspense, useCallback, useEffect, useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { getSubmitData } from 'helpers/checkout';
import { useSelector, useDispatch } from 'react-redux';
import { intlModule, useFormStatus, authModule } from '@chedri/base';
import { FormattedNumber } from 'react-intl';
import CheckoutProceedButton from 'components/ePayment/CheckoutProceedButton';
import {
  PAYMENT_BILL,
  PAYMENT_PLUGIN_PAYPAL_PLUS,
  PAYMENT_PLUGIN_STRIPE_PLAIN,
  PAYMENT_OPTION_STRIPE_PLAIN,
} from 'js/constants';
import { resetCheckout, initCheckout, updateCheckout } from 'actions/CheckoutActions';
import { getPaymentType, getPaymentNameByType, getPaymentOptionByPlugin } from 'helpers/payment';
import { useHistory } from 'react-router-dom';
import PaymentOptions from 'components/ePayment/PaymentOptions';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { forEach as _forEach } from 'lodash';
import { formStatusDispatchHelper } from 'actions/FormStatusActions';
import { addNotification } from 'actions/NotificationsActions';
import { resetCart } from 'actions/CartActions';
import { getSummaryPositions, isCartTextileQuantitiesValid } from 'helpers/cart';
import CartWarning from 'components/modals/CartWarning';
import Paypal from 'components/layout/paypal/Paypal';
// import globals from 'js/globals';
import CartWizardStep from './CartWizardStep';
import InvoiceTable from '../InvoiceTable';
import CartWizardDelivery from './CartWizardDelivery';
// import CartWizardSplitDelivery from './CartWizardSplitDelivery';
import Headline from '../Headline';
import IconRadioGroup from '../../forms/IconRadioGroup';
import Spinner from '../../Spinner';
import LegalTextHelper from '../LegalTextHelper';
import ModalDealerItemSalesTermsOfService from '../../modals/ModalDealerItemSalesTermsOfService';
import ModalCancellationPolicy from '../../modals/ModalCancellationPolicy';
import ModalPrivacyPolicy from '../../modals/ModalPrivacyPolicy';
import ModalPaypalButtons from '../../modals/ModalPaypalButtons';
import Loader from '../Loader';

CartWizardFinish.propTypes = {
  countMissingDataChecks: PropTypes.number,
  countMissingDomainRegistrations: PropTypes.number,
};

CartWizardFinish.defaultProps = {
  countMissingDataChecks: 0,
  countMissingDomainRegistrations: 0,
};

function CartWizardFinish({ countMissingDataChecks = 0, countMissingDomainRegistrations = 0, countDataCheckSet = 1 }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const username = useSelector(authModule.selectors.getId);
  const userCountry = useSelector(({ auth }) => auth?.profile?.country);
  const appIntl = useSelector(state => state.intl);
  const messages = useSelector(intlModule.selectors.getMessages);
  const cart = useSelector(state => state.cart);
  const isCartPending = useSelector(state => state.cart.pending);
  const owner = useSelector(state => _get(state, 'settings.data.owner.id', null));
  const orderFormStatus = useSelector(state => state.formStatus.orderNew);
  const checkout = useSelector(state => state.checkout);
  const activePaymentPlugin = useSelector(state => state.checkout.activePaymentPlugin);
  // Uppercase constant strings. Example activePaymentPlugin values : "BILL", "STRIPE_PLAIN"
  const remarks = useSelector(state => state.checkout.remarks);
  const paypalClientId = useSelector(state => state.data.projectConfig?.data?.paypal_client_id);
  const altDebtorNumber = useSelector(state => state.auth?.financial?.alt_debtor_number);
  const debtorNumber = useSelector(state => state.auth?.ident?.debtor_number);
  const isOrderNewFormPending = useSelector(state => state?.formStatus?.orderNew?.pending);
  const [canClosePaypal, setCanClosePaypal] = useState(false);
  const [showSalesTerms, setShowSalesTerms] = useState(false);
  const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showMissingDomainRegistration, setShowMissingDomainRegistration] = useState(false);
  const [showMissingDataCheck, setShowMissingDataCheck] = useState(false);
  // const [splitDeliveryWarning, setSplitDeliveryWarning] = useState(0);
  const [showMissingQuantityWarning, setShowMissingQuantityWarning] = useState(false);
  const [showMismatchTextileQuantity, setShowMismatchTextileQuantity] = useState(false);
  const [showMissingAddressWarning, setShowMissingAddressWarning] = useState(false);
  const [showPaypalModal, setShowPaypalModal] = useState(false);
  const [ignoreMissingDatacheck, setIgnoreMissingDatacheck] = useState(false);
  const [billingContactFormDirty, setBillingContactFormDirty] = useState(false);
  const [shippingContactFormDirty, setShippingContactFormDirty] = useState(false);
  const [showBillingContactFormNeedsAttentionWarning, setShowBillingContactFormNeedsAttentionWarning] = useState(false);
  const [showShippingContactFormNeedsAttentionWarning, setShowShippingContactFormNeedsAttentionWarning] = useState(false);
  // const { debug } = globals;

  useEffect(() => {
    // catch event when PayPal modal is open but bad response comes in. Must close the modal.
    if (showPaypalModal && canClosePaypal && !isOrderNewFormPending) {
      setCanClosePaypal(false);
      setShowPaypalModal(false);
    }
  }, [showPaypalModal, canClosePaypal, isOrderNewFormPending]);

  const { available_payment_options: availablePaymentOptions = [] } = cart;
  // in example: [ "bill", "stripePlain", "PAYPAL_PLUS" ]

  const positions = getSummaryPositions(cart, messages);

  let countMissingQuantity = 0;

  const nullOrders = [];

  _forEach(positions, ({ quantity, info, label }, i) => {
    if (quantity === null) {
      if (info !== 'PDF Download' && info !== 'Website') {
        countMissingQuantity = 1;
        const coordsPosition = i + 1;
        const name = label?.name ?? label[0]?.name ?? label;

        nullOrders.push(
          <div key={name}>
            <a
              href={appIntl.urlPrefix + messages.missingQuantity.cart + '#' + coordsPosition}
              onClick={() => {
                disableShowMissingQuantityWarning();
              }}
            >
              <p className="text-center" style={{ color: '#CF1719' }}>
                <span>
                  {coordsPosition}. Position {name} x 0 -{' '}
                </span>{' '}
                <span> {messages.hint_missing_order_quantity}</span>
              </p>
            </a>
          </div>
        );
      }
    }
  });

  // are Invoice and Shipping addresses defined?
  const {
    invoice_address: invoiceAddress,
    shipping_address: shippingAddress,
    use_invoice_address_for_shipping: useInvoiceAddressForShipping,
  } = cart;

  const isAddressesFilled = invoiceAddress && (shippingAddress || useInvoiceAddressForShipping);

  const setRemarks = useCallback(remarks => {
    dispatch(updateCheckout({ remarks }));
  });

  const setPaymentMethod = useCallback(methodType => {
    dispatch(
      updateCheckout({
        activePaymentPlugin: methodType,
        payment: getPaymentType(methodType),
      })
    );
  });

  const showError = useCallback((message, code = 400) => {
    dispatch(addNotification(code, message));
  });

  const validate = useCallback(() => {
    return Promise.resolve({ ...checkout, ...cart });
  }, [checkout, cart]);

  const placeAnOrder = useCallback(data => {
    formStatusDispatchHelper('orderNew', '/api/orders.json', data, 'post', dispatch, 1);
  });

  /**
   * Verify validity of the selected payment plugin, adjust it if necessary
   */
  useEffect(() => {
    const ePaymentTotal = cart.total_gross;

    if (ePaymentTotal > 0 && availablePaymentOptions.length > 0 && activePaymentPlugin) {
      const paymentOptionName = getPaymentOptionByPlugin(activePaymentPlugin);
      if (availablePaymentOptions.includes(paymentOptionName)) {
        // valid option
        return;
      }

      // need to change the active payment method
      const defaultBill = 'bill';
      const defaultPlugin = PAYMENT_BILL;

      const paymentType = availablePaymentOptions.includes(defaultBill) ? defaultBill : availablePaymentOptions[0];
      const pluginName = getPaymentNameByType(paymentType) ?? defaultPlugin;

      setPaymentMethod(pluginName);
    }
  }, [availablePaymentOptions, activePaymentPlugin]);

  // function changeSplitDeliveryWarning(change) {
  //   setSplitDeliveryWarning(splitDeliveryWarning + change);
  // }
  const enableShowMissingDomainRegistration = () => {
    setShowMissingDomainRegistration(true);
  };
  const disableShowMissingDomainRegistration = () => {
    setShowMissingDomainRegistration(false);
  };
  const enableShowMissingDataCheck = () => {
    setShowMissingDataCheck(true);
  };
  const disableShowMissingDataCheck = () => {
    setShowMissingDataCheck(false);
  };
  const enableShowMissingQuantityWarning = () => {
    setShowMissingQuantityWarning(true);
  };
  const enableShowBillingContactFormNeedsAttentionWarning = () => {
    setShowBillingContactFormNeedsAttentionWarning(true);
  };
  const enableShowShippingContactFormNeedsAttentionWarning = () => {
    setShowShippingContactFormNeedsAttentionWarning(true);
  };
  const handleBillingContactFormDirty = (status) => {
    setBillingContactFormDirty(status);
  };
  const handleShippingContactFormDirty = (status) => {
    setShippingContactFormDirty(status);
  };
  const disableShowMissingQuantityWarning = () => {
    setShowMissingQuantityWarning(false);
  };
  const enableShowMismatchTextileQuantity = () => {
    setShowMismatchTextileQuantity(true);
  };
  const disableShowMismatchTextileQuantity = () => {
    setShowMismatchTextileQuantity(false);
  };
  const enableShowMissingAddressWarning = () => setShowMissingAddressWarning(true);

  useEffect(() => {
    // init checkout
    dispatch(
      resetCheckout(
        initCheckout([], null, {
          activePaymentPlugin: PAYMENT_BILL,
          payment: getPaymentType(PAYMENT_BILL),
        })
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      updateCheckout({
        availablePlugins: availablePaymentOptions,
      })
    );
  }, [availablePaymentOptions]);

  useFormStatus(
    orderFormStatus,
    () => {
      history.replace(appIntl.urlPrefix + 'orderComplete');
      dispatch(resetCart());
    },
    () => {
      if (!_isEmpty(orderFormStatus.errors) && orderFormStatus.errors.message) {
        showError(orderFormStatus.errors.message, orderFormStatus.errors.code || 400);
      } else {
        showError(messages.unknown_error);
      }
    }
  );

  const paymentModal = () => {
    // display a modal with PayPal buttons
    setShowPaypalModal(true);
  };

  const textileQuantityMismatch = !isCartTextileQuantitiesValid(cart);

  const onSubmitHandler =
    countMissingQuantity > 0
      ? enableShowMissingQuantityWarning
      : textileQuantityMismatch
      ? enableShowMismatchTextileQuantity
      : billingContactFormDirty
      ? enableShowBillingContactFormNeedsAttentionWarning
      : shippingContactFormDirty
      ? enableShowShippingContactFormNeedsAttentionWarning
      : countMissingDataChecks > 0 && countDataCheckSet > 0
      ? enableShowMissingDataCheck
      : countMissingDomainRegistrations > 0
      ? enableShowMissingDomainRegistration
      : !isAddressesFilled
      ? enableShowMissingAddressWarning
      : activePaymentPlugin === PAYMENT_PLUGIN_PAYPAL_PLUS
      ? paymentModal
      : placeAnOrder;

  const onSubmitHandlerAfterDatacheckModal = (...args) => {
    // hide missing-datacheck warning modal
    disableShowMissingDataCheck();

    // do not "disable" because of missing datachecks
    setIgnoreMissingDatacheck(true);

    const action =
      countMissingDomainRegistrations > 0
        ? enableShowMissingDomainRegistration
        : !isAddressesFilled
        ? enableShowMissingAddressWarning
        : activePaymentPlugin === PAYMENT_PLUGIN_PAYPAL_PLUS
        ? paymentModal
        : placeAnOrder;

    action(...args);
  };

  const isDatacheckOkay = countMissingDataChecks === 0 || ignoreMissingDatacheck || countDataCheckSet === 0;
  const canPlaceAnOrder =
    isAddressesFilled && !(countMissingQuantity || !isDatacheckOkay || countMissingDomainRegistrations);

  /**
   * Callback from Paypal onApprove event handler
   * Transaction is authorized and captured by now.
   * Save PayPal response and proceed saving the order
   * with redirect to the ThankYou page.
   *
   * @param {*} authResult
   */
  const sendPaypalReply = paypalResult => {
    if (!orderFormStatus.pending) {
      validate()
        .then(data => {
          const submitData = getSubmitData(data, checkout);

          // see the structure in helpers/checkout:
          submitData.activePaymentPlugin = PAYMENT_PLUGIN_PAYPAL_PLUS;
          submitData.payment = {
            plugin: PAYMENT_PLUGIN_PAYPAL_PLUS,
            type: getPaymentType(PAYMENT_PLUGIN_PAYPAL_PLUS),
            paypalResult,
          };

          placeAnOrder(submitData);
          setCanClosePaypal(true);
        })
        .catch(console.error);
    } else {
      console.error('Paypal post-processing declined while form is pending');
    }
  };

  // Payment method choices
  const paymentOptions = [];
  if (availablePaymentOptions.includes(PAYMENT_OPTION_STRIPE_PLAIN)) {
    paymentOptions.push({
      value: PAYMENT_PLUGIN_STRIPE_PLAIN,
      className: 'wizard-payment-method',
      label: (
        <div className="wizard-payment-method__cards">
          <img src="/img/mastercard.png" alt="MasterCard" title="MasterCard" className="m-r-5" />
          <img src="/img/visa.png" alt="Visa" title="Visa" />
        </div>
      ),
    });
  }
  if (availablePaymentOptions.includes('bill')) {
    paymentOptions.push({
      value: PAYMENT_BILL,
      className: 'wizard-payment-method',
      label: messages.payment_bill,
    });
  }
  if (availablePaymentOptions.includes(PAYMENT_PLUGIN_PAYPAL_PLUS)) {
    paymentOptions.push({
      value: PAYMENT_PLUGIN_PAYPAL_PLUS,
      className: 'wizard-payment-method',
      label: messages.payment_options,
    });
  }

  const paypalOptions = {
    cartId: cart.id,
    paypalClientId,
    buyerCountry: userCountry,
    altDebtorNumber,
    debtorNumber,
    disabled: !canPlaceAnOrder,
    positions,
    sumNet: cart.sum_net,
    totalNet: cart.total_net,
    vatRates: cart.total_vat_rates,
    totalGross: cart.total_gross,
    discountNet: cart.discount_net,
    transportationCost: cart.total_shipping_price,
    currency: cart.currency,
    sendPaypalReply,
    debug: false, // @paypal/react-paypal-js internal debugging
  };



  return (
    <div className="wizard-finish">
      <h1>{messages.complete_order}</h1>
      <div className="wizard-step">
        <CartWizardStep.Frame>
          <CartWizardStep.Body>
            <CartWizardDelivery
              billingContactFormNeedsAttention={handleShippingContactFormDirty}
              shippingContactFormNeedsAttention={handleShippingContactFormDirty}  />
            {/*
              <div className="m-t-30">
                <CartWizardSplitDelivery setSplitDeliveryWarning={changeSplitDeliveryWarning} />
              </div>
            */}
          </CartWizardStep.Body>
        </CartWizardStep.Frame>
        <CartWizardStep.Frame>
          <CartWizardStep.Body>
            <div className="wizard-payment">
              <div className="wizard-payment-body">
                <Headline description={messages.payment_method_description}>{messages.payment_method}</Headline>
                <div>
                  <IconRadioGroup
                    name="payment-method"
                    variant="primary"
                    data={paymentOptions}
                    value={activePaymentPlugin}
                    onChange={setPaymentMethod}
                  />
                </div>
                {activePaymentPlugin === PAYMENT_PLUGIN_STRIPE_PLAIN && (
                  <div>
                    <PaymentOptions
                      clientId={username}
                      ePaymentTotal={cart.total_gross}
                      currency={cart.currency}
                      showPaymentSelector={false}
                    />
                  </div>
                )}
              </div>
              <div className="wizard-payment-body">
                <div className="invoice-simple m-t-20">
                  <div className="label">{messages.total_gross}</div>
                  <div className="value m-t-5">
                    <FormattedNumber value={cart.total_gross} style="currency" currency={cart.currency} />
                  </div>
                </div>
              </div>
            </div>
            <div className="wizard-payment">
              {/*
                <div className="wizard-payment-body wizard-payment-body__flex">
                  <Headline
                    description={
                      <>
                        <span className="bold">{messages.cart_hint} </span>
                        <span>
                          {messages.cart_hint_description_part_1}
                          <span className="bold">&quot;{messages.cart_hint_description_part_2}&quot;</span>{' '}
                          {messages.cart_hint_description_part_3}
                        </span>
                      </>
                    }
                  >
                    {messages.your_message}
                  </Headline>
                  <textarea
                    value={remarks}
                    className="wizard-comment-textarea"
                    name="headers"
                    onChange={e => setRemarks(e.target.value)}
                  />
                </div>
              */}
              <div className="wizard-payment-body">
                <InvoiceTable
                  positions={positions}
                  sumNet={cart.sum_net}
                  totalNet={cart.total_net}
                  vatRates={cart.total_vat_rates}
                  totalGross={cart.total_gross}
                  discountNet={cart.discount_net}
                  transportationCost={cart.total_shipping_price}
                  currency={cart.currency}
                />
              </div>
            </div>
            <div className="m-t-20 m-b-10 wizard-terms-and-conditions">
              <LegalTextHelper
                text={messages.checkout_buy_order_legal_text}
                substitutes={{
                  '{company}': 'Creacheck GmbH',
                  '{general_terms_and_conditions}': (
                    /*
                    <a href="#showTerms" className="text-master b-b" onClick={() => setShowSalesTerms(true)}>
                      {messages.signup_agreement_text_terms}
                    </a>
                     */
                    <a href={appIntl.urlPrefix + 'terms'} target="_blank" rel="noopener noreferrer">
                      {messages.signup_agreement_text_terms}
                    </a>
                  ),
                  '{cancellation_policy_and_cancellation_form}': (
                    /*
                    <a
                      href="#showCancellationPolicy"
                      className="text-master b-b"
                      onClick={() => setShowCancellationPolicy(true)}
                    >
                      {messages.signup_agreement_text_cancellation_policy_and_form}
                    </a>
                     */
                    <a href={appIntl.urlPrefix + 'terms#wiru'} target="_blank" rel="noopener noreferrer">
                      {messages.signup_agreement_text_cancellation_policy_and_form}
                    </a>
                  ),
                  '{privacy_policy}': (
                    /*
                    <a href="#showPrivacyPolicy" className="text-master b-b" onClick={() => setShowPrivacyPolicy(true)}>
                      {messages.signup_agreement_text_data_use}
                    </a>
                     */
                    <a href={appIntl.urlPrefix + 'privacy'} target="_blank" rel="noopener noreferrer">
                      {messages.signup_agreement_text_data_use}
                    </a>
                  ),
                }}
              />
              <ModalDealerItemSalesTermsOfService
                show={showSalesTerms}
                close={() => setShowSalesTerms(false)}
                owner={owner}
              />
              <ModalCancellationPolicy
                show={showCancellationPolicy}
                close={() => setShowCancellationPolicy(false)}
                owner={owner}
              />
              <ModalPrivacyPolicy show={showPrivacyPolicy} close={() => setShowPrivacyPolicy(false)} owner={owner} />
              <ModalPaypalButtons show={showPaypalModal} onHide={() => setShowPaypalModal(false)}>
                <Paypal {...paypalOptions} />
              </ModalPaypalButtons>
            </div>
            {isCartPending && (
              <div className="wizard-body__pending">
                <Spinner />
              </div>
            )}
          </CartWizardStep.Body>
          <Suspense
            fallback={
              <li>
                <Loader />
              </li>
            }
          >
            <div className="checkout-submit">
              <CheckoutProceedButton
                bsStyle="primary"
                className="pull-right btn-animated from-left fa fa-check"
                iconClassName="none"
                disabled={orderFormStatus.pending || cart.sum_net < 0}
                label={messages.commit_to_buy}
                onSubmit={onSubmitHandler}
                formStatus={orderFormStatus}
                raiseError={showError}
                validate={validate}
              />
            </div>
          </Suspense>
        </CartWizardStep.Frame>
      </div>
      {showMissingDomainRegistration && (
        <CartWarning isShow={showMissingDomainRegistration} onHide={disableShowMissingDomainRegistration} />
      )}
      {showMissingDataCheck && (
        <CartWarning isShow={showMissingDataCheck} onHide={disableShowMissingDataCheck}>
          <br /><br />
          <p className="text-center">{messages.hint_dataproof_1}</p>
          <p className="text-center">{messages.hint_dataproof_2}</p>
          <p className="text-center">
            <br />
            <br />
            {messages.hint_dataproof_3}
          </p>
          <p className="text-center">
            <br />
            <br />
            <Button bsStyle="primary" bsSize="large" onClick={disableShowMissingDataCheck}>
              {messages.back_to_cart}
            </Button>{' '}
            <CheckoutProceedButton
              bsStyle="warning"
              bsSize="large"
              className="btn-animated"
              iconClassName="none"
              disabled={orderFormStatus.pending || cart.sum_net < 0}
              label={messages.commit_to_buy}
              onSubmit={onSubmitHandlerAfterDatacheckModal}
              formStatus={orderFormStatus}
              raiseError={showError}
              validate={validate}
            />
          </p>
        </CartWarning>
      )}
      {showMissingQuantityWarning && (
        <CartWarning isShow={showMissingQuantityWarning} onHide={disableShowMissingQuantityWarning}>
          <br /><br />
          <p className="text-center">{messages.missing_order_quantity}</p>
          {nullOrders}
        </CartWarning>
      )}
      {showMismatchTextileQuantity && (
        <CartWarning isShow={showMismatchTextileQuantity} onHide={disableShowMismatchTextileQuantity}>
          <p className="text-center">{messages.textile_order_quantity_mismatch}</p>
        </CartWarning>
      )}
      {showMissingAddressWarning && (
        <CartWarning isShow={showMissingAddressWarning} onHide={() => setShowMissingAddressWarning(false)}>
          <br /><br />
          <p className="text-center">{messages.missing_invoice_shipping_address}</p>
        </CartWarning>
      )}
      {showBillingContactFormNeedsAttentionWarning && (
        <CartWarning isShow={showBillingContactFormNeedsAttentionWarning} onHide={() => setShowBillingContactFormNeedsAttentionWarning(false)}>
          <br/><br/>
          <p className="text-center">{messages.billing_address_form_is_not_submitted}</p>
        </CartWarning>
      )}
      {showShippingContactFormNeedsAttentionWarning && (
        <CartWarning isShow={showShippingContactFormNeedsAttentionWarning} onHide={() => setShowShippingContactFormNeedsAttentionWarning(false)}>
          <br/><br/>
          <p className="text-center">{messages.shipping_address_form_is_not_submitted}</p>
        </CartWarning>
      )}
    </div>
  );
}

export default CartWizardFinish;
