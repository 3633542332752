/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-unused-vars */
import React from 'react';

export const locale = {
  "locales": ["en"],
  "messages": {
    "editor": {
      "first_name": "First name",
      "name": "Name",
      "subject": "Subject",
      "message": "Message",
      "custom_dropdown": "Customised dropdown",
      "custom_label": "Own labelling",
      "set_autoplay": "Autoplay",
      "autoplay_info": "Attention! Some browsers do not allow autoplay in most cases. However, muted autoplay is always permitted.",
      "set_muted": "Mute",
      "data_protection_officer": "Data Protection Officer",
      "data_protection_authority": "Data protection authority",
      "button_font_color": "Font colour",
      "set_label_dropdown": "Please assign a label for the dropdown",
      "set_label_custom_field": "Please assign a label for the input field",
      "empty_field": "Free field",
      "custom_field": "User-defined field",
      "change_label": "Change label",
      "edit_label": "Edit label",
      "salutation": "Salutation",
      "title": "Title",
      "link_title": "Link name",
      "main_text": "Main text",
      "more_text": "further text",
      "create_text": "Create text",
      "edit_text_slider": "No text element has been created yet",
      "edit_rss_elements": "No mould elements selected yet, click here to add.",
      "rss_settings": "RSS feed settings",
      "select_rss": "You can select an RSS feed from the templates",
      "available_rss": "Available RSS Feeds",
      "load_rss_part_1": "You have selected the following RSS feed.",
      "load_rss_part_2": "Click on the 'Get RSS Feeds' button to load the feeds",
      "load_rss_feeds": "Retrieve RSS Feeds",
      "color": "Colour",
      "background_color": "Background colour",
      "distance_to_border": "Distance to the edge",
      "color_of_qrcode": "QR code colour",
      "click_me": "Click me",
      "new_menu_item": "New menu item",
      "external": "external",
      "imprint": "Imprint",
      "privacy": "Data protection",
      "january": "January",
      "february": "february",
      "march": "March",
      "april": "April",
      "may": "May",
      "june": "June",
      "july": "July",
      "august": "August",
      "september": "September",
      "october": "october",
      "november": "November",
      "december": "December",
      "monday": "Monday",
      "tuesday": "Tuesday",
      "wednesday": "Wednesday",
      "thursday": "Thursday",
      "friday": "Friday",
      "saturday": "Saturday",
      "sunday": "Sunday",
      "edit_date": "Edit appointment",
      "oclock": "Clock",
      "filter_selection": "Filter selection",
      "use_filter": "Apply",
      "sample_picture_found": "Sample image found",
      "already_edited_in_another_tab": "This edition may already be edited in another tab.",
      "information_for_contact": "Data for contact form",
      "mail_receiver": "E-mail recipient",
      "google_analytics": "Google Analytics",
      "measurement_id": "Measurement ID for Google Analytics",
      "new_picture_inserted": "New picture inserted",
      "text_changed": "Text changed",
      "edit_title": "Click here to edit the title",
      "edit_description": "Click here to edit the image description",
      "edit_copyright": "Click here to edit the copyright",
      "no_changes": "No changes",
      "changes_on_document": "Changes to the document",
      "changes_on_page": "Changes on page",
      "page": "Page",
      "choose_button_label": "Please select a label for the button",
      "edit_calendly": "Click here to edit the settings for Calendly",
      "exchanged_with_page": "exchanged with page",
      "upload_failed": "Upload failed",
      "try_again": "Please try again",
      "saving_failed": "Save failed",
      "new_page_inserted": " new page/s inserted after page",
      "delete_pages": "Delete pages",
      "all_content_deleted": "Pages and all content on them will be deleted.",
      "yes_delete": "Yes, delete",
      "pages_deleted": " Page/s deleted, by page",
      "rather_not": "Better not",
      "new_here": "Are you new here?",
      "first_start": "It looks like you have opened our new editor for the first time. Would you like an introduction?",
      "yes_absolutely": "Yes, absolutely",
      "im_fine": "I can manage",
      "no_page_definitions_found": "No page definitions found!",
      "move_up": " Move page(s) up",
      "side_position_fixed": "Side position fixed",
      "generated_in_pdf": "Pages are automatically generated in the PDF",
      "move_down": " Move page(s) down",
      "by": "To",
      "add_page": " Insert pages",
      "remove_page": " Remove pages",
      "unsaved_changes": "You have unsaved changes",
      "i_have_it": "Ok, now I've got it!",
      "document_pictures": "Images in the document",
      "used_images": "Images used",
      "take_a_photo": "Take a picture",
      "unused_images": "Unused images",
      "content_is_saved": "Contents are saved",
      "page_arrangement": "Side arrangement",
      "print_sheet_view": "Print sheet view",
      "view_safety_distance": "View of safety distance",
      "view_structure": "Structure view",
      "start_tutorial": "Start tutorial",
      "adjust_by_window": "Adapt page to window",
      "adjust_by_width": "Adjust page to width",
      "adjust_by_height": "Adjust page to height",
      "delete_content": "Delete content",
      "delete_selected_content_block": "This removes the selected content block and its contents (images, texts).",
      "delete_children_of_parents": "Please note that this is a parent element. If you delete this, all associated child elements will also be deleted.",
      "reset_content": "Reset content",
      "reset_content_block_to_root": "This resets the selected content block and its contents (images, texts) to their original state.",
      "yes_reset": "Yes, reset",
      "copy_full_content": "Copy entire page content",
      "paste_content": "Paste content from clipboard",
      "enlarge_block": "Enlarge block",
      "shrink_block": "Reduce block size",
      "text_overflow": "Text overflow",
      "initial_image_available": " Initial image available",
      "paste_content_after": "Paste content from clipboard after these",
      "deselect_element": "Deselect element",
      "select_element": "Select element",
      "block_can_not_move": "Block cannot be moved",
      "up": "To the top",
      "reset_block": "Reset block",
      "change_backgroundcolor": "Change background colour",
      "change_fontcolor": "Change font colour",
      "copy_block": "Copy block",
      "delete_block": "Delete block",
      "down": "Downwards",
      "product_in_cart": "Product in shopping basket",
      "added_to_cart": " has been added to your shopping basket.",
      "great": "Great!",
      "to_cart": "To the shopping basket",
      "are_you_sure": "Are you sure?",
      "reset_to_selected_status": "The document is reset to the selected status",
      "abort": "Cancel",
      "reset_to_version": "Reset to version",
      "choose_a_campaign": "Select campaign",
      "name_for_edition": "Name for this edition",
      "save_to": "Save as...",
      "save": "Save",
      "settings": "Settings",
      "short_summary": "Brief summary",
      "keywords_separated": "Keywords separated by a comma",
      "serial_letter": "Mail merge",
      "activate_serial_letter": "Activate mail merge",
      "serial_letter_database": "Mail merge database",
      "serial_letter_database_upload_info": "The upload supports the following file formats - .xlsx, .xls and .csv",
      "serial_letter_info": "Please enter the placeholders in the cells of the first row in the database. Below this, enter the respective values. For example as follows:",
      "preview_of_database": "Database preview",
      "serial_letter_database_upload": "Upload",
      "no_error": "Without error",
      "error": "Error",
      "information_for_imprint": "Information for imprint",
      "responsible_for_content": "Responsible for content",
      "company_association": "Company / Association",
      "street": "Street",
      "zip_code": "Postcode",
      "city": "Place",
      "email": "E-mail address",
      "phone_number": "Telephone",
      "data_protection_information": "Information on data protection",
      "responsible": "Person responsible",
      "change": "Change",
      "export_image": "Export image",
      "preview": "Preview",
      "preview_button": "Button preview",
      "print_pdf": "Print PDF",
      "initialize": "Initialise!",
      "order": "Order",
      "versions": "Versions",
      "currently_used_image": "Currently used image",
      "upload_new_image": "Upload new image",
      "new_cut": "Recut",
      "take_on": "Take over",
      "choose_file": "Select file",
      "image_description": "Image description",
      "image_upload": "Image upload",
      "mediathek": "Media library",
      "pixabay": "Pixabay",
      "add_content": "Add content",
      "copied_content_block": "Content block copied",
      "unsaved_content": "You have unsaved changes! Do you really want to leave the editor?",
      "filter_settings": "Filter settings",
      "filter_grayscale": "Greyscale",
      "filter_sepia": "Sepia",
      "filter_blur": "blur",
      "filter_brightness": "Brightness",
      "filter_contrast": "Contrast",
      "filter_hueRotate": "Rotate colour tone",
      "filter_invert": "Invert colours",
      "filter_opacity": "Transparency",
      "filter_saturate": "Saturation",
      "filter_default": "Standard",
      "filter_set": "Discontinued",
      "work_surface": "The work surface is located here.",
      "work_surface_description": "This is where the design process takes place. Images, texts and elements can be inserted and edited here.",
      "page_navigation": "Here you will find the page navigation",
      "page_navigation_description": "Here you can switch between the individual pages and add, remove or swap pages.",
      "content_blocks": "Here you will find the content blocks",
      "content_blocks_description": "Here you can insert various image and text elements into your advertising material by clicking on them. If there is not enough space on the advertising material, you can delete superfluous elements at any time.",
      "screen_view": "You can change the screen view here.",
      "screen_view_description": "Here you can change the view of your web media. 100% always represents the original size of the advertising material.",
      "error_display": "Errors are displayed here.",
      "error_display_description": "The system notifies you here if a text overflow or other technical errors are found.",
      "options": "Here you will find options for your advertising material.",
      "options_description": "Click on the preview button to generate a preview PDF or on the order button to place the advertising material in the shopping basket. With 'Save as' you can copy your advertising material to another campaign.",
      "tutorial_end": "That was our editor in a nutshell!",
      "tutorial_end_description": "Now you are able to quickly and easily design your advertising material in compliance with the corporate design.",
      "content_block_copied": "Content block copied",
      "content_blocks_switched": "Content blocks swapped",
      "content_block_reset": "Content block reset",
      "content_block_resize": "Changed content block size",
      "content_block_removed": "Content block deleted",
      "no_place": "No room",
      "content_block_inserted": "Content block inserted",
      "no_dates_available": "No dates available, click here to add one.",
      "new_date": "New date",
      "edit_dates": "Edit appointments",
      "date_description": "Description of the appointment",
      "add": "Add",
      "no_dates_availableTwo": "No dates available",
      "date_title": "Title of the appointment",
      "date_place": "Location of the appointment",
      "date_from": "Am/Dn",
      "date_to": "Until",
      "no_forms": "No mould elements selected yet, click here to add",
      "read_privacy_policy": "I have read and understood the privacy policy.",
      "contact_form_settings": "Contact form settings",
      "dropdown_settings": "Dropdown settings",
      "add_options": "Add options",
      "dropdown_label": "Dropdown label",
      "dropdown_option_fields": "Dropdown radio buttons",
      "pls_choose": "Please select a form element",
      "pls_chooseTwo": "Please select your desired contact form elements",
      "send": "Sending",
      "google_maps_element_settings": "Location settings Google Maps",
      "no_location": "You have not yet added an address, click here to edit",
      "found_textoverflow": "Text overflow found",
      "minimum_size": "Minimum size",
      "too_low_resolution": "Picture has too low resolution!",
      "set_scale_free": "Activate free scaling",
      "unset_scale_free": "Deactivate free scaling",
      "no_images_selected": "No pictures selected yet, click here to add",
      "gallery_settings": "Gallery settings",
      "add_image": "Add image",
      "add_image_front": "Add picture at the beginning",
      "add_image_back": "Add picture at the end",
      "slider_images_invert": "Invert picture order",
      "image_text_settings": "Image text settings",
      "change_slider": "To change the title or description, click on the respective text in the slider",
      "change_image_title": "Change picture title",
      "change_image_description": "Change image description",
      "image_copyright": "Image copyright",
      "slider_settings": "Slider settings",
      "new_titel": "New title",
      "new_link_title": "New link title",
      "edit_text_title": "Edit title",
      "edit_text_link": "Edit link",
      "edit_link_title": "Edit link title",
      "edit_text_text": "Edit text",
      "add_element": "Add element",
      "add_element_before": "Add element at the front",
      "add_element_after": "Add element at the back",
      "edit_text_of_element": "To edit the texts, click on the respective text in the slider",
      "invert_elements": "Invert sequence",
      "edit_nav": "Edit navigation",
      "term": "Designation",
      "link": "Link",
      "actions": "Actions",
      "content_of_QRCode": "What content should be included in the QR code?",
      "address_of_website": "Enter the address of the website:",
      "name_surname": "First name and surname",
      "business_card": "Business card",
      "must_url": "Must be a URL",
      "position": "Position",
      "phone": "Telephone",
      "website": "Website",
      "address": "Address",
      "email_message": "E-mail message",
      "text": "Text",
      "recipient_email_address": "Recipient e-mail address",
      "reference": "Subject",
      "content": "Contents",
      "your_text": "Your text",
      "add_QRCode": "Insert QR code",
      "no_preview_of_twitter": "Unfortunately it is not possible to display Twitter here, please use the website preview function",
      "no_preview_of_calendly": "Unfortunately it is not possible to display Calendly here, please use the website preview function",
      "twitter_link": "Posted Twitter link:",
      "social_media_settings": "Social media settings",
      "calendly_settings": "Calendly settings",
      "button_settings": "Button settings",
      "action_button_content_text": "Click here to edit the settings for the action button",
      "action_button_link_element": "Reference to an element",
      "action_button_external_link": "Reference to an external link",
      "action_button_or": "or",
      "button_color": "Button colour",
      "add_social_media_account": "No social media account added yet, click here to select one.",
      "choice_of_provider": "Selection of social media providers",
      "embed_code": "Embed code from social media channel",
      "link_to_your_page": "Link to your page at",
      "link_to_your_calendly_page": "Link to your Calendly page",
      "link_to_your_page_two": "Link to your page",
      "video_settings": "Video settings",
      "add_video": "No video selected yet, click here to insert one",
      "choice_video_provider": "Selection of video providers",
      "upload_video": "Upload video",
      "choice_mp4_file": "Select video (.mp4) file",
      "nav_type": "Type",
      "nav_type_url": "URL",
      "nav_type_page": "Page",
      "nav_type_page_prefix": "Page"
    },
    "medialibrary": {
      "new_folder": "New folder",
      "sorting": "Sorting",
      "directories": "Directories",
      "medialibrary": "Media library",
      "media": "Media",
      "upload_media": "Upload media",
      "sorting_description": "Please note that the sorting is based on the file name and not on the defined title.",
      "sorting_info_text": "Select a sort order for the folders and media. The sorting set also applies within all folder views.",
      "paste": "Insert",
      "info": "Please note!",
      "info_text": "One or more files already exist. These have not been uploaded again.",
      "back_to_root": "Back to the root directory",
      "directory_by": "Directory of",
      "created_by": "Created by",
      "no_folder_created": "No folders have been created",
      "no_media_found": "No media in the folder",
      "upload_processing": "Your uploads are processed",
      "not_uploaded": "Media could not be uploaded!",
      "close": "Close",
      "preview": "Image/video preview",
      "name": "Name",
      "upload_info": "Info",
      "options": "Options",
      "status": "Status",
      "adjust_meta": "Customise metadata",
      "title": "Title",
      "description": "Description of the",
      "save": "Save",
      "create_new_directory": "Create new directory",
      "directory_name": "Directory name",
      "rename_folder": "Rename folder",
      "copyright": "Copyright",
      "upload": "Upload",
      "wastebasket": "Wastepaper basket",
      "are_you_sure_erase_file": "Are you sure you want to permanently delete the file?",
      "are_you_sure_erase_folder": "Are you sure you want to delete the folder irrevocably?",
      "erase": "Delete"
    },
    "meta": {
      "campaigns": {
        "title": "Campaigns",
        "description": "Campaigns",
        "name": "Campaigns"
      },
      "productShop": {
        "title": "Shop",
        "description": "Are you looking for merchandise? Then you've come to the right place.",
        "name": "Article"
      },
      "carShop": {
        "title": "Vehicles shop",
        "description": "Looking for your dream car? Then take a look at our vehicles now.",
        "name": "Vehicles"
      },
      "car_detail_search": {
        "title": "Not sure which car suits you? Use our car search to find the perfect car",
        "description": "Are you looking for a car with specific requirements, but don't know exactly which car you need? Select vehicle type, drivetrain and equipment level.",
        "name": "Detailed search"
      },
      "car_brands": {
        "title": "Select your brand",
        "description": "Choose your brand and discover the best offers on the market.",
        "name": "Brand selection"
      },
      "car_models": {
        "title": "Select the model of your {brandName}",
        "description": "Choose a model from the most popular {modelOne} to {modelTwo}.",
        "name": "Models"
      },
      "car_model_drive": {
        "title": "Select the fuel type and transmission for your {brandName} {modelName}",
        "description": "We have the {brandName} {modelName} with {fuelTypes} engines. Available gearbox types are {gearboxTypes}.",
        "name": "Fuel type"
      },
      "car_model_equipment": {
        "title": "Select the equipment level for your {brandName} {modelName}",
        "description": "Choose from Budget, Family, Comfort or Business. The most popular, Comfort, offers parking sensors, Bluetooth and more.",
        "name": "Equipment"
      },
      "favorites": {
        "title": "Your favourites, saved for later",
        "description": "It's great that you've saved some of our best items for later. Now try selecting the one you want.",
        "name": "Favourites"
      },
      "cart": {
        "title": "Shopping basket",
        "description": "Shopping basket",
        "name": "Shopping basket"
      },
      "checkout": {
        "title": "Cash desk",
        "description": "Cash desk",
        "name": "Cash desk"
      },
      "clients": {
        "title": "Customers",
        "description": "Customers",
        "name": "Customers"
      },
      "client_details": {
        "title": "Company",
        "description": "Company",
        "name": "Company"
      },
      "leads": {
        "title": "Leads",
        "description": "Leads",
        "name": "Leads"
      },
      "lead_details": {
        "title": "Lead",
        "description": "Lead",
        "name": "Lead"
      },
      "cars": {
        "title": "Vehicles",
        "description": "Vehicles",
        "name": "Vehicles"
      },
      "car_overview": {
        "title": "Vehicle overview",
        "description": "Vehicle overview",
        "name": "Vehicle overview"
      },
      "car_details": {
        "title": "Vehicle",
        "description": "Vehicle",
        "name": "Vehicle"
      },
      "pages": {
        "title": "Page",
        "description": "Page",
        "name": "Page"
      },
      "page_details": {
        "title": "Page",
        "description": "Page",
        "name": "Page"
      },
      "profile": {
        "title": "Profile",
        "description": "Profile",
        "name": "Profile"
      },
      "locations": {
        "title": "Locations",
        "description": "Locations",
        "name": "Locations"
      },
      "company_locations": {
        "title": "Locations",
        "description": "Locations",
        "name": "Locations"
      },
      "data_push": {
        "title": "Data push",
        "description": "Data push",
        "name": "Data push"
      },
      "white_label_projects": {
        "title": "White label projects",
        "description": "White label projects",
        "name": "White label projects"
      },
      "users": {
        "title": "Users",
        "description": "Users",
        "name": "Users"
      },
      "user_details": {
        "title": "Users",
        "description": "Users",
        "name": "Users"
      },
      "user_import": {
        "title": "Import users",
        "description": "Import users",
        "name": "Import users"
      },
      "teams": {
        "title": "Teams",
        "description": "Teams",
        "name": "Teams"
      },
      "questionnaires": {
        "title": "Questionnaires",
        "description": "Questionnaires",
        "name": "Questionnaires"
      },
      "questionnaire_details": {
        "title": "Questionnaire",
        "description": "Questionnaire",
        "name": "Questionnaire"
      },
      "document_types": {
        "title": "Document types",
        "description": "Document types",
        "name": "Document types"
      },
      "document_type_details": {
        "title": "Document type",
        "description": "Document type",
        "name": "Document type"
      },
      "templates": {
        "title": "Templates",
        "description": "Templates",
        "name": "Templates"
      },
      "cms_elements": {
        "title": "CMS elements",
        "description": "CMS elements",
        "name": "CMS elements"
      },
      "cms_element_details": {
        "title": "CMS element",
        "description": "CMS element",
        "name": "CMS element"
      },
      "brands": {
        "title": "Brands",
        "description": "Brands",
        "name": "Brands"
      },
      "models": {
        "title": "Models",
        "description": "Models",
        "name": "Models"
      },
      "categories": {
        "title": "Categories",
        "description": "Categories",
        "name": "Categories"
      },
      "category_details": {
        "title": "Category",
        "description": "Category",
        "name": "Category",
        "authorization_on_mobile_device": "Authorisation on mobile devices",
        "hide_category": "Hide category"
      },
      "attributes": {
        "title": "Attributes",
        "description": "Attributes",
        "name": "Attributes"
      },
      "attribute_details": {
        "title": "Attribute",
        "description": "Attribute",
        "name": "Attribute"
      },
      "attribute_groups": {
        "title": "Attribute groups",
        "description": "Attribute groups",
        "name": "Attribute groups"
      },
      "dynamic_data_definitions": {
        "title": "Dynamic data definitions",
        "description": "Dynamic data definitions",
        "name": "Dynamic data definitions"
      },
      "pipelines": {
        "title": "Pipelines",
        "description": "Pipelines",
        "name": "Pipelines"
      },
      "pipeline_details": {
        "title": "Pipeline",
        "description": "Pipeline",
        "name": "Pipeline"
      },
      "items": {
        "title": "Products",
        "description": "Products",
        "name": "Products"
      },
      "item_details": {
        "title": "Product",
        "description": "Product",
        "name": "Product"
      },
      "calendar": {
        "title": "Calendar",
        "description": "Calendar",
        "name": "Calendar"
      },
      "orders": {
        "title": "Orders",
        "description": "Orders",
        "name": "Orders"
      },
      "settings": {
        "title": "Settings",
        "description": "Settings",
        "name": "Settings"
      },
      "four_zero_four": {
        "title": "404",
        "description": "404",
        "name": "404"
      },
      "account": {
        "title": "Account",
        "description": "Account",
        "name": "Account"
      },
      "forgot_password": {
        "linktext": "Forgotten your password?",
        "title": "Forgotten password",
        "description": "Forgotten password",
        "name": "Forgotten password"
      },
      "renew_password": {
        "title": "Renew password",
        "description": "Renew password",
        "name": "Renew password"
      },
      "new_password_after_reset": {
        "title": "New password after reset",
        "description": "New password after reset",
        "name": "New password after reset"
      },
      "pricing": {
        "title": "Prices",
        "description": "Prices",
        "name": "Prices"
      },
      "signedup": {
        "title": "Registered",
        "description": "Registered",
        "name": "Registered"
      },
      "go_pro": {
        "title": "Go pro",
        "description": "Go pro",
        "name": "Go pro"
      },
      "paypal": {
        "title": "Pay with Paypal",
        "description": "Pay with Paypal",
        "name": "Pay with Paypal"
      },
      "lead_catchers": {
        "title": "Lead catchers",
        "description": "Lead catchers",
        "name": "Lead catchers"
      },
      "bills": {
        "title": "Invoices",
        "description": "Invoices",
        "name": "Invoices"
      },
      "provisionServiceDocuments": {
        "title": "Commissions"
      },
      "commissionServiceDocuments": {
        "title": "Commission documents"
      },
      "integrations": {
        "title": "Integrations",
        "description": "Integrations",
        "name": "Integrations"
      },
      "order_complete": {
        "title": "Order completed. Thank you very much",
        "description": "Order completed. Thank you very much",
        "name": "Order completed. Thank you very much"
      },
      "order_details": {
        "title": "Order details",
        "description": "Order details",
        "name": "Order details"
      },
      "my_company": {
        "title": "My company",
        "description": "My company",
        "name": "My company"
      },
      "white_label_plugins": {
        "title": "White label plugins",
        "description": "White label plugins",
        "name": "White label plugins"
      },
      "plugins": {
        "title": "Creacheck Plugins",
        "description": "Creacheck Plugins",
        "name": "Creacheck Plugins"
      },
      "bill_details": {
        "title": "Invoice details",
        "description": "Invoice details",
        "name": "Invoice details"
      },
      "activate_plugin": {
        "title": "Activate plugin",
        "description": "Activate plugin",
        "name": "Activate plugin"
      },
      "model_groups": {
        "title": "Model groups",
        "description": "Model groups",
        "name": "Model groups"
      },
      "model_group_details": {
        "title": "Model group details",
        "description": "Model group details",
        "name": "Model group details"
      },
      "model_assets": {
        "title": "Model images",
        "description": "Model images",
        "name": "Model images"
      },
      "model_asset_details": {
        "title": "Model Image details",
        "description": "Model Image details",
        "name": "Model Image details"
      },
      "lead_per_mail_rules": {
        "title": "Lead by e-mail rules",
        "description": "Lead by e-mail rules",
        "name": "Lead by e-mail rules"
      },
      "car_rental": {
        "title": "Safe and fast booking in {city}. {count} cars to choose from",
        "description": "Book your car quickly and safely. Believe us, renting a car has never been so quick and easy.",
        "name": "Letting"
      },
      "failed_refunds": {
        "title": "Failed refunds",
        "description": "Failed refunds",
        "name": "Failed refunds"
      },
      "companySignup": {
        "title": "Registration for companies",
        "description": "Register your business and benefit from the latest ecosystem for car dealerships",
        "name": "Registration for companies"
      },
      "price_negotiations": {
        "title": "Price negotiations",
        "description": "Price negotiations",
        "name": "Price negotiations"
      },
      "price_negotiation_details": {
        "title": "Price negotiation",
        "description": "Price negotiation",
        "name": "Price negotiation"
      },
      "communities": {
        "title": "Communities list",
        "description": "Communities list",
        "name": "Communities list"
      },
      "car_service_locations": {
        "title": "Locations where service appointments are available",
        "description": "Locations",
        "name": "Locations"
      },
      "car_service_resources": {
        "title": "Choose a service consultant",
        "description": "Service consultant",
        "name": "Service consultant"
      },
      "car_service_free_slots": {
        "title": "Choose a free time slot to park your car",
        "description": "Time window",
        "name": "Time window"
      },
      "car_service_client_data": {
        "title": "Log in or register to book your appointment",
        "description": "Login",
        "name": "Login"
      },
      "car_service_car_data": {
        "title": "Enter the vehicle details to book the service",
        "description": "Vehicle data",
        "name": "Vehicle data"
      },
      "car_service_summary": {
        "title": "Last step - Check your data and selection",
        "description": "Check",
        "name": "Check"
      },
      "car_services": {
        "title": "Workshop services",
        "description": "services",
        "name": "services"
      },
      "car_service_stations": {
        "title": "Workshop workstations",
        "description": "Workstations",
        "name": "Workstations"
      },
      "document_templates": {
        "title": "Document templates",
        "description": "Document templates",
        "name": "Document templates"
      },
      "document_template_details": {
        "title": "Document template",
        "description": "Document template",
        "name": "Document template"
      }
    },
    "sort": {
      "relevance": "Relevance",
      "username": "email",
      "lastName": "Surname",
      "cdate": "Newest first",
      "cdateDesc": "Newest first",
      "dueDate": "Due date",
      "fav": "Most popular",
      "priceAsc": "Lowest price",
      "rentPriceAsc": "Lowest price",
      "priceDesc": "Highest price",
      "rentPriceDesc": "Highest price",
      "priority": "Priority",
      "mdate": "Last updated",
      "countryName": "Country",
      "vat": "VAT",
      "sumGross": "Amount"
    },
    "jobTypes": {
      "wedding": "Wedding",
      "family_portrait": "Family portrait",
      "maternity": "Maternity",
      "newborn": "Newborn baby",
      "couple": "Pair",
      "pet": "Pet",
      "commercial_advertising": "Commercial / Advertising",
      "fashion": "Fashion",
      "model_portfolio": "Model portfolio",
      "event": "Event",
      "headshots": "Headshots",
      "real_estate_and_architecture": "Real estate and architecture",
      "other": "Other"
    },
    "pipelineEvent": {
      "afterLeadCreated": "created according to lead",
      "afterJobAccepted": "accepted after the job",
      "beforeJobScheduled": "before the planned job",
      "afterJobScheduled": "after the planned job",
      "afterGalleryCreated": "created according to gallery",
      "afterPreviousStage": "after previous stage"
    },
    "paymentEvents": {
      "beforeJobDate": "before job date",
      "onJobDate": "on job date",
      "afterJobDate": "by job date",
      "afterInvoiceDate": "after invoice date"
    },
    "autocomplete": {
      "placeholder": "Select...",
      "noResultsText": "No results found",
      "loadingPlaceholder": "Loading"
    },
    "salesDoc": {
      "debtor_number": "Customer number",
      "order_number": "Order number",
      "order_totals": "Order totals",
      "quote_from": "From",
      "quote": "Offer",
      "quote_for": "Offer for",
      "quotes": "Offers",
      "new_quote": "New offer",
      "invoice_from": "From",
      "invoice": "Invoice",
      "invoice_date": "Invoice date",
      "tax_invoice": "Invoice",
      "order": "order",
      "credit_note": "Credit note",
      "credit_notes": "Credit notes",
      "invoice_for": "Invoice for",
      "invoices": "Invoices",
      "new_invoice": "New invoice",
      "cancellation_invoice_to": "Cancellation invoice for",
      "cancellation_invoice": "Cancellation invoice",
      "to_refunded": "Still to be reimbursed:",
      "positions": "Positions",
      "issued": "Issued",
      "cancelled": "STORNO",
      "billing_period": "Billing period",
      "billing_period_not_valid": "Invalid billing period",
      "pro_rate": "pro rata",
      "name": "Name",
      "description": "Description of the",
      "quantity": "Quantity",
      "unit_price": "Unit price",
      "inc_tax": "Taxes included",
      "exc_tax": "Without taxes",
      "subtotal": "Subtotal",
      "total": "Total amount",
      "amount_due": "Total net",
      "total_due": "Invoice amount",
      "target": "Client",
      "due_date": "Due on",
      "to_pay": "Still to be paid:",
      "payed": "Paid",
      "payment": "Payment",
      "download_csv_ready": "CSV export completed",
      "no_vat_eu_country": "Tax-free intra-Community deliveries according to § 4 No. 1b UstG.",
      "no_vat_non_eu_country": "Tax-free export delivery according to § 6 UstG",
      "thanks_for_order": "Thank you for your order. Unless otherwise stated, the above services will be performed on the date of this invoice.",
      "bill_by_date": "Please transfer the amount, stating the invoice number, to the following account by %date%:",
      "intended_use": "Intended use",
      "paid_with_cash_cc": "The invoice was paid in cash / by EC card",
      "amount_from_credit_card": "The amount of %amount% will be debited from your credit card.",
      "amount_from_paypal": "The amount of %amount% will be debited from your PayPal account.",
      "debit_from_account": "The amount of %amount% will be debited from your account.",
      "register_and_taxes": "Commercial register & taxes",
      "managing_director": "Managing Director"
    },
    "provisionDoc": {
      "provision_calculation": "Commission calculation",
      "client": "Customer",
      "invoice_no_date": "Invoice no. / date",
      "payedDate": "paid on",
      "serviceType": "Service Type",
      "amount": "Amount",
      "provisionRate": "Commission rate",
      "provisionValue": "Commission",
      "totalAmount": "Total amount",
      "provision": "Commission",
      "provisions": "Commissions",
      "provision_for": "Commission for",
      "download_pdf": "Download PDF"
    },
    "paymentStatus": {
      "late": "Late",
      "paid": "Paid",
      "unpaid": "Unpaid",
      "inProgress": "In progress"
    },
    "shopItem": {
      "plus_shipping": "plus shipping costs",
      "variants_possible": "Various selection options are available"
    },
    "sepaAgreement": {
      "sepa_direct_debit_mandate": "SEPA direct debit mandate (direct debit authorisation)",
      "mandate_reference": "Mandate reference",
      "will_be_communicated_separately": "will be communicated separately",
      "payee": "Payee",
      "creditor": "Creditor",
      "payer": "Payer",
      "i_allow_payee_direct_debit": "I hereby authorise the payee to collect payments from my account by direct debit until further notice. At the same time, I instruct my bank to honour the direct debits drawn on my account by the payee.",
      "note_i_can_claim_reimbursement_within_eight_weeks": "Note: I can request a refund of the debited amount within eight weeks of the debit date. The conditions agreed with my bank apply.",
      "document_issued_electronically_and_valid_without_signature": "This letter was created automatically and is also valid without a signature",
      "shortening_notice_period": "Shortening the notice period",
      "deadline_for_advance_information_of_direct_debit_is_shortened_to_one_day": "The deadline for advance notification of the SEPA direct debit is reduced to one day.",
      "i_issue_sepa_direct_debit_mandate": "I hereby issue the SEPA direct debit mandate"
    },
    "sad": {
      "offer": "Offer",
      "modification": "Amendment",
      "order": "order",
      "offers": "Offers",
      "orders": "Orders",
      "offer_short": "Ang.",
      "order_short": "Best.",
      "conversion_rate_short": "CR",
      "conversion_rate": "Conversion rate",
      "last_offer_date": "Last offer",
      "last_offer_date_short": "Last Ang.",
      "car_data": "Vehicle data",
      "class": "Class",
      "model_name": "Model name",
      "model_name_short": "Model name (short)",
      "model_range": "Series",
      "body_design": "Body shape",
      "car_color": "Colour",
      "base_price": "Base price",
      "list_price": "List price",
      "optional_equipment": "Special equipment",
      "incl": "incl.",
      "factory_discount": "Factory estate",
      "cash_discount": "Cash discount",
      "volume_discount": "Quantity discount",
      "transportation_costs": "Transfer costs",
      "price_reduction": "Price reduction",
      "car_image": "Vehicle image from configurator",
      "discount": "Estate",
      "sales_price": "Selling price",
      "max_speed": "Maximum speed",
      "car_acceleration": "Acceleration (0 - 100)",
      "online_code": "Online code",
      "sales_activity": "Sales activity",
      "last_modified": "Last change",
      "offer_date": "Offer date",
      "order_date": "Order date",
      "order_location": "Order location",
      "pre_contract_nr": "V-Order no.",
      "pre_contract_date": "V order dat.",
      "contract_nr": "Order no.",
      "shipment_place": "Place of delivery",
      "shipment_key": "Delivery key",
      "special_agreement": "Special agreement",
      "contact_person": "Contact person",
      "customer_account_number": "Debtor account no.",
      "financing": "Financing",
      "leasing": "Leasing",
      "financing_product": "Product",
      "paid_deposit": "Down payment",
      "paid_deposit_leasing": "Special leasing payment",
      "credit_amount": "Total loan amount",
      "monthly_rate": "Monthly instalment",
      "contract_duration": "Runtime",
      "total_mileage": "Mileage",
      "purchase_price": "Purchase price",
      "annual_percentage_rate": "Effective annual interest rate",
      "nominal_interest_percentage_rate": "Debit interest fixed p.a.",
      "debit_interest_percentage_rate": "Nominal interest p.a.",
      "total_amount": "Total amount",
      "final_instalment": "Final instalment",
      "application_date": "Application date",
      "application_approval_date": "Authorisation date",
      "application_rejection_date": "Rejection date",
      "client_private": "Private",
      "client_business": "Commercial",
      "monthly_leasing_rate": "Monthly leasing instalment",
      "monthly_financing_rate": "Monthly financing instalment",
      "monthly_insurance_rate": "Monthly motor vehicle insurance premium",
      "no_winleas_data_available": "No WinLEAS data available",
      "winleas_data_available": "Financing (F) and / or leasing (L) data available from WinLEAS",
      "discount_cash": "Cash ambulance",
      "discount_volume": "Quantity/recycler discount",
      "discount_environmental": "Environmental bonus",
      "discount_additional": "Additional conditions",
      "deduction": "Discount",
      "deduction_mbl": "MBL discount",
      "deduction_mbl_interest": "Interest discount MBL",
      "deduction_consultant": "Discount consultant",
      "deduction_consultant_interest": "Interest rate discount consultant",
      "commission": "Commission",
      "commission_standard": "Standard",
      "commission_addition_deduction": "Mark up / mark down",
      "commission_subsequent_scope_of_supply": "Next. Scope of delivery",
      "commission_total": "Total",
      "subsidy": "Subsidy",
      "subsidy_contract_partner": "Subsidy VP",
      "subsidy_factory": "Subsidy plant",
      "subsidy_mbvd": "Subsidy MBVD",
      "shortcut_lp_kp": "LP/KP",
      "campaign": "Action",
      "service_rate": "Service rate",
      "residual_value": "Residual value",
      "table_residual_value": "Residual table value",
      "residual_value_change": "Change in residual value",
      "ref": "regarding",
      "net": "net",
      "gross": "gross",
      "list_price_short": "LP",
      "sales_price_short": "KP",
      "note_save": "Save note",
      "notes": "Notes",
      "salesman": "Seller",
      "salesman_blacklist": "Seller (Blacklist)",
      "salesman_number": "Seller no.",
      "salesman_number_short": "No.",
      "enterprise_number": "Company no.",
      "Mercedes": "Mercdes",
      "Smart": "Smart",
      "equipment_line": "Line",
      "packages": "Packages",
      "upholstery": "Upholstery",
      "single_equipments": "Other",
      "included_in": "contained in",
      "store": "Branch",
      "save_filterset": "Save filter set",
      "internal_activity": "Internal business",
      "offer_made": "Offer created",
      "in_progress": "In progress",
      "predecessor_offer": "Predecessor offer / duplicate",
      "lost": "Business lost",
      "ordered": "Order completed",
      "canceled": "Order cancelled"
    },
    "rad": {
      "earnings": "Yield",
      "occupancy_rate": "Utilisation",
      "amount": "Amount"
    },
    "external_account_status": {
      "login_fail": "Please check the access data! Otherwise no more data can be read out.",
      "login_warning": "Please check the access data!",
      "login_valid": "Access data correct",
      "last_successful_login": "Last successful login"
    },
    "categoryitems_stock": "Piece",
    "initialize": "Init",
    "customer": "Company",
    "customer_": "Customer",
    "select_mandant": "Select client",
    "initial_title": "Initial title of the advertising medium",
    "will_be_shown_to_the_user_in_frontend": "Is displayed to users in the frontend",
    "producttemplate_data_saved": "Template data saved",
    "product_size": "Product format / size",
    "template_upload_files": "Select file/s to upload",
    "example_of_product_size": "e.g: DIN A4, DIN long...",
    "output_format": "Output format",
    "product_size_width": "Width in pixels",
    "product_size_height": "Height in pixels",
    "template_folder": "Template path",
    "template_dpi": "DPI for output",
    "template_upload": "Upload",
    "template_pageSpace": "Grid spaces",
    "output_exportType": "Output type",
    "template_fonts": "Fonts",
    "template_fontSizeMin": "Font size min",
    "template_styles": "CSS & LESS data",
    "template_fontSizeMax": "Font size max",
    "template_init_amount_of_pages": "Initial number of pages",
    "template_page_add_increase": "Infidelity",
    "template_pageMaxAmount": "Number of pages max",
    "template_pageMinAmount": "Number of pages min",
    "template_pageTop": "Padding top",
    "template_pageBottom": "Padding bottom",
    "template_pageFirst": "Page number Title page",
    "template_pageFirstFixed": "Front page fixed",
    "template_pageLast": "Page number Last page",
    "template_pageLastFixed": "Last page fixed",
    "template_endless": "Endless page",
    "template_fixed": "Page fixed",
    "template_sample_page": "Number of pages Standard content",
    "font_colors": "Text colours",
    "back_colors": "Background colours",
    "client_logo": "Company logo",
    "template_pageNumber": "Number of pages",
    "page_names": "Page settings",
    "add_contentBlock": "Add content block",
    "css": "General CSS",
    "cssPDF": "CSS for PDF output only",
    "cssPrintPDF": "CSS only for printable PDF output",
    "cssWebsite": "CSS for output as a web page",
    "chosenItem": "Current product selection",
    "pdf_price": "PDF price",
    "auflagen": "Conditions",
    "print_prices": "Base prices",
    "proof_base_price": "Base price data check",
    "new_proof_base_price": "New base price data check",
    "get_vendor_price": "Call up prices",
    "add_variant_line": "Add line",
    "remove_all_item_variant": "Delete all editions",
    "get_vendor_prices": "Price call-off supplier",
    "get_vendor_id": "Query supplier product ID",
    "no_vendor_id": "No supplier product ID set!",
    "cc_item_id": "Product ID CreaCheck",
    "vendor_item_id": "Product ID Supplier",
    "vendor_item_name": "Product name Supplier",
    "vendor_item_missing_attributes": "No price can be found for this attribute combination.",
    "vendor_item_save_attributes": "Please select the attributes!",
    "vendor_item_must_define_attributes": "You must define attributes in order to create variants.",
    "vendor_item_has_no_attributes": "This product group has no attributes",
    "vendor_item_all_n_quantities": "Shows all {quantityCount} quantities",
    "vendor": "Supplier",
    "internal_item_id": "Internal ID",
    "select_vendor_product": "Select product group",
    "item_type": "Product type",
    "print_item": "Print product",
    "website_item": "Website",
    "normal_item": "Other product",
    "not_more_pages": "You cannot create more page names than there are possible pages!",
    "info_title_page": "Title page cannot be moved or deleted",
    "info_last_page": "Last page cannot be moved or deleted",
    "page_definition_page_count_info": "Page number 0 = default value for all pages without definition, last for last page",
    "padding_on_top": "Top spacing",
    "padding_on_bottom": "Distances below",
    "padding_on_left": "Spacing left",
    "padding_on_right": "Spacing right",
    "delimitation_of_grid_blocks": "Grid blocks can be added without limit. The page then becomes longer and longer.",
    "fixed_page": "The page is fixed and cannot be moved.",
    "page_definition": "Page definition",
    "add_definition": "Add definition",
    "page_default_value": "Page number 0 = default value for all pages without definition",
    "page_count": "Number of pages",
    "page_widths": "Side widths",
    "page_heights": "Side heights",
    "no_special_formatting": "No special formatting",
    "min_max_font_size": "Min-/Max-FontSize",
    "activate_min_max_font_size": "Activate or deactivate Min-/Max-FontSize",
    "filename": "File name",
    "for_user": "For users",
    "user_can_use_font": "User can use this font in the editor",
    "import_successful": "Import completed, look in the other tabs",
    "import_resets_template": "Import resets the template, it must be reinitialised, all existing customer editions with this template become unusable!!!!",
    "problem": "A problem has occurred",
    "reading_pages": "Reading pages",
    "done": "Finished",
    "versions": "Versions",
    "template_not_edited": "This template has not yet been edited.",
    "dataProof_infoText": "In a manual data check, our experts check all the technical criteria of the pre-press stage for you. These include printability, compliance with all technical specifications and the subjective colour effect. You can find more detailed information on data checking under FAQ.",
    "media_library": "Media library",
    "count": "Quantity",
    "welcome_message": "Hello, {name}",
    "email": "email",
    "website": "Website",
    "phone": "Telephone",
    "fax": "fax",
    "password": "password",
    "repeat_password": "Repeat password",
    "company": "Company",
    "company_name": "Company name",
    "company_data_timeline_tab_title": "Timeline of company data",
    "company_data_success": "Company data is successfully changed!",
    "company_data_error": "Error when changing the company data!",
    "updated_by": "Updated by",
    "companies": "Companies",
    "first_name": "First name",
    "last_name": "Surname",
    "street": "Street",
    "house_number": "House number",
    "apartment_number": "Flat number",
    "address_line2": "Address line 2",
    "zip_code": "Postcode",
    "city": "City",
    "country": "Country",
    "currency": "Currency",
    "previous_year": "Previous year",
    "vat_name": "VAT Name",
    "vat_id_name": "VAT ID Name",
    "vat_id": "VAT - IdNr",
    "vat_rates": "VAT rates",
    "coc_number": "COC number",
    "registered_at": "Registered at",
    "page": "Page",
    "page_of": "from",
    "save": "Save",
    "save_and_email": "Save and e-mail",
    "add": "Add",
    "add_client": "Add company",
    "update_company_data": "Change company name",
    "add_user": "Add user",
    "add_product": "Add product",
    "add_producttemplate": "Add template",
    "add_question": "Add question",
    "add_option": "Add option",
    "add_questionnaire": "Add questionnaire",
    "add_template": "Add template",
    "add_position": "Add position",
    "add_pipeline": "Add pipeline",
    "add_quote": "Add offer",
    "add_invoice": "Add invoice",
    "add_stage": "Add level",
    "add_gallery": "Add gallery",
    "add_job": "Add job",
    "add_payment_schedule": "Add payment plan",
    "add_payment": "Add payment",
    "add_location": "Add location",
    "add_data_definition": "Add data definition",
    "add_car": "Add vehicle",
    "add_document": "Add document",
    "add_document_type": "Add document type",
    "add_team": "Add team",
    "add_brand": "Add brand",
    "add_model": "Add model",
    "add_model_group": "Add model group",
    "add_model_asset": "Add model image",
    "add_category": "Add category",
    "add_attribute": "Add property",
    "add_attribute_group": "Add attribute group",
    "add_value": "Add value",
    "add_variant": "Add variant",
    "add_lead": "Add lead",
    "add_task": "Add task",
    "add_page": "Add page",
    "add_data_push": "Add data push",
    "add_white_label": "Add white label project",
    "add_menu_element": "Add menu item",
    "add_secondary_menu_element": "Add secondary menu item",
    "add_cms_element": "Add CMS element",
    "add_lead_catcher": "Add lead catcher",
    "add_white_label_plugin": "Add white label plugin",
    "add_lead_per_mail_rule": "Add lead by e-mail rule",
    "add_message": "Add a message",
    "add_car_service": "Add service",
    "add_car_service_station": "Add workstation",
    "add_team_member": "Add team member",
    "add_document_template": "Add document template",
    "add_lead_autoresponse": "Add lead autoresponse",
    "add_main_menu_version": "Add main menu version",
    "add_lead_tagger": "Add lead tagger",
    "add_lead_assignment_strategy": "Add assignment strategy",
    "add_escalation_rule": "Add escalation rule",
    "add_coupon": "Add voucher",
    "add_company": "Add company",
    "add_item": "Add product",
    "add_manual_entry": "Add manual input",
    "add_allowed_domain": "Add authorised domain",
    "update_allowed_domain": "Update authorised domain",
    "send_message": "Send message",
    "send": "Send",
    "details": "details",
    "delete": "Delete",
    "undelete": "Restore",
    "undelete_user_confirm": "Restore the user?",
    "or": "or",
    "actions": "Actions",
    "client": "Company",
    "job": "Job",
    "active_jobs": "Active jobs",
    "questionnaire": "Questionnaire",
    "review_and_send": "Check and send",
    "download": "Download",
    "duplicate": "Duplicate",
    "client_name": "Name",
    "address": "Address",
    "title": "Title",
    "uploading": "Upload",
    "status": "Status",
    "next_stage": "Next level",
    "type": "Type",
    "schedule": "Timetable",
    "location": "Location",
    "sent_on": "Sent on",
    "name": "Name",
    "description": "Description of the",
    "bill_number": "Invoice number",
    "quote_number": "Offer number",
    "do_sort": "Sort",
    "date": "date",
    "gallery": "Gallery",
    "questionnaires": "Questionnaires",
    "job_takes_whole_day": "This job takes up the whole day",
    "from": "From",
    "to": "To",
    "question": "Question",
    "subject": "Topic",
    "content": "Contents",
    "template": "Template",
    "stage_has_due_date": "This stage has a due date",
    "stage_sends_automated_email": "This stage sends an automated e-mail",
    "day": "Day",
    "days": "Days",
    "hours": "Hours",
    "review": "Check",
    "timezone": "Time zone",
    "client_since": "Company added on",
    "user_since": "User since",
    "payment_instructions": "Payment instructions",
    "bank_name": "Bank name",
    "debtor_number": "Debtor ID",
    "account_holder": "Account holder",
    "account_number": "Account number",
    "bank_location": "Bank location",
    "bank_code_number": "Bank sort code",
    "iban": "IBAN",
    "swift_bic": "SWIFT/BIC",
    "company_logo": "Company logo",
    "percent_of_order": "Percentage of the order",
    "percent_of_remaining_balance": "Per cent of the remaining amount",
    "amount": "Amount",
    "due": "Due",
    "payment_schedule": "Payment plan",
    "record_payment": "Log payment",
    "mark_as_unpaid": "Mark as unpaid",
    "close": "Close",
    "yes": "Yes",
    "want_to_mark_the_payment_as_paid": "Do you really want to mark this payment as paid?",
    "want_to_mark_the_payment_as_unpaid": "Do you really want to mark this payment as unpaid?",
    "payment_marked_as_paid": "Payment logged",
    "payment_marked_as_unpaid": "Payment marked as unpaid",
    "overdue_payments": "Overdue payments",
    "next_payment": "Next payment",
    "quote_accepted": "Offer accepted",
    "quote_rejected": "Offer rejected",
    "todays_tasks": "Today's tasks",
    "overdue_tasks": "Overdue tasks",
    "overdue_and_upcoming_payments": "Overdue and upcoming payments",
    "jobs_in_progress": "Jobs in progress",
    "appointments": "Dates",
    "appointment": "Date",
    "last_update": "Last update",
    "mark_as_completed": "Mark as completed",
    "want_to_mark_stage_complete": "Would you like to mark the step as completed?",
    "stage_marked_complete": "Stage marked as completed",
    "gallery_download": "Download image",
    "gallery_sharing": "Share gallery",
    "gallery_social_media": "Images on social media",
    "preview": "Preview",
    "invoice_preview": "Invoice preview",
    "preview_pdf": "Preview PDF",
    "message": "Message",
    "signup": "Register",
    "continue": "Continue",
    "password_assistance": "Password help",
    "password_renewing": "New password",
    "not_any_user_information": "No user information.",
    "failed_redirection": "Forwarding failed.",
    "renew_password": "Before you can log in, you must set a new password. We have just sent you an e-mail with instructions.",
    "get_new_password": "Request new password",
    "set_new_password": "Set your new password",
    "forgot_password": {
      "infotext": "If you have forgotten your password, you can reset it here. Please enter your registered e-mail address in the form below and click on \"Continue\". We will then send you an e-mail with further information.",
      "infotext_robethood": "If you have forgotten your password, you can reset it here. Please enter the registered e-mail address in the form below and click on \"Continue\". We will then send you an e-mail with further information.",
      "info_email_not_used": "If you no longer use the email address associated with your Creacheck account, please contact us via our contact form: {link}.",
      "info_email_not_used_robethood": "If you no longer use the email address associated with your account, please contact us via our contact form: {link}.",
      "enter_new_password": "There is only this step ahead of you to log in to Creacheck. Simply enter your new password twice. It's that simple."
    },
    "thanks_for_signing_up": "Thank you for registering",
    "confirm_your_email": "Confirm your e-mail address",
    "signed_up_gratulations": "Welcome to CreaCheck. We have sent you an e-mail to complete the registration process. Please confirm your registration.",
    "signed_up_gratulations_robethood": "Welcome to our website. We have sent you an e-mail to complete the registration process. Please confirm your registration.",
    "check_email_for_message_from_us": "Please check your e-mail inbox.",
    "check_email_for_message_from_us_robethood": "Please check your e-mail inbox",
    "new_clients": "New customers",
    "users": "Users",
    "user": "Users",
    "won_from_count_leads": "Gained from {count} leads",
    "deals_won_out_of_count": "Deals won by {count}",
    "new_badge": "New",
    "answer_badge": "Answer",
    "accept": "Accept",
    "reject": "Reject",
    "quote_rejected_on_date": "Offer rejected on {date}",
    "quote_accepted_on_date": "Offer accepted on {date}",
    "want_to_accept_the_quote": "Do you want to accept the offer? The contractor will be informed directly of your decision.",
    "want_to_reject_the_quote": "Do you want to reject the offer? The contractor will be informed directly of your decision.",
    "want_to_convert_quote_to_invoice": "Would you like to convert this offer into an invoice?",
    "convert_quote_to_invoice": "Convert the offer into an invoice",
    "convert_to_invoice": "Convert to invoice",
    "convert_quote_from_template": "Convert offer to another template",
    "completed_on_date": "Completed on {date}",
    "due_date_on_date": "Due date {date}",
    "due_date": "Due date",
    "current_stages_due_date": "Due date of the current stage",
    "go_pro": "Go Pro",
    "recent_activity": "Last activity",
    "load_more": "Load more",
    "your_galleries": "Your galleries",
    "unread_by_you": "Unread by you",
    "count_images": "{count} images",
    "view": "View",
    "filled_out_on": "Filled in on",
    "filled_out_by": "Filled in by",
    "you_answered_the_questionnaire_on_date": "You answered the questionnaire on {date}",
    "company_sent_a_questionnaire": "{company} has sent a questionnaire and asks for answers",
    "allow_to_download_full_res": "Allow the customer to download images in full resolution",
    "allow_to_share_with_friends": "Allow the customer to share the gallery with friends",
    "allow_to_share_on_social_media": "Allow the customer to share images via social media",
    "got_it": "OK",
    "learn_more": "Learn more",
    "this_website_uses_cookies": "Our website uses cookies to ensure you get the best experience on our website.",
    "are_you_sure": "Are you sure?",
    "no": "No",
    "closed_please_add_timeframe": "<span><span className='text-danger semi-bold'>Closed</span> - please add a time frame</span>",
    "not_active_please_add_timeframe": "<span><span className='text-danger semi-bold'>Not active</span> - please add a time frame</span>",
    "note": "Note",
    "change_password": "Change password",
    "roles": "Roles & rights",
    "active_set": "Current subscription",
    "infos": "Information on",
    "documents": "Documents",
    "document": "Document",
    "financial": "Financial",
    "bookingAccountNumber": "Posting account",
    "costAccountNumber": "Cost centre",
    "locations": "Locations",
    "white_label": "White label",
    "show_password_text": "Show password text",
    "parent": "Parent",
    "change_parent": "Change parent",
    "cancel": "Cancel",
    "timeline_undo": "Undo",
    "confirm_action_ramifications": "Please confirm that you understand the implications of your action",
    "client_will_not_be_visible_in_search_results": "The selected customer is no longer visible in your customer search",
    "changing_parent_takes_a_while": "Changing the precursor can take a while",
    "load_message_from_template": "Load message from the template",
    "questions": "Questions",
    "search": "Search",
    "search_for": "Search for",
    "dynamic_data_definitions": "Dynamic data definitions",
    "dynamic_data_definition": "Dynamic data definition",
    "data_identifier": "Data identifier",
    "cars": "Vehicles",
    "rentals": "Rent",
    "no_car_available": "No trolley available",
    "file": "File",
    "files": "Files",
    "teams": "Teams",
    "team": "Team",
    "document_types": "Document types",
    "document_type": "Document type",
    "system_type": "System type",
    "brands": "Brands",
    "models": "Models",
    "model": "Model",
    "model_groups": "Model groups",
    "model_group": "Model group",
    "model_assets": "Model images",
    "model_asset": "Model image",
    "categories": "Categories",
    "attributes": "Attributes",
    "attribute": "Attribute",
    "values": "Values",
    "value": "Value",
    "select_category": "Select category",
    "position": "Position",
    "color": "Colour",
    "car": "Product",
    "category": "Category",
    "brand": "Brand",
    "required": "Mandatory field",
    "multilanguage": "Multiple languages",
    "seo": "SEO",
    "page_title": "Page title",
    "keywords": "Keywords",
    "item": "Article",
    "sku": "SKU",
    "variants": "Variants",
    "variant": "Variant",
    "price": "Price",
    "prices": "Prices",
    "variant_attributes": "Variant attributes",
    "no_value_set": "No value set",
    "identification": "Identification",
    "templates": "Templates",
    "vin": "FIN",
    "leads": "Leads",
    "lead": "Lead",
    "order_it": "Order",
    "order": "order",
    "orders": "Orders",
    "images": "Pictures",
    "image": "Picture",
    "upc": "UPC",
    "visibility": "Visibility",
    "show_item_online": "Product visible online",
    "use_as_resource": "Use as a resource",
    "stock_and_prices": "Inventory and prices",
    "sales_price": "Selling price",
    "sales_point": "Distribution",
    "warehouse": "Warehouse",
    "stock_level": "Stock",
    "rent_price": "Rental price",
    "pseudo_price": "Pseudo price",
    "express_price": "Express price",
    "overnight_price": "Overnight price",
    "sameday_price": "Sameday price",
    "basic_price": "Base price",
    "weekday_price": "Weekday Price",
    "weekend_price": "Weekend price",
    "week_price": "Weekly price",
    "month_price": "Monthly price",
    "unit_of_measure": "Unit of measurement",
    "basic_unit": "Basic unit",
    "packaging_unit": "Packaging unit",
    "subscribe": "Subscribe",
    "all": "All",
    "pipeline": "Pipeline",
    "pipelines": "Pipelines",
    "default_team": "Standard team",
    "select_pipeline": "Select a pipeline",
    "please_select_pipeline_first": "Please select a pipeline first",
    "view_type": "View",
    "stage": "Level",
    "stages": "Steps",
    "properties": "Properties",
    "unassigned": "Without allocation",
    "assignee": "Processor",
    "order_type": "Order type",
    "internal_comment": "Internal commentary",
    "comments": "Comments",
    "comment": "Comment",
    "no_comments_yet": "There are no comments yet",
    "show_all_comments": "Show all comments",
    "people": "Persons",
    "watchers": "Observer",
    "dates": "Dates",
    "date_created": "Created",
    "date_updated": "Updated",
    "closed": "Closed",
    "start_watching_lead": "Start watching this lead",
    "stop_watching_lead": "Stop watching this lead",
    "no_longer_watching_lead": "You no longer observe this lead",
    "watching_lead": "You observe this lead",
    "wrote_comment": "Has written a comment",
    "replied_to_comment": "Replied to a comment",
    "language": "Language",
    "languages": "Languages",
    "primary_language": "Main language",
    "secondary_languages": "Secondary languages",
    "won": "Won",
    "lost": "Lost",
    "change_pipeline": "Change pipeline",
    "start_pipeline": "Start pipeline",
    "pipeline_started": "Pipeline launched",
    "change_stage": "Change level",
    "switch_team": "Change team",
    "open": "Open",
    "budget": "Budget",
    "always_available": "Always available",
    "default_location": "Standard location",
    "selected_category_has_no_attributes": "Selected category has no attributes",
    "car_overview": "Vehicle overview",
    "make_reservation": "Make a reservation",
    "reservation": "Reservation",
    "cms_pages": "Pages",
    "grid_fluid": "Liquid",
    "grid_normal": "Normal",
    "cms_add_container": "Add container",
    "cms_add_row": "Add line",
    "cms_add_element": "Add element",
    "media_files": "Media files",
    "upload": "Upload",
    "page_design": "Page design",
    "translations": "Translations",
    "translate": "Translate",
    "category_filters": "Category filter",
    "attribute_groups": "Attribute groups",
    "select_attribute": "Select attribute",
    "display": "Display",
    "select_multiple": "Multiple selection",
    "use_null_as_min_val": "Set 0 as minimum",
    "filter": "Filters",
    "do_filter": "Filter",
    "mobile_phone": "Mobile phone",
    "mark_lead_won": "Mark lead as won",
    "mark_lead_lost": "Mark lead as lost",
    "mark_lead_deleted": "Mark lead as deleted",
    "reopen_lead": "Reopen lead",
    "won_reason": "Reason why won",
    "lost_reason": "Reason why lost",
    "delete_reason": "Reason why deleted",
    "reopen_reason": "Reason for reopening",
    "no_reason_specified": "No reason given",
    "lead_marked_won": "Lead marked won. Congratulations",
    "lead_marked_lost": "Lead marked lost",
    "lead_marked_deleted": "Lead marked deleted",
    "lead_reopened": "Lead reopened",
    "stage_changed": "Lead level changed",
    "pipeline_changed": "Lead pipeline changed",
    "team_changed": "Lead assigned to another team",
    "current_pipeline": "Current pipeline",
    "current_stage": "Current level",
    "file_extension": "Extension",
    "file_size": "Size",
    "file_uploaded": "Uploaded",
    "no_client_assigned": "No customer assigned",
    "no_products_assigned": "No products assigned",
    "no_order_assigned": "No order assigned",
    "planned_duration_in_days": "Planned duration (days)",
    "lead_budget_must_be_set": "Lead budget must be set",
    "client_must_be_assigned": "Customer must be assigned",
    "order_must_be_assigned": "Order must be assigned",
    "product_must_be_assigned": "Product must be assigned",
    "lead_has_to_have_following_documents": "Lead must have the following documents (documents must be valid)",
    "client_has_to_have_following_documents": "Customer must have the following documents (documents must be valid)",
    "count_days": "{count, plural, one {# day} other {# days}}",
    "longer": "Longer",
    "date_from": "Date from",
    "time_from": "Time from",
    "date_to": "Date until",
    "time_to": "Time until",
    "rate": "Rate",
    "car_rental_weekday": "Letting - Weekday Rate",
    "car_rental_weekend": "Rental - Weekend Rate",
    "car_rental_week": "Letting - weekly instalment",
    "car_rental_month": "Rental - Monthly instalment",
    "document_overdue_please_review": "Document overdue. Please check",
    "count_cars": "{count, plural, one {# vehicle} other {# vehicles}}",
    "count_products": "{count, plural, one {# product} other {# products}}",
    "we_found_count_cars": "We have found {count, plural, one {# vehicle} other {# vehicles}}",
    "we_found_count_products": "We have found {count, plural, one {# product} other {# products}}",
    "delete_all_filters": "Delete all filters",
    "image_recognition_tags": "Tags recognised from the image",
    "username": "User name",
    "handover_must_be_performed": "The vehicle handover protocol must be completed",
    "document_pages": "Document pages",
    "pages": "Pages",
    "handover": "Handover",
    "mileage": "Mileage",
    "client_documents": "Customer documents",
    "lead_documents": "Lead documents",
    "fuel_level": "Fuel level",
    "start_editing": "Start editing",
    "design": "Design",
    "responsive": "Responsive",
    "back": "Back",
    "use_text_description": "Use text description",
    "use_visual_builder": "Use visual builder",
    "set_image": "Set image",
    "select_image": "Select image",
    "uploaded_by_me": "Uploaded by me",
    "current_item": "Current article",
    "current_page": "Current page",
    "difficulties": "Difficulties",
    "damages": "Damage",
    "damage_note": "Notice of damage",
    "no_damages_in_protocol": "No damage in the log",
    "add_damage": "Add damage",
    "my_company": "My company",
    "user_administration": "User administration",
    "client_administration": "Contact management",
    "crm_administraion": "CRM management",
    "content_administration": "Content management",
    "product_administration": "Product management",
    "producttemplate_administration": "Template management",
    "car_service_administration": "Workshop management",
    "site_administration": "Website administration",
    "data_push": "Data push",
    "client_has_no_ancestors": "Customer has no ancestors",
    "domain": "Domain",
    "entity": "Entity",
    "event": "Event",
    "endpoint_url": "Endpoint URL",
    "authentication": "Authentication",
    "headers": "Headers",
    "none": "None",
    "social_media": "Social media",
    "style": "Style",
    "html_header": "HTML header",
    "custom_html_header": "Customised HTML header",
    "css_file_name": "User-defined CSS file name",
    "custom_css": "Custom CSS",
    "custom_less": "User-defined LESS",
    "custom_font_folder": "Select font directory",
    "custom_font": "Upload custom font",
    "custom_css_mail": "Customised CSS for emails",
    "main_menu": "Main menu",
    "menu_css_class": "Main menu CSS class",
    "menu_css_class_scroll": "Main menu CSS class when scrolling",
    "white_label_projects": "White label projects",
    "imprint": "Imprint",
    "terms_of_service": "GTC",
    "rental_terms_of_service": "General terms and conditions of hire",
    "car_sales_terms_of_service": "General Terms and Conditions of Sale for Used Vehicles",
    "item_sales_terms_of_service": "General Terms and Conditions of Sale Products",
    "privacy_policy": "Data protection",
    "cancellation_policy": "Cancellation policy",
    "footer": "Footer",
    "hide_footer": "Hide footer",
    "unknown_property": "Unknown parameter",
    "unknown": "unknown",
    "price_from": "from",
    "price_to": "until",
    "slider_from": "from",
    "slider_to": "until",
    "within": "within",
    "of": "from",
    "within_count_km_of_place": "within {count}km of {place}",
    "click_to_start_geolocation": "Click here to start geolocalisation",
    "geolocation_error": "We had a problem recognising your position or your position is not known. Please enter your position manually.",
    "count_km_from": "km from",
    "car_power": "Performance",
    "car_power_unit": "PS",
    "car_gear": "Gearbox",
    "car_capacity": "Displacement",
    "car_capacity_unit": "cm³",
    "car_fueltype": "Fuel type",
    "car_type": "Vehicle type",
    "car_num_previous_owners": "Number of vehicle owners",
    "car_num_doors": "Number of doors",
    "car_num_seats": "Number of seats",
    "car_emission_class": "Pollutant class",
    "car_condition": "Vehicle condition",
    "car_construction_year": "Year of construction",
    "car_color_manufacturer": "Colour (manufacturer)",
    "car_parking_assistance": "Parking aid",
    "car_interior_material": "Material of the interior fittings",
    "car_airbags": "Airbags",
    "car_air_conditioning": "Air conditioning",
    "car_environment_badge": "Environmental badge",
    "car_co2_emissions": "CO2 emissions",
    "car_co2_emissions_unit": "g/km",
    "car_fuel_consumption": "Consumption",
    "car_fuel_consumption_unit": "l/100km",
    "combined": "combined",
    "combined_short": "comb.",
    "urban": "urban",
    "extra_urban": "out of town",
    "energy_efficiency_class": "Energy efficiency class",
    "technical_data": "Technical data",
    "first_registration": "First registration",
    "proceed_to_checkout": "Checkout",
    "home": "Home page",
    "checkout": "Cash desk",
    "your_cart": "Your shopping trolley",
    "your_car": "Your car",
    "your_data": "Your data",
    "services": "Service",
    "shipping_information": "Transfer",
    "payment_details": "Payment",
    "summary": "Summary",
    "find_out_more": "Learn more",
    "qty": "Quantity",
    "checkout_next": "Further",
    "checkout_previous": "Back",
    "please_select": "Please select",
    "enum_many_glue": ",",
    "enum_last_glue": " and",
    "enum_end": " select",
    "show_details": "Show details",
    "add_to_cart": "Add to shopping basket",
    "show_cart": "Show shopping basket",
    "cart": "Shopping basket",
    "missingQuantity": {
      "cart": "shopping basket"
    },
    "cart_is_empty": "Shopping basket is empty",
    "item_already_in_cart": "This item is already in your shopping basket",
    "remove": "Delete",
    "removed_from_cart": "has been deleted from the shopping basket",
    "continue_with_login": "Continue with login",
    "dont_have_an_account": "No account yet",
    "continue_without_login": "Continue without login",
    "already_have_an_account_enter_username_and_password": "Already have an account? Please enter your username and password in the form below.",
    "continue_without_login_and_register": "You can continue the purchase process and create your account here.",
    "continue_booking_without_login_and_register": "You can continue the booking process and create your account here",
    "online_discount": "Online discount",
    "your_personal_data": "Your personal data",
    "login_data": "Access data",
    "shipping": "Shipping",
    "payment_cash": "Cash",
    "payment_creditCard": "Credit card",
    "payment_payPal": "PayPal",
    "payment_wire_transfer": "Bank transfer",
    "payment_bill": "Invoice",
    "choose_different_payment_method": "Select another payment method",
    "i_will_pick_up_my_car": "I will pick up my car",
    "i_will_pick_up_my_order": "I will pick up my order",
    "i_want_my_car_delivered": "I would like to have my vehicle delivered.",
    "i_want_my_order_delivered": "I would like my order to be delivered",
    "we_could_not_calculate_route_to_address": "We could not calculate a route to the specified address. Please check and try again.",
    "credit_card_holder": "Credit card holder",
    "credit_card_number": "Credit card number",
    "car_registration": "Vehicle registration",
    "car_insurance": "Car insurance",
    "service_provided_in_country_by_company": "Service in {country} provided by {company}",
    "i_wish_to_have_car_registered": "Yes, I would like to use the authorisation service",
    "i_wish_to_get_car_insurance": "I would like to take out car insurance",
    "today": "Today",
    "in_10_days": "In 10 days",
    "your_money_is_safe_with_us": "Your money is safe with us",
    "car_transport": "Car transport",
    "delivery": "delivery",
    "decide_car_delivery_or_pickup": "It's up to you to decide whether you want to collect your car yourself or have it delivered. Either way, the car will be in perfect shape, clean and with a full tank of petrol.",
    "secured_payment_until_car_is_delivered": "Secure payment process until delivery of the vehicle",
    "e_payment_to_reserve_car_and_secure_services": "E-payment to reserve the car and secure selected services",
    "e_payment_credit_card_paypal": "Electronic payment (credit card or PayPal)",
    "multiple_secure_options_to_pay_for_the_car": "Secure payment options for the car (bank transfer, cash on collection)",
    "buy_car_by_clicking_button": "You can buy this car now by clicking the button below",
    "payment_with_credit_card_ending_last4_expiring_exp_month_exp_year_issued_to_card_holder": "Credit card payment ending with {last4} and expiring in {expMonth}/{expYear}, issued to {cardHolder}",
    "click_on_paypal_checkout_to_continue": "Please click on the Paypal Checkout button to continue.",
    "paypal_is_approved_continue_to_summary": "Your PayPal payment has been authorised. Please continue with the summary to complete the order and authorise payment.",
    "paypal_buttons_loading": "Load PayPal buttons...",
    "please_wait_saving_order": "Save the order. Please wait...",
    "error_loading_paypal_scripts": "PayPal scripts could not be loaded. Please close this popup and open it again.",
    "link": "Link",
    "hide_on_devices": "Hide on devices",
    "mobile_phones": "Mobile phones",
    "tablets": "Tablets",
    "desktop_pcs": "Desktop PCs",
    "show_content_on_mouse_over": "Show content when the mouse is over",
    "menu_content": "Menu content",
    "link_to_page": "Link to the page",
    "page_has_no_translations": "This page has no translations",
    "search_results": "Search results",
    "press_enter_to_search": "Press the enter key to search",
    "no_results_found": "No results found",
    "create_new_entry_now": "You can now create a new entry:",
    "no_results_for_your_search": "We have not found any results for your search query",
    "change_image": "Change image",
    "employee_data": "Employee data",
    "newsletter": "Permission to receive newsletters",
    "editorAutosave": "Automatic saving in the editor",
    "is_profile_public": "Is the profile public?",
    "parent_page": "Parent page",
    "cms_elements": "CMS elements",
    "cms_element": "CMS element",
    "new_lead_from_website": "New lead from the website",
    "website_settings": "Website settings",
    "profile": "Profile",
    "my_bills": "My invoices",
    "integrations": "Integrations",
    "email_templates": "E-mail templates",
    "cms_templates": "CMS templates",
    "cms_template": "CMS template",
    "select_brand": "Select brand",
    "all_brands_visible": "All brands are visible",
    "contact_us": "Contact us",
    "thank_you": "Thank you very much",
    "thank_you_for_contacting_us": "Thank you for contacting us. We have received your enquiry and will get back to you as soon as possible",
    "cms_standard": "Standard",
    "select_element": "Select CMS element",
    "divider": "Divider",
    "testimonial": "Recommendation",
    "hero_unit": "Hero element",
    "image_carousel": "Slider",
    "call_to_action": "Call to action",
    "bar_counter": "Bar counter",
    "products": "Products",
    "producttemplates": "Templates",
    "single_product": "Single product",
    "single_producttemplate": "Single template",
    "lead_form": "Lead form",
    "image_carousel_element": "Slider image",
    "cms_grid": "Container",
    "cms_row": "Line",
    "cms_col": "Column",
    "text_color": "Text colour",
    "bar_counter_now": "Now",
    "shadow": "Shadow",
    "no_shadow": "No shadow",
    "small": "Small",
    "big": "Large",
    "rounded_corners": "Rounded corners",
    "no_rounded_corners": "No rounded corners",
    "image_position": "Image position",
    "icon": "Icon",
    "icon_class": "Icon class (CSS)",
    "position_top": "Above",
    "position_left": "Links",
    "bar_color": "Bar colour",
    "bar_background_color": "Bar background colour",
    "button_icon": "Button symbol",
    "button_text": "Button text",
    "full_height": "Full height",
    "padding_top": "Distance above",
    "padding_bottom": "Distance below",
    "lead_source": "Lead source",
    "change_item": "Change article",
    "show_infos_on_top": "Show info above",
    "show_infos_on_bottom": "Show info below",
    "condensed": "Condensed",
    "assign_item": "Assign product",
    "image_animation": "Image animation",
    "no_animation": "No animation",
    "fade_in": "Fade in",
    "from_left": "From the left",
    "from_right": "From the right",
    "from_top": "From above",
    "from_bottom": "From below",
    "image_gallery": "Picture gallery",
    "elements": "Elements",
    "accordion": "Accordion",
    "map": "Map",
    "height": "Height",
    "video": "Video",
    "link_to_video": "Link to the video",
    "video_possible_sources": "Videos from the following portals are supported:: YouTube, Vimeo, Dailymotion",
    "autoplay": "Automatic playback",
    "aspect_ratio": "Aspect ratio",
    "image_compare": "Compare image",
    "second_image": "Second picture",
    "login_form": "Login form",
    "button_signin": "Log in",
    "alternative_login": "...or log in with:",
    "alternative_login_long": "Use our social login function",
    "login_with": "Log in with",
    "show_facebook_login": "Show Facebook login",
    "show_google_login": "Show Google login",
    "signup_form": "Registration form",
    "signup_legal_explanation": "Registration - legal declaration",
    "signup_agreement_text": "By registering, I agree to the {terms}.",
    "contact_agreement_text": "I have read and understood the {data_use}.",
    "signup_agreement_text_terms": "General Terms and Conditions",
    "signup_agreement_text_data_use": "Privacy policy",
    "signup_agreement_text_cancellation_policy_and_form": "Cancellation policy and the cancellation form",
    "will_become_link_with_title_title": "a link with the title: {title}",
    "show_only_when_user_logged_id": "Only display if the user is logged in",
    "car_rental_form": "Car hire form",
    "car_search_form": "Car search form",
    "car_search_funnel": "Car search configurator",
    "background_image": "Background image",
    "background_color": "Background colour",
    "same_col_height": "Same column height",
    "pick_up_and_drop_off": "Collection & return",
    "pick_up_time": "Pick-up time",
    "drop_off_time": "Return time",
    "get_a_quote": "Determine prices",
    "select_location": "Please select a location",
    "type_address_to_start_search": "Please enter an address to start the search",
    "menu_version": "Menu version",
    "menu_transparent": "Transparent",
    "menu_dark": "Dark",
    "menu_light": "Light",
    "logo_version": "Logo version",
    "logo_default": "Use standard logo",
    "logo_scroll": "Use scroll logo",
    "select_a_pick_up_location": "Please select a pick-up location first",
    "could_not_find_location_please_enter_more_details": "We could not find a location. Please enter your pick-up location with further details",
    "client_not_found": "Customer not found",
    "order_will_directly_be_marked_paid": "The order is immediately marked as paid",
    "invoice_will_directly_be_marked_paid": "The invoice is immediately marked as paid",
    "rental_period": "Rental period",
    "change_booking_dates": "Change booking data",
    "per_day": "per day",
    "buy_now": "Buy now",
    "book_now": "Book now",
    "rent_car_now": "Rent now",
    "commit_to_buy": "Order with obligation to pay",
    "back_to_cart": "Back to the shopping basket",
    "equipment": "Equipment",
    "technical_specifications": "Technical data",
    "cancel_checkout_question": "Cancel checkout?",
    "how_it_works": "How it works",
    "secure_payment": "Secure payment",
    "top_services": "Top services",
    "if_you_buy_this_car_you_will_get_lots_of_services": "When you buy this car, don't be afraid of paperwork. We can organise the registration, insurance and car loan if you wish. Simply select the service you require at checkout.",
    "need_more_info_question": "Do you need more information?",
    "contact_dealer": "Contact dealer",
    "move_around_to_see_changes": "Move to see the changes",
    "lead_catchers": "Lead catchers",
    "lead_catcher": "Lead catcher",
    "advanced_routing": "Advanced routing",
    "add_route": "Add route",
    "source": "Source",
    "can_be_ordered_online": "Can be ordered online",
    "count_years_warranty": "{count} {count, plural, one {year} other {years}} Guarantee",
    "amount_online_advantage": "{amount} Online advantage",
    "your_order": "Your order",
    "file_name": "File name",
    "tags": "Tags",
    "save_and_set_image": "Saving and setting",
    "choose_or_drop_file_to_upload": "Select or drag a file over the upload page.",
    "media_formats_supported": "JPG and PNG are supported.",
    "max_file_upload_size": "The maximum file size is 15 MB",
    "no_files_found": "No files found.",
    "upload_file_by_dragging_over": "Upload a file by simply dragging a file over the page",
    "your_car_is_ready_to_check_out": "Your dream car is ready for you",
    "choosing_one_of_our_cars_is_a_good_decision": "Just a few more steps to your dream vehicle. Benefit from our online advantages.",
    "your_car_will_be_delivered_to_you": "Your vehicle is delivered to your desired location",
    "your_will_get_a_amount_discount_for_ordering_online": "For online orders you receive a discount of {amount}",
    "you_will_receive_extended_warranty": "You receive an extended warranty",
    "you_will_get_exclusive_offers_when_using_our_app": "You receive exclusive offers when you use our mobile app",
    "you_dont_risk_when_ordering_online": "You don't risk much when you order online.",
    "your_car_rent_is_nearly_done": "Your car hire is almost ready!",
    "please_continue_with_booking_and_payment": "Please proceed with the booking and payment. We will make sure that your car is ready on the selected pick-up date. Online booking saves you time!",
    "please_provide_address_delivery_method_payment": "Please enter your address, select the shipping method and the payment details.",
    "your_information_is_safe_with_us": "Your information is safe with us.",
    "we_offer_parcel_shipment_for_just_amount": "For your convenience, we offer one parcel delivery for all our orders for only {amount}.",
    "free_delivery_on_all_our_orders": "We offer free delivery on all our orders.",
    "get_your_car_delivered": "delivery",
    "cant_pick_your_car_yourself_we_got_you_covered": "What happens if you find your dream car and can't pick it up yourself? No worries. We have a solution. Once your car is registered and insured, a driver will arrange a delivery appointment with you and park your car in your driveway.",
    "we_are_more_than_happy_to_show_you_around_your_new_car": "Of course, we look forward to welcoming you in person and showing you your new car.",
    "we_can_complete_your_order_in_shop_for_pickup": "But, if you wish, we can complete your order in our shop and you can pick it up when you have time.",
    "we_will_inform_you_when_we_complete_order_for_pickup": "We will inform you when we complete your order. Then we will wait patiently for you to pick it up.",
    "we_will_ship_your_order_to_provided_address": "We will dispatch your order with DHL to the address you specify. We will inform you when the order has been dispatched. You will also receive an order tracking number.",
    "we_calculated_route_from_car_to_you": "We have calculated a route from where the car is located to the address you provided. It looks like our driver has to drive {distance}km to bring the car to your front door.",
    "service_includes": "Service includes:",
    "driver_will_call_you_and_schedule_delivery": "Our driver will call you and deliver the car when it suits you best",
    "your_car_will_be_washed": "Your car is being washed",
    "your_car_will_be_fully_tanked": "Your car is filled up with petrol",
    "you_will_get_quick_tour_around_car": "You get a short tour of the car",
    "you_can_pick_up_car_yourself": "Do you have any questions? Our team will be happy to help you.",
    "only_one_step_left": "Just one more step!",
    "check_if_everything_is_correct_and_provide_payment_data": "Please check that everything is correct, give us your payment details and submit the order.",
    "terms_of_contract_for_additional_services": "Contractual conditions for additional services",
    "terms_of_use_marketplace": "Terms of use of the marketplace",
    "for_the_sales_of_used_vehicles": "Sale of used vehicles",
    "for_the_rental_of_vehicles": "Vehicle hire",
    "your_payment_schedule": "Payment run",
    "shipping_pickup": "Collection",
    "shipping_parcel": "Package",
    "shipping_transport": "Transport",
    "car_interior": "Interior fittings",
    "car_exterior": "Exterior fittings",
    "we_are_processing_your_order_please_wait": "We are processing your order. Please wait...",
    "we_are_processing_your_booking_please_wait": "We are processing your booking. Please wait...",
    "your_order_was_completed_successfully": "Your order has been successfully completed.",
    "we_created_a_user_profile": "We have created a user profile for you",
    "check_your_email": "Check your inbox",
    "your_orders": "{count, plural, one {your order number} other {your order numbers}}:",
    "first_experience_with_our_shop_saved_profile": "It seems that this is your first experience with our online shop. We have successfully saved your profile information in our database. To continue processing your order, please check your mailbox and confirm your email address.",
    "order_details_sent_to_email_check_history_for_details": "All order details have been sent to your e-mail address. You can view your order history and order status at any time in your user account.",
    "timeline": "Timeline",
    "promotions": "Actions",
    "payment_method": "Payment method",
    "first_payment": "1. payment",
    "second_payment": "2. payment",
    "shipping_method": "Shipping method",
    "payment_1_e_payment_to_secure_your_order": "Deposit via electronic payment to reserve your vehicle.",
    "payment_2_choose_payment_method_for_the_car": "Select a payment method for your vehicle.",
    "payment_escrow": "Escrow account",
    "payment_car_credit": "Financing",
    "full_refund": "Full refund",
    "partial_refund": "Partial refund",
    "cancel_order": "Cancel order",
    "create_shipping": "Create dispatch",
    "schedule_car_registration": "Authorisation",
    "schedule_transport": "Plan transport",
    "is_published": "Is published",
    "no_salesman_available": "No seller is currently available",
    "any_questions": "Questions?",
    "feel_free_to_ask_our_online_sales_team": "Simply contact our online sales team",
    "please_complete_your_details": "Please complete your details",
    "registration_is_stressfull_get_your_car_registered": "For some people, registering a car is stressful and time-consuming. Get your car registered in your name without the hassle.",
    "review_your_order": "Check your order",
    "in_order_to_confirm_identity_please_complete_your_details": "To confirm your identity, we need some additional information:",
    "payment_instructions_will_be_provided_after_seller_accepts_order": "The seller will give you detailed payment instructions after your order has been accepted.",
    "nationality": "Nationality",
    "date_of_birth": "Date of birth",
    "place_of_birth": "Place of birth",
    "discover_more": "Discover more",
    "more_of_product": "More {product}",
    "more_of_product_in_city": "More {product} in {city}",
    "order_placed": "Order placed",
    "show_order_details": "Show order details",
    "white_label_plugins": "White label plugins",
    "white_label_plugin": "White label plugin",
    "enhance_chedri_project_with_plugins": "Improve creacheck experience with plugins",
    "plugin": "Plugin",
    "plugins": "Plugins",
    "enhance_your_project_with_plugins": "You can choose how much of Creacheck functionality you need. Check available plugins, their functions and prices.",
    "week": "week",
    "month": "month",
    "quarter": "Quarter",
    "year": "Year",
    "more_infos": "More information",
    "less_infos": "Less information",
    "activate_plugin": "Activate plugin",
    "partially_activate_plugin": "Sign plugin activation",
    "terminate_plugin": "Exit plugin",
    "detailed_description": "Detailed description",
    "installation_instructions": "Installation instructions",
    "you_are_not_an_adminitrator": "You are not an administrator",
    "creditor_id": "Creditor ID",
    "i_understand_the_cost_and_want_activate_plugin": "I understand the costs and would like to activate the plugin",
    "i_understand_the_running_cost_when_terminating_plugin": "I understand the costs that will be charged at the end of the billing period",
    "are_you_sure_you_want_to_terminate_plugin": "Do you really want to stop the plugin?",
    "security_settings": "Security settings",
    "limit_write_rights": "Restrict write permissions",
    "limit_read_rights": "Restrict read rights",
    "edit_your_company_data": "You can edit your company details here. Change the contact and financial data used for billing purposes",
    "latest_order": "Last order",
    "latest_orders": "Last orders",
    "latest_invoices": "Last invoices",
    "latest_editions": "Last edited",
    "overdue_invoices": "Invoices due",
    "view_all": "Show all",
    "i_accept": "I accept",
    "accept_terms_before_continuing": "Before you continue, you must accept our terms and conditions and privacy policy",
    "approve_car_sale": "Authorise car sale",
    "enter_vin_number": "Enter the chassis number of the vehicle",
    "mark_as_paid": "Mark as paid",
    "enter_ein_number": "Enter electronic insurance confirmation",
    "send_payment_instructions": "Send payment instructions",
    "submit_vin_number": "Send the chassis number of the vehicle",
    "approve_and_inform_client": "Authorise and inform the customer",
    "submit_ein_number": "Send electronic insurance confirmation",
    "ein": "Electronic insurance confirmation",
    "payment_reference": "Payment reference",
    "upload_sales_cotract": "Upload sales contract",
    "cancellation_reason": "Reason for cancellation",
    "enter_bank_data": "Enter bank details",
    "pickup_invite": "Invitation to pick up",
    "mark_picked_up": "Mark as collected",
    "refunds": "Refunds",
    "sent_on_date": "Sent on {date}",
    "not_sent_yet": "Not yet dispatched",
    "pick_up_location": "Pick-up location",
    "emails": "Emails",
    "use_following_list_to_personalize_your_emails": "Use the following list to personalise emails:",
    "recipients_first_name": "First name of the recipient",
    "recipients_last_name": "Last name of the recipient",
    "senders_company_name": "Company name of the sender",
    "system_mail_registration_description": "Sent when a new user registers on the website",
    "system_mail_invitation_description": "Sent when an employee creates a new user with an email address",
    "system_mail_car_rental_pickup_reminder_description": "Sent one day before the customer collects their hire car",
    "system_mail_car_rental_dropoff_reminder_description": "Sent one day before the customer hands in their hire car",
    "terms_of_use": "Terms of use",
    "contract": "Contract",
    "order_data_processing": "Order processing contract",
    "i_read_and_accept_plugins_terms_of_use": "I have read and understood the terms of use and expressly accept them.",
    "i_read_and_accept_plugins_contract": "I have read and understood the contract and expressly accept it.",
    "i_read_and_accept_plugins_order_data_processing": "I have read and understood the order data processing and expressly accept it.",
    "send_activation_request": "Send activation request",
    "missing_companys_bank_data": "Seems we are missing your company's bank details.",
    "you_can_fill_missing_data_in_settings": "You can enter missing data in {settings}.",
    "not_sole_representative_click_below_to_request_plugin_activation": "You are not authorised to activate plugins yourself. Click on the button to activate the plugin. The authorised user is prompted to complete the activation process.",
    "not_authorized_click_below_to_request_plugin_activation": "You are not authorised to activate plugins. Click on the button to send an activation request to authorised users.",
    "select": "Select",
    "enter_app_secret": "Enter app secret",
    "enter_page_access_token": "Enter page access token",
    "legal": "Legal",
    "activation_pending": "Pending activation",
    "termination_pending": "Cancellation pending",
    "form_contains_errors": "There are some problems in the form",
    "internal_car_reference": "Internal car reference",
    "system_debtor_number": "System debitor number",
    "internal_company_reference": "Internal company reference",
    "internal_location_reference": "Internal location reference",
    "show_notes": "Show notes",
    "show_my_notes": "Show my notes",
    "show_by_me": "Show from me",
    "items": "Products",
    "send_text_message": "Send SMS",
    "wrote_message": "Message written",
    "sent_text_message": "One SMS sent",
    "wrote_note": "Note written",
    "please_check_document_check_expiration_date": "Please check the document. Make sure that you have seen the document. Check the expiry date",
    "i_reviewed_the_document": "I have checked the document",
    "receive_updates_in_messenger": "Would you like to receive updates in Messenger?",
    "weve_received_your_request_and_will_contact_you": "We have received your enquiry and will get in touch with you as soon as possible",
    "check_your_email_to_confirm": "Please check your e-mail to confirm the sending of this contact form.",
    "response_text": "Answer text",
    "entries": "Entries",
    "timeline_block": "Timeline block",
    "external_accounts": "External accounts",
    "send_copy_to_me": "Send a copy to me",
    "user_tracking": "User tracking",
    "select_model": "Select model",
    "select_model_group": "Select model group",
    "equipment_level_budget": "Budget",
    "equipment_level_family": "Family",
    "equipment_level_comfort": "Comfort",
    "equipment_level_business": "Business",
    "salutation": "Salutation",
    "getting_started": "First steps",
    "set_your_locations": "Define your locations",
    "set_up_locations_for_your_car_business_set_services_opening_hours_upload_photos": "Each vehicle requires a location from which customers can choose. Go to the settings where you can define all the desired locations. There you can decide which services you offer at each location, set opening hours and upload photos to make it easier for customers to find your business.",
    "now_that_locations_are_set_create_users_so_they_can_work_together": "Now that your customers know where you do business, let us know who works for you. Please create users so they can collaborate using Creacheck technologies",
    "cms_content": "CMS content",
    "this_is_blank_page_use_editor_to_create_great_one": "This is a blank page. In our visual editor you can create a beautiful page with graphics, beautiful typography and compatible with all devices.",
    "cars_to_sell": "Vehicles for sale",
    "your_business_is_car_sales_but_there_are_no_cars_create_yourself_or_use_import": "Your business is to sell vehicles. Ours is to make it easier for you. You have not yet entered any vehicles in our system. Simply use the convenient vehicle import function or enter your vehicles manually.",
    "create_manually": "Create manually",
    "import": "Import",
    "car_import": "Import vehicles",
    "most_car_dealers_use_mobilede_enter_your_interface_username_and_password_to_import": "As we found out, most car dealers use mobile.de to present their cars. So you most likely have access to the web service. Please enter your username and password. We will import all your cars automatically and keep your listing synchronised. If you have several login details, you can enter more in your profile.",
    "we_will_import_count_cars_for_this_username": "We will import {count} cars for this username",
    "mobile_de_username": "Mobile.de Seller API username",
    "mobile_de_password": "Mobile.de Seller API Password",
    "missing_bank_data": "Missing bank details",
    "we_dont_have_your_bank_data": "Apparently we do not have your bank details",
    "monthly_billing_relies_on_sepa_direct_debit_fill_in_bank_data_in_settings": "Our monthly billing is based on SEPA direct debit. As we do not have any bank details, you cannot use many of our plugins. Please go to the settings and enter your bank details.",
    "fill_in_bank_data": "Enter the bank details",
    "sepa_mandate": "SEPA mandate",
    "sepa_mandates": "SEPA mandates",
    "missing_sepa_mandate": "We are missing a SEPA mandate",
    "changed_bank_data_please_generate_new_sepa_mandate": "It seems you have recently changed your bank account number. To automate our monthly billing process and create less stress for you, please create a new SEPA mandate.",
    "generate_sepa_mandate_now": "Generate a new SEPA mandate",
    "columns": "Columns",
    "send_doc_as_message": "Send document to the customer (e-mail)",
    "alternative_recepient": "Alternative receiver",
    "enter_email_address": "Please enter a valid e-mail address",
    "job_title": "Job title",
    "service_point": "Service",
    "rental_point": "Rental base",
    "seller_data": "Seller data",
    "car_location": "Vehicle location",
    "save_data_before_nav_to_pathname": "Your data will not be saved. Would you like to switch to {pathname} without saving?",
    "share": "Share",
    "assignee_changed_from_assignee_to_assignee": "The assignee has been changed from %oldAssignee% to %newAssignee%",
    "stage_changed_from_name_to_name": "The level has been changed from %oldName% to %newName%",
    "handover_filled_out": "Vehicle handover protocol has been completed",
    "data_changed": "Data updated",
    "key": "key",
    "old_value": "Old value",
    "new_value": "New value",
    "no_details_available": "Detailed information is not available",
    "services_we_offer_in_city": "Services that we offer for you in {city}",
    "damages_present": "Damage present",
    "user_is_online": "User is online",
    "user_is_offline": "User is offline",
    "click_to_start_chat": "Click here to start the chat",
    "click_to_view_chat_history": "Click here to view the chat history",
    "theme_colors": "Theme colours",
    "logos": "Logos",
    "send_invitation_with_username_and_password": "Send invitation with user name and password (only for new users)",
    "cookie_settings": "Cookie details",
    "accept_only_necessary_cookies": "Only accept necessary cookies",
    "accept_all_cookies": "Accept all cookies",
    "privacy_preference_center": "Data protection preference centre",
    "your_privacy": "Your privacy",
    "basic_information_on_cookies": "When you visit a website, information may be stored or retrieved in your browser, usually in the form of cookies. This information may be about you, your preferences or your device. This information is mainly used to make the website work as expected. The information does not usually identify you directly, but can provide a more personalised web experience. Because we respect your right to privacy, you can choose not to allow certain types of cookies. Select a category to learn more and change our default settings. However, blocking some types of cookies may impact your experience of the website and the services we are able to offer.",
    "necessary_cookies": "Necessary cookies",
    "performance_cookies": "Performance cookies",
    "targeting_cookies": "Targeting cookies",
    "always_active": "Always active",
    "cookies_used": "Cookies used",
    "necessary_cookies_information": "These cookies are necessary for the website to function and cannot be disabled in our systems. They are usually only set in response to actions you take that relate to a service request, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the website will not work. These cookies do not store any personally identifiable information.",
    "save_settings": "Save settings",
    "save_and_continue": "Save and continue",
    "priority": "Priority",
    "closed_leads": "Closed leads",
    "show": "Display",
    "hide": "Hide and seek",
    "first_registration_from": "First registration from",
    "first_registration_to": "First registration until",
    "power_from": "Engine power from",
    "power_to": "Motor power up to",
    "mileage_from": "Mileage from",
    "mileage_to": "Mileage to",
    "included_mileage": "{mileage} kilometres",
    "really_cancel": "Really cancel?",
    "unsaved_information_will_be_lost": "Unsaved data is lost",
    "client_signature": "Customer signature",
    "another_photo": "Another photo",
    "try_again": "Try again",
    "back_to_vehicle": "Back to the vehicle",
    "image_upload_finished": "The upload of the images is complete",
    "you_have_no_internet_connection": "You do not have an Internet connection",
    "waiting_to_reconnect": "Waiting for reconnection",
    "plus_count_more": "+ {count} more",
    "document_is_missing": "One document is missing",
    "upload_document_name_now": "Upload document '{name}' now",
    "lead_per_mail": "Lead by e-mail rule",
    "create_rules_to_forward_emails": "Create rules to forward emails as a copy to other users. If the 'Data e-mail plugin' is activated, you can also forward e-mails with important information in XML format directly to Customer One.",
    "lead_per_mail_rules": "Lead by e-mail rules",
    "include_xml_data_in_email": "Insert data in XML format into the e-mail",
    "sad_activity": "SAD activity",
    "e_payments": "E-payments",
    "stripe_connect": "Stripe Connect",
    "we_will_handle_epayments_for_you": "With Stripe Connect, we can process credit card payments on your behalf. After clicking \"Connect to Stripe\", you can use your existing Stripe account to connect to the Creacheck platform or create a new Stripe account if you don't have one yet. With your own Stripe account, you can see your entire payment history and withdraw money at any time.",
    "connect_with_stripe": "Connect with Stripe",
    "stripe_accounts": "Stripe accounts",
    "stripe_account_connected": "Your Stripe account is now connected",
    "stripe_accounts_are_connected": "Your Stripe account is now connected to {projectName}. {projectName} can now accept credit card payments on your behalf.",
    "money_won": "Money won",
    "leads_won": "Leads gained",
    "offered_by": "Your car dealer",
    "pay_with_saved_credit_card": "Pay with your stored credit card",
    "pay_with_new_credit_card": "Pay with a new credit card",
    "settings": "Settings",
    "account": "Functions",
    "clients": "Companies",
    "payments": "Payments",
    "calendar": "Calendar",
    "contact": "Contact us",
    "login": "Log in",
    "logout": "Logout",
    "previous_odometer_reading": "Previous odometer reading",
    "current_odometer_reading": "Current mileage",
    "odometer_reading": "Mileage",
    "tank_volume": "Tank volume",
    "vehicle_fuel_costs": "Vehicle refuelling costs",
    "mileage_surcharge": "Additional kilometre surcharge",
    "insurance_self_participation": "Insurance excess",
    "additional_costs_due": "Additional costs due",
    "no_additional_costs": "No additional costs",
    "charge_customer_additional_costs": "Charge the customer additional costs",
    "warning_you_chose_not_to_charge_customer_for_additional_costs": "Attention! You have decided not to charge the customer any additional costs",
    "cancel_booking": "Cancel booking",
    "no_invoices_found": "No invoices found",
    "upload_drivers_license": "Upload driving licence",
    "order_details": "Order details",
    "shipping_address": "Shipping address",
    "order_overview": "Order overview",
    "booked_by_mistake": "Booked by mistake",
    "booked_wrong_dates": "Wrong dates booked",
    "found_better_price": "Better price found",
    "change_of_plans": "Change of plan",
    "client_called_to_cancel": "The customer called and asked to cancel",
    "client_wrote_email_to_cancel": "The customer has written an email and asked to cancel",
    "car_damaged_before_transport": "The vehicle was damaged before transport",
    "car_damaged_in_transport": "The vehicle was damaged during transport",
    "item_not_available": "Article no longer available",
    "other_reason": "Other",
    "license_plate_number": "Licence plate",
    "open_in_google_maps": "Open in Google Maps",
    "access_identifier": "Access code",
    "branch": "Branch",
    "new": "New",
    "in_progress": "In progress",
    "failed": "Failed",
    "not_started": "Not started",
    "finished": "Finished",
    "failed_refunds": "Failed refunds",
    "list_all_failed_refund_payments": "List all unsuccessful refunds with details",
    "rent_sync_vamoso_reservation_success": "Successfully reserved in the Vamoso system. The reservation number is %reservationId%",
    "rent_sync_vamoso_reservation_failure": "It was not possible to reserve the car in the Vamoso system. The system returned the following error message: \"%errorText%\"",
    "rent_sync_vamoso_cancellation_success": "The reservation in the Vamoso system was successfully cancelled",
    "rent_sync_vamoso_cancellation_failure": "It was not possible to cancel the reservation in the Vamoso system. The system returned the following error message: \"%errorText%\"",
    "rent_sync_vamoso_booking_success": "Successfully booked in the Vamoso system",
    "rent_sync_vamoso_booking_failure": "Booking the reservation in the Vamoso system was not possible. The system returned the following error message: \"%errorText%\"",
    "rent_sync_vamoso_return_success": "The vehicle was successfully marked as returned in the Vamoso system",
    "rent_sync_vamoso_return_failure": "It was not possible to return the car to the Vamoso system. The system returned the following error message: \"%errorText%\"",
    "view_lead_details": "Show lead details",
    "change_category": "Change category",
    "json_url_parameters": "JSON URL parameters",
    "layout_version": "Layout version",
    "layout_wide": "Broad",
    "layout_narrow": "Narrow",
    "layout_detailed": "Detailed",
    "layout_slider": "Slider",
    "layout_list": "List",
    "search_version": "Search module version",
    "not_sure_go_to_advisory_mode": "I'm not sure: here is the {link}",
    "link_to_advisory_mode": "Counselling mode",
    "desired_pickup_location": "Your desired pick-up location",
    "incl_short": "incl.",
    "included": "Included",
    "included_services": "Included services",
    "insurance": "Insurance",
    "liability_and_comprehensive_insurance_with_excess_amount_of_amount_per_claim": "Liability and fully comprehensive insurance with an excess of {amount} per claim",
    "tyres_suitable_for_winter": "Winter tyres",
    "additional_driver": "Additional driver",
    "on_request": "on request",
    "navigation_system": "Navigation system",
    "child_seat": "Child seat",
    "order_comments_and_special_wishes": "Further wishes",
    "ordering_as_person": "I order as a private individual",
    "ordering_as_company": "I order as a company",
    "filter_by_date": "Filter by date",
    "car_rental_max_days_count_exceeded": "Unfortunately, the selected period exceeds our maximum rental period of {count, plural, one {# day} other {# days}}",
    "require_document_number": "Request document number",
    "require_document_issuing_city": "Request the place of issue of the document",
    "require_document_issuing_authority": "Request issuing authority of the document",
    "require_document_issue_date": "Request date of issue of the document",
    "require_document_expiration_date": "Request expiry date of the document",
    "doc_number": "Document number",
    "doc_issuing_city": "Place of issue of the document",
    "doc_issuing_authority": "Issuing authority of the document",
    "doc_issue_date": "Date of issue of the document",
    "doc_expiration_date": "Expiry date of the document",
    "chedri_plugins": "Creacheck Plugins",
    "in_order_to_use_our_software_in_its_full_potential_you_need_to_activate_plugins_in_order_to_do_that_please_confirm_that_you_may_sign_contracts_on_behalf_of_your_company": "<p>In order to use our software, you must activate the desired functions. Each function is delivered in the form of a plugin, which is usually accompanied by a payment obligation (fee).<br/>When you activate a plugin, you will be asked to accept the plugin terms and conditions. However, before you can proceed, you must confirm that you are authorised to act on behalf of your company.</p",
    "i_declare_that_i_am_entitled_to_legally_sign_contracts_on_behalf_of_my_company": "I declare that I am authorised to sign legally binding contracts on behalf of my company, {company}",
    "your_company_was_successfully_registered": "We have successfully registered you and your company in our system",
    "check_email_for_more_instructions_about_your_company_registration": "We have sent information on how to proceed to the e-mail address you provided. Please check your inbox. If you have any questions or do not know how to proceed, please contact us.",
    "dependent_on": "Depending on",
    "we_have_more_plugins_that_further_enhance_the_functionality": "We have other plugins that further improve the functionality of {plugin}.",
    "finish_plugin_configuration": "Exit plugin configuration",
    "it_seems_that_you_havent_finished_configuring_plugins": "It appears that you have not yet completed the configuration of the activated plugins. External login data must be defined for some plugins (e.g. for the mobile.de interface or Vamoso login data). Please go to the settings and add missing accounts.",
    "lead_created_from_an_email": "This lead was created from an email",
    "show_original_message": "Show original message",
    "we_found_count_similar_leads": "We have found {count, plural, one {# similar lead} other {# similar leads}}",
    "show_similiar_leads": "Show similar leads",
    "new_lead_from_failed_refund": "New lead due to failed refund",
    "you_can_apply_company_data_from_company_profile": "You can transfer company data from the company profile",
    "load_company_data": "Load company data",
    "company_lookup": "Company search",
    "match_lead_tags": "Synchronise lead tags",
    "is_active": "Is active",
    "is_renew_password_at_next_login": "Renew password at next login",
    "no_overdue_tasks": "There are no overdue tasks",
    "great_job": "Well done!",
    "no_leads_for_today": "No leads for you today",
    "address_not_set": "Address not set",
    "statistics": "Statistics",
    "select_assignee": "Select an editor",
    "kroschke_order_in_progress": "Kroschke order with vehicle ID %kroschkeVehicleId% was successfully initialised",
    "in_original": "In the original",
    "a_copy": "One copy",
    "mark_documents_sent": "Mark documents as sent",
    "i_sent_the_documents": "I have sent the documents",
    "documents_were_sent": "Documents have been sent",
    "documents_sent": "Documents sent",
    "define_custom_license_plate_number": "Define preferred licence plate",
    "about_car_registration": "The authorisation service includes",
    "i_would_like_individual_license_plate_number": "Yes, I would like to select my desired character",
    "we_gather_necessary_documents": "Registration with your licensing authority",
    "we_produce_license_plates": "Procurement and mounting of licence plates",
    "license_plate_reservation_number": "Reservation number of the licence plate",
    "with_custom_license_plate_number": "With personalised number plate",
    "after_the_order_reserve_desired_license_plate_at_local_registration_office": "After ordering, you must reserve your desired licence plate online at your local registration office.",
    "inform_us_about_your_license_plate_reservation_so_we_can_start_the_process": "Once you have done this, please let us know your desired licence plate number and your reservation number (which you received from the registration office).",
    "clarify": "Clarify",
    "notification": "Communication",
    "license_plate_reservation_data_missing": "The reservation data for the licence plate is missing",
    "license_plate_reservation_number_filled_out": "Reservation data for licence plates entered. Licence plate number: %licensePlateNumber%. Reservation number: %reservationNumber%",
    "distance_planned": "Planned route",
    "distance_driven": "Route travelled",
    "planned_pickup_date": "Planned collection date",
    "pickup_date": "Collection date",
    "planned_delivery_date": "Planned delivery",
    "your_initial_offer": "Your first offer",
    "your_next_offer": "Your next offer",
    "car_number": "Vehicle number",
    "send_price_proposal": "Send price proposal",
    "our_price": "Our price",
    "purchase_price": "Purchase price",
    "our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it": "We try to optimise our prices for you, but if you think the price is still too high, let us know what we can do for you.",
    "select_the_car_you_love": "Select desired car",
    "sign_up_or_log_in_if_you_already_have_an_account": "Register or log in if you already have an account",
    "enter_desired_price": "Enter the desired price",
    "send_the_form_our_employee_will_contact_you": "Send form. Our employee will contact you as soon as possible",
    "if_we_settle_on_a_price_you_can_buy_the_car_online": "If we agree on a price, you have the option of buying the car directly on our website",
    "you_have_to_log_in_to_proceed": "You must log in to continue",
    "rent_a_car": "Rent a vehicle",
    "pending_price_negotiations_count": "Pending {count, plural, one {# price proposal} other {# price proposals}}",
    "my_price_negotiation": "My price suggestion",
    "price_negotiation": "Price proposal",
    "price_negotiations": "Price suggestions",
    "new_price_negotiation_request": "New price negotiation enquiry",
    "expired": "Expired",
    "first_offer": "First offer",
    "current_offer": "Current offer",
    "expires": "Expires",
    "accept_the_offer": "Accept offer",
    "send_a_new_offer": "Send new offer",
    "your_new_offer": "Your new offer",
    "your_project_dashboard": "Your {project} dashboard",
    "add_my_vehicle": "Add my vehicle",
    "no_reply_yet": "No answer yet",
    "employee_replied": "Employee has replied",
    "price_negotiation_details": "Price proposal details",
    "current_offer_is_amount_you_can_now_proceed_to_checkout": "Your current offer is {amount}. You can proceed to checkout with your negotiated price.",
    "show_timeline": "Show history",
    "communities": "Communities",
    "gain_coverage_with_community": "Increase your reach with new platforms. Acquire new customers with just one click",
    "community": "Community",
    "is_community": "Community active",
    "join_community": "Join Community",
    "leave_community": "Leave community",
    "join_jahreswagen_community_and_profit_from_additional_sales_channel": "<div><p>One thing that is completely new in Creacheck is communities. Thanks to the Creacheck software, it is possible to create platforms for multiple companies to join.</p><p>One such platform is Jahreswagen.de. You can take a look at the offers of Jahreswagen. If you want to become a member, go to the community settings and join the Jahreswagen community.</p><p>Membership is free. However, transaction fees apply.</p><p className='m-t-20 alert alert-info'>If you have your own idea for a community, get in touch with us. We will then find a solution for you.</p></div>",
    "car_description": "Vehicle description",
    "marketplace_terms": "Terms of use of the marketplace",
    "online_appointment_booking": "Online appointment booking",
    "my_online_appointments": "My online appointments",
    "employee": "Employees",
    "choice_of_date": "Choice of date",
    "works": "Working",
    "make_selection": "Select",
    "remove_selection": "Remove",
    "tasks": "Tasks",
    "task": "Task",
    "car_service": "Service",
    "car_services": "services",
    "car_service_station": "Workstation",
    "car_service_stations": "Workstations",
    "services_offered_at_your_locations": "Define services at your locations.",
    "service_stations_where_your_employees_work": "Define workstations so that you can track utilisation and see occupied and free resources.",
    "team_members": "Team members",
    "service_details": "Service Details",
    "service_location": "Your chosen service location",
    "service_drop_off_time": "Your selected service time",
    "car_service_appointments": "Workshop dates",
    "car_service_booked_at": "Appointment booked",
    "show_booking_details": "Show booking details",
    "saving_draft": "Draft is saved",
    "draft_saved": "Draft saved",
    "define_custom_page_templates_to_meet_your_cd": "We supply the Creacheck system with integrated templates. Here you can create your own templates to better match your corporate design.",
    "edit_description": "Edit description",
    "edit_language": "Edit language",
    "create_blank_page": "Create a blank page",
    "create_from_template": "Create from template",
    "use_template": "Use the template",
    "time_left": "Remaining time",
    "animated_header": "Animated header",
    "header_size": "Header size",
    "options": "Options",
    "effects": "Effects",
    "animation": "Animation",
    "parallax": "Parallax",
    "animation_repeat": "Repeat animation",
    "animation_intensity": "Animation intensity",
    "animation_duration": "Animation duration",
    "animation_delay": "Animation delay",
    "animation_initial_opacity": "Initial opacity of the animation",
    "animation_speed_curve": "Animation speed curve",
    "parallax_intensity": "Parallax intensity",
    "animation_direction": "Parallax direction",
    "parallax_initial_opacity": "Initial covering power of the parallax",
    "append_html": "Attach HTML",
    "css_classes": "CSS classes",
    "forward_url": "Redirect to this URL",
    "effect_fade": "Fade in",
    "effect_slide": "Slides",
    "effect_zoom": "Zoom",
    "effect_bounce": "Bounce",
    "effect_flip": "Flip",
    "effect_fold": "Folds",
    "effect_roll": "Rollers",
    "direction_center": "Centre",
    "direction_left": "Links",
    "direction_right": "Right",
    "direction_top": "Above",
    "direction_bottom": "Below",
    "repeat_once": "Once",
    "repeat_loop": "Loop",
    "selected_template": "Selected template",
    "premade_layouts": "Prefabricated layouts",
    "your_existing_pages": "Your existing pages",
    "count_templates": "{count} Templates",
    "background_attachment": "Background appendix",
    "background_position": "Background position",
    "background_repeat": "Background Repetition",
    "background_size": "Background size",
    "edit_mode": "Edit mode",
    "effects_active": "Effects active",
    "document_templates": "Document templates",
    "code_document_templates_for_print_use_the_templates_for_individual_quotes_and_invoices": "Code your own document templates to print them for your customers. You can also design your own versions of quotes and invoices.",
    "currencies": "Currencies",
    "document_template": "Document template",
    "document_content": "Document content",
    "number_prefix": "Number prefix",
    "sales_documents": "Sales documents",
    "theme": "Theme",
    "choose_template": "Select document template",
    "default": "Standard",
    "favorites": "Favourites",
    "favorites_list": "Favourites list",
    "require_client_address": "Request the customer's address",
    "require_client_email": "Request e-mail address of the customer",
    "require_client_phone": "Request the customer's telephone number",
    "require_message": "Request message",
    "subscription": "Subscription",
    "subscription_plan": "Subscription plan",
    "main_plugin": "Main plugin",
    "target_organisation": "Target organisation",
    "reference_number": "Reference number",
    "show_invoice": "Show invoice",
    "delivery_date": "Delivery date",
    "test_drive": "Test drive",
    "find_your_perfect_car": "Find the perfect vehicle",
    "select_car_design": "Select design",
    "multiple_selection_possible": "Multiple selection possible",
    "fuel_type": "Fuel",
    "select_equipment_level": "Select equipment level",
    "engine": "Motorisation",
    "like_budget": "Like budget",
    "like_family": "Like Family",
    "like_comfort": "Like comfort",
    "we_did_not_find_any_results_for_your_search": "Sorry, your search did not match",
    "we_did_not_find_any_cars_at_this_location_for_the_selected_period": "We did not find any cars at this location for the selected period",
    "we_did_not_find_any_cars_for_your_seaech": "Unfortunately we have not found any cars for your search query",
    "all_cars": "Show all cars",
    "search_for_cars": "Search for cars",
    "used_car_search": "Used car search",
    "dropdown": "Drop-down list",
    "checkboxes": "Checkbox",
    "multiple_choice": "Multiple selection",
    "text_input": "Text input",
    "number_input": "Number input",
    "multiline_input": "Multiline text input",
    "paragraph": "Section",
    "change_questionnaire": "Change questionnaire",
    "answers_to_a_questionnaire": "Answers to a questionnaire",
    "request_filling_out_questionnaire": "Send your customers a questionnaire",
    "send_questionnaire_as_message": "Send the questionnaire as a message",
    "ingoing": "Incoming",
    "outgoing": "Outgoing",
    "vat_short": "VAT.",
    "vat": "Value added tax",
    "price_net": "{price} net",
    "environment": "Environment",
    "inquire": "Enquiries",
    "continue_to_product_name": "Continue to {productName}",
    "you_can_find_this_and_other_vehicles_at_company": "You can find this and other vehicles at {company}",
    "more_vehicles_based_on_your_search": "More vehicles based on your search",
    "add_to_favorites": "Save as favourite",
    "remove_from_favorites": "Remove from favourites",
    "share_the_offer": "Share offer",
    "any_question_the_company_team_is_at_your_disposal": "Do you have any questions? The {company} team is at your disposal.",
    "you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal": "You want this vehicle but the price is not quite right? Submit your price proposal.",
    "you_want_to_rent_this_vehicle_at_project_name_you_can": "You would like to drive this vehicle for a longer period of time, at {projectName} you have the possibility to rent your dream vehicle.",
    "uploaded_video": "Upload video",
    "lead_autoresponse": "Lead autoresponse",
    "automatically_respond_to_incoming_leads": "Responds automatically to incoming leads. Define days of the week and hours when the autoresponder is active.",
    "client_gave_count_answers_to_the_name_questionnaire": "The customer gave {count} answers to the questionnaire \"{name}\"",
    "new_lead_from_comms_center": "New lead after closed communication",
    "customer_chat_ended": "Chat with a customer ended",
    "chatter_welcome_message": "You are very welcome. Thank you for trying out our chat. How can I help you today?",
    "please_rate_us": "Please rate us",
    "reservation_fee": "Reservation fee",
    "item_name": "Article name",
    "deposit": "Down payment",
    "balance_due": "Remaining amount due",
    "authorize": "Authorise",
    "authorized_by_name_on_date": "Approved by {name} on {date}",
    "auto_generated": "Automatically generated",
    "not_authorized": "Not authorised",
    "settings_instructions": "Instructions for settings",
    "payment_config": "Payment configuration",
    "projects": "Projects",
    "invoice_address": "Billing address",
    "deliver_adress": "Delivery address",
    "your_contract_partner": "Your contractual partner",
    "remaining_balance": "Remaining amount",
    "item_filter_is_active": "Visible online",
    "item_filter_is_resource": "Is Resource",
    "item_filter_is_subscription": "Financing",
    "cancelled_by_name_on_date": "Cancelled by {name} on {date}",
    "add_credit_card_data_in_next_step": "In the next step you can add credit card details",
    "upon_collection": "On collection",
    "leads_manually_set_to_high_priority": "Leads manually set to high priority",
    "leads_with_no_reaction_for_2_hours_since_creation": "Leads without response for 2 hours since creation",
    "leads_that_were_not_updated_for_2_days": "Leads that have not been updated for 2 days",
    "use_this_element_as_reference": "Use this element as a reference",
    "you_can_reuse_this_element_on_multiple_pages": "Content that is reused on multiple websites can be referenced. Elements for the Express Page Builder cannot be defined as a reference. Such elements are always copied",
    "company_data": "Company data",
    "company_singup_company_data_hint": "We need the following information from you so that we can create your company and assign you a domain.",
    "company_singup_personal_data_hint": "<p>In order for you to receive your access with your own user data, we need the following information.<br/>You will then receive an e-mail with your personal access data.</p>",
    "your_test_domaine": "Your test domain name",
    "your_test_domaine_hint": "<p>Please create a domain name in which your project will be hosted. The domain will be available immediately after registration.<br/>Remember that we can also host projects under a domain name specified by you. Just contact us later.</p>",
    "features": "Features",
    "forms": "Forms",
    "pricings": "Prices",
    "testimonials": "References",
    "show_your_page_structure_and_responsive_visibility": "Displays your page structure with labels and responsive visibility",
    "toggle_edit_mode_for_page_preview": "Switch the editing mode for a page preview",
    "toggle_animation_and_parallax_effects": "Activate and deactivate animation and parallax effects",
    "use_quick_page_builder_to_create_content_faster": "Use the Quick Page Builder to create content much faster",
    "cancel_invoice": "Cancel invoice",
    "refunded": "Refunded",
    "invoice_mark_refunded": "Make the invoice refundable",
    "denied_to_pay": "Refusal to pay",
    "invoice_mark_denied_to_pay": "Will not pay",
    "registration_success": "Successfully registered",
    "no_images_yet": "No pictures yet.",
    "sortingPrice": "Sorting price",
    "main_menu_version": "Main menu version",
    "main_menu_versions": "Main menu versions",
    "administration_of_main_menu_versions": "Management of the main menu versions",
    "featured": "Highlighted",
    "list_element_class": "List element (left) CSS class",
    "link_class": "Link CSS class",
    "width": "Width",
    "primary_menu_elements": "Elements of the main menu",
    "secondary_menu_elements": "Elements of the secondary menu",
    "has_secondary_elements": "Has secondary elements",
    "active": "active",
    "company_singup_wizard_step_subscription_plan": "Select packages",
    "company_singup_wizard_step_subscription_plan_headline": "Select the package you want to test.",
    "company_singup_wizard_step_additional_plugins": "Additional packages",
    "company_singup_wizard_step_subscription_plan_main_plugin_headline": "I would like to test the following features:",
    "company_singup_wizard_step_company_data": "Contact details of your company",
    "company_singup_wizard_step_company_data_headline": "Name and contact details of your company",
    "company_singup_wizard_step_personal_data": "Your employee profile",
    "company_singup_wizard_step_personal_data_headline": "Your employee profile",
    "company_singup_wizard_step_personal_data_hint": "To be able to create an employee profile for you, we need your personal details. This account gives you administration rights for your %platformName% project.",
    "company_singup_wizard_step_personal_data_domain_hint": "<p>Please create a domain name in which your project will be hosted. The domain will be available immediately after registration.<br/>Remember that we can also host projects under a domain name you specify. Just contact us later.</p>",
    "company_singup_wizard_free_trial_hint": "Test our offers for one month free of charge and without obligation.",
    "company_signup_wizard_free_trial_plugin_hint": "Test one month free of charge, then:",
    "included_in_subscription_plan": "Included in the subscription, otherwise",
    "mostly_asked": "Most frequently asked",
    "test_package": "Test package",
    "active_since": "active since",
    "test_period_until": "Test phase until",
    "navigate_to_plugins_and_configure": "To the plugins overview and settings",
    "termination_date": "Date of termination",
    "lead_taggers": "Lead Taggers",
    "lead_tagger": "Lead Tagger",
    "share_with": "Share with",
    "password_weak": "Weak",
    "password_medium": "Medium",
    "password_strong": "Strong",
    "plugin_already_added_in_whitelabel_project": "The plugin is already assigned to the white label project.",
    "lead_channel": "Channel",
    "lead_channel_web": "Web",
    "lead_channel_mobile": "App",
    "lead_channel_phone": "Telephone",
    "lead_channel_email": "E-mail",
    "lead_channel_personally": "Personal",
    "allow_others_to_share": "Allow others to share with more people",
    "additional_imprint_information": "Additional imprint information",
    "share_with_the_company_i_work_for": "With the company I work for, I share",
    "share_with_all_companies_in_my_holding_structure": "With all the companies in my holding structure, share",
    "latest_lead": "Latest lead",
    "latest_edit": "Last editing",
    "reaction_time": "Response time",
    "no_reaction_time": "No reaction",
    "gt_2_days": "> 2 days",
    "lt_5_minutes": "< 5 minutes",
    "lt_1_hour": "< 1 hour",
    "lt_2_hours": "< 2 hours",
    "lt_6_hours": "< 6 hours",
    "lt_1_day": "< 1 day",
    "lt_2_days": "< 2 days",
    "by_employee": "By employee",
    "by_company": "By company",
    "by_location": "By location",
    "by_source": "According to source",
    "client_data": "Customer data",
    "show_in_lead_description": "Show in the lead description",
    "this_answer_will_be_shown_in_lead_description": "This response is displayed in the lead description",
    "this_answer_will_not_be_shown_in_lead_description": "This answer is not displayed in the lead description",
    "lead_assignment_strategy": "Lead assignment strategy",
    "lead_assignment_strategies": "Lead assignment strategies",
    "change_the_lead_assignment_strategies_for_companies_and_pipelines": "Change the way your employees are assigned leads based on their organisations and pipelines.",
    "strategy": "Strategy",
    "deleted": "Deleted",
    "assign_to_me": "Assign to me",
    "responsibilities": "Responsibilities",
    "escalation_rules": "Escalation rules",
    "escalation_rule": "Escalation rule",
    "define_rules_when_you_would_like_to_inform_users_about_overdue_leads": "Defines rules that your teams can use to identify leads that have been abandoned or that team members are not responding to.",
    "category_images": "Category images",
    "use_document_template": "Use document template",
    "escalation_level_one": "Stage one - inform employees",
    "escalation_level_two": "Step two - inform team leader",
    "automatically_terminate_the_lead": "End lead automatically (mark lead as lost)",
    "no_reaction": "No reaction",
    "no_reaction_for": "No reaction for",
    "no_update": "No update",
    "no_update_for": "No update for",
    "escalation_level": "Escalation level",
    "time_settings": "Time settings",
    "pending": "Stands out",
    "skipped": "Skipped",
    "holding_structure": "Holding structure",
    "show_sibling_companies": "Sibling companies show",
    "show_descendant_companies": "Show subordinate companies",
    "subscription_type": "Financing model",
    "no_subscription_type_selected": "At least one financing model must be selected.",
    "financing": "Financing",
    "monthly_payment": "Monthly instalment",
    "final_payment": "Final instalment",
    "interest_rate": "Interest rate",
    "availability": "Availability",
    "delivery_time_month": "Delivery time (in months)",
    "delivery_time": "Delivery time",
    "cancelation_period_month": "Cancellation period (in months)",
    "enter_financing_data": "Enter financing data",
    "marketing_agency": "Marketing agency",
    "edit_company_read_and_write_rights": "Edit the company's read and write permissions",
    "grant_data_access": "Grant data access",
    "deny_data_access": "Deny data access",
    "allow_data_access_for_a_single_company": "Enable data access for a single company",
    "allow_data_access_for_a_whole_level": "Enable data access for an entire level",
    "deny_data_access_for_a_single_company": "Deny data access for a single company",
    "deny_data_access_for_a_whole_level": "Deny data access for an entire level",
    "closing_costs": "Closing fee",
    "nominal_interest_rate": "Target interest rate",
    "has_rate_insurance": "Payment protection",
    "is_user_based": "Is user-based",
    "min_user_count": "Min users Number",
    "max_user_count": "Max number of users",
    "price_per_user": "Price per user",
    "monthly": "Monthly",
    "instantly": "Immediately",
    "quarterly": "Quarterly",
    "annual": "Annually",
    "monthly_fee": "Monthly fee",
    "starting_fee": "Entry fee",
    "included_templates_count": "Number of templates included",
    "simple_templates_count": "Number of templates included (single)",
    "middle_templates_count": "Number of templates included (average)",
    "complex_templates_count": "Number of included templates (Complex)",
    "surcharge": "Surcharge",
    "base_surcharge": "Basic surcharge",
    "max_surcharge": "Maximum surcharge",
    "dataproof_surcharge": "Dataproof surcharge",
    "aws_domain_hosting": "Domain hosting via AWS",
    "domain_hosting": "Website hosting incl. domain",
    "desired_domain": "Desired domain",
    "redirect_uri": "Forwarding",
    "check_availability": "Check availability",
    "cart_edition_title": "Designation:",
    "cart_recent_edition": "Last edited:",
    "cart_campaign_title": "Campaign:",
    "cart_last_saved": "Last saved:",
    "remove_item": "Remove product",
    "config": "Configure",
    "submit_config": "Confirm configuration",
    "complete_order": "Finalise order",
    "payment_method_description": "Please select the desired payment method",
    "cart_hint": "Note:",
    "cart_hint_description_part_1": "Please only enter general comments here. Comments on products can only be taken into account if you have selected the option",
    "cart_hint_description_part_2": "Manual data check",
    "cart_hint_description_part_3": "and have entered the comment for the respective product.",
    "your_message": "Your message to us",
    "hint_dataproof_1": "You have waived the manual data check by our expert for one or more items.",
    "hint_dataproof_2": "We would like to point out that in this case you bear full responsibility for your own design. In the event of data errors, incorrect colours in photos, etc., a complaint is excluded. Our general terms and conditions apply.",
    "hint_dataproof_3": "If you opt for the manual data check, your file will be checked by an expert for any errors (technical check, no spelling). In the event that we overlook an error, we assume responsibility. No risk for you!",
    "hint_missing_order_quantity": "Order quantity missing!",
    "missing_order_quantity": "You have not selected an order quantity for one or more items.",
    "missing_invoice_shipping_address": "The billing or delivery address is missing. Please select an address.",
    "billing_address_form_is_not_submitted": "The billing address has not been submitted. Please complete the form.",
    "shipping_address_form_is_not_submitted": "The delivery address has not been submitted. Please fill in the form.",
    "no_invoice_address": "No billing address created",
    "no_delivery_address": "No delivery address created",
    "use_invoice_contact": "Use billing contact",
    "order_item": "Article",
    "order_price": "Price",
    "order_copies": "Edition",
    "order_totals": "Order totals",
    "subtotal": "Subtotal",
    "invoice_amount": "Invoice amount",
    "remainder_available": "Undistributed remaining quantity available",
    "reduce_edition": "To add a new address, you must first reduce the number of copies of the existing address.",
    "split_delivery_item_hint": "The distribution of the total quantity has not yet been finalised. The quantity specification must be a value between 1 and the remaining quantity still to be distributed or the circulation; currently a value of no more than",
    "net_amount": "Net amount",
    "gross_amount": "Gross amount",
    "is_debtor": "Creditor",
    "is_debtor_number_provider": "Debtor number Provider",
    "is_never_remind": "Do not send reminders",
    "is_demo": "Demo mode",
    "is_vendor": "Supplier",
    "should_user_pay": "The user should pay",
    "receive_payment_from_users": "Receive payment from users",
    "use_payment_receiver": "Use payee option",
    "payment_receiver": "Payee",
    "use_summary_invoice": "Use collective invoice",
    "credit_limit": "Credit limit",
    "commission_receiver": "Commission recipient",
    "budget_value": "Budget value",
    "billing_value": "Settlement value",
    "coupon_billing": "Billing value for selected billing period",
    "coupon_billing_period": "Billing period",
    "coupon_recharge_period": "Top up voucher",
    "coupon_print_type": "Restrict voucher",
    "coupon_never": "Never",
    "coupon_unset": "No restriction",
    "coupon_print_pdf": "Print PDF only",
    "recurring": "Recurring",
    "period": "Period",
    "without_end_date": "Without end date",
    "budget_settings": "Budget settings",
    "no_budget_set": "No budget set",
    "current_period_budget_usage": "Budget utilisation in the current period",
    "user_entered_no_message": "User has not entered a message",
    "coupons": "Vouchers",
    "coupon": "Voucher",
    "create_discounts_and_subsidy_products": "Create discount codes and subsidise products for your partners",
    "authorization_status": "Authorisation status",
    "user_budget": "User budget",
    "manual_vat_check": "Reduced VAT rate check",
    "manual_data_check": "Manual data check",
    "completed": "Completed",
    "changes_requested": "Changes requested",
    "rejected": "Rejected",
    "request_changes": "Request changes",
    "reply": "Answers",
    "coupon_monetary": "Fixed discount",
    "coupon_percentage": "Per cent discount",
    "calculations": "Calculations",
    "percent_discount_value": "Per cent discount",
    "monetary_discount_value": "Cash discount",
    "company_budget": "Company budget",
    "enter_0_to_ignore_this_setting": "Enter 0 to ignore this setting",
    "min_order_value": "Minimum order value",
    "max_order_discount": "Maximum discount per order",
    "coupon_budget_exceeded": "Voucher budget exceeded",
    "billing_target": "Billing target",
    "billing_settings": "Billing settings",
    "dataproof_textarea": "Do we need to consider anything?",
    "edit": "Edit",
    "edit_client": "Edit customer data",
    "edit_gallery": "Edit gallery details",
    "edit_job": "Edit job details",
    "edit_position": "Edit position",
    "edit_product": "Edit product",
    "edit_producttemplate": "Edit template",
    "edit_question": "Edit question",
    "edit_template": "Edit template",
    "edit_step": "Edit pipeline stage",
    "edit_payment": "Edit payment",
    "edit_team": "Edit team",
    "edit_document_type": "Edit document type",
    "edit_brand": "Edit brand",
    "edit_model": "Edit model",
    "edit_category": "Edit category",
    "edit_attribute": "Edit attribute",
    "edit_data_push": "Edit data push",
    "edit_lead_catcher": "Edit lead catcher",
    "edit_external_account": "Edit external account",
    "edit_comment": "Edit comment",
    "edit_delivery_date": "Edit delivery date",
    "edit_lead_tagger": "Edit lead tagger",
    "edit_commission_receiver": "Edit commission recipient",
    "edit_payment_receiver": "Edit payee",
    "edit_billing_target": "Edit billing target",
    "create_client": "Create new company",
    "create_gallery": "Create new gallery",
    "create_job": "Create new job",
    "create_product": "Create new product",
    "create_producttemplate": "Create new template",
    "create_questionnaire": "Create a new questionnaire",
    "create_question": "Create new question",
    "create_template": "Create new email template",
    "create_cms_template": "Create new CMS template",
    "create_pipeline": "Create new pipeline",
    "create_step": "Create new pipeline stage",
    "create_payment_schedule": "Create a new payment plan",
    "create_payment": "Create new payment",
    "create_message": "Write a message",
    "create_text_message": "Write a text message (SMS)",
    "create_note": "Write a note",
    "create_user": "Create new user",
    "create_users": "Create user",
    "create_doc_type": "Create new document type",
    "create_team": "Create team",
    "create_brand": "Create brand",
    "create_model": "Create model",
    "create_category": "Create category",
    "create_attribute": "Create attribute",
    "create_attribute_group": "Create attribute group",
    "create_car": "Create vehicle",
    "create_item": "Create product",
    "create_lead": "Create lead",
    "assign_lead": "Assign lead",
    "create_page": "Create page",
    "create_data_push": "Create new data push",
    "create_white_label_project": "Create a new white label project",
    "create_cms_element": "Create new CMS element",
    "create_lead_catcher": "Create a new lead catcher",
    "create_white_label_plugin": "Create new white label plugin",
    "create_dynamic_data_definition": "Create a new dynamic data definition",
    "add_account": "Add account",
    "create_external_account": "Add new external account",
    "create_model_group": "Create new model group",
    "create_model_asset": "Create new model image",
    "create_lead_per_mail_rule": "Create new lead via e-mail rule",
    "duplicate_page": "Duplicate page",
    "duplicate_cms_element": "Duplicate CMS element",
    "duplicate_cms_template": "Duplicate CMS template",
    "create_task": "Create task",
    "create_car_service": "Create service",
    "create_car_service_station": "Create workstation",
    "create_doc_template": "Create new document template",
    "create_lead_autoresponse": "Create new lead autoresponse",
    "create_main_menu_version": "Create main menu version",
    "create_lead_tagger": "Create lead tagger",
    "create_lead_assignment_strategy": "Create lead assignment strategy",
    "create_escalation_rule": "Create escalation rule",
    "create_coupon": "Create voucher",
    "client_data_saved": "Customer data stored",
    "user_data_saved": "User data saved",
    "job_data_saved": "Job data saved",
    "product_data_saved": "Product data saved",
    "template_data_saved": "Template data saved",
    "company_logo_saved": "Logo has been saved",
    "payment_schedule_data_saved": "Payment plan has been saved",
    "questionnaire_filled_out": "Completed questionnaire",
    "your_message_has_been_sent": "Your message has been sent",
    "email_with_password_reset_instructions_sent": "Email with password reset instructions has been sent",
    "password_changed_after_reset": "Your password has been changed. Welcome back",
    "quote_accepted_saved": "Offer was accepted",
    "quote_rejected_saved": "Offer was rejected",
    "quote_saved": "Offer saved",
    "message_sent": "Message has been sent",
    "note_saved": "Note has been saved",
    "holding_info_saved": "Holding information stored",
    "new_password_set": "A new password has been set",
    "questionnaire_data_saved": "Questionnaire saved",
    "doc_type_saved": "Document type saved",
    "team_saved": "Team saved",
    "document_saved": "Document saved",
    "brand_saved": "Brand saved",
    "model_saved": "Model saved",
    "category_saved": "Category saved",
    "attribute_saved": "Attribute saved",
    "item_data_saved": "Product data saved",
    "car_data_saved": "Vehicle data stored",
    "variant_saved": "Variant saved",
    "pipeline_saved": "Pipeline saved",
    "comment_saved": "Comment saved",
    "lead_saved": "Lead saved",
    "order_saved": "Order saved",
    "document_reviewed": "Document reviewed",
    "locations_saved": "Locations saved",
    "email_confirmed": "E-mail confirmed",
    "page_saved": "Page saved",
    "template_saved": "Template saved",
    "data_push_saved": "Data push saved",
    "white_label_saved": "White label project saved",
    "attribute_group_saved": "Attribute group saved",
    "cms_element_saved": "CMS element saved",
    "lead_catcher_saved": "Lead catcher saved",
    "image_data_saved": "Image data saved",
    "white_label_plugin_saved": "White label plugin saved",
    "dynamic_data_definition_saved": "Dynamic data definition saved",
    "company_data_saved": "Company data stored",
    "contact_data_saved": "Contact details saved",
    "no_changes_made": "No changes made",
    "car_order_approved": "Car sale authorised",
    "vin_number_updated": "Chassis number of the vehicle updated",
    "ein_number_updated": "Electronic insurance confirmation updated",
    "order_marked_as_paid": "Order marked as paid",
    "payment_instructions_sent": "Payment instructions sent",
    "order_cancelled": "Order cancelled",
    "sales_contract_uploaded": "Sales contract uploaded",
    "bank_data_updated": "Bank details updated",
    "pickup_invite_sent": "Pick-up invitation sent",
    "order_picked_up": "Order picked up",
    "order_assignee_kroschke_documents_sent": "The car dealer has sent the documents required to register the vehicle",
    "order_client_kroschke_documents_sent": "The customer has sent the documents required for the registration of his vehicle",
    "plugin_partial_activated": "Plugin partially activated",
    "plugin_request_activate_sent": "Plugin activation request sent",
    "plugin_activated": "Plugin activated",
    "plugin_terminated": "Plugin terminated",
    "changes_saved": "Changes saved",
    "app_secret_saved": "App secret saved",
    "page_access_token_saved": "Page Access Token saved",
    "text_message_sent": "SMS sent",
    "account_saved": "Account saved",
    "model_group_saved": "Model group saved",
    "model_asset_saved": "Model image saved",
    "sepa_agreement_saved": "SEPA mandate saved",
    "document_uploaded": "A document has been uploaded",
    "customer_sent_message": "Customer has sent a message",
    "lead_created": "Lead created",
    "lead_watching": "Started with the observation of the leads",
    "lead_unwatching": "Ends the monitoring of leads",
    "lead_per_mail_rule_saved": "Lead saved by e-mail rule",
    "drivers_license_uploaded": "Driving licence uploaded",
    "plugin_saved": "Plugin saved",
    "settings_saved": "Settings saved",
    "page_duplicated": "Page duplicated",
    "cms_template_duplicated": "CMS template duplicated",
    "cms_element_duplicated": "CMS element duplicated",
    "price_negotiation_started": "Price negotiation started",
    "new_price_negotiation_message_offer_sent": "New price negotiation offer sent",
    "you_joined_community": "You have joined the community",
    "you_left_community": "You have left the community",
    "car_service_booked": "Online appointment booked",
    "car_service_saved": "Service saved",
    "car_service_station_saved": "Workstation saved",
    "team_member_added": "Team member added",
    "team_member_removed": "Team member removed",
    "doc_template_saved": "Document template saved",
    "invoice_issued": "Invoice issued",
    "request_to_fill_out_questionnaire_saved": "Request to fill out a questionnaire saved",
    "request_to_fill_out_questionnaire_name_saved": "Request to fill out a questionnaire \"{name}\" saved",
    "lead_autoresponse_saved": "Lead autoresponse saved",
    "main_menu_version_saved": "Main menu version saved",
    "lead_tagger_saved": "Lead Tagger saved",
    "share_settings_updated": "Release settings updated",
    "lead_assignment_strategy_saved": "Lead allocation strategy saved",
    "escalation_rule_saved": "Escalation rule saved",
    "value_type": "Type",
    "value_precision": "Accuracy",
    "category_already_in_attribute": "The category is already assigned to the attribute.",
    "no_attributes_for_variants": "You must define attributes in order to create variants.",
    "budget_saved": "Budget saved",
    "budget_removed": "Budget removed",
    "coupon_saved": "Voucher saved",
    "authorization_data_saved": "Authorisation data stored",
    "template_data": "General information",
    "template_pages": "Page settings",
    "template_editor": "Editor & PDF settings",
    "template_timeline": "Timeline",
    "template_blocks": "Content blocks",
    "template_import": "Import",
    "template_export": "Export",
    "template_generate": "Generator (TEST)",
    "template_print_data": "Print data",
    "template_import_select_template": "Select template folder",
    "template_import_select_print_data_document": "Select print data document",
    "template_upload_print_data_title": "Upload print data to the template directory",
    "template_upload_print_data_actual_file_title": "Uploaded print data",
    "template_upload_print_data_info": "Adds a file to the S3 template directory. This file is used for products when they are created and no print data is generated.",
    "template_blocks_template": "HTML block",
    "template_category_thumb": "Thumbnail icon class (e.g.: fad fa-circle)",
    "add_block_category": "New category",
    "edit_block_category": "Edit category",
    "add_block_variant": "New variant",
    "edit_block_variant": "Edit variant",
    "add_block": "New block",
    "copy_block": "Copy block",
    "edit_block": "Edit block",
    "edit_takeit": "Take over",
    "edit_import": "Import",
    "edit_import_warning": "Are you sure? This will overwrite all unsaved entries!",
    "template_has_content_blocks": "Variable content",
    "template_in_test": "Test mode for orders",
    "placeholder": "Placeholder for mail merge",
    "select_customer": "Select customers",
    "company_added": "Company added",
    "company_removed": "Company removed",
    "item_added": "Product added",
    "item_removed": "Product removed",
    "category_added": "Category added",
    "category_removed": "Category removed",
    "select_user_set": "Select user authorisation",
    "sent_reminders": "Memories sent",
    "number_of_sent_reminders": "Number of reminders sent",
    "set_number": "Set number",
    "email_to_the_customer_on_save": "Send to the customer by e-mail when saving",
    "email_on_save": "E-mail when saving",
    "shown_name": "Display name",
    "filename_thumbnail": "File name for thumbnail",
    "name_of_imagefile": "Name of the image file, without directory. Example: thumb1.jpg",
    "block_position": "Position of the block in grid lines, 0 for top, makes the block absolute",
    "defined_raster_space": "Predefined grid location",
    "needed_raster_space": "Required grid space",
    "size_group_info_text": "All blocks with the same SizeGroup can be switched through in size in the editor",
    "size_group": "Size Group",
    "group_index_info_text": "All blocks with the same SizeGroup and the same block size can be switched through with the index. From index 1.",
    "group_index": "Group Index",
    "bottom_info_text": "ATTENTION: bottom positions the block absolutely at the bottom of the page, with the distance set in PX",
    "bottom_label": "Position block absolutely at the bottom",
    "fixed_block_label": "Fixed block",
    "fixed_block_info_text": " Block cannot be moved",
    "once_per_document_label": "Once per document",
    "once_per_document_info_text": "only allowed once in the entire document",
    "no_copies_label": "Block cannot be copied",
    "no_copies_info_text": "Block cannot be copied",
    "no_options_label": "Do not show any options",
    "no_options_info_text": "Block has no options in the editor when inserted.",
    "once_per_page_label": "Once per page",
    "once_per_page_info_text": "only allowed once on one side",
    "once_per_variant_label": "Once per Variant",
    "once_per_variant_info_text": "Only allowed once per variant",
    "once_per_category_label": "Once per category",
    "once_per_category_info_text": "only one per category allowed",
    "only_whitelabel_admin_can_modify_label": "Whitelabel administrator only",
    "only_whitelabel_admin_can_modify_info_text": "This field can only be changed by the whitelabel administrator. It should be used when the whitelabel administrator creates a template.",
    "restricted_to_page_label": "Allowed on page",
    "restricted_to_page_info_text": "Only available on this page, -1 = allowed everywhere",
    "exclude_pages_label": "Exclude pages (e.g.: 1,2,3...)",
    "exclude_pages_info_text": "Not allowed on these pages, separate with comma",
    "only_last_label": "Last page only",
    "only_last_info_text": "Block can only be inserted on the last page.",
    "container_block_label": "Container block",
    "container_block_info_text": "This block contains a container for further blocks",
    "inline_block_label": "Inline block",
    "inline_block_info_text": "Block is treated as display:inline-block",
    "inner_label": "Can be inserted into block container",
    "inner_info_text": "Block can be inserted into block container",
    "container_class_label": "Limit to container block",
    "container_class_info_text": "Class name of the container in which the block can be inserted.",
    "only_inside_block_label": "Can only be inserted in block containers",
    "only_inside_block_info_text": "Block can only be inserted in block containers",
    "container_space_label": "Grid spaces in the container block",
    "container_space_info_text": "Available grid lines within the block (same grid as Page!)",
    "container_cols_label": "Columns in the container block",
    "container_cols_info_text": "available grid lines are multiplied by this value",
    "website_only": "For websites only",
    "navName": "Name for navigation",
    "nav_link_label": "Link for navigation",
    "nav_link_info_text": "Anchor link to the ID of the block",
    "nav_options": "Options for navigation",
    "combi_parent_label": "Combination element (parent)",
    "combi_parent_info_text": "Parent element, can be inserted at any time",
    "combi_child_label": "Combination element (Child)",
    "combi_child_info_text": "Child elements can only be set in combination with the parent element",
    "disable_group_label": "Group options",
    "disable_group_info_text": "Here you can define groups that are mutually exclusive, e.g. P#Group1,Group2 and D#Group2,Group1. Definition parameter - P = valid for the page, D = valid for the document.",
    "events": {
      "TimelineMailPasswordReset": "Customer has requested the password change",
      "TimelineMailRegistration": "Customer has registered with Creacheck.com",
      "TimelineMailOauthRegistration": "Customer has registered with Creacheck.com with social media login",
      "TimelineMailInvitation": "The customer has been invited to use Creacheck.com",
      "TimelineMailContactForm": "Customer has sent a message via the contact form",
      "TimelineMailInvitationAccepted": "The customer has accepted the invitation",
      "TimelineMailNewInvoice": "You have issued an invoice to the customer",
      "TimelineMailNewQuote": "You have sent an offer to the customer",
      "TimelineMailNewMessage": "You have sent a message to the customer",
      "TimelineQuoteAccepted": "The customer has accepted your offer",
      "TimelineQuoteRejected": "Unfortunately, the customer has rejected your offer",
      "TimelineOrderCreated": "New order",
      "TimelineMailNewCustomerMessage": "E-mail message from the customer",
      "TimelineMonitoringPaymentReceipt": "Monitor incoming payments",
      "TimelineClosingManualDataCheck": "Manual data check closed",
      "TimelineUpdatedByForeignStatus": "Order status updated",
      "TimelineMailedForeignOrderStatusOrderCanceled": "cancelled by supplier",
      "TimelineMailedForeignOrderStatusInDelivery": "in delivery",
      "TimelineMailedForeignOrderStatusInProduction": "in production",
      "TimelineMailedForeignOrderStatusInReview": "in consultation",
      "TimelineMailedForeignOrderStatusInOrder": "in order",
      "TimelineMailedOrderedWebAndDownloadPDF": "Web-enabled and downloadable PDF sent to e-mail recipients.",
      "TimelineMailedOrderedDownloadPDF": "Download PDF sent to e-mail recipients.",
      "TimelineMailedOrderedWebPDF": "Web-enabled sent to e-mail recipients.",
      "TimelineDomainHostingHasBeenAuthorized": "Domain hosting has been authorised",
      "TimelineDomainNotAvailable": "Domain is no longer available",
      "TimelineDomainHostingDomainValidated": "Domain has been validated",
      "TimelineExceptionCheckDomainAvailability": "Error when checking for domain availability of {0}. Error: {2}",
      "TimelineDomainFailRegistering": "Registration of domain {0} failed",
      "TimelineExceptionRegisterDomain": "Registration of domain {0} failed with error: {2}",
      "TimelineDomainSuccessfullyRegistered": "Domain {0} was successfully registered.",
      "TimelineDomainSuccessfullyRedirected": "Domain successfully redirected",
      "TimelineDomainCreatingCertificate": "Certificate for domain {0} is created with error: {1}",
      "TimelineDomainCreatingDistributionFailed": "Distribution of domain {0} failed with error: {1}",
      "TimelineDomainCreatingSuccessfullyHosted": "Domain {0} was successfully hosted",
      "TimelineDomainCreatingFailedHosting": "Domain {0} could not be hosted with error: {1}",
      "TimelineDomainHostingCompleted": "Completed",
      "TimelineMissingClientDataFirstAndLastName": "Missing first and last name of the customer!",
      "TimelineMissingClientDataPhoneNumber": "Missing telephone number of the customer!",
      "TimelineDomainCreatingSuccessfullyFinished": "Domain {0} was successfully completed"
    },
    "attributeName": {
      "select": "Selection field",
      "html": "HTML field",
      "checkbox": "Checkbox",
      "text": "Text field",
      "colors": "Choice of colours",
      "number": "Number field",
      "boolean": "Yes/No",
      "date": "date"
    },
    "attributeType": {
      "select": "You can define several options to select. The user can then select one of them",
      "html": "User can write a longer text with Rich Content Editor",
      "checkbox": "You can define several options to select. The user can then select several of them",
      "text": "User can enter a simple text",
      "colors": "User can select one of the colours defined here",
      "number": "The user can enter a number",
      "boolean": "User can choose between yes and no",
      "date": "The user can select a date"
    },
    "attributeValueType": {
      "string": "Text",
      "number": "Number",
      "date": "date"
    },
    "attributePrecision": {
      "year": "Year",
      "month": "month",
      "day": "Day",
      "time": "Time of day"
    },
    "categoryDisplay": {
      "select": "Yes/no",
      "number_range": "Number range",
      "date_range": "Date range",
      "list": "List selection"
    },
    "invoice_actions": {
      "tab_title": "Actions on the invoice",
      "refunded": {
        "title": "Manually refunded",
        "label": "Mark as refunded",
        "info_text": "Manual marking of the invoice as already refunded without creating a cancellation invoice"
      },
      "denied_to_pay": {
        "title": "Payment refused",
        "label": "Mark as denied",
        "info_text": "Mark that the invoice will never be paid"
      },
      "amount_paid": {
        "title": "Enter amount paid",
        "placeholder": "Enter amount paid",
        "button": "Save amount paid"
      },
      "sent_reminders": {
        "title": "Sent reminders",
        "placeholder": "Number of reminders sent",
        "button": "Save number"
      }
    },
    "mail": {
      "registration": {
        "subject": "Thank you for registering with %company%",
        "subject_robethood": "Robethood Creatives Portal: Registration successful.",
        "explanation": "Thank you for your registration. Please confirm your e-mail address within 24 hours. Only with a confirmed e-mail address can you receive a new Internet password or information about changes.",
        "explanation_first_name_form": "<p>Thank you for your registration. Please confirm your e-mail address within 24 hours.</p><p>Only with a confirmed e-mail address can you receive a new password or information about changes.</p>",
        "possible_actions": "Once your e-mail address has been confirmed, you will immediately have access to all the functions we have prepared for you.",
        "possible_actions_first_name_form": "Once your e-mail address has been confirmed, you will immediately have access to all the functions we have prepared for you.",
        "call_to_action": "Confirm"
      },
      "ordered_web_pdf": {
        "subject": "Web-enabled PDF for ordering %orderId% from %company%",
        "explanation": "Thank you for your order. You can open and download the web-enabled PDF by clicking on the button below.",
        "explanation_first_name_form": "Thank you for your order. You can open and download the web-enabled PDF by clicking on the button below.",
        "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
        "possible_actions_first_name_form": "You can log in to the online portal at %domain% at any time and view your order.",
        "call_to_action": "Show order",
        "download": "Download web-enabled PDF"
      },
      "ordered_download_pdf": {
        "subject": "PDF for order %orderId% from %company%",
        "explanation": "Thank you for your order. You can open and download the PDF by clicking on the button below.",
        "explanation_first_name_form": "Thank you for your order. You can open and download the PDF by clicking on the button below.",
        "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
        "possible_actions_first_name_form": "You can log in to the online portal at %domain% at any time and view your order.",
        "call_to_action": "Show order",
        "download": "Download PDF"
      },
      "order_vat_data_check_done": {
        "subject": "Your order for the manual data check for the order %orderId% from %company%",
        "explanation": "Thank you for your order and for commissioning the manual data check.",
        "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
        "call_to_action": "Show status"
      },
      "order_reduced_vat_authorized": {
        "subject": "Your application for a reduced VAT rate for the order %orderId% has been approved",
        "explanation": "Thank you for your order. We have authorised the order for the reduced VAT rate.",
        "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
        "call_to_action": "Show status"
      },
      "order_reduced_vat_rejected": {
        "subject": "Your request for a reduced VAT rate for the order %orderId% has not been approved",
        "explanation": "Thank you for your order. We have checked your order and found that it does not qualify for the reduced VAT rate. The normal VAT rate will be applied.",
        "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
        "call_to_action": "Show status"
      },
      "chedri_registration": {
        "subject": "Your registration in the %company%",
        "subject_fiendly": "Your registration with %company%",
        "explanation": "<p>Hello %firstName% %lastName%,</p><p>Thank you for your registration.</p><p>We have successfully created a test account for you and your company.</p>",
        "possible_actions": "Once your e-mail address has been confirmed, you can log in with <strong>%username%</strong> as your username and the password you selected during registration.",
        "call_to_action": "Confirm"
      },
      "invitation": {
        "subject": "Invitation from %company%",
        "explanation": "On behalf of %company%, we are delighted to invite you to our online design portal.",
        "login_data": "If you accept the invitation, use the following data to log in: <strong>%username%</strong> as your username and <strong>%password%</strong> as your password. Please make sure to change your password after logging in.",
        "call_to_action": "Accept invitation",
        "explanation_first_name_form": "On behalf of %company%, we are delighted to invite you to our online design portal.",
        "login_data_first_name_form": "If you accept the invitation, use the following data to log in: <strong>%username%</strong> as your username and <strong>%password%</strong> as your password. Please make sure to change your password after logging in."
      },
      "new_quote": {
        "subject": "New offer from %company%",
        "explanation": "%company% sends you a quote for a service you have requested.",
        "possible_actions": "You now have the option of checking and accepting the offer. You can also decline the offer and leave a message explaining why.",
        "call_to_action": "Check offer"
      },
      "new_invoice": {
        "subject": "New invoice from %company%",
        "explanation": "%company% will send you an invoice for a service you have requested. You can find the invoice in our online portal. We have also attached the invoice to this message.",
        "explanation_first_name_form": "%company% will send you an invoice for a service you have requested. You can find the invoice in our online portal. We have also attached the invoice to this message.",
        "possible_actions": "You can log in to the online portal at %domain% at any time and view your invoices.",
        "possible_actions_first_name_form": "You can log in to the online portal at %domain% at any time and view your invoices.",
        "call_to_action": "Show invoice"
      },
      "new_sales_commission": {
        "subject": "New commission from %company%.",
        "explanation": "%company% will send you a commission for a service you have requested. You can find the commission in our online portal. We have also attached the commission to this message.",
        "possible_actions": "You can log in to the online portal at %domain% at any time and view your commission online.",
        "call_to_action": "Show commission"
      },
      "new_questionnaire": {
        "subject": "%company% would like you to complete a questionnaire",
        "explanation": "<p>Hello %firstName% %lastName%,</p><p>%company% is sending you a new questionnaire.</p>",
        "possible_actions": "You have the option of viewing and completing the questionnaire online.",
        "call_to_action": "View questionnaire"
      },
      "questionnaire_filled_out": {
        "subject": "The questionnaire has just been completed",
        "explanation": "<p>Hello %firstName% %lastName%,</p><p>The questionnaire requested by %targetFirstName% has just been completed.</p>",
        "possible_actions": "You can view the answers in the lead timeline.",
        "call_to_action": "Open lead"
      },
      "contact_form_cms": {
        "subject": "New contact from %name%",
        "info": "Notification of the submission of a contact form. Please process this immediately and ensure that the customer receives the best possible support."
      },
      "contact_form_edition": {
        "subject": "New contact",
        "info": "Notification of the submission of a contact form. Please process this immediately and ensure that the customer receives the best possible support.",
        "form_fields": "Completed form fields"
      },
      "password_renew": {
        "subject": "Renew password",
        "explanation": "<p>Hello %email%,<br><br>You have tried to log in to %platformName%. Your password has expired. You need to set a new password to be able to log in in the future. Click on the link below to enter a new password.</p>",
        "possible_actions": "You have the option of setting a new password. Simply click on the button.",
        "call_to_action": "Set new password",
        "explanation_first_name_form": "<p>Hello %email%,<br><br>you have tried to log in to %platformName%. Your password has expired. You need to set a new password to be able to log in in the future. Click on the link below to enter a new password.</p>",
        "possible_actions_first_name_form": "You have the option of setting a new password. Simply click on the button."
      },
      "password_reset": {
        "subject": "Reset password",
        "explanation": "<p>Good afternoon %email%,</p><br /><p>a new password has just been requested from %platformName% via our platform. If you have made this request, please click on the link below.</p",
        "explanation_first_name_form": "<p>Hello %email%,</p><br /><p>a new password has just been requested from %platformName% via our platform. If you have made this request, click on the link below.</p",
        "possible_actions": "You have the option of setting a new password. Simply click on the button.",
        "possible_actions_first_name_form": "You have the option of setting a new password. Simply click on the button.",
        "call_to_action": "Set new password"
      },
      "message": {
        "explanation": "%company% has sent you a message",
        "possible_actions": "You can reply to this message by clicking on this button:",
        "call_to_action": "Answers"
      },
      "customerMessage": {
        "explanation": "Customer has sent you a message"
      },
      "lead_comment_mention": {
        "subject": "They were mentioned in the %title% lead",
        "explanation": "%creator% mentioned you in a comment.",
        "call_to_action": "Open lead"
      },
      "lead_assignee_changed": {
        "subject_assigned": "You are now assigned to the lead '%title%'",
        "subject_unassigned": "They are no longer assigned to the lead '%title%'",
        "explanation_assigned": "You are now assigned to the lead '%title%'. The previous editor was %oldAssignee%.",
        "explanation_unassigned": "They are no longer assigned to the lead '%title%'. The new assignee is %newAssignee%.",
        "call_to_action": "Open lead"
      },
      "bank_data_reminder": {
        "subject": "We need your bank details for your order",
        "explanation": "You have booked services for which bank details are required.",
        "possible_actions": "Please go to the order and enter the missing data.",
        "call_to_action": "Enter bank details"
      },
      "order_approved": {
        "subject": "Your order with %company% has just been approved",
        "explanation": "We are pleased to inform you that your order %orderId% has been approved.",
        "possible_actions": "You may want to go to your order to view the details.",
        "call_to_action": "Show order"
      },
      "sales_contract_uploaded": {
        "subject": "The purchase contract for your order is now available"
      },
      "order_cancelled": {
        "subject": "Your order with %company% has been cancelled",
        "explanation": "We are sorry, but we had to cancel your order %orderId%.",
        "possible_actions": "Please go to your order to view the details.",
        "call_to_action": "Show order"
      },
      "payment_instructions": {
        "subject": "Payment information for your order at %company%",
        "explanation": "Below you will find the payment information for your order %orderId%:",
        "possible_actions": "Please go to your order to view the details.",
        "call_to_action": "Show order"
      },
      "pickup_invite": {
        "subject": "Your order is ready for collection",
        "explanation": "Your order with the %orderId% is ready for collection.",
        "possible_actions": "Please go to your order to view the details.",
        "call_to_action": "Show order"
      },
      "order_picked_up": {
        "subject": "Thank you for your order"
      },
      "request_plugin_activation": {
        "subject": "Plugin activation requested",
        "explanation": "<p>Hello %firstName% %lastName%,</p><p>%creatorFirstName% %creatorLastName% would like to activate the plugin <strong>%pluginName%</strong> for the project %platformName%.</p>",
        "possible_actions": "You can log in and use the activation form by pressing the following button.",
        "call_to_action": "Activate the plugin"
      },
      "plugin_activated": {
        "subject": "Plugin activated",
        "explanation": "<p>Hello %firstName% %lastName%,</p><p>We would like to inform you that the %pluginName% for the %platformName% project has been activated.</p>",
        "call_to_action": "See it in action"
      },
      "lead_data": {
        "subject": "New lead: %title%",
        "call_to_action": "Show lead"
      },
      "order": {
        "item_sales": {
          "title": "Your order",
          "explanation": "The details of your order are listed again below.",
          "subject": "Your order with %company%",
          "possible_actions": "You can also find all information about your order in your customer account.",
          "call_to_action": "View in customer account"
        }
      },
      "rent_sync_vamoso_error": {
        "subject": "Order #%orderId%: Error in Rent Sync for Vamoso Plugin: %errorType%",
        "explanation": "Unfortunately, our plugin could not perform a task in your Vamoso system. Please check the booking status manually. <br/>Error type: %errorType% <br/>Error message: %errorText%"
      },
      "lead_deadline_reminder": {
        "subject": "They are assigned to leads that are due tomorrow",
        "explanation": "Here you will find a list of the leads assigned to you. These leads are still open and due tomorrow.",
        "explanation_first_name_form": "Here you will find a list of the leads assigned to you. These leads are still open and due tomorrow."
      },
      "chat_history": {
        "subject": "Your chat history",
        "explanation": "Thank you for contacting us. Please find attached your chat history with %firstName% %lastName%. I hope we were able to help you with all your questions or problems. If you have any further questions, please feel free to contact us again at any time.",
        "greatingchat_history": "Chat history",
        "greating": "Thank you for using the chat of %platformName%. Your chat partner was %firstName% %lastName% Below you can see the chat history from %date%.",
        "adaption": "I hope we have been able to help you with all your questions or problems. If you have any further questions, please feel free to contact us again at any time.",
        "privacy_policy": "You can find our privacy policy at <a href=\"%link%\" target=\"_blank\">'%link%</a>",
        "call_to_action": "Visit us again"
      },
      "lead_pool_notification": {
        "subject": "New lead in the pool: %title%",
        "explanation": "A new lead is available in the system. We have not yet assigned an employee. Click on the button below to view details.",
        "call_to_action": "Open lead"
      },
      "lead_escalation_level1": {
        "subject": "[LEVEL1] %name%",
        "explanation": "You are receiving this email because one or more leads have reached the first escalation level defined in the %name% rule. Please check the leads to see what needs to be done."
      },
      "lead_escalation_level2": {
        "subject": "[LEVEL2] %name%",
        "explanation": "You are receiving this email because one or more leads have reached the second escalation level defined in the %name% rule. Please check the leads and try to motivate your teams to work harder."
      },
      "campaign_budget_auth_request": {
        "subject": "Warning: Campaign budget exceeded.",
        "explanation": "the campaign budget was exceeded.",
        "call_to_action": "Show order"
      },
      "campaign_budget_auth_request_unsuccessful": {
        "subject": "Warning: Problem with the authorisation of the campaign budget.",
        "explanation": "A problem has been detected with the authorisation of the campaign budget.",
        "call_to_action": "Show order"
      },
      "user_budget_auth_request": {
        "subject": "Warning: User budget exceeded.",
        "explanation": "the user budget was exceeded.",
        "call_to_action": "Show order"
      },
      "user_budget_auth_request_unsuccessful": {
        "subject": "Warning: Problem with the authorisation of the user budget",
        "explanation": "A problem has been detected with the authorisation of the user budget.",
        "call_to_action": "Show order"
      },
      "order_auth_changes_requested": {
        "subject": "Info: Changes requested",
        "call_to_action": "Show order"
      },
      "order_auth_changes_implemented": {
        "subject": "Info: Changes made",
        "call_to_action": "Show order"
      },
      "manage_notification": "Manage email notifications",
      "unsubscribe": "Unsubscribe",
      "confidentiality_notice": "Confidentiality notice: This e-mail is intended exclusively for the specified recipient and may contain confidential and/or legally protected information. If you are not the correct recipient or have received this e-mail in error, please inform the sender immediately and destroy this e-mail. Unauthorised copying and unauthorised forwarding of this e-mail is not permitted.",
      "no_legal_binding_notice": "This message is for information purposes only and is not legally binding. Due to the easy manipulability of e-mails, we cannot accept any liability for the content.",
      "invoice_due_reminder": {
        "subject": "Payment reminder",
        "explanation": "Unfortunately, we have not yet been able to establish receipt of payment for the following invoice.",
        "explanation_first_name_form": "Unfortunately, we have not yet been able to establish receipt of payment for the following invoice.",
        "request_payment": "To avoid further costs, we request immediate payment of the total amount. Please use the following bank details for payment of this invoice:",
        "request_payment_first_name_form": "To avoid further costs, please pay the total amount immediately. Please use the following bank details to pay this invoice:",
        "contact": "If you have any questions about this reminder, please contact us by e-mail at %email% or by telephone on the %phone% hotline.",
        "contact_first_name_form": "If you have any questions about this reminder, please contact us by e-mail at %email% or by telephone on the %phone% hotline.",
        "bank_account": "Bank details",
        "bank_name": "Bank name",
        "account_holder": "Account holder",
        "iban": "IBAN",
        "swift_bic": "SWIFT/BIC",
        "bank_code_number": "Bank sort code",
        "account_number": "Account number",
        "invoice_number": "Invoice no:"
      },
      "last_seen_reminder": {
        "subject": "Last seen Reminder",
        "user_not_seen": "The user '%clientUsername%' has not been seen for %daysNotSeen% days.",
        "contact_user": "Please contact them to ensure that everything is in order.",
        "contact_user_first_name_form": "Please contact them to make sure that everything is in order.",
        "customer_profil": "Customer profile"
      },
      "order_foreign_status": {
        "do_contact_support": {
          "subject": "Order - Please contact customer support",
          "explanation": "Thank you for your order. We have a question about your order. Please contact our customer support.",
          "explanation_first_name_form": "Thank you for your order. We have a question about your order. Please contact our customer support.",
          "possible_actions": "-",
          "contact": "Contact us",
          "phone": "Telephone:",
          "email": "e-mail:",
          "call_to_action": "-"
        },
        "in_delivery": {
          "subject": "The order is currently being dispatched",
          "explanation": "Your order has been produced and handed over to the courier for delivery.",
          "explanation_first_name_form": "Your order has been produced and handed over to the courier for delivery.",
          "possible_actions_tracking": "You can track the delivery on the courier service's website.",
          "possible_actions_tracking_first_name_form": "You can track the delivery on the courier service's website.",
          "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
          "possible_actions_first_name_form": "You can log in to the online portal at %domain% at any time and view your order.",
          "call_to_action": "Order details",
          "tracking_numbers": "Tracking numbers"
        },
        "in_production": {
          "subject": "The order is currently being produced",
          "explanation": "Thank you for your order. We have placed your order. It is currently being produced.",
          "explanation_first_name_form": "Thank you for your order. We have placed your order. It is currently being produced.",
          "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
          "possible_actions_first_name_form": "You can log in to the online portal at %domain% at any time and view your order.",
          "call_to_action": "Order details"
        },
        "invalid_print_data": {
          "subject": "Order - invalid print data",
          "explanation": "Invalid print data was found during the order.",
          "call_to_action": "Order details"
        },
        "missing_print_data": {
          "subject": "Order - missing print data",
          "explanation": "Missing print data was found when the order was placed.",
          "call_to_action": "Order details"
        },
        "order_canceled": {
          "subject": "The order has been cancelled",
          "explanation": "Your order has been successfully cancelled. We are sorry that you had to cancel your order and we hope that we can help you again in the future.",
          "explanation_first_name_form": "Your order has been successfully cancelled. We are sorry that you had to cancel your order and we hope that we can help you again in the future.",
          "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
          "possible_actions_first_name_form": "You can log in to the online portal at %domain% at any time and view your order.",
          "call_to_action": "Order details"
        },
        "payment_pending": {
          "subject": "Purchase order - payment outstanding",
          "explanation": "We have not yet received payment for your order.",
          "explanation_first_name_form": "We have not yet received payment for your order.",
          "possible_actions": "You can log in to the online portal at %domain% at any time and view your order.",
          "possible_actions_first_name_form": "You can log in to the online portal at %domain% at any time and view your order.",
          "call_to_action": "Order details"
        }
      },
      "contact_form_cms_confirm": {
        "subject": "Please confirm your e-mail",
        "explanation": "<p>Your email has been entered into the contact form on our website.</p><p>If you have completed our contact form, please click the Confirm button.</p><p>Otherwise, simply ignore this message.</p>",
        "call_to_action": "Confirm"
      },
      "contact_form_edition_confirm": {
        "subject": "Please confirm your e-mail",
        "explanation": "<p>Your email address has been entered in the contact form on our website.</p><p>If you have completed our contact form, please click the Confirm button.</p><p>Otherwise, simply ignore this message.</p>",
        "call_to_action": "Confirm"
      },
      "order_content_check_status": {
        "subject": "the content check for the order %orderId% was %status%",
        "explanation": "Thank you for your order. We have checked the contents of your order and have given it %status% .",
        "possible_actions": "You can call up your order to view the details.",
        "call_to_action": "View order",
        "reason": "Reason"
      },
      "lead_follow_up_reminder": {
        "subject": "Please check the assigned tasks",
        "explanation": "Here you will find a list of the tasks assigned to you. These tasks are still open.",
        "explanation_first_name_form": "Here you will find a list of the tasks assigned to you. These tasks are still open."
      },
      "client_domain_authorization_code": {
        "subject": "Domain authorisation code",
        "explanation": "After cancelling the %itemName% subscription for the domain %clientDomain%, we will provide you with an authorisation code that you can use to transfer your domain to another registrar.",
        "explanation_authorization_code": "The authorisation code is %authorizationCode%"
      }
    },
    "contact_form_cms_confirm": {
      "success": {
        "heading": "Your enquiry has been sent",
        "body": "<p>Thank you for contacting us!</p><p>We have received your enquiry and will get back to you as soon as possible.</p>"
      },
      "already_confirmed": {
        "heading": "This application has already been confirmed",
        "body": "<p>We have received your enquiry and will get in touch with you as soon as possible.</p>"
      },
      "not_found": {
        "heading": "Not found",
        "body": "<p>Please make sure you have clicked the button in our email to get here.</p>"
      }
    },
    "contact_form_edition_confirm": {
      "success": {
        "heading": "Your enquiry has been sent",
        "body": "<p>Thank you for contacting us!</p><p>We have received your enquiry and will get back to you as soon as possible.</p>"
      },
      "already_sent": {
        "heading": "This application has already been confirmed",
        "body": "<p>We have received your enquiry and will get in touch with you as soon as possible.</p>"
      },
      "not_found": {
        "heading": "Not found",
        "body": "<p>Please make sure you have clicked the button in our email to get here.</p>"
      },
      "expired": {
        "heading": "Code expired",
        "body": "<p>The confirmation link was only valid for 2 days. Please fill out the contact form again.</p>"
      }
    },
    "formGroup": {
      "basic_information": "Basic information",
      "contact_person": "Contact person",
      "address_information": "Address",
      "address_settings": "Address settings",
      "bank_account": "Bank details",
      "company_information": "Company information",
      "user_roles": "User roles",
      "subscription_roles": "User subscription",
      "opening_hours": "Opening hours",
      "position_on_map": "Position on the map",
      "white_label_parent": "Precursor",
      "user_teams": "User teams",
      "contact_data": "Contact details",
      "permissions": "Authorisations",
      "person_information": "User information"
    },
    "hints": {
      "small_hint_for_you": "A little tip for you",
      "task_is_automated": "This task is automated. You must wait until the automation task is running. After that, we will mark it as completed for you.",
      "please_complete_previous_tasks": "You can't edit this task now. Please take care of the previous tasks first.",
      "companyLogoNew": "<p>You have not yet uploaded your logo. To upload one, simply drag and drop an image (jpeg or png) onto this page.</p>",
      "companyLogoChange": "<p>This is the logo you have uploaded. To change it, simply drag and drop an image (jpeg or png) onto this page.</p>)",
      "companyFinancial": "<div><p>Please fill in the information required for invoicing</p><p>The bank details you fill in here will be displayed in footer on your quotes and invoices</p></div>",
      "companyPaymentInfo": "<p>This information is displayed on every invoice you create.</p"
    },
    "vendors": {
      "association": "Association with client data",
      "vendor_api": "Service name",
      "pricing": "Pricing",
      "operating_mode": "Operating mode",
      "price_update_method": "Price update method",
      "order_processing": "Order processing",
      "order_method": "Ordering method",
      "order_email": "E-mail address for orders",
      "phone_number": "Telephone number",
      "web_shop_url": "Webshop URL",
      "trial": "trial",
      "live": "live",
      "prices_by_vendor": "Prices are managed by the provider",
      "prices_by_employee": "Prices are checked and maintained by the employee",
      "prices_by_api": "Prices are updated via an API",
      "notified_by_email": "Will be notified of incoming orders by e-mail",
      "accepts_only_email": "Only accepts orders by e-mail",
      "use_webshop": "Orders via webshop",
      "order_by_api": "Orders via API",
      "order_by_api_and_webshop": "Orders via API or webshop"
    },
    "client_account": "Customer account",
    "deleted_campaigns": "Deleted campaigns",
    "deleted_editions": "Deleted editions",
    "deleted_folders": "Deleted folders",
    "deleted_media": "Deleted media",
    "root": "Root directory",
    "trash_bin": "Wastepaper basket",
    "delete_trash_bin": "Empty wastepaper basket",
    "pls_wait": "Please wait. The desired action is executed.",
    "go_back": "Back",
    "restore": "Restore",
    "archived_campaigns": "Archived campaigns",
    "load_all_campaigns": "Load all campaigns",
    "load_creacheck_campaigns": "Load Creacheck campaigns",
    "load_flyeralarm_campaigns": "Load Flyeralarm campaigns",
    "load_designgenie_campaigns": "Load DesignGenie campaigns",
    "archived_editions": "Archived editions",
    "sorting_name": "Name",
    "sorting_date_ASC": "Oldest first",
    "sorting_date_DESC": "Newest first",
    "sorting_owner": "Company",
    "sorting_creator": "Created by",
    "campaign_title": "Campaign",
    "dataproof": "Data check",
    "not_ordered": "not ordered",
    "supplier_order": "Supplier order",
    "change_of_address": "Change of address",
    "not_available": "not available",
    "selected_shipping_method": "Selected shipping method",
    "ordered_items": "Ordered articles",
    "standard": "Standard",
    "express": "Express",
    "aws_iframe_hosting": "AWS Iframe Hosting",
    "aws_hosting_html_file_for_iframe_downloaded": "AWS Hosting HTML file for iframe with title: %title%",
    "lead_stage_change_confirm_title": "Change lead status",
    "lead_stage_change_confirm_body": "Do you really want to change the status of the lead?",
    "iframe_implementation_file": "Iframe implementation file",
    "iframe_implementation_file_download_failed": "Iframe implementation file could not be downloaded: %error%",
    "lead_stage_change_error_domain_hosting_in_progress": "The line cannot be changed if the current phase is running or if the authorisation has not been approved.",
    "lead_stage_change_error_domain_hosting_command_to_start_pipeline_has_been_sent": "The line cannot be changed if the current phase is running or if the authorisation has not been approved.",
    "allowed_domain_already_exists": "The domain %domain% is already included in the list of authorised domains.",
    "allowed_domain_domain_name_should_not_be_empty": "The domain name must not be empty.",
    "allowed_domains_title": "Allowed domains",
    "allowed_domains_description": "Here you can add domains that may be used for the implementation of iframes.",
    "allowed_domains_add": "Add domain",
    "allowed_domains_domain_name": "Domain name",
    "allowed_domains_default_src": "Default-Src",
    "allowed_domains_script_src": "Script-Src",
    "allowed_domains_connect_src": "Connect-Src",
    "allowed_domains_style_src": "Style-Src",
    "allowed_domains_img_src": "Img-Src",
    "allowed_domains_font_src": "Font-Src",
    "allowed_domains_frame_src": "Frame-Src",
    "created_at": "Created on",
    "created_by": "Created by",
    "overnight": "Overnight",
    "create_printData": "Create print data",
    "created_new_printData": "New print data has been created",
    "uploaded_printData_successful": "Print data has been successfully uploaded",
    "no_printData": "No print data",
    "no_images": "No image files available",
    "not_the_original_printFile": "Note: This is not the original print file",
    "printDataFiles": "Print data file(s)",
    "image_files": "Image files",
    "customer_comment": "Customer comment",
    "actions_": " Actions",
    "import_success": "Your import has been executed",
    "import_rejected": "Your import has been cancelled",
    "import_exec": "Your import is executed",
    "import_successRE": "Your import has been reset",
    "import_rejectedRE": "The process was cancelled",
    "import_execRE": "Your import will be reset",
    "choice_csv": "Please select a CSV file",
    "csv_content": "CSV content",
    "warning_and_notes": "Warnings and notes",
    "start_remove": "Start removal",
    "reverse_remove": "Undo removal",
    "user_import_success": "Import successful",
    "user_duplicate_import": "Duplicate entry found",
    "start_import": "Start import",
    "reverse_import": "Undo import",
    "upload_local_changes": "Upload local changes",
    "dataproof_master_copies_upload": "Here you can upload the locally edited template/data file(s) with [Upload].",
    "edit_edition": "Edit advertising material",
    "dataproof_master_copies_recreate": "Here, [Create print data] can be used to initiate a new creation of the template/print data file(s) based on the original or the template/print data file(s) can be edited locally with [Edit].",
    "hint": "Note",
    "dataproof_hint_master_copies_upload": "The locally changed template/print data file(s) must be uploaded with [Upload] for the change to take effect after post-processing has been completed.",
    "edit_": "Edit",
    "send_invoice": "Send invoice",
    "sended_invoice": "The invoice was sent to",
    "send_email_tracking": "Email shipment tracking",
    "sended_email": "The email has been sent!",
    "tracking": "Consignment tracking",
    "send_tracking_to_customer": "Send shipment tracking to customers",
    "to_the_product": "To the product",
    "order_sended": "The order has been sent!",
    "failure_when_ordering": "An error has occurred during the order!",
    "please_create_orderhistory_email": "The order was sent by email! Please create an order history!",
    "new_status_set": "The new status has been set!",
    "change_of_status": "Status change",
    "statusQuery": "Status enquiry",
    "totalStatus": "Overall status:",
    "printData": "Print data",
    "current_printdata_sended": "The current print data has been sent!",
    "orderHistory": "Order process",
    "orderHistory_created": "The order history has been created!",
    "order_created": "The order was successfully created!",
    "enter_trackingLink": "Enter tracking link",
    "query_orderStatus": "Query order status",
    "foreignId": "External order no.",
    "orderStatus": "Order status",
    "orderDate": "Order date",
    "options_": "Options",
    "type_": "Kind",
    "comment_": "Comment",
    "orderOptions": "Ordering options",
    "ordering_by_webshop": "Order via webshop",
    "ordering_by_email": "Order by email",
    "info_to_the_emailOrder": "Additional information for email orders",
    "add_or_edit_foreignId": "Add or edit external order number",
    "create_trackingLink": "Create link for shipment tracking",
    "campaign": {
      "copy_edition": "Duplicate",
      "created_by": "Created by:",
      "no_edition": "You have not edited any advertising material yet!",
      "configuration": "General options",
      "select_all": "All products",
      "budget": "Campaign budget",
      "access": "Access rights",
      "inactive": "Lock campaign",
      "delete": "Delete campaign",
      "transfer_title": "Transfer the campaign to another user",
      "campaign_owner": "Campaign owner",
      "campaign_creator": "Campaign creator",
      "change_owner": "Change campaign owner",
      "change_creator": "Change campaign creator",
      "order_all": "Order entire campaign",
      "share": "Share campaign",
      "archive": "Archive campaign",
      "recent_editions": "Last edited",
      "marked_editions": "Marked advertising material",
      "marked_campaigns": "Labelled campaigns",
      "own_campaigns": "Own campaigns",
      "shared_editions": "Shared advertising material",
      "last_edited": "Edited on:",
      "generated": "Created on:",
      "edition_edit": "Start editor",
      "edition_item": "Selected product",
      "submission_item": "Selected submission",
      "edition_configuration": "2. settings",
      "edition_order": "Add to shopping basket",
      "edition_reorder": "Reorder",
      "ordered": "Ordered!",
      "edition_delete": "Delete",
      "edition_preview": "Preview",
      "edition_edit_alt": "Edit",
      "edition_error_empty": "You have %s unedited pages",
      "submissions": "Templates",
      "new": "Create new campaign",
      "back_to_list": "All campaigns",
      "new_edition": "Create new advertising material",
      "settings": "Manage campaign",
      "campaign_archived": "Your campaign has been archived",
      "successfully_archived": "Archiving successful",
      "save_settings": "Save",
      "cancel_settings": "Cancel",
      "title": "Campaign name",
      "edition_title": "Designation",
      "publisher": "Indication of the publisher",
      "creator": "Campaign created by:",
      "editions": "Advertising material",
      "edition": "Advertising material",
      "sidebar": "Campaigns",
      "mark_edition": "Mark advertising material",
      "unmark_edition": "Remove marking",
      "campaign_title_valid": "Please enter a name for your campaign.",
      "campaign_publisher_valid": "Please enter your copyright details.",
      "choose_company_to_load_campaign": "To load the campaigns, please select a company or click on the 'Load all campaigns' button",
      "no_product_selected": "No product selected",
      "new_edition_title": "New edition",
      "added_to_cart": "Your campaign has been added to the shopping basket!",
      "edition_add_to_cart_title": "Product in shopping basket",
      "edition_add_to_cart_button_label": "Great",
      "edition_add_to_cart_label": "To the shopping basket",
      "edition_added_to_cart_message": " has been added to your shopping basket.",
      "edit_disabled": "The editing of the edition was temporarily deactivated due to a template update.",
      "export_image": "Export image",
      "unknown": "unknown",
      "already_ordered": "This edition has already been ordered and can no longer be edited. However, you can duplicate the edition and edit it. See menu",
      "modal_tabs": {
        "default_edition": "General advertising material",
        "edition_from_submission": "Advertising material from template"
      }
    },
    "uom": {
      "piece": "Piece",
      "gram": "gram",
      "kilogram": "Kilogramme",
      "running_meter": "Running metre",
      "liter": "Litres",
      "package": "Package",
      "kilometer": "Kilometres"
    },
    "uom_symbole": {
      "gram": "g",
      "kilogram": "kg",
      "running_meter": "linear metres",
      "liter": "l",
      "package": "Pkt.",
      "kilometer": "km"
    },
    "pipelineViews": {
      "self": "Assigned to me",
      "unassigned": "Without allocation",
      "pastdeadline": "Deadline exceeded",
      "duetoday": "Due today"
    },
    "leadPriority": {
      "critical": "Critical",
      "high": "High",
      "normal": "Normal",
      "low": "Low"
    },
    "orderTypes": {
      "item_sales": "Sale",
      "car_rent": "Car hire",
      "car_sales": "Car sales",
      "car_service": "Workshop appointment"
    },
    "genderOptions": {
      "male": "Male",
      "female": "Female"
    },
    "salutationOptions": {
      "mr": "Mr",
      "mrs": "Mrs"
    },
    "team_service": "Service team",
    "team_dispo": "Disposition team",
    "team_rent": "Rental team",
    "team_sales": "Sales team",
    "car_documents": "Vehicle documents",
    "nav_module_chip": "Sat nav module / chip",
    "safety_vest": "Safety waistcoat",
    "parking_disk": "Parking disc",
    "ice_scraper": "Ice scraper",
    "key_supplement_heating_taxis": "Key add-on (parking heater/taxi sign)",
    "shelf_blind": "Hat shelf / roller blind",
    "onboard_folder": "On-board folder",
    "first_aid_kit": "First aid kit",
    "warning_triangle": "Warning triangle",
    "tirefit_spare_wheel": "Tirefit / spare wheel",
    "handover_damage": {
      "front": "Front",
      "back": "Rear",
      "left": "Links",
      "right": "Right",
      "top": "Above",
      "interior": "Interior"
    },
    "dark": "It is dark",
    "garage": "In a garage",
    "snow_ice": "Snow / Ice",
    "rain_wet": "It is raining / the car is wet",
    "countries": {
      "de": "Germany",
      "pl": "Poland",
      "fr": "France"
    },
    "cookie_description": {
      "PHPSESSID": "Identifies your current browser session",
      "tempTarget": "We use the temporary target cookie to identify the products you are looking for and your interests",
      "chedriCookiesBoxClosed": "We use this cookie to indicate that you have seen and accepted our cookie information",
      "chedriCookiesPerformance": "Used to determine whether you accept our performance cookies",
      "chedriCookiesTargeting": "Used to determine whether you accept our targeting cookies",
      "ga": "Used to distinguish users in the Google Analytics service",
      "gid": "Used to distinguish users in the Google Analytics service",
      "act": "This is a Facebook cookie that is used for their analysis and research",
      "c_user": "Facebook login for user logins, which is used to display adverts that are more relevant to users and users' interests",
      "datr": "Used by Facebook to identify the user's browser",
      "fr": "Contains a unique browser and user ID that is used for targeted advertising",
      "sb": "Used by Facebook to improve the suggestions of friends",
      "xs": "A Facebook cookie that is used to manage a session. It works in conjunction with the c_user cookie to authenticate your identity on Facebook"
    },
    "cookie_ttl": {
      "6_months": "6 months",
      "60_days": "60 days",
      "2_years": "2 years",
      "1_day": "1 day"
    },
    "upload_and_manage_media_files": "Here you can upload and manage your media.",
    "profile_information_avatar_contact_address": "Here you can edit your profile information. Avatar, contact details, address for invoicing, financial information.",
    "define_sales_points_address_position_map_opening_hours": "Define your points of sale: Address, position on the map, opening hours. Important for managing products and employees.",
    "check_incoming_and_outgoing_invoices_check_details_and_download_as_pdf": "You can check incoming and outgoing invoices generated by the system or other users. View details and download in PDF format.",
    "found_api_interesting_personal_api_key_here": "Do you find our API interesting? Would you like to try it out? You can find your personal API key here.",
    "need_our_data_in_own_system_configure_data_push": "Do you need our data in your own system? Here you can configure data push services to subscribe to data events.",
    "projects_running_on_different_domains": "Projects that are executed on different domains",
    "administration_of_your_website_logo_menu_pages": "Manage your website. Logo, main menu, system pages",
    "create_users_manage_profile_financial_roles_teams": "Create users who work in your organisation, manage their profile and financial information, review and define security roles and assign teams.",
    "provision_service": "Booked services",
    "provision_service_description": "Here you can create, edit or delete new types of services",
    "provision_service_title": "Title",
    "provision_service_type": "Name",
    "provision_service_description_text": "Description of the",
    "provision_service_primary_rate": "Commission in per cent for prim. owner",
    "provision_service_secondary_rate": "Commission in per cent for sec. owner",
    "provision_service_type_status": "Status",
    "add_provision_type": "Add commission type",
    "edit_provision_type": "Edit",
    "create_provision_type": "Create commission type",
    "service_type_onboarding": "Onboarding (installation)",
    "service_type_subscriptions": "Subscriptions (MRR, subscriptions && user fees)",
    "service_type_additional_services": "Additional services (templates)",
    "service_type_product_sales": "Product sale",
    "sales": "Distribution",
    "primary_owner": "Primary owner",
    "secondary_owner": "Secondary owner",
    "provision_start": "Start",
    "provision_ending": "End",
    "provision_actions": "Actions",
    "add_provision_service": "Create commission",
    "edit_provision_service": "Edit commission",
    "save_provision_type": "Save",
    "prov_service_client_name": "Name",
    "provision_service_documents": "Commissions",
    "commission_service_documents": "Commissions - Documents",
    "assign_service_type": "Assign new service type",
    "provision_service_documents_description": "You can view all commissions here",
    "provision_service_documents_download": "Download documents",
    "provision_service_documents_download_start": "download",
    "provision_service_documents_name": "Name",
    "provision_service_documents_title": "Title",
    "provision_service_documents_description_text": "Description of the",
    "provision_service_documents_serviceType": "Type",
    "provision_service_documents_provisionRate": "Commission rate",
    "provision_service_documents_provisionValue": "Commission value",
    "provision_service_documents_provisionAssignee": "Receiver",
    "provision_service_documents_provisionPayedDate": "paid on",
    "provision_service_documents_IsActive": "Status",
    "provision_service_documents_Client": "Customer",
    "provision_service_documents_choose_month": "Please select the desired month",
    "commission_service_documents_description": "You can view and download all commission invoices and details here",
    "commission_service_documents_download": "Download documents",
    "commission_service_documents_download_start": "download",
    "commission_client": "Customer",
    "commission_invoice_no": "Invoice number",
    "commission_payed_data": "paid on",
    "commission_service_type": "Service Type",
    "commission_amount": "Amount",
    "commission_provision": "Commission",
    "create_update_teams_assign_system_types": "Create and edit teams within your company. Assign system types to your teams.",
    "create_document_types_use_for_process_automation": "Create your own document types so that you can use them in integrations or for process automation.",
    "write_email_templates_send_to_clients": "Create email templates that can be used when sending messages to customers or to automate some workflows.",
    "define_reusable_cms_elements": "Define reusable elements that can be used on any page.",
    "manage_brands_edit_name_description": "Manage product brands, edit their names and descriptions in multiple languages.",
    "manage_car_models_and_aliases": "Manage car models from which users can choose. Define aliases for the import.",
    "display_and_manage_category_tree": "Display and manipulate the category tree. You can change names and descriptions.",
    "display_and_manage_product_templates": "Create and manage templates and enable users to get creative.",
    "define_product_attributes_search_and_variants": "Define additional attributes that you can specify in your products. Use these attributes to help users search for products. Create product variants based on attributes.",
    "group_attributes_to_better_manage_and_inform": "You can group attributes to better manage the user experience in the backend and present item attributes to your customers in a clearer way.",
    "entity_fields_missing_add_new": "If you are missing some fields in your forms, you can add them here. Useful for marketing campaigns and integrations.",
    "define_manage_pipelines_and_stages": "Define and customise pipelines for your workflows. Add, edit and remove stages.",
    "create_questionnaires_for_workflows_and_campaigns": "Create questionnaires that can be used in workflows or various marketing campaigns.",
    "catch_leads_from_other_sources_with_custom_email": "Catch leads from multiple sources with our Lead Catcher. Configure individual email addresses for each desired source.",
    "group_car_models_into_families_for_users_to_easily_find": "Group vehicle models into families to make it even easier for users to search for cars.",
    "enrich_car_search_functionality_with_own_model_images": "Enrich the car search function with your own model photos. Assign photos to models or model groups.",
    "tag_leads_with_custom_regular_expressions": "Extend the functionality of our tag system with user-defined tags. Knowledge of regular expressions is required.",
    "unknown_error": "Unknown error occurred",
    "username_required": "Please enter your user name",
    "email_required": "Please enter your email",
    "email_invalid": "Email is invalid",
    "client_name_required": "Please enter your name",
    "password_required": "Please enter your password",
    "password_length": "The password must consist of at least 8 characters",
    "repeat_password_required": "Please repeat your password",
    "repeat_password_length": "The repeated password must consist of at least 8 characters",
    "message_required": "Please enter a message",
    "second_password_incorrect": "Second password is not the same as the first one",
    "registration_not_confirmed": "Your registration has not yet been confirmed. Please check your mailbox and click on the button to confirm your e-mail address",
    "account_deleted": "This account has been deleted!",
    "account_inactive": "The subscription to this account has been cancelled!",
    "company_required": "Please enter the company name",
    "first_name_required": "Please enter the first name",
    "last_name_required": "Please enter the surname",
    "country_required": "Please select a country",
    "phone_required": "Please enter the telephone number",
    "street_required": "Please enter the street name",
    "house_number_required": "Please enter the house number",
    "zip_code_required": "Please enter the postcode",
    "zip_code_invalid": "The postcode '{{ value }}' is not a valid postcode",
    "city_required": "Please enter the name of the city",
    "vat_id_required": "Please enter the sales tax ID",
    "timezone_required": "Please select a time zone",
    "select_job": "Please select a job",
    "title_required": "Please enter the title",
    "type_required": "Please select a type",
    "location_required": "Please enter the location",
    "questionnaire_required": "Please select a questionnaire",
    "subject_required": "Please enter the subject",
    "amount_required": "Please enter the amount",
    "time_count_required": "Please enter the amount of time",
    "name_required": "Please enter the name",
    "price_required": "Please enter the price",
    "description_required": "Please enter the description",
    "question_required": "Please enter the question",
    "content_required": "Please enter the content",
    "template_required": "Please select a template",
    "payment_schedule_required": "Please select a payment plan",
    "answer_required": "Please enter the answer",
    "website_required": "Please enter the address of the website",
    "fax_required": "Please enter the fax number",
    "currency_required": "Please select a currency",
    "vat_name_required": "Please enter the name of the VAT",
    "vat_id_name_required": "Please enter the name of the VAT ID",
    "vat_rates_required": "Please enter the VAT rates",
    "coc_number_required": "Please enter the COC number",
    "registered_at_required": "Please enter the information under which your company is registered",
    "bank_name_required": "Please enter the bank name",
    "account_holder_required": "Please enter the full name of the account holder",
    "account_number_required": "Please enter the account number",
    "bank_location_required": "Please enter the bank location",
    "bank_code_number_required": "Please enter the bank sort code",
    "iban_required": "Please enter the IBAN",
    "min_message": "This value is too short. It should have {{ limit }} characters or more",
    "max_message": "This value is too long. It should have {{ limit }} characters or less",
    "min_max_length": "This value should be between {{ min }} and {{ max }} characters long",
    "greater_than_or_equal": "This value should be greater than or equal to {{ compared_value }}.",
    "price_type_invalid": "The price type is invalid",
    "This value should not be blank": "This value should not be empty",
    "required_message": "This value must not be empty",
    "This value is not a valid URL": "This value is not a valid URL",
    "must_upload_a_file": "You need to upload a file",
    "file_format_not_supported": "The file you are trying to upload is not supported",
    "category_not_empty": "Category is not empty",
    "brand_has_items": "The brand has allocated products",
    "template_has_items": "Products are assigned to the template",
    "item_has_variants": "The product has assigned variants",
    "no_valid_variant": "No valid variant",
    "no_stage_set": "No level has been set",
    "no_pipeline_set": "No pipeline was set",
    "no_users_found": "No users found",
    "no_team_set": "No team set",
    "budget_required": "Please define the budget",
    "client_required": "Please assign a customer",
    "order_required": "Please assign an order",
    "item_required": "Please assign a product",
    "document_required": "Please ensure that documents are available and valid",
    "brand_required": "Please select a brand",
    "model_required": "Please select a model",
    "order_type_invalid": "Order type not valid",
    "item_already_reserved": "Some products are not available on selected days",
    "wrong_credentials": "Your user name or password has been entered incorrectly. Please try again or click on \"Forgotten password\"",
    "no_car_found": "No vehicle found for this lead",
    "data_push_event_invalid": "Data push event is invalid",
    "data_push_auth_invalid": "Data push authentication type is invalid",
    "cart_type_invalid": "Shopping basket type is invalid",
    "system_type_invalid": "The system type is invalid",
    "menu_version_invalid": "Menu version is not valid",
    "logo_version_invalid": "Logo version is not valid",
    "payment_method_invalid": "We could not process the payment method you specified",
    "nationality_required": "Please enter your nationality",
    "place_of_birth_required": "Please enter your place of birth",
    "date_of_birth_required": "Please enter your date of birth",
    "id_required": "Please enter the ID",
    "meta_identifier_required": "Please select an identifier",
    "payment_status_invalid": "The payment status is not valid",
    "iban_invalid": "The value you entered is not a valid account number",
    "swift_bic_required": "Please enter a valid SWIFT/BIC number",
    "reference_required": "Please enter a reference",
    "service_status_invalid": "The service status is not valid",
    "date_proposal_type_invalid": "Suggested date type is not valid",
    "mobile_phone_invalid": "Mobile phone number is invalid",
    "mobile_phone_required": "Please enter your mobile number",
    "phone_invalid": "Phone number is invalid",
    "fax_invalid": "Fax number is invalid",
    "salutation_required": "Please select a salutation",
    "gender_required": "Please select a gender",
    "stripe_error": "Error when connecting to Stripe",
    "stripe_connect_error_with_type": "We could not connect CreaCheck to your Stripe account. Error code: {type}.",
    "car_rental_pickup_time_invalid": "The desired pick-up time is outside the opening hours of the location",
    "car_rental_drop_off_time_invalid": "The requested drop-off time for a car is outside the opening hours of the location",
    "accept_terms_and_cancellation_before_continuing": "Please confirm that you have read our General Terms and Conditions and the cancellation policy",
    "problem_processing_your_credit_card": "We have a problem processing your credit card",
    "invoice_type_invalid": "Invoice type is invalid",
    "doc_number_required": "Please enter the document number",
    "doc_issuing_city_required": "Please indicate the issuing city",
    "doc_issuing_authority_required": "Please indicate the issuing authority",
    "doc_issue_date_required": "Please enter the date of issue",
    "doc_expiration_date_required": "Please enter the expiry date",
    "test_domain_required": "Please enter a test domain name",
    "iban_has_wrong_format": "The IBAN format is not correct",
    "car_cash_payment_data_missing_or_invalid": "Cash payment data is missing or invalid",
    "car_registration_service_only_available_in_germany": "The authorisation service is currently only available in Germany",
    "car_transport_only_available_in_germany": "Our transport service is currently only available in Germany",
    "target_type_invalid": "Target type not valid",
    "company_email_must_be_different_from_users_email": "Your company's e-mail address must be different from your e-mail address",
    "car_transport_distance_too_short": "Car transport is only possible to locations that are more than 100 km away",
    "first_offer_price_too_low": "Your first offer is too low and was not accepted",
    "first_offer_price_too_high": "Your first offer is higher than the original price and was not accepted",
    "current_offer_too_low": "Your current offer is too low",
    "not_your_turn_to_negotiate_the_price": "You cannot update your offer now. Please wait for a response from our employee",
    "the_question_must_be_answered": "The question \"{{ question }}\" must be answered",
    "validation_problems_with_item_attributes": "Check whether the attributes have been filled in correctly. Problems that have occurred are marked in red",
    "the_field_name_contains_following_errors": "The {fieldName} field contains the following error: {helpText}",
    "plugin_required": "Please select a subscription plan.",
    "invalidRegex": "The regular expression you specified is invalid",
    "assignment_strategy_type_invalid": "Assignment strategy type is not valid",
    "cannot_remove_role_company": "It is not possible to remove user role ROLE_COMPANY",
    "coupon_type_invalid": "Please select a valid voucher type",
    "subscription_starting_fee": "Entry fee for the subscription",
    "comms_center_monthly_fee": "Creacheck Communications Centre",
    "comms_center_chat": "Creacheck Communication Centre - Customer chat",
    "comms_center_call": "Creacheck Communication Centre - Customer call",
    "0": "<strong>Fehler beim Einloggen</strong>",
    "You are not authenticated": "Unfortunately we do not know the email and password",
    "access_denied": "To log in, you must accept the access for Creacheck.com",
    "oauth_email_missing": "We cannot log you in through social media. Please log in with your e-mail address.",
    "User already exists": "The e-mail address you entered already exists in our database. Have you forgotten your password?",
    "next": "Further",
    "create_comment": "Write a comment",
    "new_pipeline": "New pipeline",
    "new_stage": "New level",
    "quantity": "Quantity",
    "login_error": "Error when logging in",
    "not_found": "Not found",
    "deal_information": "Deal information",
    "login_domain": "Login domain",
    "login_domain_successfully_changed": "The login domain has been successfully changed.",
    "missing_lead_information": "Please enter any missing lead information",
    "missing_informations": "Missing information",
    "missing_client_signature": "Missing customer signature",
    "previous": "Previously",
    "current": "Current",
    "customCssInvalid": "Custom CSS is invalid ({{ error }})",
    "accountAndLogin": "Account & Login",
    "invalidEIN": "Invalid eVB number (electronic insurance confirmation)",
    "radio": "Radio",
    "airConditioning": "Air conditioning",
    "parkingAssistanceRear": "Rear parking aid",
    "airConditioningAutomatic": "Automatic climate control",
    "navigation": "Sat Nav",
    "alloyRims": "Aluminium rims",
    "parkingAssistanceFrontRear": "Front & rear parking aid",
    "metallicPaint": "Metallic paintwork",
    "cruiseControl": "Cruise control",
    "seatHeating": "Seat heating",
    "leatherSeats": "Leather seats",
    "equipmentLevel": "Equipment level",
    "motorPower": "Performance",
    "currentLocation": "Current location",
    "distance": "Distance",
    "selected": "selected",
    "noBrandSelected": "No brand selected",
    "showMore": "Show more",
    "showLess": "Show less",
    "optional": "Optional",
    "altogether": "In total",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "payment_data_missing_or_invalid": "Payment data is missing or invalid",
    "previousSearchRequests": "Previous searches",
    "reset": "Reset",
    "number_days": "{days, plural, zero {# days} one {# day} other {# days}}",
    "number_months": "{months, plural, zero {# months} one {# month} other {# months}}",
    "outdated_browser": "Your browser is outdated!",
    "update_browser_text": "Please update your browser to display this website correctly.",
    "update_browser_btn_text": "Update the browser now",
    "save_successfully": "Successfully saved",
    "add_quickly": "Add quickly",
    "load_data": "Load data",
    "car_data_import_vin_info": "Enter the valid VIN to automatically complete the vehicle data form.",
    "car_data_import_missing_attr_values": "Some attribute values are missing - they are marked in the form.",
    "vin_invalid": "FIN is invalid",
    "others": "Other",
    "activate_plugin_to_enable_feature": "This function is only available with the following plugin. Activate the plugin to continue using this functionality.",
    "car_data_import_failed": "It was not possible to import vehicle data for this VIN. Check whether the specified VIN is correct.",
    "send_mail_via_outlook": "The email is sent with your Microsoft Outlook account ({email}) and is later available in your \"Sent\" folder.",
    "permission_required": "Authorisations required",
    "phone_call_permissions_required": "This application must access your phone accounts in order to make calls",
    "calling": "Call me",
    "callingTo": "Call to",
    "startingCall": "Initiate a call",
    "callEnded": "Call ended",
    "inCall": "'In call",
    "callCancelled": "Call cancelled",
    "callRejected": "Call rejected",
    "is_calling": "%name% calls...",
    "contacts": "Contacts",
    "addresses": "Address book",
    "chat": "chat",
    "calls": "Calls",
    "endCustomerNewMessage": "Message from new customer",
    "returnToTheActiveCall": "Back to the active call",
    "agentStatus": "Agent status",
    "switchOnAgentNote": "Activate to accept customer chats and video calls",
    "switchOffAgentNote": "Deactivate to not accept customer chats and video calls",
    "activateAgent": "Activate the acceptance of customer chats",
    "deactivateAgent": "Deactivate the acceptance of customer chats",
    "startAudioCallWith": "Start phone call with {{to}}",
    "startVideoCallWith": "Start video call with {{to}}",
    "terms_and_conditions": "Terms and conditions",
    "form_incorrect_data": "The data you have entered is not correct. Please check your entries and send them again.",
    "accept_terms_and_condition_start_chat": "If you click on \"Start chat\", you accept the terms and conditions",
    "start_chat": "Start chat",
    "connecting_with": "Connect with",
    "connected_with": "Connected with",
    "do_calling": "calls",
    "chat_inactivity_warning": "The chat has been inactive for too long and will be closed soon.",
    "get_in_contact_with_us": "Get in touch with us",
    "no_agent_available": "Unfortunately none of the agents are online. Please leave a message and we will contact you as soon as possible.",
    "hello": "Hello!",
    "we_are_here_for_you": "We are here for you and look forward to your questions or feedback.",
    "start_conversation": "Start conversation",
    "welcome_customer": "My name is {firstName} {lastName}. How can I help you?",
    "end_chat": "Exit",
    "send_request": "Send enquiry",
    "checkout_payment_agreement": "I consent to {company} commencing the provision of the additional services before the start of the cancellation period and I understand that I will lose my right of cancellation once these additional services have been provided in full.",
    "checkout_buy_order_legal_text": "The {general_terms_and_conditions} and the {cancellation_policy_and_cancellation_form} of {company} ({privacy_policy}) apply to the purchase",
    "checkout_services_terms_cancelation_legal_text": "For the additional services booked ({booked_services}), {general_terms_and_conditions} and the {cancellation_policy_and_cancellation_form} of {company} ({privacy_policy}) apply",
    "no_credit_cards": "No credit cards",
    "minimize_video": "Minimise video view",
    "maximize_video": "Maximise video view",
    "enable_fullscreen": "Activate full screen mode",
    "disable_fullscreen": "Deactivate full screen mode",
    "enable_screensharing": "Share desktop",
    "disable_screensharing": "End desktop sharing",
    "mute_microphone": "Mute microphone",
    "unmute_microphone": "Unmute microphone",
    "enable_camera": "Switch on the camera",
    "disable_camera": "Switch off the camera",
    "end_call": "End the call",
    "end_videocall": "End the video stream",
    "muted": "Muted",
    "overall_feedback": "How would you rate your contact with us?",
    "connection_quality_feedback": "How would you rate the connection quality?",
    "send_feedback": "Send feedback",
    "customer_chat_ended_info": "Your chat has been terminated",
    "checking_connection": "Connection is checked",
    "call_again": "Call again",
    "end_call_local_disconnected": "Call termination due to connection problems",
    "end_call_remote_disconnected": "Call termination due to connection problems on the {remoteUserName} page",
    "network_disconnected_warning": "There is currently no connection to the Internet.",
    "network_disconnected": "Connection interrupted",
    "network_want_reconnect": "Your connection has been interrupted. Would you like to reconnect?",
    "leave_as_an_email_message": "Leave us an e-mail message",
    "continue_call": "Continue call",
    "continue_text_chat": "Continue text chat",
    "continue_conversation": "Continue conversation",
    "disconnected": "Separate",
    "disconnect_reason": "Possible reason",
    "disconnect_reason_transport_close": "Leave this page",
    "disconnect_reason_ping_timeout": "No internet connection",
    "customer_may_return": "The customer can come back soon.",
    "mobile": "Mobile phone",
    "headquarter": "Centre",
    "extension-number": "Extension number",
    "add_contact": "Add contact",
    "add_address": "Add address",
    "choose_contact": "Select contact",
    "number": "Number",
    "new_number": "New number",
    "address_mail": "Postal address",
    "address_delivery": "Delivery address",
    "address_invoice": "Invoice address",
    "new_address": "New address",
    "find_address": "Find address",
    "searching": "Search...",
    "address_change": "Change address",
    "address_ask_change": "Changing this address will affect all contacts with this address. Would you like to change the address?",
    "designation": "Designation",
    "net": "Net",
    "gross": "Gross",
    "exp_delivery_time": "expected delivery time",
    "workdays": "Working days",
    "special_offer": "Action",
    "transportation_cost": "Shipping costs",
    "discount": "Discount",
    "total": "Total",
    "total_gross": "Total (incl. VAT)",
    "product_details": "Product details",
    "type_of_order": "Type of order",
    "number_of_copies": "Edition",
    "type_of_order_choices": "Regular print production or download the print data as a PDF file",
    "type_of_order_website": "Provision of this website",
    "print_production": "Print production",
    "pdf_download": "PDF-Download",
    "website_upload": "Upload the website",
    "production_time": "Production time",
    "production_time_description": "Please select the desired option",
    "production_standard": "Standard production",
    "production_express": "Express production",
    "production_overnight": "Overnight production",
    "choose_edition": "Select edition",
    "choose_edition_description": "Editions with graduated prices. Discounts and promotions are highlighted if available.",
    "more_options": "Further options",
    "more_options_description": "Add further options to your order.",
    "manual_data_review": "Manual data check (incl. insurance)",
    "manual_item_entry": "Manual input of the position",
    "web_enabled_pdf": "Print data also available as a web-enabled PDF.",
    "cart_production_type_change_warning_title": "The circulation is adjusted",
    "cart_production_type_change_warning": "The selected print run is not available for the {{shippingType}}. It will be changed to the maximum available print run. Are you sure you want to continue?",
    "cart_no_warranty_without_manual_data_check": "Note: Manual data check not selected.",
    "campaign_budget_exceed_warning": "Campaign budget exceeded. Approval required!",
    "user_budget_exceed_warning": "User budget exceeded. Release required!",
    "order_quantity": "Order quantity",
    "data_check": "Data check",
    "split_shipping": "Partial delivery",
    "cart_not_logged_in": "No logged in user",
    "cart_contact_or_address_not_found_invoice": "Please select a billing address",
    "cart_contact_or_address_not_found_shipping": "Please select a delivery address",
    "cart_edtion_item_or_variant_not_found": "Issue, article or variant not found",
    "cart_edtion_item_not_available": "The article %item_name% is no longer available",
    "cart_no_print_type_found": "No print type found",
    "cart_print_type_invalid": "Print type invalid:",
    "cart_no_shipping_type_found": "No shipping method found",
    "cart_checkout_info_missing": "Checkbox information is missing:",
    "cart_reduced_vat_rate_check_not_supported": "Requested check of the reduced VAT rate for items that do not support it:",
    "cart_no_price_found": "The price could not be found",
    "cart_price_mismatch": "The price does not correspond to the price we presented to the user",
    "cart_web_pdf_price_mismatch": "The web PDF price does not correspond to the price we have presented to the user",
    "cart_contact_or_address_not_found_split_shipping": "Please select an address for split shipping",
    "cart_quantity_not_found_split_shipping": "Quantity parameters not found for split dispatch",
    "cart_split_shipping_quantity_mismatch": "The split shipping quantity does not match the production quantity",
    "cart_coupons_mismatch": "The coupons used do not match",
    "cart_coupon_cant_be_used": "The requested coupon has expired or cannot be used",
    "cart_coupon_value_mismatch": "The coupon value is not correct",
    "cart_camaign_budget_processing_error": "Unexpected problem occurred while processing the campaign budget",
    "cart_campaign_budget_has_changed": "The campaign budget has changed",
    "cart_user_budget_has_changed": "The user budget has changed",
    "cart_user_budget_processing_error": "Unexpected problem occurred when processing the user budget",
    "cart_domain_not_active": "The domain is not active",
    "cart_missing_desired_domain": "Specification of the desired domain is missing",
    "lead_monitor_order_payment_receipt": "Monitor incoming payments",
    "lead_format_message_description_route53_register_domain": "Register domain {0} and redirect to {1}",
    "lead_title_route53_register_domain": "Register AWS domain",
    "domain_not_available": "Domain not available",
    "invoice_payment_option": {
      "enabled": "Activated",
      "disabled": "Deactivated",
      "inherited": "Inherited",
      "title": "Payment of the invoice",
      "loading": "Load inherited setting",
      "effective_setting": "Effective setting"
    },
    "invoice_payment_threshold": {
      "title": "Threshold value",
      "placeholder": "in the example, 100",
      "no_effect": "No effect",
      "loading": "Loading inherited settings",
      "info_text": "Minimum order value for payment by invoice",
      "effective_threshold": "Effective threshold",
      "not_defined": "not defined"
    },
    "remind_period": {
      "days": "Days",
      "inherited_setting": "Inherited attitude",
      "title": "Due date of the invoice Reminder period"
    },
    "sellDownloadPdf": "Download PDF sell",
    "success_management": {
      "title": "Success management",
      "manager": "Success manager",
      "switch_title": "Activate recently viewed memories",
      "interval": "Last viewed period, days",
      "last_seen": "Last seen",
      "error_no_self": "Cannot assign self as Success Manager",
      "error_no_same_parent": "Success Manager must be subordinate to another company"
    },
    "copy": "Copy",
    "is_client_registration_allowed": "Activate registration",
    "is_email_validation_enabled": "Validate email",
    "email_validation_pattern": "Regex for email validation",
    "confirmation_email_cc": "CC for registration confirmation",
    "client_registration_title": "Registration settings",
    "registration_fields_show": "Display fields",
    "registration_fields_require": "Mandatory fields",
    "accept_terms_of_use_before_continuing": "Before you continue, you must accept our terms of use",
    "subscription_settings": "Subscriptions",
    "client_subscription_settings": "Customer and product subscriptions",
    "subscription_settings_description": "Here you can create a basic subscription and assign a type",
    "client_subscription_settings_description": "Here you can assign the basic subscription to a customer or an item depending on the type and adjust the prices",
    "subscriptions_": "Subscriptions",
    "subscription_name": "Name",
    "subscription_title": "Title",
    "subscription_description": "Description of the",
    "subscription_fee": "Fee",
    "subscription_startingFee": "Entry fee (optional)",
    "subscription_start": "Start",
    "subscription_end": "End",
    "subscription_status": "Status",
    "subscription_action": "Actions",
    "subscription_items": "Products",
    "subscription_clients": "Customers",
    "subscription_companies": "Companies",
    "subscription_companies_detailed_role": "Company User/Role",
    "subscription_basic": "Basic subscription",
    "add_subscription": "Add subscription",
    "create_subscription": "Create subscription",
    "edit_subscription": "Save subscription",
    "subscription_language": "Language selection",
    "period_status": "Deactivate at the end of the period",
    "subscription_edit": "Edit",
    "omit_subscription_fee": "Omit entry fee",
    "invoice_email": "Email address for invoices",
    "order_processing_contract": "Order processing contract",
    "accept_order_processing_contract_before_continuing": "Before you continue, you must accept our order processing contract",
    "no_image_title": "No image title found. Click here to edit.",
    "no_image_description": "No image description found. Click here to edit",
    "no_image_copyright": "No copyright information found. Click here to edit",
    "no_text": "No text found. Click here to edit",
    "subscription_clients_and_companies": "Customers and companies",
    "save_subscription": "Save",
    "go_to": "Go to",
    "no_invoices_to_display": "No invoices to display",
    "url_settings": "Url settings",
    "update_notification": "Update management",
    "update_notification_description": "Description of the",
    "update_notification_settings_description": "You can manage notifications for updates here",
    "update_name": "Name",
    "update_notification_meta": {
      "title": "Update management",
      "description": "Manage your update notifications here",
      "name": "Update management"
    },
    "new_update_notification": "Create new update notification",
    "update_from": "from",
    "update_to": "until",
    "edit_update_notification": "Edit update notification",
    "individual_prices": "Customised prices",
    "template_pdf_profile_x3": "Create PDF in X-3 format",
    "countries_for_prices": {
      "de": "Germany",
      "ch": "Switzerland",
      "at": "Austria",
      "es": "Spain"
    },
    "choose_country": "Please select a country",
    "product_groups": "Product groups - Suppliers",
    "product_groups_description": "Here you can manage the product groups of suppliers",
    "new_product_group": "Add new product group",
    "edit_product_group": "Edit",
    "create_product_group": "Create",
    "product_group_number": "Product group number",
    "save_product_group": "Save",
    "product_group_actions": "Actions",
    "product_group_status": "Status",
    "hide_preview": "Deactivate preview in the editor",
    "editor_settings": "Settings Editor",
    "share_edition": "Share edition",
    "canceled_quote": "Offer rejected",
    "active_quote": "Offer pending",
    "manual_quote_status": "Status of the offers",
    "invalid_manual_quote_status": "Invalid status of the offer",
    "internal_status": "Internal status",
    "edit_quote": "Edit offer",
    "copy_quote": "Copy offer",
    "open_lead": "Open Lead",
    "upload_pdf": "Upload and link PDF",
    "upload_pdf_title": "PDF Upload",
    "upload_pdf_status_done": "Your PDF file has been successfully uploaded",
    "upload_pdf_status_error": "PDF upload failed.",
    "placeholder_upload_error": "A problem has occurred during upload!",
    "linkedin_insight_tag": "LinkedIn Insight Tag",
    "linkedin_partner_id": "Partner Id",
    "tracking_pixel": "Tracking Pixel e.g. Meta Pixel",
    "tracking_pixel_data": "Implementation code",
    "jsWebsite": "Javascript",
    "upload_website_favicon": "Favicon",
    "upload_website_favicon_label": "Image upload and preview",
    "upload_website_favicon_info": "Please note! The required file format is PNG. Depending on the device used, the optimum resolution is between 32 x 32px and a maximum of 64 x 64px. You can also use other sizes as long as they are square and do not exceed the maximum resolution.",
    "upload_favicon_error": "Image does not have the required resolution",
    "go_back_to_campaign": "Back to the campaign",
    "no_orders_to_display": "Keine Bestellungen zum Anzeigen",
    "no_editions_to_display": "Keine Editionen zum Anzeigen",
    "google_tag_manager": "Google Tag Manager",
    "google_tag_manager_id": "GTM ID",
    "website_analyse": "Website analysis",
    "legal_information": "Legal information",
    "contact_info": "Contact information",
    "import_users_via_csv": "Create users by importing a CSV file",
    "remove_users_via_csv": "Remove users by importing a CSV file",
    "import_users": "Import users",
    "remove_users": "Remove user",
    "fill_data_policy_before_autosave": "Automatic saving failed. Please complete the data protection and legal notice information. These can be found in the settings",
    "invoice_customer_info": "ID - Company / Name",
    "invoice_search_debtor": "Debtor number",
    "invoice_search_vat": "VAT",
    "invoice_search_amount": "Amount",
    "search_field_name": "Select a search field",
    "search_field_info_text": "If you want to search for a numerical value in the search field, you must select a field",
    "your_export": "Your export",
    "export_download_info": "Your file has been created and can now be used.",
    "export_download": "download",
    "export_file": "File",
    "export_share": "Share",
    "image_permission_title": "Attention!",
    "image_permission_warning": "Image rights available/consent obtained from all persons?",
    "other_settings": "Other settings",
    "image_rights_query": "Image rights query",
    "direct_ordering_label": "Direct order",
    "direct_ordering_info_text": "In the case of a direct order, the edition created can no longer be edited. It is placed directly in the shopping basket.",
    "subscription_automation": {
      "title": "Billing target for new user subscriptions",
      "payer": "Payer",
      "fee_for_role_user": "Fee for ROLE_USER",
      "fee_for_role_admin": "Fee for ROLE_ADMIN",
      "fee_for_role_wl_admin": "Fee for ROLE_WL_ADMIN",
      "hint_title": "Note",
      "hint_body": "Please create and activate a company subscription for this company to cover all users created later.",
      "debtor_ancestor": "Each user subscription is billed to the %billingTargetName%.",
      "debtor_ancestor_missing": "No predecessor company is marked as debtor. Please correct this."
    },
    "need_content_authorization": "Content authorisation required",
    "publisher_info_text": "The publisher is automatically inserted in every necessary advertising medium in accordance with the legal guidelines.",
    "mark_campaign": "Mark campaign",
    "template_exports": {
      "download_complete": "Download completed",
      "export": "Export",
      "download_files": "Download files",
      "synchronize": "Synchronise"
    },
    "info_import_and_export": {
      "note": "General information on import and export",
      "import": "Import: Imports all information about the template settings (CSS, content blocks, other settings) and files. Creates an initial zip file for the export. Existing zip files are overwritten.",
      "upload": "Upload: Adds further files to the S3 template directory. These files are added to the zip file for the export.",
      "sync": "Synchronise: Synchronises the template settings (CSS, content blocks, other settings) with the files (index.html, page.css, pdf.css, printpdf.css, website.css, website.js) in the S3 template directory and the zip file (export).",
      "export": "Export: Creates the structure of an index.html for copying and pasting",
      "download": "Download files: Downloads the existing zip file. (Perform a synchronisation before downloading to obtain the latest data",
      "copy": "Copy Template: The 'Copy Template' function only copies the template settings, but not the directories and files"
    },
    "template_upload_title": "Upload files to the template directory",
    "pls_choose_text": "Please select a text",
    "discounts": {
      "discount_type_invalid": "Discount type is not valid",
      "discounts_tab_title": "Discounts",
      "intro_text": "Please enter the FlyerAlarm product group number and the corresponding discount type and value. These will be applied to all users within the current organisation during checkout.",
      "none_yet_add_some": "No discounts have been defined yet. Please add some.",
      "number_must_be_unique": "Number must be unique",
      "must_be_0_to_100": "Must be from 0 to 100"
    },
    "link_to_uploaded_pdf": "Url to the uploaded PDF file",
    "upload_qrcode_logo": "Upload logo for QR code",
    "choose_file": "Select file",
    "upload_successful": "Upload successful",
    "upload_failed": "Upload failed",
    "qr_logo": "Logo",
    "qr_example": "Example",
    "qr_settings": "QR code settings",
    "qr_margin": "Distance",
    "qr_upload": "Upload QRCode logo",
    "google_search_console": "Google Search Console",
    "gsc_html_tag": "HTML-TAG verification",
    "content_id": "Content-Id",
    "gsc_html_tag_example": "Please use the HTML tag verification. Copy the Id e.g. Xu9c238409d8JDSF8... from the content attribute of the meta tag that you receive from Google for the verification and paste the ID here. Example: <meta name='google-site-verification' content='Xu9c238409d8JDSF8...' />",
    "order_complete": {
      "good_luck": "Good luck with your campaign!",
      "order_complete": "Your order has been successfully completed",
      "pls_check_inbox": "Please check your inbox.",
      "order_info": "All order details have been sent to the e-mail address we have on file for you. You can view your order history at any time in your user account at",
      "orders": "Orders",
      "view": "view.",
      "start_next_campaign": "Start another campaign now"
    },
    "no_product_selected": "No product selected",
    "mail_messages": {
      "header": {
        "header_title": "The smart online design portal",
        "support": "Help & FAQ",
        "login": "LOGIN"
      },
      "footer": {
        "email_info": "You are receiving this e-mail because you have registered with %company%.",
        "email_info_first_name_form": "You are receiving this e-mail because you have registered with %company%.",
        "dont_reply": "This email was sent from an address that cannot receive incoming emails. </br>Please do not reply to this message. If you have any questions or concerns, please contact us at",
        "agb": "GTC",
        "privacy_policy": "Data protection",
        "imprint": "Imprint"
      }
    },
    "content_check": "Content review",
    "terms_of_payment": "Terms of payment",
    "terms_of_payment_edit": "Edit terms of payment",
    "cancel_impersonation": "End role reversal",
    "impersonate": "Accept role",
    "template_deleted_warning": "Attention! The template of the edition is no longer available.",
    "click_to_enable_editing": "Click here to activate editing on the current tab.",
    "click_to_send_a_request_to_enable_editing": "Click here to send a request to activate processing",
    "waiting_for_form_release": "Wait for release",
    "already_edited_by_another_user": "This edition may already be edited by another user.",
    "please_wait": "Please wait.",
    "user_already_requested_release": "has already applied for approval",
    "user_requested_that_you_give_up_the_editing": "asks for permission to edit.",
    "emergency_release": "Enforce release. Use at your own risk. Edition could be overwritten by another user if they are currently working on it.",
    "alt_debtor_number": "Alternative debtor ID",
    "use_bootstrap_label": "Bootstrap",
    "use_bootstrap_infotext": "If you activate this option, the bootstrap libraries are integrated",
    "last_queried_attributes": "Last requested prices",
    "transfer_campaign": "Transfer",
    "duplicate_campaign_description": "Duplicate the campaign and assign it to individual users or all users of a company",
    "duplicate_campaign": "Duplicate",
    "clone_to_single_user": "Create duplicate for one or more users",
    "clone_to_all_users_of_company": "Create duplicate for all users of a company",
    "enum_option_invalid": "Invalid option",
    "pdf_download_option": {
      "enabled": "Activated",
      "disabled": "Deactivated",
      "inherited": "Inherited",
      "title": "PDF download allowed",
      "loading": "Load inherited setting",
      "effective_setting": "Effective setting"
    },
    "choose_image_for_filter": "Please select a picture",
    "invoice_email_bcc": {
      "send_new_invoice_email_bcc": "Send new invoice by e-mail BCC",
      "email_address_bcc": "E-mail address BCC",
      "info_text": "A hidden copy of the new invoice email will also be sent to this address."
    },
    "reduced_vat_rate": {
      "cart_item_hint": "This item is eligible for a reduced VAT rate after a manual check by a Creacheck employee"
    },
    "forgot_password_infotext": "If you have forgotten your password, you can reset it here. Please enter your registered e-mail address in the form below and click on \"Continue\". We will then send you an e-mail with further information.",
    "forgot_password_infotext_robethood": "If you have forgotten your password, you can reset it here. Please enter the registered e-mail address in the form below and click on \"Continue\". We will then send you an e-mail with further information.",
    "forgot_password_info_email_not_used": "If you no longer use the email address associated with your Creacheck account, please contact us using our contact form:",
    "forgot_password_info_email_not_used_robethood": "If you no longer use the email address associated with your account, please contact us via our contact form:",
    "invoice_greeting_male": "Dear Mr",
    "invoice_greeting_female": "Dear Mrs",
    "invoice_greeting_text": "Thank you very much for your order via %company%. We will charge you for the following services:",
    "bank_account": "Bank details",
    "mandatory_field": "Declare this as a mandatory field",
    "custom_checkbox": "Customised checkbox",
    "set_label_checkbox": "Please assign a label for the checkbox",
    "set_checkbox_link": "You can link the label here",
    "change_checkbox_link": "Change link",
    "checkbox_infotext": "You can link the label by placing the corresponding area in $ characters. e.g. I agree to the $Privacy Policy$.",
    "perforation": {
      "cart_radiobox_title": "Options for filing perforation",
      "attribute_is_custom_fa_setting": "Is customised FA preselected perforation options",
      "set_perforation_options": "Set perforation options",
      "preset": "Default setting",
      "how_to": "Please search for the product group identifier, select some variants, try out different quantities and shipping methods. Then select the perforation option to be preselected with this attribute. Several options can be added.",
      "select_variant": "Please select a variant to display its quantities and shipping methods.",
      "select_shipping_type": "Please click on a shipping method to see the available options.",
      "select_option_value": "Please click on an option to add it to the selection.",
      "please_try_other": "Please try another variant, quantity or shipping method",
      "options": "Options",
      "other_options": "Other options",
      "selected_options": "Selected options",
      "perforation_options": "Perforation options",
      "product_group": "Product group",
      "variants": "Variants",
      "quantities_in_variant": "Quantities in variant"
    },
    "textile_select_exactly_n_items": "Please select exactly {total}",
    "textile_exact_number_selected": "All good: exactly {total} selected articles",
    "textile_order_quantity_mismatch": "The number of fabric sizes must match the selected edition.",
    "tracking_pixel_help_text": "Please note that the implementation may differ depending on the provider. In our example, we use the tracking pixel from Meta. The integration primarily requires domain verification. To do this, you need to integrate a meta tag, which in our example was provided by Meta. See the example code below. Please note that verification can take up to 72 hours, depending on the provider. In the second step, add the base code for the tracking pixel integration. For more information, please read the documentation provided by your respective provider.",
    "reg_greeting_male": "Dear Mr",
    "reg_greeting_female": "Dear Mrs",
    "reg_gender_male": "Mr",
    "reg_gender_female": "Mrs",
    "reg_name": "Salutation and name",
    "reg_phone_number": "Telephone number",
    "reg_domain": "Domain",
    "reg_ancestors": "Structure IDs",
    "reg_explanation": "<p>Thank you for your registration. Please confirm your e-mail address within 24 hours.</p><p>Only with a confirmed e-mail address can you receive a new password or information about changes.</p>",
    "widget_order_stats": {
      "title": "Total orders",
      "orders_count": "Count orders",
      "orders_gross_total": "Total gross orders",
      "no_orders_in_date_range": "No orders within the selected period",
      "loading": "Loading",
      "this_month": "This month",
      "previous_month": "Last month",
      "last_3_months": "Last 3 months",
      "last_6_months": "Last 6 months",
      "last_12_months": "Last 12 months",
      "for_companies": "for company(ies)",
      "for_clients": "for the customer(s)",
      "title1": "Total orders",
      "title2": "The 10 most ordered products",
      "items_count": "Products count",
      "item_types_count": "Products (types)"
    },
    "cart_domain": "The domain you specified",
    "cart_domain_available": "is available!",
    "cart_domain_not_available": "is no longer available!",
    "cart_domain_not_available_title": "Domain not available",
    "pages_to_delete": "The following pages will be removed:",
    "page_to_delete": "Page",
    "sales_doc_reverse_charge": "The invoice is issued without VAT, as the reverse charge procedure applies in this case. VAT must be declared and paid by the recipient of the service.",
    "invoice_for_service": "Invoice for service",
    "phone_invalid_country": "Country code of the telephone number is invalid",
    "mobile_phone_invalid_country": "Country code of the mobile phone number is invalid",
    "fax_invalid_country": "The country code of the fax number is invalid",
    "search_campaign": "Search for campaigns",
    "coupon_usage": "Voucher use",
    "coupon_balance_title": "Coupon balance sheet",
    "get_coupon_balance": "Query remaining amount",
    "total_coupon_budget": "Total budget:",
    "remaining_coupon_budget": "Remaining budget:",
    "spent_coupon_budget": "Spent budget:",
    "coupon_balance_success": "Query successful",
    "upload_print_data_previews": "Upload print data preview images",
    "uploaded_print_data_previews": "Uploaded print data Preview images",
    "confirm_upload": "Confirm",
    "image_minimum_size": "Picture does not correspond to the minimum size!",
    "data_policy_info": "Data protection and imprint",
    "fill_data_policy_before_save": "Please complete the data protection and legal notice information before saving. You can find these in the settings",
    "subscription_liu_subscribe": "Subscribe",
    "subscription_liu_cancel": "Cancellation",
    "subscription_liu_extend": "Extend",
    "subscription_status_changed_to_active": "Status changed to active!",
    "subscription_status_changed_to_inactive": "Status changed to inactive!",
    "sub_start_end": "The start date cannot be greater than the end date!",
    "subscription_liu_send": "Sending",
    "subscription_to_client": {
      "subscription_not_available": "Please choose a subscription!",
      "type_parameter_not_present": "The parameter %type% is missing",
      "date_parameter_not_present": "The %type% parameter is missing",
      "end_date_greater_then_start_date": "The end date cannot be greater than the start date!",
      "end_date_less_then_first_day_of_next_month": "The end date cannot be earlier than the first date of the next month!"
    },
    "subscription_billing_period": "Accounting period",
    "mail_greeting_female": "Hello Mrs",
    "mail_greeting_male": "Hello Mr",
    "mail_greeting_first_name_form": "Hello",
    "mail_preview": "E-mail preview",
    "mail_preview_update": "Start/update preview",
    "mail_preview_close": "Close",
    "mail_preview_hint": "Please note that the preview is only for checking the layout and messages. All templates contain dummy data.",
    "tracking_details": "Click on the tracking number for more information",
    "ups_tracking_title": "Shipment tracking via API (UPS)",
    "ups_tracking_button": "Query status",
    "tracking_informations": "Consignment information",
    "ups_tracking_status": "Status of your shipment (UPS)",
    "shipment_package": "Package",
    "shipment": "Programme",
    "shipment_package_current_status": "Current status",
    "shipment_package_current_status_description": "Description of the",
    "shipment_package_delivery_date": "Delivered on",
    "shipment_package_delivery_location": "Receive where",
    "shipment_package_delivery_receivedBy": "Receiving from",
    "tracking_error": "An error has occurred during the query!",
    "1": "<strong>Fehler beim Einloggen</strong>",
    "website_title": "Title for website (search engines)",
    "basic_billing_settings": "Basic billing settings",
    "currency_and_vat": "Currency and VAT",
    "basic_client_settings": "Basic settings",
    "client_settings_info_text": {
      "info_text_phone": "To enter your landline number correctly, please enter the full number including the country code. Make sure that you do not use any spaces or special characters. Example for Germany: +49XXXXXXXXXXX.",
      "info_text_mobile": "To enter your mobile number correctly, please enter the full number including the country code. Make sure that you do not use any spaces or special characters. Example for Germany: +49XXXXXXXXXXX.",
      "info_text_demo": "Activate the demo mode, e.g. to carry out test orders.",
      "info_text_image_rights_query": "If you activate this option, an additional prompt will be displayed each time you upload an image. You will then be asked to confirm that you own the rights of use for the uploaded image. This helps us to ensure that all uploaded images are legally sound and do not infringe any copyrights.",
      "info_text_pdf_download_option": "Activates the PDF download option in the editor.",
      "info_text_needs_content_authorization": "If you activate the content check, all orders placed by your company members must be checked and confirmed manually.",
      "info_text_main_language": "Select the language in which you mainly want to use the application. By selecting a language, we customise the user interface to your needs.",
      "info_text_password_renew": "If you activate this option, we will send the user a password renewal email the next time they log in",
      "info_text_editor_auto_save": "If you activate this option, the editor automatically saves your changes every 5 minutes."
    },
    "content_check_status_rejected": "Rejected",
    "content_check_status_approved": "authorised",
    "cart_remaining_user_budget": "Remaining user budget",
    "cart_remaining_user_budget_after_order": "Remaining user budget after the order",
    "cart_budget_user": "User budget",
    "archived_product_templates": "Archived templates",
    "archived_product_templates_description": "Here you can find all archived templates",
    "archive_template": "Archive",
    "restore_template": "Restore",
    "budget_value_used": "Utilised",
    "budget_value_reserved": "Reserved",
    "budget_value_residual": "Residual value",
    "double_opt_in": "Double opt-in consent procedure",
    "export_video": "Export video",
    "available_actions": "Available actions",
    "mail_text_rhenus": {
      "support": "FAQ & Help",
      "login": "Login",
      "thank_you": "Thank you for your order!",
      "order_foreign_status": {
        "in_delivery": {
          "explanation_first_name_form": "Your order has been produced and handed over to the courier for delivery.",
          "possible_actions_tracking_first_name_form": "Track your delivery at",
          "possible_actions": "Under <a href='%domain%'><span class='r_url bold'>%domain%</span></a> you can view your order at any time."
        }
      },
      "order_item_sales": {
        "explanation": "The details of your order are listed below:",
        "possible_actions": "Under <a href='%domain%'><span class='r_url bold'>%domain%</span></a> you can view your order at any time.",
        "call_to_action": "Order details"
      },
      "order_content_check_status": {
        "explanation": "Thank you for your order. The order has been sent after the content check %status%.",
        "possible_actions": "Under <a href='%domain%'><span class='r_url bold'>%domain%</span></a> you can view your order at any time.",
        "call_to_action": "Order details"
      },
      "possible_actions_default": "Under <a href='%domain%'><span class='r_url bold'>%domain%</span></a> you can view your order at any time.",
      "call_to_action_order_details_default": "Order details",
      "contact_form_edition": {
        "subject": "New contact",
        "info": "Notification of the submission of a contact form. Please process this immediately and ensure that the customer receives the best possible support.",
        "form_fields": "Completed form fields"
      },
      "contact_form_cms": {
        "subject": "New contact from %name%",
        "info": "Notification of the submission of a contact form. Please process this immediately and ensure that the customer receives the best possible support."
      },
      "password_renew": {
        "greeting": "Hello %email%,",
        "explanation_first_name_form": "You have tried to log in to %platformName%. Your password has expired. You must set a new password to be able to log in in the future."
      },
      "password_reset": {
        "greeting": "Hello %email%,",
        "explanation_first_name_form": "A new password has just been requested from %platformName% via our platform. If you have made this request, click on the link below."
      }
    },
    "authorized": "Authorised",
    "open_in_new_tab": "Open",
    "subscription_set_availability_period": "Set the availability period of the subscription",
    "failed_video_generation": "Video could not be generated! Please check if the uploaded files are valid!",
    "video_uploads_missing": "No video uploads found in edition blocks!",
    "subscription_video_missing": "The video subscription was not found!",
    "download_invoices": "Download invoices",
    "download_user_list": "Download user list",
    "cookie_management": "Cookie consent manager",
    "cookie_manager_id": "Cookie Manager URL (optional) - ccm19.de",
    "supported_cookie_manger_provider": "Only Cookie Constent Managers from ccm19.de are currently supported.",
    "cookie_management_custom": "This setting is optional. A cookie consent manager is integrated into your website by default.",
    "payment_options": "Paypal / credit card / direct debit",
    "default_logo_size": "Email logo. Please note that a logo size of ...px x 175px (H x W) is required for the standard email layout. The 'Mail logo' option must also be activated.",
    "lead_authorization": {
      "authorization": "Authorisation",
      "status": "Status",
      "approved": "authorised",
      "rejected": "Rejected",
      "pending": "Outstanding",
      "type": "Type"
    },
    "loading": "Loading",
    "no_data": "No data",
    "whiteLabel": "WhiteLabel",
    "widget_login_stats": {
      "widget_title": "Login statistics",
      "number_of_logins_label_title": "Number of registrations"
    },
    "widget_edition_stats": {
      "widget_title": "Output statistics",
      "social_media_downloads_label_title": "Social media downloads",
      "total_editions_label_title": "Total expenditure",
      "amount_of_new_edition_per_date": "Number of new issues per date",
      "amount_of_new_edition_per_template_per_date": "Number of new issues per template"
    },
    "widget_item_stats": {
      "widget_title": "Article statistics",
      "used_coupons_title": "Vouchers used",
      "turnovers_title": "Turnover",
      "for_items": "for articles",
      "total_used_coupons_count_label_title": "Total vouchers used",
      "total_turnovers_count_label_title": "Total sales",
      "total_used_coupons_value_label_title": "Total value of the vouchers used",
      "total_turnovers_value_label_title": "Total value of sales"
    },
    "pipeline_owner": "Owner",
    "set_to_null": "Set owner to zero"
  }
}
